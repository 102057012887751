import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Background from '../../images/bg-user-profile.svg'
import BackgroundProfile from '../../images/background-profile.svg'
import { ReactComponent as IcDownload } from '../../images/download.svg'
import User from '../../images/user-1.svg'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

class ProfileCard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      user: this.props.user,
      work: this.props.work
    }
  }

  render () {
    return (
      <div className='card bg-white border-none text-left pb-0 mb-3 rounded-lg'>
        <div className='card-header white-br-bottom rounded-lg-top' style={{ height: '200px', backgroundImage: `url(${BackgroundProfile})`, objectFit: 'cover' }} />
        <div className='card-body text-left pt-0'>
          <div className='row'>
            <div style={{ transform: 'translateY(-35px)' }} className='col-lg-3 col-xs-12 d-flex justify-content-center'>
              <img src={this.state.user.image_url ?? User} alt='company logo' className='avatar-xl rounded-circle mx-4' />
            </div>
            <div className='mb-2 mt-4 col-lg-8 col-xs-12 '>
              <b className='lg-font'>
                {this.state.user.name}
              </b>
              <br />
              {
                this.state.work && (
                  <p className='py-2 mb-0'>
                    <span className='d-block normal-font'>{this.state.work.job_position} at </span>
                    <span className='normal-font company-address'>{this.state.work.company}</span>
                  </p>
                )
              }
              <div className='normal-font'>
                <span className='pr-2 v1'><b>{this.state.user.profile_views.users}</b> Profile Views</span>
                <span className='pl-2'><b>{this.state.user.profile_views.company}</b> Viewed by Company</span>
              </div>
            </div>
            {/* <div className='ml-auto mt-4'>
              <button type='button' className='w-150px normal-font rounded-pill btn btn-recruiter-primary'>
                <IcDownload className='hc-ic mr-2 svg-white' />Download CV
              </button>
            </div> */}
          </div>
        </div>
        <div className='card-footer bg-transparent' style={{ padding: '.75rem 0.25rem' }}>
          <div className='d-flex'>
            <div className='p-2'>
              <Link to='/profile/edit' className='w-150px rounded-pill btn btn-recruiter-primary'>Edit Profile</Link>
            </div>
            <div className='ml-auto p-2'>
              <div className='d-flex h-100 align-items-center text-orange normal-font font-weight-medium'>
                <IcDownload className='hc-ic mr-2' />Download CV
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ProfileCard.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error,
  invalid: state.invalid
})

export default withRouter(connect(mapStateToProps, { })(ProfileCard))
