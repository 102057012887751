import React, { Component } from 'react'
import Logo from '../../images/hackalab-logo white 1.svg'

class AuthCard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLogoHidden: this.props.isLogoHidden ?? false,
      title: this.props.title,
      footer: this.props.footer
    }
  }

  render () {
    return (
      <div className='card-regist text-center'>
        {
            this.state.isLogoHidden ? null : (
              <div className='mb-4'>
                <img src={Logo} className='App-logo' alt='hacklab' />
              </div>
            )
        }
        <div className='card border-none text-left shadow p-3 pb-0 mb-2 bg-white rounded-lg'>
          <div className='card-body'>
            {this.state.title ? (<h5><b>{this.state.title}</b></h5>) : null}
            {this.props.children}
          </div>
        </div>
        {this.state.footer}
      </div>
    )
  }
}

export default AuthCard
