import { SET_CURRENT, SEARCH_JOBS, SET_JOBS, SET_SUGGESTED_COMPANY } from '../actions/responseTypes'

const initState = {}

export const responseDashboard = (state = initState, action) => {
  switch (action.type) {
    case SET_SUGGESTED_COMPANY:
      return { ...state, suggestedCompany: action.payload }
    case SET_JOBS:
      return { ...state, jobs: action.payload }
    case SEARCH_JOBS: 
      return { ...state, search: action.payload}
    case SET_CURRENT:
      return { ...state, current: action.payload}
    default:
      return state
  }
}

const ResponseDashboard = {
  responseDashboard,
}

export default ResponseDashboard
