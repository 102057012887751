import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FileDrop } from 'react-file-drop'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import User from '../../images/user-1.svg'
import { ReactComponent as IcCamera } from '../../images/camera.svg'
import { ReactComponent as IcPlus } from '../../images/plus-rect.svg'
import { ReactComponent as IcClose } from '../../images/close.svg'
import { ReactComponent as IcUploadCV } from '../../images/uploadCV.svg'
import {
  CustomDate,
  EditButton,
  HomeLanding,
  CustomInput,
  CustomMultiSelect,
  CustomSelect,
  CustomTextarea,
  CustomCheckbox
} from '../../components'
import {
  getProvince,
  getCity,
  getMaritalStatus,
  getSkillInterest,
  getJobRole,
  getIndustry,
  uploadProfilePicture,
  uploadProfileCV,
  userAction,
  updateProfile,
  getDegree
} from '../../actions'
import {
  GET_SKILL_INTEREST,
  GET_JOB_ROLE,
  GET_INDUSTRY,
  SET_PROFILE_PICTURE,
  GET_MARITAL_STATUS,
  GET_PROVINCE,
  GET_CITY,
  UPDATE_PROFILE,
  GET_EDUCATION_DEGREE
} from '../../actions/responseTypes'
import moment from 'moment'
import { RULES_PROFILE, Validator } from '../../rules'
import { alertErrorValidation, captionErrorValidation } from '../../utils'

const eduTemp = {
  degree: '',
  school: '',
  start_date: moment(new Date()).format('YYYY-MM-DD'),
  end_date: moment(new Date()).format('YYYY-MM-DD'),
  currently_study_here: 0,
  description: ''
}

const jobTemp = {
  job_position: '',
  company: '',
  start_date: moment(new Date()).format('YYYY-MM-DD'),
  end_date: moment(new Date()).format('YYYY-MM-DD'),
  currently_work_here: 0,
  job_description: ''
}


const UploadCV = styled.div`
  height: 150px;
  padding:20x;
  background: #EFEFEF;
  border: 1px dashed #939393;
  box-sizing: border-box;
  border-radius: 20px;
  width: 100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 600px) {
    height: 230px;
  }
`

const TextCV = styled.div`
  display:flex;
  margin-top:10px;
  .line{
    color: #EE852C;
    border-bottom:1px solid #EE852C;
  }
  @media only screen and (max-width: 600px) {
    display:flex;
    margin-top:10px;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    text-align: center;
  }
`
class EditProfile extends Component {
  constructor(props) {
    super(props)
    const defaultForm = {
      phone_number: props.thisUser.phone ?? '',
      fullname: props.thisUser.name,
      province: props.thisUser.province ?? '',
      city: props.thisUser.city ?? '',
      marital_status: props.thisUser.marital_status ?? '',
      job_role: props.thisUser.job_role.map((item) => item.id) ?? [],
      industry_category: props.thisUser.industry_category.map((item) => item.id) ?? [],
      skill_interest: props.thisUser.skill_interest.map((item) => item.id) ?? [],
      about_you: props.thisUser.about_you ?? '',
      work_experience: (props.thisUser.work_experience.length > 0) ? props.thisUser.work_experience : [jobTemp],
      educational_background: (props.thisUser.educational_background.length > 0) ? props.thisUser.educational_background : [eduTemp]
    }
    this.state = {
      items: [1, 2, 3, 4, 5],
      image: null,
      imageCV: props.thisUser?.user_has_resume ? props.thisUser.user_has_resume.file_name : null,
      file: null,
      fileCV: null,
      form: defaultForm,
      validation: {},
      avatarUpdated: false,
      cvUpdated: false,
      error: false,
      loading: false,
      status: [
        { text: 'Loading...', id: 1 },
      ],
      provinces: [
        { name: 'Loading...', id: 1 },
      ],
      cities: [
        { name: 'Loading...', id: 1 },
      ],
      degree: [
        { text: 'Loading...', id: 1 },
      ],
      job_roles: [],
      categories: [],
      skills: []
    }
    this.onChangeFile = this.onChangeFile.bind(this)
    this.onChangeFileCV = this.onChangeFileCV.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleFormValidation = this.handleFormValidation.bind(this)
    this.onAddEducation = this.onAddEducation.bind(this)
    this.onAddJob = this.onAddJob.bind(this)
    this.onRemoveJob = this.onRemoveJob.bind(this)
    this.onRemoveEducation = this.onRemoveEducation.bind(this)
  }

  componentDidMount = async () => {
    await this.props.getJobRole()
    await this.props.getIndustry()
    await this.props.getSkillInterest()
    await this.props.getProvince()
    await this.props.getMaritalStatus()
    await this.props.getDegree()
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.error !== this.props.error && this.props.error) {
      alertErrorValidation(this.props.error)
      const check = captionErrorValidation(this.props.error, RULES_PROFILE)
      this.setState({ loading: false, validation: check })
    }

    if (prevProps.thisUser !== this.props.thisUser && this.props.thisUser) {
      const link = this.props.thisUser?.user_has_resume ? this.props.thisUser.user_has_resume.file_name : null;
      this.setState({ imageCV: link });
    }
    if (prevProps.response !== this.props.response && this.props.response) {
      switch (this.props.response.type) {
        case GET_JOB_ROLE:
          this.setState({ job_roles: this.props.response.data ?? [] })
          break;
        case GET_SKILL_INTEREST:
          this.setState({ skills: this.props.response.data ?? [] })
          break;
        case GET_INDUSTRY:
          this.setState({ categories: this.props.response.data ?? [] })
          break;
        case GET_MARITAL_STATUS:
          this.setState({ status: this.props.response.data ?? [] })
          break;
        case GET_PROVINCE:
          this.setState({ provinces: this.props.response.data ?? [] })
          await this.props.getCity(this.props.thisUser.province ?? "")
          break;
        case GET_CITY:
          this.setState({ cities: this.props.response.data.cities ?? [] })
          break;
        case GET_EDUCATION_DEGREE:
          this.setState({ degree: this.props.response.data ?? [] })
          break;
        case SET_PROFILE_PICTURE:
          let user = this.props.response.data.userdata
          user.user_detail.profile_picture_link = this.props.response.data.status_table.profile_picture_link
          await this.props.userAction(user)
          this.setState({ loading: false }, () => {
            toast.success('Data successfully changed!', {
              position: toast.POSITION.TOP_CENTER
            })
          })
          this.props.history.push('/profile')
          break;
        case UPDATE_PROFILE:
          await this.props.userAction(this.props.response.data)
          if (!this.state.avatarUpdated) {
            this.setState({ loading: false }, () => {
              toast.success('Data successfully changed!', {
                position: toast.POSITION.TOP_CENTER
              })
            })
            this.props.history.push('/profile')
          }
          break;
        default:
          break;
      }
    }
  }

  onChangeFile = async (ev) => {
    ev.stopPropagation()
    ev.preventDefault()
    const file = ev.target.files[0]
    if (file !== null) {
      this.setState({ image: URL.createObjectURL(file), avatarUpdated: true, file: file })
    }
  }

  onChangeFileCV = async (ev) => {
    ev.stopPropagation()
    ev.preventDefault()
    const file = ev.target.files[0]
    if (file !== null) {
      this.setState({ imageCV: URL.createObjectURL(file), cvUpdated: true, fileCV: file })
    }
  }


  onDragFileCV = async (ev) => {
    const file = ev[0]
    if (file !== null) {
      this.setState({ imageCV: URL.createObjectURL(file), cvUpdated: true, fileCV: file })
    }
  }

  onAddEducation(e) {
    e.preventDefault()
    const edus = this.state.form.educational_background
    // if (edus.length >= 5) {
    //   return
    // }
    edus.push(eduTemp)
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        educational_background: edus
      }
    }))
  }

  onRemoveEducation(i) {
    const edus = this.state.form.educational_background
    edus.splice(i, 1)
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        educational_background: edus
      }
    }))
  }

  onAddJob(e) {
    e.preventDefault()
    const jobs = this.state.form.work_experience
    // if (jobs.length >= 5) {
    //   return
    // }
    jobs.push(jobTemp)
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        work_experience: jobs
      }
    }))
  }

  onRemoveJob(i) {
    const jobs = this.state.form.work_experience
    jobs.splice(i, 1)
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        work_experience: jobs
      }
    }))
  }

  handleInputChange = (name, value) => {
    let tmp = name.split('.')
    let result = value
    let isSave = true
    switch (tmp[0]) {
      case 'work_experience': case 'educational_background':
        result = JSON.parse(JSON.stringify(this.state.form[tmp[0]]))
        if (tmp[2] === 'start_date') {
          let end_date = moment(result[parseInt(tmp[1])]['end_date'])
          let new_start = moment(value)
          if (end_date.diff(new_start) >= 0) {
            result[parseInt(tmp[1])][tmp[2]] = value
          } else {
            toast.error('the start date field must before end date field', {
              position: toast.POSITION.TOP_CENTER
            })
            let validation = this.state.validation
            validation[name] = ['the start date field must before end date field']
            this.setState({ validation: validation })
            isSave = false

            return false
          }
        } else if (tmp[2] === 'end_date') {
          let start_date = moment(result[parseInt(tmp[1])]['start_date'])
          let new_end = moment(value)
          if (new_end.diff(start_date) > 0) {
            result[parseInt(tmp[1])][tmp[2]] = value
          } else {
            toast.error('the end date field must after start date field', {
              position: toast.POSITION.TOP_CENTER
            })
            let validation = this.state.validation
            validation[name] = ['the end date field must after start date field']
            this.setState({ validation: validation })
            isSave = false

            return false
          }
        } else {
          result[parseInt(tmp[1])][tmp[2]] = value
          if (tmp[2] === 'currently_work_here') {
            result[parseInt(tmp[1])].end_date = (value == 0) ? moment(new Date()).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD')
          }
          if (tmp[2] === 'currently_work_here') {
            result[parseInt(tmp[1])].end_date = (value == 0) ? moment(new Date()).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD')
          }
        }

        break
      default:
        if (name === 'province') {
          this.props.getCity(value)
        }
        break
    }

    if (isSave) {
      this.setState(prevState => ({
        form: {
          ...prevState.form,
          [tmp[0]]: result
        }
      }))
    }
  }

  handleFormValidation = async (event) => {
    event.preventDefault()
    let rules = RULES_PROFILE

    let validation = new Validator(this.state.form, rules);
    let isValid = validation.passes();
    let errors = validation.errors.all()
    this.setState({ validation: errors })
    if (isValid) {
      this.setState({ loading: true })
      await this.props.updateProfile(this.state.form)
      if (this.state.avatarUpdated) {
        const reader = new FileReader()
        reader.readAsDataURL(this.state.file)
        reader.onload = async () => {
          let base64Image = reader.result.split(',')[1]
          await this.props.uploadProfilePicture({ image: base64Image })
        }
        reader.onerror = function (error) {
          toast.error("Failed to update avatar", {
            position: toast.POSITION.TOP_CENTER
          })
        }
      }
      if (this.state.cvUpdated) {
          const form = new FormData();
          form.append('resume',this.state.fileCV);
          await this.props.uploadProfileCV(form);
      }
    } else {
      Object.keys(errors).forEach(function (key) {
        errors[key].forEach((item) => {
          toast.error(item, {
            position: toast.POSITION.TOP_CENTER
          })
        })
      });
    }

  }

  render() {

    return (
      <HomeLanding>
        <form onSubmit={this.handleFormValidation} className='container-padding'>
          <div className='card bg-white border-none text-left pb-0 mb-3 rounded-lg'>
            <div className='card-header'>
              <h6 className='pt-2'><b>Personal Information </b></h6>
            </div>
            {/* <ul className='list-group list-group-flush'>
              <li className='list-group-item' style={{ height: '200px', backgroundImage: `url(${Background})`, objectFit: 'cover' }}>
                <EditButton />
              </li>
            </ul> */}
            <div className='card-body text-center pt-0'>
              <div>
                <div className='d-flex justify-content-center'>
                  <input
                    id='myInput'
                    type='file'
                    accept='image/*'
                    ref={(ref) => {
                      return this.upload = ref
                    }}
                    style={{ display: 'none' }}
                    onChange={this.onChangeFile}
                  />

                  <div onClick={() => { !this.state.loading && this.upload.click() }} className='my-3 position-relative fit-width'>
                    <img src={this.state.image ?? this.props.thisUser.image_url ?? User} className={`avatar bg-secondary ${!this.state.loading ? 'scale-down cursor-pointer' : 'img-cover'} rounded-circle`} alt='icon' />
                    <div>
                      <IcCamera className='hc-ic camera-ic svg-white' />
                    </div>
                  </div>
                </div>

                <div className='row text-left medium-font mb-2' style={{ marginTop: '8px' }}>
                  <div className='col-md-8 offset-md-2'>
                    <div className='form-group'>
                      <label className='pl-3 normal-font font-weight-medium'>Enter Username</label>
                      <CustomInput
                        validation={this.state.validation['fullname']}
                        placeholder="Enter Username"
                        value={this.state.form.fullname}
                        onChange={this.handleInputChange}
                        name="fullname"
                        id="fullname"
                      />
                    </div>
                    <div className='form-group'>
                      <label className='pl-3 normal-font font-weight-medium'>Phone Number</label>
                      <CustomInput
                        validation={this.state.validation['phone_number']}
                        placeholder="Enter Phone Number"
                        value={this.state.form.phone_number}
                        onChange={this.handleInputChange}
                        name="phone_number"
                        id="phone_number"
                      />
                    </div>
                    <div className='form-row'>
                      <div className='col-md-12'>
                        <label className='pl-3 normal-font font-weight-medium'>Work Location</label>
                      </div>
                      <div className='form-group col-md-6'>
                        <CustomSelect
                          validation={this.state.validation['province']}
                          value={this.state.form.province}
                          data={this.state.provinces}
                          onChange={this.handleInputChange}
                          placeholder='Choose a province'
                          displayValue={'name'}
                          name="province"
                          id="province"
                        />
                      </div>
                      <div className='form-group col-md-6'>
                        <CustomSelect
                          disabled={(this.state.form.province === '')}
                          validation={this.state.validation['city']}
                          value={this.state.form.city}
                          data={this.state.cities}
                          onChange={this.handleInputChange}
                          displayValue={'name'}
                          placeholder='Choose a city'
                          name="city"
                          id="city"
                        />
                      </div>
                    </div>
                    <div className='form-group'>
                      <label className='pl-3 normal-font font-weight-medium'>Marital Status</label>
                      <CustomSelect
                        validation={this.state.validation['marital_status']}
                        value={this.state.form.marital_status}
                        data={this.state.status}
                        onChange={this.handleInputChange}
                        placeholder='Choose a marital status'
                        name="marital_status"
                        id="marital_status"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='card bg-white border-none text-left mb-3 rounded-lg'>
            <div className='card-header'>
              <h6 className='pt-2'><b>Job & Skill Interest </b></h6>
            </div>
            <div className='card-body text-center pt-0'>
              <div>
                <div className='row text-left medium-font mb-2'>
                  <div className='col-md-8 offset-md-2'>
                    <div className='form-group'>
                      <label className='pl-3 normal-font font-weight-medium'>Job Role</label>
                      <CustomMultiSelect
                        showCheckbox
                        validation={this.state.validation['job_role']}
                        avoidHighlightFirstOption
                        closeOnSelect={false}
                        selectedValues={this.state.form.job_role}
                        options={this.state.job_roles}
                        onChange={this.handleInputChange}
                        displayValue='text'
                        name='job_role'
                        id='job_role'
                        placeholder='Choose a job role category'
                      />
                    </div>
                    <div className='form-group'>
                      <label className='pl-3 normal-font font-weight-medium'>Industry Category</label>
                      <CustomMultiSelect
                        showCheckbox
                        validation={this.state.validation['industry_category']}
                        avoidHighlightFirstOption
                        closeOnSelect={false}
                        selectedValues={this.state.form.industry_category}
                        options={this.state.categories}
                        onChange={this.handleInputChange}
                        displayValue='text'
                        name='industry_category'
                        id='industry_category'
                        placeholder='Choose a industry category'
                      />
                    </div>
                    <div className='form-group'>
                      <label className='pl-3 normal-font font-weight-medium'>Skill Interest</label>
                      <CustomMultiSelect
                        showCheckbox
                        validation={this.state.validation['skill_interest']}
                        avoidHighlightFirstOption
                        closeOnSelect={false}
                        selectedValues={this.state.form.skill_interest}
                        options={this.state.skills}
                        onChange={this.handleInputChange}
                        displayValue='text'
                        name='skill_interest'
                        id='skill_interest'
                        placeholder='Choose a skill interest'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='card bg-white border-none text-left rounded-lg'>
            <div className='card-header'>
              <h6 className='pt-2'><b>Background Information </b></h6>
            </div>
            <div className='card-body text-center pt-0'>
              <div>
                <div className='row text-left medium-font mb-2'>
                  <div className='col-md-8 offset-md-2'>
                    <h6 className='pt-4'><b>Summary </b></h6>
                    <hr />
                    <div className='form-group'>
                      <label className='pl-3 normal-font font-weight-medium'>About You</label>
                      <CustomTextarea
                        placeholder='Write about you here'
                        rows={4}
                        name='about_you'
                        id='about_you'
                        value={this.state.form.about_you}
                        validation={this.state.validation['about_you']}
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <h6 className='pt-4'><b>Work Experiences </b></h6>
                    {
                      this.state.form.work_experience.map((item, i) => {
                        return (
                          <div key={`jobs-${i}`}>
                            <hr />
                            <IcClose onClick={() => this.onRemoveJob(i)} className='hc-ic float-right mr-3 cursor-pointer' />

                            <div className='form-group'>
                              <label className='pl-3 normal-font font-weight-medium'>Company Name</label>
                              <CustomInput
                                validation={this.state.validation[`work_experience.${i}.company`]}
                                placeholder="Enter company name"
                                value={item.company ?? ''}
                                onChange={this.handleInputChange}
                                name={`work_experience.${i}.company`}
                              />
                            </div>
                            <div className='form-group'>
                              <label className='pl-3 normal-font font-weight-medium'>Job Position</label>
                              <CustomInput
                                validation={this.state.validation[`work_experience.${i}.job_position`]}
                                placeholder="Enter your job position"
                                value={item.job_position ?? ''}
                                onChange={this.handleInputChange}
                                name={`work_experience.${i}.job_position`}
                                id={`work_experience.${i}.job_position`}
                              />
                            </div>
                            <div className='form-group'>
                              <label className='pl-3 normal-font font-weight-medium'>Job Description</label>
                              <CustomTextarea
                                rows={4}
                                validation={this.state.validation[`work_experience.${i}.job_description`]}
                                placeholder="Enter your job description"
                                value={item.job_description ?? ''}
                                onChange={this.handleInputChange}
                                name={`work_experience.${i}.job_description`}
                                id={`work_experience.${i}.job_description`}
                              />
                            </div>
                            <div className='form-row'>
                              <div className='form-group col-md-6'>
                                <label className='pl-3 normal-font font-weight-medium'>Start Date</label>
                                <CustomDate
                                  validation={this.state.validation[`work_experience.${i}.start_date`]}
                                  value={item.start_date ?? ''}
                                  onChange={this.handleInputChange}
                                  name={`work_experience.${i}.start_date`}
                                  maxDate={new Date()}
                                  showDisabledMonthNavigation
                                />
                              </div>
                              <div className='form-group col-md-6'>
                                <label className='pl-3 normal-font font-weight-medium'>End Date</label>
                                {
                                  (item.currently_work_here == 0) ? (
                                    <CustomDate
                                      validation={this.state.validation[`work_experience.${i}.end_date`]}
                                      value={item.end_date ?? ''}
                                      onChange={this.handleInputChange}
                                      maxDate={new Date()}
                                      name={`work_experience.${i}.end_date`}
                                    />
                                  )
                                    : (
                                      <CustomInput
                                        value={'Present'}
                                        disabled={true}
                                      />
                                    )
                                }
                              </div>
                            </div>
                            <CustomCheckbox
                              validation={this.state.validation[`work_experience.${i}.currently_work_here`]}
                              checked={item.currently_work_here}
                              className='ml-3'
                              value={1}
                              emptyValue={0}
                              name={`work_experience.${i}.currently_work_here`}
                              type="checkbox"
                              onChange={this.handleInputChange}
                              id={`customCheck${i}`}
                              checkboxLabel={'I’m currently work here'}
                            />
                          </div>
                        )
                      })
                    }
                    <div className='d-flex w-100 mt-4 align-items-center justify-content-center'>
                      <button onClick={this.onAddJob} className='btn rounded-lg btn-recruiter-border d-flex align-items-center font-weight-medium'><IcPlus className='hc-ic mr-2' /> Add More Position</button>
                    </div>
                    <h6 className='pt-4'><b>Education </b></h6>
                    {
                      this.state.form.educational_background.map((item, i) => {
                        return (
                          <div key={`education-${i}`}>
                            <hr />
                            <IcClose onClick={() => this.onRemoveEducation(i)} className='hc-ic float-right mr-3 cursor-pointer' />
                            <div className='form-group'>
                              <label className='pl-3 normal-font font-weight-medium'>School Name</label>
                              <CustomInput
                                validation={this.state.validation[`educational_background.${i}.school`]}
                                placeholder="Enter school name"
                                value={item.school}
                                onChange={this.handleInputChange}
                                name={`educational_background.${i}.school`}
                              />
                            </div>
                            <div className='form-group'>
                              <label className='pl-3 normal-font font-weight-medium'>Degree</label>
                              <CustomSelect
                                validation={this.state.validation[`educational_background.${i}.degree`]}
                                value={item.degree}
                                data={this.state.degree}
                                displayValue='text'
                                onChange={this.handleInputChange}
                                placeholder='Please select degree'
                                name={`educational_background.${i}.degree`}
                                id={`educational_background.${i}.degree`}
                              />
                            </div>
                            <div className='form-group'>
                              <label className='pl-3 normal-font font-weight-medium'>Field of Study</label>
                              <CustomInput
                                validation={this.state.validation[`educational_background.${i}.title`]}
                                placeholder="Enter your major name"
                                value={item.title ?? ''}
                                onChange={this.handleInputChange}
                                name={`educational_background.${i}.title`}
                                id={`educational_background.${i}.title`}
                              />
                            </div>
                            <div className='form-group'>
                              <label className='pl-3 normal-font font-weight-medium'>Description</label>
                              <CustomTextarea
                                rows={4}
                                validation={this.state.validation[`educational_background.${i}.description`]}
                                placeholder="Enter your education description"
                                value={item.description ?? ''}
                                onChange={this.handleInputChange}
                                name={`educational_background.${i}.description`}
                                id={`educational_background.${i}.description`}
                              />
                            </div>
                            <div className='form-row'>
                              <div className='form-group col-md-6'>
                                <label className='pl-3 normal-font font-weight-medium'>Start Date</label>
                                <CustomDate
                                  validation={this.state.validation[`educational_background.${i}.start_date`]}
                                  value={item.start_date ?? ''}
                                  onChange={this.handleInputChange}
                                  name={`educational_background.${i}.start_date`}
                                  maxDate={new Date()}
                                  showDisabledMonthNavigation
                                />
                              </div>
                              <div className='form-group col-md-6'>
                                <label className='pl-3 normal-font font-weight-medium'>End Date</label>
                                {
                                  (item.currently_studied_here == 0) ? (
                                    <CustomDate
                                      validation={this.state.validation[`educational_background.${i}.end_date`]}
                                      value={item.end_date ?? ''}
                                      onChange={this.handleInputChange}
                                      maxDate={new Date()}
                                      name={`educational_background.${i}.end_date`}
                                    />
                                  )
                                    : (
                                      <CustomInput
                                        value={'Present'}
                                        disabled={true}
                                      />
                                    )
                                }
                              </div>
                              <CustomCheckbox
                                validation={this.state.validation[`educational_background.${i}.currently_studied_here`]}
                                checked={item.currently_studied_here != 0}
                                className='ml-3'
                                value={1}
                                emptyValue={0}
                                name={`educational_background.${i}.currently_studied_here`}
                                type="checkbox"
                                onChange={this.handleInputChange}
                                id={`customCheckStudy${i}`}
                                checkboxLabel={'I’m currently studied here'}
                              />
                            </div>
                          </div>
                        )
                      })
                    }
                    <div className='d-flex w-100 mt-4 align-items-center justify-content-center'>
                      <button onClick={this.onAddEducation} className='btn rounded-lg btn-recruiter-border d-flex align-items-center font-weight-medium'><IcPlus className='hc-ic mr-2' /> Add More School</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='card bg-white border-none text-left rounded-lg mt-3'>
            <div className='card-header'>
              <h6 className='pt-2'><b>Curriculum Vitae </b></h6>
            </div>
            <div className='card-body text-center pt-0'>
              <div>
                <div className='row text-left medium-font mb-2'>
                  <div className='col-md-8 offset-md-2'>
                    <h6 className='pt-4'><b>Upload File </b></h6>
                    <input
                      id='myInput'
                      type='file'
                      accept='*'
                      ref={(ref) => {
                        return this.CVupload = ref
                      }}
                      style={{ display: 'none' }}
                      onChange={this.onChangeFileCV}
                    />
                    <FileDrop onDrop={this.onDragFileCV}>
                      <UploadCV>
                          <div className="text-center" onClick={()=>this.CVupload.click()}>
                            <IcUploadCV />
                            <TextCV><div className="line">Choose file </div>&nbsp; or drag & drop your file here (max. 3 Mb)</TextCV>
                          </div>
                          {this.state.imageCV ? <a target="_blank" className="mt-3" href={this.state.imageCV} >{this.state.imageCV}</a> : null}
                      </UploadCV>
                    </FileDrop>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row py-5'>
            <div className='col-md-3 offset-md-3 pb-3'>
              <button type='button' className='normal-font form-control py-4 btn btn-recommendation-reverse rounded-pill'>Reset</button>
            </div>
            <div className='col-md-3 '>
              <button type='submit' className='normal-font form-control py-4 btn btn-primary rounded-pill'>
                {
                  !this.state.loading ? (<div>Save Changes</div>) :
                    (
                      <div>
                        <span className="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>
                        Loading...
                      </div>
                    )
                }
              </button>
            </div>
          </div>
        </form>
      </HomeLanding>
    )
  }
}

EditProfile.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  thisUser: state.thisUser,
  auth: state.auth,
  error: state.error,
  invalid: state.invalid,
  response: state.response
})

const mapStateToDispatch = {
  uploadProfilePicture,
  userAction,
  getJobRole,
  getIndustry,
  getSkillInterest,
  getMaritalStatus,
  getProvince,
  getCity,
  updateProfile,
  getDegree,
  uploadProfileCV
}

export default withRouter(connect(mapStateToProps, mapStateToDispatch)(EditProfile))
