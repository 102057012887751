import React, { Component } from 'react'
import Logo from '../../images/hackalab-logo 1.svg'
import LogoWhite from '../../images/hackalab-logo white 1.svg'
import LogoMobile from '../../images/logo-mobile.svg'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ReactComponent as IcHamburger } from '../../images/hamburger.svg'
import { ReactComponent as IconHome } from '../../images/home.svg'
import { ReactComponent as IconAssessment } from '../../images/assesment.svg'
import { ReactComponent as IconJob } from '../../images/suitcase.svg'
import { ReactComponent as IconCalendar } from '../../images/calendar-grey.svg'
import { ReactComponent as IconCompany } from '../../images/building.svg'
import { ReactComponent as IconUsers } from '../../images/users.svg'
import { ReactComponent as IconLogout } from '../../images/logout.svg'
import { ReactComponent as IcArrowDown } from '../../images/arrow-dropdown.svg'
import { logoutUser, getUser } from '../../actions';
import { setSearchJobs } from '../../actions/dashboard'
import User from '../../images/user-1.svg'
import Company from '../../images/building2.svg'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { alertErrorValidation } from '../../utils';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import MediaQuery, { useMediaQuery } from 'react-responsive'

class NavBar extends Component {
  constructor(props) {
    super(props)
    const company = JSON.parse(localStorage.getItem('company'))
    this.state = {
      company: company,
      loading: false,
      user: this.props.thisUser,
      search: this.props.search ?? true,
      isRecruiter: company !== null ?? this.props.isRecruiter ?? false,
      isPaneOpen: false,
      showMenu: false,
    }

    this.handlerLogout = this.handlerLogout.bind(this)
    this.handleCompanyChange = this.handleCompanyChange.bind(this)
  }

  componentDidMount = async () => {
    if (this.props.thisUser && this.props.thisUser.job_role.length === 0) {
      this.props.history.push('/category')
    } else if (this.props.thisUser && this.props.thisUser.skill_interest.length === 0) {
      this.props.history.push('/skill')
    }
    await this.props.getUser()
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.error !== this.props.error && this.props.error) {
      alertErrorValidation(this.props.error)
      this.setState({ loading: false })
    }
  }

  handlerLogout = async () => {
    await this.props.logoutUser()
  }

  handleCompanyChange = async (item) => {
    if (item) {
      localStorage.setItem('company', JSON.stringify(item))
      this.props.history.push('/my-company')
    } else {
      localStorage.removeItem('company')
      this.props.history.push('/home')
    }
    this.setState({ isRecruiter: (item !== null), isPaneOpen: false })
  }

  onClickMenu = () => {
    this.setState({ isPaneOpen: false })
  }

  searchCallback = (e) => {
    this.props.setSearchJobs(e.target.value)
  }

  onEnterSearchCallback = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.props.onEnterSearch()
    }
  }

  onClickSearchCallback = (e) => {
    e.preventDefault()
    this.props.onClickSearch()
  }

  render() {
    const colorHover = this.state.isRecruiter ? 'color-recruiter-navbar' : 'color-navbar'
    const dropdownHover = this.state.isRecruiter ? 'recruiter' : ''

    return (
      <nav className='navbar fixed-top nav-home navbar-expand-lg navbar-light bg-light'>
        <div className='container' style={{ flexWrap: "nowrap" }}>
          {/* <div className='row full-width'> */}
          {/* <div className='col-12'> */}
          <MediaQuery minWidth={1024}>
            <Link className='navbar-brand' to={this.state.isRecruiter ? '/my-company' : '/home'}>
              <img src={Logo} className='App-logo' alt='hacklab' />
            </Link>
          </MediaQuery>
          <MediaQuery maxWidth={1024}>
            <Link className='navbar-brand mr-0' to={this.state.isRecruiter ? '/my-company' : '/home'}>
              <img src={LogoMobile} className='App-logo' alt='hacklab' style={{ width: "100px", marginLeft: '-1.4rem' }} />
            </Link>
          </MediaQuery>
          {/* </div> */}
          {
            this.state.search
              ? (
                <div className='' style={{ width: "80%" }}>
                  <MediaQuery maxWidth={1024}>
                    <form className='form-inline my-2 my-lg-0 search-form' style={{ width: "80%" }} onKeyDown={this.onEnterSearchCallback} onChange={this.searchCallback} >
                      <input onChange={this.searchCallback} type='search' placeholder='Search' aria-label='Search' style={{ width: "80%" }} />
                      <button onClick={this.onClickSearchCallback} tabIndex='0' onKeyDown={this.onEnterSearchCallback} className='icon_search' />
                    </form>
                  </MediaQuery>
                  <MediaQuery minWidth={1024}>
                    <div className='form-inline my-2 my-lg-0 ml-4 search-form-dekstop search-form' style={{ width: "70%" }} onKeyDown={this.onEnterSearchCallback} onChange={this.searchCallback}>
                      <input onChange={this.searchCallback} type='search' placeholder='Search' aria-label='Search' />
                      <button onClick={this.onClickSearchCallback} tabIndex='0' onKeyDown={this.onEnterSearchCallback} className='icon_search' />
                    </div>
                  </MediaQuery>
                </div>
              )
              : null
          }
          <IcHamburger onClick={() => { this.setState({ isPaneOpen: true }) }} className='d-lg-none mr-2' />
          <div className={`col-5 d-lg-block d-none ${this.state.search ? '' : 'offset-4'}`}>
            <ul className='navbar-nav float-right'>
              {
                this.state.isRecruiter
                  ? (
                    <>
                      <li className='nav-item' key='home'>
                        <Link to='/my-company' className={`${this.props.location.pathname === '/my-company' ? 'active' : ''} nav-link medium-font font-weight-medium ${colorHover} d-flex align-items-center justify-content-center`} >
                          <IconHome className='mr-1' /> Home
                        </Link>
                      </li>
                      <li className='nav-item' key='jobs-listing'>
                        <Link className={`${this.props.location.pathname === '/recruiter/jobs' ? 'active' : ''} nav-link medium-font font-weight-medium ${colorHover} d-flex align-items-center justify-content-center`} to='/recruiter/jobs'>
                          <IconJob className='mr-1' /> Jobs Listing
                        </Link>
                      </li>
                    </>
                  )
                  : (
                    <>
                      <li className='nav-item' key='home'>
                        <Link to='/home' className={`${this.props.location.pathname === '/home' ? 'active' : ''} nav-link medium-font font-weight-medium ${colorHover} d-flex align-items-center justify-content-center`} >
                          <IconHome className='mr-1' /> Home
                        </Link>
                      </li>
                      {/* <li className='nav-item' key='bctn'>
                            <Link to='/bctn' className={`${this.props.location.pathname === '/bctn' ? 'active' : ''} nav-link medium-font font-weight-medium ${colorHover} d-flex align-items-center justify-content-center`} >
                              <IconCalendar className='mr-1' /> BCTN2.0
                            </Link>
                          </li> */}
                      <li className='nav-item' key='jobs'>
                        <Link className={`${this.props.location.pathname === '/jobs' ? 'active' : ''} nav-link medium-font font-weight-medium ${colorHover} d-flex align-items-center justify-content-center`} to='/jobs'>
                          <IconJob className='mr-1' /> Jobs
                        </Link>
                      </li>
                      <li className='nav-item' key='companies'>
                        <Link className={`${this.props.location.pathname === '/companies' ? 'active' : ''} nav-link medium-font font-weight-medium ${colorHover} d-flex align-items-center justify-content-center`} to='/companies'>
                          <IconCompany className='mr-1' /> Companies
                        </Link>
                      </li>
                      <li className='nav-item' key='assessment'>
                        <Link to='/assesment-search' className={`${this.props.location.pathname === '/assesment-search' ? 'active' : ''}  nav-link medium-font font-weight-medium ${colorHover} d-flex align-items-center justify-content-center`} >
                          <IconAssessment className='mr-1' /> Assessment
                        </Link>
                      </li>
                    </>
                  )
              }
              <li className='nav-item dropdown-hover dropdown ml-3' key='profile'>
                <Link
                  className={`nav-link dropdown-toggle medium-font font-weight-medium ${colorHover} d-flex align-items-center justify-content-center`} to='#' id='navbarDropdown' role='button'
                  data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'
                >
                  <img src={this.props.thisUser.image_url ?? User} className='hc-ava rounded-circle mr-2' alt='icon' /> Me
                </Link>
                <div className='dropdown-menu dropdown-menu-right' aria-labelledby='navbarDropdown'>
                  <div className='dropdown-item py-2 disabled d-flex align-items-center'>
                    <img src={(this.state.company === null ? this.props.thisUser.image_url : (this.props.thisUser.companies.length ? this.props.thisUser.companies[0].logo_link : Company) || Company) ?? User} className='hc-ava rounded-circle mr-2' alt='icon' /> <b className='color-dark'>{this.state.company === null ? this.props.thisUser.name : this.props.thisUser.companies[0].company_name}</b>
                  </div>
                  {/* SWITCH ACC */}
                  <div className='dropdown-divider m-0' />
                  {
                    this.state.isRecruiter && this.props.thisUser.companies && this.props.thisUser.companies.length > 0 && (
                      <>
                        <div className='dropdown-item cursor-pointer recruiter py-2 d-flex align-items-center' onClick={() => this.handleCompanyChange(null)}><IconUsers className='hc-ic mr-2 svg-default' /> Personal Account</div>
                        <div className='dropdown-divider m-0' />
                      </>
                    )
                  }
                  {this.props.thisUser.companies.length === 0 && (
                    <>
                      <Link className='dropdown-item cursor-pointer py-2 d-flex align-items-center' to={'/create-company'}><IconJob className='hc-ic mr-2' /> Create Company Page</Link>
                      <div className='dropdown-divider m-0' />
                    </>
                  )
                  }
                  {/* END SWITCH ACC */}

                  {/* LIST COMPANIES  */}
                  {
                    !(this.state.isRecruiter)
                    && this.props.thisUser.companies
                    && this.props.thisUser.companies.length > 0
                    && this.props.thisUser.companies.map((item) => {
                      return (<div key={item.uuid} className='cursor-pointer dropdown-item py-2 d-flex align-items-center' onClick={() => this.handleCompanyChange(item)}><IconJob className='hc-ic mr-2' /> {item.company_name}</div>)
                    })
                  }
                  {
                    !(this.state.isRecruiter)
                    && this.props.thisUser.companies
                    && this.props.thisUser.companies.length > 0
                    && (<div className='dropdown-divider m-0' />)
                  }
                  {/* END LIST COMPANIES  */}

                  <Link className={`dropdown-item py-2 ${dropdownHover}`} to={this.state.company === null ? '/profile' : `/recruiter/profile`}>{this.state.company === null ? 'User' : 'Company'} Profile</Link>
                  {
                    !(this.state.isRecruiter) &&
                    <Link className={`dropdown-item py-2 ${dropdownHover}`} to='/my-applicant'>My Applicant</Link>
                  }
                  {/* {<Link className={`dropdown-item py-2 ${dropdownHover}`} to={this.state.company === null ? '/settings' : `/recruiter/settings`}>Settings</Link>} */}
                  {<Link className={`dropdown-item py-2 ${dropdownHover}`} to={this.state.company === null ? '/settings' : `/recruiter/reports`}>Settings</Link>}
                  <div className='dropdown-divider m-0' />
                  <div onClick={this.handlerLogout} className={`dropdown-item py-2 d-flex align-items-center ${dropdownHover}`}><IconLogout className='hc-ic mr-2' /> Log Out</div>
                </div>
              </li>
            </ul>
          </div>
          {/* </div> */}
        </div>
        {!(this.state.isRecruiter)
                    && this.props.thisUser.companies
                    && this.props.thisUser.companies.length ?
        <SlidingPane
          className="sliding-pane"
          overlayClassName="sliding-pane-overlay"
          isOpen={this.state.isPaneOpen}
          from="right"
          closeIcon={<div className="text-white close-pane">X</div>}
          title={<div className="text-center">
            {/* <Link className='navbar-brand' to={this.state.isRecruiter ? '/my-company' : '/home'}> */}
            <Link className='navbar-brand' to="#">
              <img src={LogoWhite} className='App-logo' alt='hacklab' style={{ width: "100px" }} />
            </Link>
          </div>}
          // subtitle="Optional subtitle."
          onRequestClose={() => {
            // triggered on "<" on left top click or on outside click
            this.setState({ isPaneOpen: false });
          }}
        >
          <ul className='nav-burger-menu pt-3'>
            <li className="nav-burger-item">
              <Link to='/home' onClick={() => { this.setState({ isPaneOpen: false }) }}>
                <h5 className="font-weight-bold text-white">Home</h5>
              </Link>
            </li>
            <li className="nav-burger-item">
              <Link to='/jobs' onClick={this.onClikMenu}>
                <h5 className="font-weight-bold text-white">Jobs</h5>
              </Link>
            </li>
            <li className="nav-burger-item">
              <Link to='/companies' onClick={this.onClikMenu}>
                <h5 className="font-weight-bold text-white">Companies</h5>
              </Link>
            </li>
            <li className="nav-burger-item" style={{ paddingBottom: '1rem', borderBottom: '1px solid #FFFFFF' }}>
              <Link to='/assesment-search' onClick={() => { this.setState({ isPaneOpen: false }) }}>
                <h5 className="font-weight-bold text-white">Assessment</h5>
              </Link>
            </li>
            <li className="nav-burger-item mb-1">
              <div onClick={() => { this.setState({ showMenu: !this.state.showMenu }) }} className="d-flex justify-content-center ml-3">
                <h5 className="font-weight-bold text-white">Me</h5>
                <IcArrowDown className="ml-2 mt-1" />
              </div>
            </li>
          </ul>
          {
            this.state.showMenu && (
              <>
                <ul className="nav-burger-menu">
                  {this.props.thisUser.companies.length === 0 && (
                    <li className="nav-burger-item">
                      <Link to='/create-company' onClick={this.onClikMenu}>
                        <btn className="btn btn-primary rounded px-5">Create Company</btn>
                      </Link>
                    </li>
                  )}
                  {
                    !(this.state.isRecruiter)
                    && this.props.thisUser.companies
                    && this.props.thisUser.companies.length > 0
                    && this.props.thisUser.companies.map((item) => {
                      // return (className='btn btn-primary btn-lg btn-block'> {item.company_name}</div>)
                      return(
                        <li className="nav-burger-item mb-2">
                          <button onClick={() => this.handleCompanyChange(item)} className="btn btn-primary btn-lg btn-block">{item.company_name}</button>
                      </li>
                      )
                    })
                  }
                  <li className="nav-burger-item mb-2">
                    <Link to='/profile' onClick={this.onClikMenu}>
                      <h5 className="font-weight-bold text-white">User Profile</h5>
                    </Link>
                  </li>
                  <li className="nav-burger-item mb-2">
                    <Link to='/my-applicant' onClick={this.onClikMenu}>
                      <h5 className="font-weight-bold text-white">My Applicants</h5>
                    </Link>
                  </li>
                  <li className="nav-burger-item">
                    <Link to='/settings' onClick={this.onClikMenu}>
                      <h5 className="font-weight-bold text-white">Settings</h5>
                    </Link>
                  </li>
                  <li className="nav-burger-item" onClick={this.handlerLogout}><h5 className="font-weight-bold text-white pt-3">Logout</h5></li>
                </ul>
              </>
            )
          }
        </SlidingPane>:
        <SlidingPane
        className="sliding-pane sliding-pane__primary"
        overlayClassName="sliding-pane-overlay"
        isOpen={this.state.isPaneOpen}
        from="right"
        closeIcon={<div className="text-white close-pane">X</div>}
        title={<div className="text-center">
          {/* <Link className='navbar-brand' to={this.state.isRecruiter ? '/my-company' : '/home'}> */}
          <Link className='navbar-brand' to="#">
            <img src={LogoWhite} className='App-logo' alt='hacklab' style={{ width: "100px" }} />
          </Link>
        </div>}
        // subtitle="Optional subtitle."
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          this.setState({ isPaneOpen: false });
        }}
      >
        <ul className='nav-burger-menu pt-3'>
          <li className="nav-burger-item">
            <Link to='/home' onClick={() => { this.setState({ isPaneOpen: false }) }}>
              <h5 className="font-weight-bold text-white">Home</h5>
            </Link>
          </li>
          <li className="nav-burger-item" style={{borderBottom:'1px solid #FFFFFF', paddingBottom:'1rem'}}>
            <Link to='/recruiter/jobs' onClick={this.onClikMenu}>
              <h5 className="font-weight-bold text-white">Jobs Listing</h5>
            </Link>
          </li>
          <li className="nav-burger-item mb-1">
            <div onClick={() => { this.setState({ showMenu: !this.state.showMenu }) }} className="d-flex justify-content-center ml-3">
              <h5 className="font-weight-bold text-white">Me</h5>
              <IcArrowDown className="ml-2 mt-1" />
            </div>
          </li>
        </ul>
        {
          this.state.showMenu && (
            <>
              <ul className="nav-burger-menu">
                {/* {this.props.thisUser.companies.length === 0 && (
                  <li className="nav-burger-item">
                    <Link to='/create-company' onClick={this.onClikMenu}>
                      <btn className="btn btn-primary rounded px-5">Create Company</btn>
                    </Link>
                  </li>
                )} */}
                 <li className="nav-burger-item mb-2">
                        <button onClick={() => this.handleCompanyChange(null)} className="btn btn-primary btn-lg btn-block" style={{backgroundColor:'#ee852c'}}>Personal Account</button>
                </li>
                <li className="nav-burger-item mb-2">
                  <Link to='/recruiter/profile' onClick={this.onClikMenu}>
                    <button className="btn btn-primary btn-lg btn-block">Company Profile</button>
                  </Link>
                </li>
                <li className="nav-burger-item">
                  <Link to='/recruiter/reports' onClick={this.onClikMenu}>
                    <button className="btn btn-primary btn-lg btn-block">Settings</button>
                  </Link>
                </li>
                <li className="nav-burger-item" onClick={this.handlerLogout}><h5 className="font-weight-bold text-white pt-3">Logout</h5></li>
              </ul>
            </>
          )
        }
      </SlidingPane>}
      </nav>
    )
  }
}

NavBar.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    thisUser: state.thisUser,
    auth: state.auth,
    error: state.error,
    invalid: state.invalid,
    responseDashboard: state.responseDashboard,
  }
};

export default withRouter(connect(mapStateToProps, { logoutUser, getUser, setSearchJobs })(NavBar));
