import HomePage from '../pages/home/Home'
import AssesmentPage from '../pages/assesment/Assesment'
import AssesmentSearchPage from '../pages/assesment/Search'

import CompanyProfilePage from '../pages/company/CompanyProfile'
import CompaniesPage from '../pages/company/Companies'

import EditProfilePage from '../pages/profile/EditProfile'
import ProfilePage from '../pages/profile/Profile'

import JobsPage from '../pages/jobs/Jobs'
import JobDetailsPage from '../pages/jobs/JobDetails'
import JobsQuizPage from '../pages/jobs/JobsQuiz'
import MyApplicant from '../pages/jobs/MyApplicants'

import VerifyCompanyPage from '../recruiter-pages/auth/verification/VerifyCompany'

import VerifyCompanySuccess from '../recruiter-pages/auth/verification/VerifyCompanySuccess'

import CategoryPage from '../pages/auth/data-completion/Category'
import SkillPage from '../pages/auth/data-completion/Skill'

import TalentSettingsPage from '../pages/settings/TalentSettings'
import TalentReportsPage from '../pages/settings/TalentReports'

import { TALENT } from '../constants'
import Bctn from '../pages/bctn/Bctn'

export const talentRoutes = [
  {
    exact: true,
    role: TALENT,
    path: '/my-applicant',
    component: MyApplicant
  },
  {
    exact: true,
    role: TALENT,
    path: '/home',
    component: HomePage
  },
  {
    exact: true,
    role: TALENT,
    path: '/assesment-search',
    component: AssesmentSearchPage
  },
  {
    exact: true,
    role: TALENT,
    path: '/assesment',
    component: AssesmentPage
  },
  {
    exact: true,
    role: TALENT,
    path: '/company/:id',
    component: CompanyProfilePage
  },
  {
    exact: true,
    role: TALENT,
    path: '/companies',
    component: CompaniesPage
  },
  {
    exact: true,
    role: TALENT,
    path: '/profile',
    component: ProfilePage
  },
  {
    exact: true,
    role: TALENT,
    path: '/profile/edit',
    component: EditProfilePage
  },
  {
    exact: true,
    role: TALENT,
    path: '/jobs',
    component: JobsPage
  },
  {
    exact: true,
    role: TALENT,
    path: '/jobs/quiz/:id',
    component: JobsQuizPage
  },
  {
    exact: true,
    role: TALENT,
    path: '/jobs/:id',
    component: JobDetailsPage
  },
  {
    exact: true,
    role: TALENT,
    path: '/verify-company/:uuid',
    component: VerifyCompanyPage
  },
  {
    exact: true,
    role: TALENT,
    path: '/verify-company-success/:uuid',
    component: VerifyCompanySuccess
  },
  {
    exact: true,
    role: TALENT,
    path: '/category',
    component: CategoryPage
  },
  {
    exact: true,
    role: TALENT,
    path: '/skill',
    component: SkillPage
  },
  {
    exact: true,
    role: TALENT,
    path: '/settings',
    component: TalentSettingsPage
  },
  {
    exact: true,
    role: TALENT,
    path: '/reports',
    component: TalentReportsPage
  },
  {
    exact: true,
    role: TALENT,
    path: '/bctn',
    component: Bctn
  },
]
