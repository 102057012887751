import React, { useEffect, useState } from "react";
import AdminLanding from "../../../components/layouts/admin/AdminLanding";
import { Table, Modal } from "react-bootstrap";
import styled from "styled-components";
import Sort from "../../../images/sort.svg";
import ArrowLeft from "../../../images/pagination_arrow_left.svg";
import ArrowRight from "../../../images/pagination_arrow_right.svg";
import More from "../../../images/table_more.svg";
import { httpGet, httpPost } from "../../../actions/utils";
import { useDispatch } from "react-redux";
import { Popover } from 'react-tiny-popover'
import { toast } from 'react-toastify'

import { alertError } from '../../../utils';
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  th {
    border-top: 0;
  }
  margin-bottom:50px;
`;

const ButtonLast = styled.button`
  border: 2px solid #ebebeb;
  height: 40px;
  width: 40px;
  background: #f9f9f9;
  border-radius: 20px;
`;

const ButtonLastDisabled = styled.button`
  border: 2px solid #939393;
  height: 40px;
  width: 40px;
  background: #939393;
  border-radius: 20px;
`;

const Pagination = styled.div`
  display: flex;
`;

const WrapCenter = styled.div`
  border: 1px solid #ebebeb;
  border-radius: 10px;
  background: #f9f9f9;
  height: 40px;
  margin-left: 20px;
  margin-right: 20px;
`;

const TextCenter = styled.button`
  font-size: 18px;
  border: 0;
  background: none;
  height: 40px;
  width: 40px;
`;
const TextActive = styled.button`
  font-size: 18px;
  border: 0;
  background: none;
  height: 40px;
  width: 40px;
  border-radius: 10px;
  background-color: #ee852c;
  color: #fff;
`;

const CompanyInactive = styled.div`
  background: #939393;
  border-radius: 5px;
  font-size: 14px;
  color: #fff;
  text-align: center;
`;

const CompanyActive = styled.div`
  background: #29c973;
  border-radius: 5px;
  font-size: 14px;
  color: #fff;
  text-align: center;
`;

const CompanyHigh = styled.div`
  background: #FDD100;
  border-radius: 5px;
  font-size: 14px;
  color: #fff;
  text-align: center;
`;

const PopUp = styled.div`
  border-radius: 10px;
  margin-right: 80px;
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding-bottom: 10px;
  padding-top: 10px;
`;
const PopUpText = styled.div`
  color: #939393;
  font-size: 12px;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  :hover{
    color: #ee852c;
  }
`;

const ButtonYes = styled.button`
  color: #fff;
  background: #ee852c;
  border: none;
  border-radius: 20px;
  height: 40px;
  width: 90px;
`;

const ButtonNo = styled.button`
  color: #fff;
  background: #e5e5e5;
  border: none;
  border-radius: 20px;
  height: 40px;
  width: 90px;
`;

const ContainerAdmin = styled.div`
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
`;

const paging = 10;

function CompanyPage() {
  const dispatch = useDispatch();
  const [companyList, setCompany] = useState([]);
  const [paginationLink, setLink] = useState([]);
  const [prevPage, setPrev] = useState(null);
  const [nextPage, setNext] = useState(null);
  const [fromNo, setFrom] = useState(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(null);
  const [show, setShow] = useState(false);
  const [editID, setEditID] = useState(null);
  const [statusID, setStatusID] = useState(null);
  const [highID, setHighID] = useState(null);
  const [updateType, setUpdateType] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getData = async (p = 1, item = paging) => {
    const token = localStorage.jwtToken;
    const res = await httpGet(
      dispatch,
      `/v1/manage/company?page=${p}&perpage=${item}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setCompany(res.data);
    const newLink = res.links
      .filter((t) => t.label !== "pagination.previous")
      .filter((t) => t.label !== "pagination.next");
    setLink(newLink);
    setPrev(res.prev_page_url ? res.prev_page_url.split("?page=")[1] : null);
    setNext(res.next_page_url ? res.next_page_url.split("?page=")[1] : null);
    setFrom(res.from);
  };

  const updateStatus = async() => {
    const token = localStorage.jwtToken;
    try {
        await httpPost(
          dispatch,
          `/v1/manage/company/isshown/${editID}`,
          {
            status: statusID,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        toast.success("Sucessfully update status");
        getData();
    } catch (error) {
      console.log(error);
      alertError(error.message);
    }
  }

  const updateHigh = async() => {
    const token = localStorage.jwtToken;
    try {
        await httpPost(
          dispatch,
          `/v1/manage/company/ishighlight/${editID}`,
          {
            status: highID,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        toast.success("Sucessfully update Highlight");
        getData();
    } catch (error) {
      console.log(error);
      alertError(error.message);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <AdminLanding>
      <ContainerAdmin>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to save?</Modal.Body>
            <Modal.Footer>
              <ButtonNo variant="secondary" onClick={handleClose}>
                No
              </ButtonNo>
              <ButtonYes variant="primary" onClick={()=>{
                handleClose();
                if(updateType==='status'){
                  updateStatus();
                }else if(updateType==='highlight'){
                  updateHigh();
                }
              }}>
                Yes
              </ButtonYes>
            </Modal.Footer>
          </Modal>
        <h3 className="fw-bold">All Company</h3>
        <Wrapper>
          <Table hover className="mt-5">
            <thead>
              <tr>
                <th>
                  No
                  <img src={Sort} className="ml-1" alt="sort" />
                </th>
                <th>
                  Company Name
                  <img src={Sort} className="ml-1" alt="sort" />
                </th>
                <th>
                  Location
                  <img src={Sort} className="ml-1" alt="sort" />
                </th>
                <th>
                  Industry
                  <img src={Sort} className="ml-1" alt="sort" />
                </th>
                <th>
                  Job Posting
                  <img src={Sort} className="ml-1" alt="sort" />
                </th>
                <th>
                  Status
                  <img src={Sort} className="ml-1" alt="sort" />
                </th>
                <th>
                Highlight
                  <img src={Sort} className="ml-1" alt="sort" />
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {companyList.map((t, i) => {
                let no = fromNo + i;
                return (
                  <tr key={t.id}>
                    <td>{no}</td>
                    <td>{t.company_name}</td>
                    <td>{t?.city_detail?.name}</td>
                    <td>{t?.industry_category_detail?.industry_category}</td>
                    <td>{t.available_job_post}</td>
                    <td>
                      {!t.isshown ? (
                        <CompanyInactive>Non-Active</CompanyInactive>
                      ) : (
                        <CompanyActive>Active</CompanyActive>
                      )}
                    </td>
                    <td>
                      {!t.ishighlight ? (
                        <CompanyInactive>Off</CompanyInactive>
                      ) : (
                        <CompanyHigh>On</CompanyHigh>
                      )}
                    </td>
                    <td>
                    <Popover
                        isOpen={isPopoverOpen===t.id}
                        positions={['bottom', 'left']} 
                        padding={10}
                        reposition={false}
                        style={{marginRight: 30}}
                        onClickOutside={() => setIsPopoverOpen(null)}
                        content={({ position, nudgedLeft, nudgedTop }) => ( 
                          <PopUp>
                            <PopUpText>View Details</PopUpText>
                            <PopUpText>Edit</PopUpText>
                            <PopUpText onClick={()=>{
                              setUpdateType('highlight');
                              setEditID(t.uuid);
                              setHighID(!t.ishighlight);
                              handleShow();
                            }}>{!t.ishighlight ? 'Highlight On' : 'Highlight Off'}</PopUpText>
                            <PopUpText onClick={()=>{
                              setUpdateType('status');
                              setEditID(t.uuid);
                              setStatusID(!t.isshown);
                              handleShow();
                            }}>{!t.isshown ? 'Active' : 'Non-Active'}</PopUpText>
                          </PopUp>
                        )}
                      >
                        <div onClick={() => setIsPopoverOpen(t.id)}><img src={More} alt="ic" /></div>
                      </Popover>
                        
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {paginationLink.length && (
            <Pagination>
              {!prevPage ? (
                <ButtonLastDisabled>
                  <img src={ArrowLeft} alt="ic" />
                </ButtonLastDisabled>
              ) : (
                <ButtonLast onClick={() => getData(nextPage, paging)}>
                  <img src={ArrowLeft} alt="ic" />
                </ButtonLast>
              )}
              <WrapCenter>
                {paginationLink.map((t, i) => {
                  return t.active ? (
                    <TextActive
                      onClick={() => getData(t.label, paging)}
                      key={i}
                    >
                      {t.label}
                    </TextActive>
                  ) : (
                    <TextCenter
                      onClick={() => getData(t.label, paging)}
                      key={i}
                    >
                      {t.label}
                    </TextCenter>
                  );
                })}
              </WrapCenter>
              {!nextPage ? (
                <ButtonLastDisabled>
                  <img src={ArrowRight} alt="ic" />
                </ButtonLastDisabled>
              ) : (
                <ButtonLast
                  onClick={() => nextPage && getData(nextPage, paging)}
                >
                  <img src={ArrowRight} alt="ic" />
                </ButtonLast>
              )}
            </Pagination>
          )}
        </Wrapper>
      </ContainerAdmin>
    </AdminLanding>
  );
}

export default CompanyPage;
