import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import HomeLanding from '../../components/layouts/HomeLanding'
import User2 from '../../images/user-3.svg'
import Campus from '../../images/campus.svg'
import Company from '../../images/new-building.svg'
import { AboutCard, ProfileCard } from '../../components'
import { ReactComponent as IcAddRectangle } from '../../images/add.svg'

class Profile extends Component {
  constructor (props) {
    super(props)
    this.state = {
      items: [1, 2, 3, 4, 5],
      modalShow: true
    }
    this.fetchMoreData = this.fetchMoreData.bind(this)
  }

  fetchMoreData () {
    setTimeout(() => {
      this.setState({
        items: this.state.items.concat([1, 2, 3, 4, 5])
      })
    }, 1500)
  };

  render () {
    return (
      <HomeLanding>
        <div className='container-padding'>
          <ProfileCard user={this.props.thisUser} work={(this.props.thisUser.work_experience.length > 0) && this.props.thisUser.work_experience[0]} />
          <div className='row pt-3'>
            <div className='col-12 col-md-3'>
              <AboutCard
                className='sticky-top mt-sidebar'
                user={this.props.thisUser}
                work={(this.props.thisUser.work_experience.length > 0) && this.props.thisUser.work_experience[0]}
                education={(this.props.thisUser.educational_background.length > 0) && this.props.thisUser.educational_background[0]}
              />
            </div>
            <div className='col-12 col-md-6 pb-3'>
              <div className='card bg-white border-none text-left pb-0 mb-3 rounded-lg'>
                <div className='card-body'>
                  <h6><b>Job & Skill Interest</b></h6>
                  <hr />
                  <div className='form-group'>
                    <label className='pl-3 normal-font font-weight-medium'>Job Role</label>
                    <div className='container-items'>
                      <div className='items-wrapper'>
                        {
                              this.props.thisUser.job_role.map((item) => {
                                return (<span key={item.id}>{item.title}</span>)
                              })
                        }
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <label className='pl-3 normal-font font-weight-medium'>Industry Category</label>
                    <div className='container-items'>
                      <div className='items-wrapper'>
                        {
                              this.props.thisUser.industry_category.map((item) => {
                                return (<span key={item.id}>{item.title}</span>)
                              })
                        }
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <label className='pl-3 normal-font font-weight-medium'>Skill Interest</label>
                    <div className='container-items'>
                      <div className='items-wrapper'>
                        {
                              this.props.thisUser.skill_interest.map((item) => {
                                return (<span key={item.id}>{item.title}</span>)
                              })
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card bg-white border-none text-left pb-0 rounded-lg'>
                <div className='card-body'>
                  <h6><b>Background</b></h6>
                  <hr />
                  <span className='font-weight-medium'>Summary</span>
                  <br />
                  <div className='normal-font pt-2'>{this.props.thisUser.about_you ?? '-'}</div>
                  <hr />
                  <span className='font-weight-medium'>Experience</span>
                  <br />
                  {
                    (this.props.thisUser.work_experience.length ?? 0) === 0
                      ? '-'
                      : (
                        <ul className='timeline'>
                          {
                            this.props.thisUser.work_experience.map((item, i) => {
                              const jump = this.props.thisUser.work_experience[i-1];
                              const runNo = this.props.thisUser.work_experience.length-1;
                              return (
                                <li key={`experience-${i}`}>
                                  <div>
                                    <div>
                                      <p className='p-0 m-0'>
                                        {
                                          item.end_date === '' ? (<>
                                            {/* <span className='d-block fz-10px'>{moment(new Date()).format('MMM')}</span> */}
                                            <span className='d-block fz-12px font-weight-medium'>{moment(new Date()).format('YYYY')}</span>
                                                                  </>)
                                            : (<>
                                            {/* <span className='d-block fz-10px'>{moment(item.end_date).format('MMM')}</span> */}
                                            <span className='d-block fz-12px font-weight-medium'>{runNo === i && i>0 ? moment(new Date(item.start_date)).format('YYYY') : (i===0?'NOW':moment(new Date()).format('YYYY'))}</span>
                                             </>)
                                        }
                                      </p>
                                    </div>
                                    <div className='details-job'>
                                      <div>
                                        <img src={Company} className='company-img-profile' alt='icon' />
                                      </div>
                                      <p className='mb-0 normal-font'>
                                        <span className='d-block font-weight-medium'>{item.job_position} </span>
                                        <span className='d-block company-address font-weight-medium'>{item.company}</span>

                                        <span className='d-block text-deactive'>{moment(item.start_date).format('MMM YYYY')} - {item.currently_work_here === 1 ? 'Present' : moment(item.end_date).format('MMM YYYY')} 
                                        {moment.duration(moment(item.end_date).diff(moment(item.start_date))).format('y [years] M [months]',{ trim: "small final" }) && "("+moment.duration(moment(item.end_date).diff(moment(item.start_date))).format('y [years] M [months]',{ trim: "small final" }).replace('0 years ','')+")"}</span>
                                        {/* <span className='d-block text-deactive'>{moment.duration(moment(item.end_date).diff(moment(item.start_date))).format('y [y] M [m]')}</span> */}
                                        <span>{item.job_description}</span>
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              )
                            })
                          }
                          <li key='first'>
                            <div>
                              <div>
                                <p className='p-0 m-0'>
                                  {/* <span className='d-block fz-10px'>{moment(this.props.thisUser.work_experience[this.props.thisUser.work_experience.length - 1].start_date).format('MMM')}</span> */}
                                  <span className='d-block fz-12px font-weight-medium'>{moment(this.props.thisUser.work_experience[this.props.thisUser.work_experience.length - 1].start_date).format('YYYY')}</span>
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                        )
                  }
                  <hr />
                  <span className='font-weight-medium'>Education</span>
                  <br />
                  {
                    (this.props.thisUser.educational_background.length ?? 0) === 0
                      ? '-'
                      : (
                          this.props.thisUser.educational_background.map((item, i) => {
                            return (
                              <div key={`education-${i}`} className='d-flex align-items-center'>
                                <div className='p-2'>
                                  <img src={Campus} className='avatar-sm rounded-circle' alt='icon' />
                                </div>
                                <div className='p-2 normal-font text-left'>
                                  <span className='font-weight-medium'>{item.school}</span>
                                  <br />
                                  <span>{item.title}</span>
                                  <br />
                                  <span className='text-deactive'>{moment(item.start_date).format('MMM YYYY')} - {item.currently_studied_here ? 'Present' : moment(item.end_date).format('MMM YYYY')}</span>
                                </div>
                              </div>
                            )
                          })
                        )
                  }
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
          {this.props.thisUser.companies.length === 0 && (
                <>
                <Link to={'/create-company'} className='create-company-home'>
                  <div
                    className='card text-center pt-4 pb-3 align-items-center rounded-lg'
                    style={{ backgroundColor: '#62C3D0', border: 'none' }}
                  >
                    <div
                      className='d-flex mb-4'
                      style={{
                        border: '2px dashed #fff',
                        width: '80px',
                        height: '80px',
                        borderRadius: '50%',
                      }}
                    >
                      <IcAddRectangle className='hc-ic align-self-center mx-auto' />
                    </div>
                    <h6 className='font-weight-bold text-white'>
                      Create Company Page
                    </h6>
                    <p className='text-white'>
                      Create your company account <br />
                      and start recruit talent
                    </p>
                  </div>
                </Link>
                <br/>
                </>
              )} 
          </div>
              {/* <div className='col-12 col-md-3'>
                <div className='card sticky-top mt-sidebar custom-sidebar-summary border-none text-left pb-0 mb-3 rounded-lg'>
                  <div className='card-header white-br-bottom'>
                    <h6 className='pt-2'><b>People Also Viewed </b></h6>
                  </div>
                  <div className='card-body normal-font font-weight-bold px-0 py-4'>
                    {
                      [1, 2, 3, 4, 5].map(function (i) {
                        return (
                          <div key={`side-right-${i}`} className='d-flex item-orange'>
                            <div className='p-2 ml-3'>
                              <img src={User2} className='avatar-xs rounded-circle' alt='icon' />
                            </div>
                            <div className='p-2'>
                              <b>Fanisha Arafah</b>
                              <br />
                              <span className='font-weight-normal'>
                                UI/UX Designer
                              </span>
                            </div>
                          </div>
                        )
                      })
                      }
                  </div>
                </div>
              </div> */}
          </div>
        </div>
      </HomeLanding>
    )
  }
}

Profile.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return {
    thisUser: state.thisUser,
    auth: state.auth,
    error: state.error,
    invalid: state.invalid
  }
}

export default withRouter(connect(mapStateToProps, { })(Profile))
