export const GET_JOB_ROLE /* ------------------------ */ = 'GET_JOB_ROLE'
export const SET_JOB_ROLE /* ------------------------ */ = 'SET_JOB_ROLE'
export const GET_INDUSTRY /* ------------------------ */ = 'GET_INDUSTRY'
export const SET_INDUSTRY /* ------------------------ */ = 'SET_INDUSTRY'
export const GET_SKILL_INTEREST /* ------------------ */ = 'GET_SKILL_INTEREST'
export const SET_SKILL_INTEREST /* ------------------ */ = 'SET_SKILL_INTEREST'
export const GET_PROVINCE /* ------------------------ */ = 'GET_PROVINCE'
export const GET_CITY /* ---------------------------- */ = 'GET_CITY'
export const GET_MARITAL_STATUS /* ------------------ */ = 'GET_MARITAL_STATUS'
export const REQ_PASSWORD /* ------------------------ */ = 'REQ_PASSWORD'
export const SET_PROFILE_PICTURE /* ----------------- */ = 'SET_PROFILE_PICTURE'
export const UPDATE_PROFILE /* ---------------------- */ = 'UPDATE_PROFILE'
export const CREATE_COMPANY /* ---------------------- */ = 'CREATE_COMPANY'
export const REQ_VERIFY_COMPANY /* ------------------ */ = 'REQ_VERIFY_COMPANY'
export const GET_EDUCATION_DEGREE /* ---------------- */ = 'GET_EDUCATION_DEGREE'
export const GET_JOB_LEVEL /* ----------------------- */ = 'GET_JOB_LEVEL'
export const GET_JOB_TYPE /* ------------------------ */ = 'GET_JOB_TYPE'
export const GET_EMPLOYEE /* ------------------------ */ = 'GET_EMPLOYEE'
export const CREATE_JOB /* -------------------------- */ = 'CREATE_JOB'
export const GET_JOB /* ----------------------------- */ = 'GET_JOB'
export const GET_COMPANY_GALLERY /* ----------------- */ = 'GET_COMPANY_GALLERY'
export const GET_COMPANY /* ------------------------- */ = 'GET_COMPANY'
export const CREATE_COMPANY_GALLERY /* -------------- */ = 'CREATE_COMPANY_GALLERY'
export const GET_JOBS /* ---------------------------- */ = 'GET_JOBS'
export const GET_SUGGESTED_COMPANY /* --------------- */ = 'GET_SUGGESTED_COMPANY'
export const SET_SUGGESTED_COMPANY /* --------------- */ = 'SET_SUGGESTED_COMPANY'
export const SET_JOBS /* ---------------------------- */ = 'SET_JOBS'
export const SET_IMAGE_GALLERY /* ------------------- */ = 'SET_IMAGE_GALLERY'
export const GET_IMAGE_GALLERY /* ------------------- */ = 'GET_IMAGE_GALLERY'
export const UPDATE_COMPANY_GALLERY /* -------------- */ = 'UPDATE_COMPANY_GALLERY'
export const SET_IMAGE_CAPTION /* ------------------- */ = 'SET_IMAGE_CAPTION'
export const SET_IMAGE_GALLERY_LIST /* -------------- */ = 'SET_IMAGE_GALLERY_LIST'
export const APPLY_JOB /* --------------------------- */ = 'APPLY_JOB'
export const GET_COMPANY_DETAILS /* ----------------- */ = 'GET_COMPANY_DETAILS'
export const GET_JOB_DETAILS /* --------------------- */ = 'GET_JOB_DETAILS'
export const GET_HOMEPAGE_COMPANY /* ---------------- */ = 'GET_HOMEPAGE_COMPANY'
export const REGISTER_USER /* ----------------------- */ = 'REGISTER_USER'
export const GET_JOB_APPLICANT /* ------------------- */ = 'GET_JOB_APPLICANT'
export const GET_JOB_BY_COMPANY /* ------------------ */ = 'GET_JOB_BY_COMPANY'
export const SET_IMAGE_LOADING /* ------------------- */ = 'SET_IMAGE_LOADING'
export const UPDATE_COMPANY_PROFILE /* -------------- */ = 'UPDATE_COMPANY_PROFILE'
export const SEARCH_JOBS /* ------------------------- */ = 'SEARCH_JOBS'
export const GET_LIST_JOBS /* ----------------------- */ = 'GET_LIST_JOBS'
export const REPORT_PROBLEM /* ---------------------- */ = 'REPORT_PROBLEM'
export const SET_CURRENT /* ------------------------- */ = 'SET_CURRENT'
export const COMPANY_DASHBOARD /* ------------------- */ = 'COMPANY_DASHBOARD'
