import { GET_RESPONSE } from './types'
import { REQ_PASSWORD } from './responseTypes'

import { httpPost } from './utils'

export const reqPassword = (email = '') => async dispatch => {
  const res = await httpPost(dispatch, '/v1/auth/forgot-password', {
    email: email
  })
  if (!res) return null

  dispatch({
    type: GET_RESPONSE,
    payload: {
      type: REQ_PASSWORD,
      data: res
    }
  })
}

export const resetPassword = (body = {}) => async dispatch => {
  const res = await httpPost(dispatch, '/v1/auth/reset-password', body)
  if (!res) return null

  dispatch({
    type: GET_RESPONSE,
    payload: {
      type: REQ_PASSWORD,
      data: res
    }
  })
}