import { GET_RESPONSE } from './types'
import {
  GET_JOB_ROLE,
  GET_INDUSTRY,
  GET_SKILL_INTEREST,
  GET_PROVINCE,
  GET_CITY,
  GET_MARITAL_STATUS,
  GET_EDUCATION_DEGREE,
  GET_JOB_TYPE,
  GET_JOB_LEVEL,
  GET_EMPLOYEE,

  SET_JOB_ROLE,
  SET_INDUSTRY,
  SET_SKILL_INTEREST,
} from './responseTypes'
import { httpGet, httpPost } from './utils'

export const getJobRole = () => async dispatch => {
  let token = localStorage.jwtToken
  const res = await httpGet(dispatch, '/v1/master/job-role', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (!res) return null

  dispatch({
    type: GET_RESPONSE,
    payload: {
      type: GET_JOB_ROLE,
      data: res
    }
  })
}

export const setJobRole = (job_roles = []) => async dispatch => {
  let token = localStorage.jwtToken
  const res = await httpPost(dispatch, '/v1/user/job-role', {
    job_role: job_roles
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (!res) return null

  dispatch({
    type: GET_RESPONSE,
    payload: {
      type: SET_JOB_ROLE,
      data: res
    }
  })
}

export const getIndustry = () => async dispatch => {
  let token = localStorage.jwtToken
  const res = await httpGet(dispatch, '/v1/master/industry-category', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (!res) return null

  dispatch({
    type: GET_RESPONSE,
    payload: {
      type: GET_INDUSTRY,
      data: res
    }
  })
}

export const setIndustry = (categories = []) => async dispatch => {
  let token = localStorage.jwtToken
  const res = await httpPost(dispatch, '/v1/user/industry-category', {
    industry_category: categories
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (!res) return null

  dispatch({
    type: GET_RESPONSE,
    payload: {
      type: SET_INDUSTRY,
      data: res
    }
  })
}

export const getSkillInterest = () => async dispatch => {
  let token = localStorage.jwtToken
  const res = await httpGet(dispatch, '/v1/master/skill-interest', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (!res) return null

  dispatch({
    type: GET_RESPONSE,
    payload: {
      type: GET_SKILL_INTEREST,
      data: res
    }
  })
}

export const setSkillInterest = (skills = []) => async dispatch => {
  let token = localStorage.jwtToken
  const res = await httpPost(dispatch, '/v1/user/skill-interest', {
    skill_interest: skills
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (!res) return null

  dispatch({
    type: GET_RESPONSE,
    payload: {
      type: SET_SKILL_INTEREST,
      data: res
    }
  })
}

export const getProvince = () => async dispatch => {
  let token = localStorage.jwtToken
  const res = await httpGet(dispatch, '/v1/master/province', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (!res) return null

  dispatch({
    type: GET_RESPONSE,
    payload: {
      type: GET_PROVINCE,
      data: res
    }
  })
}

export const getCity = (province = '') => async dispatch => {
  let token = localStorage.jwtToken
  const res = await httpGet(dispatch, `/v1/master/city?province=${province}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (!res) return null

  dispatch({
    type: GET_RESPONSE,
    payload: {
      type: GET_CITY,
      data: res
    }
  })
}

export const getMaritalStatus = () => async dispatch => {
  let token = localStorage.jwtToken
  const res = await httpGet(dispatch, '/v1/master/marital-status', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (!res) return null

  dispatch({
    type: GET_RESPONSE,
    payload: {
      type: GET_MARITAL_STATUS,
      data: res
    }
  })
}

export const getJobType = () => async dispatch => {
  let token = localStorage.jwtToken
  const res = await httpGet(dispatch, '/v1/master/job-type', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (!res) return null

  dispatch({
    type: GET_RESPONSE,
    payload: {
      type: GET_JOB_TYPE,
      data: res
    }
  })
}

export const getJobLevel = () => async dispatch => {
  let token = localStorage.jwtToken
  const res = await httpGet(dispatch, '/v1/master/job-level', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (!res) return null

  dispatch({
    type: GET_RESPONSE,
    payload: {
      type: GET_JOB_LEVEL,
      data: res
    }
  })
}

export const getDegree = () => async dispatch => {
  let token = localStorage.jwtToken
  const res = await httpGet(dispatch, '/v1/master/education-degree', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (!res) return null

  dispatch({
    type: GET_RESPONSE,
    payload: {
      type: GET_EDUCATION_DEGREE,
      data: res
    }
  })
}

export const getEmployee = () => async dispatch => {
  let token = localStorage.jwtToken
  const res = await httpGet(dispatch, '/v1/master/number-of-employee', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (!res) return null

  dispatch({
    type: GET_RESPONSE,
    payload: {
      type: GET_EMPLOYEE,
      data: res
    }
  })
}