import { GET_RESPONSE } from './types'
import {
  CREATE_COMPANY_GALLERY,
  CREATE_COMPANY,
  REQ_VERIFY_COMPANY,
  CREATE_JOB,
  GET_JOB,
  GET_COMPANY_DETAILS,
  SET_IMAGE_GALLERY,
  UPDATE_COMPANY_PROFILE,
  UPDATE_COMPANY_GALLERY,
  SET_IMAGE_CAPTION,
  SET_IMAGE_GALLERY_LIST,
  SET_IMAGE_LOADING,
  REPORT_PROBLEM,
  GET_COMPANY,
  COMPANY_DASHBOARD
} from './responseTypes'

import { httpPost, httpGet } from './utils'

export const createCompany =
  (company = {}) =>
  async dispatch => {
    const token = localStorage.jwtToken
    const res = await httpPost(dispatch, '/v1/my-company', company, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (!res) return null
    localStorage.setItem('company', JSON.stringify(res?.data));
    dispatch({
      type: GET_RESPONSE,
      payload: {
        type: CREATE_COMPANY,
        data: res,
      },
    })
  }

export const getCompanyGallery = uuid => async dispatch => {
  const token = localStorage.jwtToken
  const company = JSON.parse(localStorage.getItem('company'))
  const res = await httpGet(dispatch, `/v1/company-gallery/${uuid ?? company.uuid}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (!res) return null

  dispatch({
    type: GET_RESPONSE,
    payload: res,
  })
}

export const reqVerifyCompany = (uuid, company) => async dispatch => {
  const token = localStorage.jwtToken
  const res = await httpPost(
    dispatch,
    `/v1/my-company-${company ? 'custom' : 'default'}-email/${uuid}`,
    company,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
  if (!res) return null

  dispatch({
    type: GET_RESPONSE,
    payload: {
      type: REQ_VERIFY_COMPANY,
      data: res,
    },
  })
}

export const createJob =
  (job = {}) =>
  async dispatch => {
    const token = localStorage.jwtToken
    const company = JSON.parse(localStorage.getItem('company'))
    const res = await httpPost(dispatch, `/v1/job/${company.uuid}`, job, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (!res) return null

    dispatch({
      type: GET_RESPONSE,
      payload: {
        type: CREATE_JOB,
        data: res,
      },
    })
  }

export const jobList = (page = 1, search, location) => async dispatch => {
  const token = localStorage.jwtToken
  const res = await httpGet(dispatch, '/v1/job', {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      page: page,
      perpage: 10,
      q: search,
      location: location
    }
  })
  if (!res) return null

  dispatch({
    type: GET_RESPONSE,
    payload: {
      type: GET_JOB,
      data: res
    }
  })
}

export const getCompanies = (p = 1, item = 10, s = 'no', q = '', location = '') => async dispatch => {
  const token = localStorage.jwtToken
  const res = await httpGet(
    dispatch,
    `/v1/company?q=${q}&location=${location}&page=${p}&perpage=${item}&suggested=${s}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  if (!res) return null

  dispatch({
    type: GET_RESPONSE,
    payload: {
      type: GET_COMPANY,
      data: res
    }
  })
}

export const uploadCompanyGallery = photos => async dispatch => {
  const token = localStorage.jwtToken
  const company = JSON.parse(localStorage.getItem('company'))
  const res = await httpPost(
    dispatch,
    `/v1/upload-company-gallery/${company.uuid}`,
    { photos: photos },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
  if (!res) return null

  dispatch({
    type: GET_RESPONSE,
    payload: {
      type: CREATE_COMPANY_GALLERY,
      data: res,
    },
  })
}

export const updateCompanyProfile =
  (form = {}) =>
  async dispatch => {
    const token = localStorage.jwtToken
    const company = JSON.parse(localStorage.getItem('company'))
    const res = await httpPost(
      dispatch,
      `/v1/my-company-update/${company.uuid}`,
      form,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    if (!res) return null

    dispatch({
      type: GET_RESPONSE,
      payload: {
        type: UPDATE_COMPANY_PROFILE,
        data: res,
      },
    })
  }

export const updateCompanyGalleryImage = photos => async dispatch => {
  const token = localStorage.jwtToken
  const company = JSON.parse(localStorage.getItem('company'))
  const res = await httpPost(
    dispatch,
    `​/v1/company-gallery-multiple-update​/${company.uuid}`,
    { photos },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
  if (!res) return null

  dispatch({
    type: GET_RESPONSE,
    payload: {
      type: UPDATE_COMPANY_GALLERY,
      data: res,
    },
  })
}

export const getDetailsCompany = uuid => async dispatch => {
  const token = localStorage.jwtToken
  const res = await httpGet(dispatch, `/v1/company-homepage/${uuid}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (!res) return null

  dispatch({
    type: GET_RESPONSE,
    payload: {
      type: GET_COMPANY_DETAILS,
      data: res,
    },
  })
}

export const reportProblem = (subject, message) => async dispatch => {
  const token = localStorage.jwtToken
  const res = await httpPost(
    dispatch,
    `/v1/user/report-problem/`,
    {
      subject: subject,
      message: message,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
  if (!res) return null

  dispatch({
    type: GET_RESPONSE,
    payload: {
      type: REPORT_PROBLEM,
      data: res,
    },
  })
}

export const companyDashboard =  () => async dispatch => {
  const token = localStorage.jwtToken
  const company = JSON.parse(localStorage.getItem('company'))
  const res = await httpGet(
    dispatch,
    `/v1/company-homepage/${company.uuid}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
  if (!res) return null

  dispatch({
    type: GET_RESPONSE,
    payload: {
      type: COMPANY_DASHBOARD,
      data: res,
    },
  })
}

export const setImageGallery = images => dispatch => {
  dispatch({ type: SET_IMAGE_GALLERY, payload: images })
}

export const setImageGalleryList = imageList => dispatch => {
  dispatch({ type: SET_IMAGE_GALLERY_LIST, payload: imageList })
}

export const setImageCaption = caption => dispatch => {
  dispatch({ type: SET_IMAGE_CAPTION, payload: caption })
}

export const setImageLoading = loading => dispatch => {
  dispatch({ type: SET_IMAGE_LOADING, payload: loading })
}
