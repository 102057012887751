import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { userAction, getJobRole, getIndustry, setJobRole, setIndustry } from '../../../actions'
import { GET_INDUSTRY, GET_JOB_ROLE, SET_JOB_ROLE, SET_INDUSTRY } from '../../../actions/responseTypes'
import { AuthCard, Landing, CustomMultiSelect } from '../../../components'
import { Validator, RULES_CATEGORY_INTEREST } from '../../../rules'
import { alertErrorValidation, captionErrorValidation } from '../../../utils'

class Category extends Component {
  constructor (props) {
    super(props)
    this.state = {
      form: {items: []},
      validation: {},
      error: false,
      loading: false,
      job_roles: [
        { text: 'Loading...', id: 1 },
      ],
      categories: [
        { text: 'Loading...', id: 1 },
      ]
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleFormValidation = this.handleFormValidation.bind(this)
  }

  handleInputChange = (name, value) =>  {
    this.setState(prevState => ({
      form : {
          ...prevState.form,
          [name]: value
      }
    }))
}

componentDidMount = async () => {
  if (this.props.thisUser && this.props.thisUser.job_role.length > 0  && this.props.thisUser.skill_interest.length > 0) {
    this.props.history.push('/home')
  } else if (this.props.thisUser && this.props.thisUser.job_role.length > 0) {
    this.props.history.push('/skill')
  } else {
    await this.props.getJobRole()
    await this.props.getIndustry()
  }
}

componentDidUpdate = async (prevProps) => {
  if (prevProps.error !== this.props.error && this.props.error) {
    alertErrorValidation(this.props.error)
    const check = captionErrorValidation(this.props.error, RULES_CATEGORY_INTEREST)
    this.setState({loading: false, validation: check})
  }
  if (this.props.response && prevProps.response !== this.props.response) {
    switch (this.props.response.type) {
      case GET_JOB_ROLE:
        this.setState({job_roles: this.props.response.data ?? []})
        break;
      case SET_JOB_ROLE:
        await this.props.setIndustry(this.state.form.company_segment)
        break;
      case GET_INDUSTRY:
        this.setState({categories: this.props.response.data ?? []})
        break;
      case SET_INDUSTRY:
        await this.props.userAction(this.props.response.data)
        this.setState({loading: false})
        toast.success(() => <div>Data added successfully!<br/>You will be redirected to the page <b>skill interest</b>.</div>, {
          position: toast.POSITION.TOP_CENTER
        })
        setTimeout(() => { this.props.history.push('/skill') }, 1000)
        break;
      default:
        break;
    }
  }
}

handleFormValidation = async (event) => {

    event.preventDefault()
    let rules = RULES_CATEGORY_INTEREST
    
    let validation = new Validator(this.state.form, rules);
    let isValid = validation.passes();
    let errors = validation.errors.all()
    this.setState({validation: errors})
    if (isValid) {
      this.setState({loading: true})
      await this.props.setJobRole(this.state.form.job_role)
    } else {
      Object.keys(errors).forEach(function(key) {
        errors[key].forEach((item) => {
          toast.error(item, {
            position: toast.POSITION.TOP_CENTER
          })
        })
      });
    }
}

  render () {
    return (
      <Landing
        isLogoHidden
        isBgPersonHidden
      >
        <div className='d-flex align-items-start flex-column'>
          <div className='mb-auto p-2 full-width'>
            <div className='row justify-content-center'>
              <div className='col-lg-5 col-sm-8'>
                <div className='ml-auto'>
                  <AuthCard
                    title='What your interest?'
                  >
                    <form onSubmit={this.handleFormValidation} className='text-left'>
                      <p>
                        Please select the job role and industry category of the
                        company that suits you
                      </p>
                      <div className='form-group'>
                        <CustomMultiSelect
                          showCheckbox
                          validation={this.state.validation['job_role']}
                          avoidHighlightFirstOption
                          closeOnSelect={false}
                          options={this.state.job_roles} 
                          onChange={this.handleInputChange}
                          displayValue='text' 
                          name='job_role'
                          placeholder='Choose a job role category'
                        />
                      </div>
                      <div className='form-group'>
                        <CustomMultiSelect
                          showCheckbox
                          validation={this.state.validation['company_segment']}
                          avoidHighlightFirstOption
                          closeOnSelect={false}
                          options={this.state.categories} 
                          onChange={this.handleInputChange}
                          displayValue='text' 
                          name='company_segment'
                          placeholder='Choose a company segment'
                        />
                      </div>
                      <button className='form-control rounded-pill p-4 btn btn-primary font-weight-medium'>
                      {
                        !this.state.loading ? (<div>Submit</div>) : 
                        (
                            <div>
                                <span className="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>
                                Loading...
                            </div>
                        )
                      }
                      </button>
                    </form>
                  </AuthCard>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Landing>
    )
  }
}

Category.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  thisUser: state.thisUser,
  error: state.error,
  invalid: state.invalid,
  response: state.response
})

const mapStateToDispatch = { 
  userAction,
  getJobRole,
  getIndustry,
  setJobRole, 
  setIndustry 
}

export default withRouter(connect(mapStateToProps, mapStateToDispatch)(Category))
