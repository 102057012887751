import React, {Component} from 'react';
import { Link } from "react-router-dom";


class Footer extends Component {
    render() {
        return (
            <div className="footer-land">
                <hr/>
                <div className={"row normal-font"}>
                    <div className="col-md-7 col-sm-12 pb-4 copyright-text">
                        <span className={"font-weight-medium"}>Copyright {new Date().getFullYear()} PT Hamparan Cipta Karyatama</span>
                    </div>
                    <div className="col-md col-sm-12 d-none d-sm-block px-1 pb-4 text-center">
                        <Link to={"/about"} className={"hl-primary-font"}>About Us</Link>
                    </div>
                    <div className="col-md col-sm-12 d-none d-sm-block px-1 pb-4 text-center">
                        <Link to={"/about"} className={"hl-primary-font"}>Privacy</Link>
                    </div>
                    <div className="col-md col-sm-12 d-none d-sm-block px-1 pb-4 text-center">
                        <Link to={"/about pb-2"} className={"hl-primary-font"}>Cookies</Link>
                    </div>
                    <div className="col-md col-sm-12 d-none d-sm-block px-1 pb-4 text-center">
                        <Link to={"/about"} className={"hl-primary-font"}>Terms</Link>
                    </div>
                    <div className="col-md col-sm-12 d-none d-sm-block px-1 pb-4 text-center">
                        <Link to={"/about"} className={"hl-primary-font"}>Help</Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer
