import {
  SET_IMAGE_GALLERY,
  SET_IMAGE_CAPTION,
  SET_IMAGE_GALLERY_LIST,
  SET_IMAGE_LOADING,
} from '../actions/responseTypes'

const initState = {
  loading: false,
  imageList: [],
  images:[],
  caption: ''
}

export const imageGallery = (state = initState, action) => {
  switch (action.type) {
    case SET_IMAGE_GALLERY:
      return { ...state, images: action.payload }
    case SET_IMAGE_GALLERY_LIST:
      return { ...state, imageList: action.payload }
    case SET_IMAGE_CAPTION:
      return { ...state, caption: action.payload }
    case SET_IMAGE_LOADING:
      return { ...state, loading: action.payload }
    default:
      return state
  }
}

const ResponseImageGallery = {
  imageGallery,
}

export default ResponseImageGallery
