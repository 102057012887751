import React from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import "./AdminLanding.css";
import Logo from "../../../images/hackalab-logo 1.svg";
import User from "../../../images/user-default.svg";
import MediaQuery from "react-responsive";
import NavList from "./NavList";
import { ReactComponent as IconLogout } from '../../../images/logout.svg'
import { logoutUser } from '../../../actions';

class AdminLanding extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      leftOpen: true,
      rightOpen: true,
    };
  }

  toggleSidebar = (event) => {
    let key = `${event.currentTarget.parentNode.id}Open`;
    this.setState({ [key]: !this.state[key] });
  };

  handlerLogout = async () => {
    await this.props.logoutUser()
  }

  render() {
    let leftOpen = this.state.leftOpen ? "open" : "closed";
    return (
      <div id="layout">
        <div id="left" className={leftOpen}>
          <div className="icon" onClick={this.toggleSidebar}>
            &equiv;
          </div>
          <div className={`sidebar ${leftOpen}`}>
            <div className="header">
              <img src={Logo} className="Admin-logo" alt="hacklab" />
            </div>
            <div className="content">
              <NavList />
            </div>
          </div>
        </div>

        <div id="main">
          <div className="header">
            <div className="" style={{ width: "80%" }}>
              <MediaQuery maxWidth={1024}>
                <form
                  className="form-inline my-2 my-lg-0 search-form"
                  style={{ marginLeft: 50, width: "80%" }}
                  onKeyDown={this.onEnterSearchCallback}
                  onChange={this.searchCallback}
                >
                  <input
                    onChange={this.searchCallback}
                    type="search"
                    placeholder="1Find something here..."
                    aria-label="Search"
                    style={{ width: "50%" }}
                  />
                  <button
                    onClick={this.onClickSearchCallback}
                    tabIndex="0"
                    onKeyDown={this.onEnterSearchCallback}
                    className="icon_search"
                  />
                </form>
              </MediaQuery>
              <MediaQuery minWidth={1024}>
                <div
                  className="form-inline my-2 my-lg-0 ml-4 search-form-dekstop search-form px-3 py-2"
                  style={{ width: "40%" }}
                  onKeyDown={this.onEnterSearchCallback}
                  onChange={this.searchCallback}
                >
                  <input
                    onChange={this.searchCallback}
                    type="search"
                    placeholder="Find something here..."
                    aria-label="Search"
                  />
                  <button
                    onClick={this.onClickSearchCallback}
                    tabIndex="0"
                    onKeyDown={this.onEnterSearchCallback}
                    className="icon_search"
                  />
                </div>
              </MediaQuery>
            </div>
            <div className="mr-3">
              <div class="btn-group">
                <button
                  type="button"
                  className="btn dropdown-toggle"
                  style={{ background : 'transparent' }}
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    src={User}
                    className="admin-ac rounded-circle mr-2"
                    alt="icon"
                  />
                </button>
                <div className="dropdown-menu">
                <div onClick={this.handlerLogout} className={`dropdown-item py-2 d-flex align-items-center`}><IconLogout className='hc-ic mr-2' /> Log Out</div>
                </div>
              </div>
            </div>
          </div>
          <div className="content">{this.props.children}</div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  thisUser: state.thisUser,
  auth: state.auth,
  error: state.error,
  invalid: state.invalid,
  responseDashboard: state.responseDashboard,
});

export default withRouter(connect(mapStateToProps, { logoutUser })(AdminLanding));
