import React from "react";
import AdminLanding from "../../../components/layouts/admin/AdminLanding";

function SkillPage() {
  return (
    <AdminLanding>
      <div className="container-admin">
        <h3>Skill & Interest</h3>
      </div>
    </AdminLanding>
  );
}

export default SkillPage;
