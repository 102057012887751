import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { userAction, getSkillInterest, setSkillInterest } from '../../../actions'
import { GET_SKILL_INTEREST, SET_SKILL_INTEREST } from '../../../actions/responseTypes'
import { AuthCard, Landing, CustomMultiSelect } from '../../../components'
import { Validator, RULES_SKILLS } from '../../../rules'
import { alertErrorValidation, captionErrorValidation } from '../../../utils'
class Skill extends Component {
  constructor (props) {
    super(props)
    this.state = {
      form: {items: []},
      validation: {},
      error: false,
      loading: false,
      options: [
        { text: 'Loading...', id: 1 },
      ]
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleFormValidation = this.handleFormValidation.bind(this)
}

handleInputChange = (name, value) =>  {
  this.setState(prevState => ({
    form : {
        ...prevState.form,
        [name]: value
    }
  }))
}

componentDidMount = async () => {
  if (this.props.thisUser && this.props.thisUser.job_role.length === 0) {
    this.props.history.push('/category')
  }
  // if(
  //   (
  //     this.props.thisUser && 
  //     this.props.thisUser.job_role.length > 0  && 
  //     this.props.thisUser.skill_interest.length > 0
  //   ) ||
  //   this.props.thisUser.work_experience.length === 0 ||
  //   this.props.thisUser.educational_background.length === 0 ||  
  //   this.props.thisUser.imageUrl === null ||
  //   this.props.thisUser.province === null ||
  //   this.props.thisUser.city === null ||
  //   this.props.thisUser.marital_status === null
  // ) {
  //   this.props.history.push('/profile/edit')
  // }
  // if (this.props.thisUser && this.props.thisUser.job_role.length > 0  && this.props.thisUser.skill_interest.length > 0) {
  //     this.props.history.push('/home')
  // }
  await this.props.getSkillInterest()
}

componentDidUpdate = async (prevProps) => {
  if (prevProps.error !== this.props.error && this.props.error) {
    alertErrorValidation(this.props.error)
    const check = captionErrorValidation(this.props.error, RULES_SKILLS)
    this.setState({loading: false, validation: check})
  }
  if (this.props.response && prevProps.response !== this.props.response) {
    switch (this.props.response.type) {
      case GET_SKILL_INTEREST:
        this.setState({options: this.props.response.data})
        break;
      case SET_SKILL_INTEREST:
        await this.props.userAction(this.props.response.data)
        this.setState({loading: true})
        toast.success(() => <div>Data saved successfully!<br/>You will be redirected to the page<b>edit profile</b>.</div>, {
          position: toast.POSITION.TOP_CENTER
        })
        if(
            this.props.thisUser.educational_background.length === 0 ||  
            this.props.thisUser.imageUrl === null ||
            this.props.thisUser.province === null ||
            this.props.thisUser.city === null ||
            this.props.thisUser.marital_status === null
          ) {
            setTimeout(() => { this.props.history.push('/profile/edit') }, 1000)
          } else {
            setTimeout(() => { this.props.history.push('/home') }, 1000)
          }
        break;
      default:
        break;
    }
  }
}

handleFormValidation = async (event) => {

  event.preventDefault()
  let rules = RULES_SKILLS
  
  let validation = new Validator(this.state.form, rules);
  let isValid = validation.passes();
  let errors = validation.errors.all()
  this.setState({validation: errors})
  if (isValid) {
    this.setState({loading: true})
    await this.props.setSkillInterest(this.state.form.skills)
  } else {
    Object.keys(errors).forEach(function(key) {
      errors[key].forEach((item) => {
        toast.error(item, {
          position: toast.POSITION.TOP_CENTER
        })
      })
    });
  }
}

  render () {
    return (
      <Landing
        isLogoHidden
        isBgPersonHidden
      >
        <div className='d-flex align-items-start flex-column'>
          <div className='mb-auto p-2 full-width'>
            <div className='row justify-content-center'>
              <div className='col-lg-5 col-sm-8'>
                <div className='ml-auto'>
                  <AuthCard title='What your skill interest?'>
                    <form className='text-left' onSubmit={this.handleFormValidation}>
                      <p>Please select the skill interest that suits you</p>
                      <div className='form-group'>
                        <CustomMultiSelect
                          showCheckbox
                          validation={this.state.validation['skills']}
                          avoidHighlightFirstOption
                          closeOnSelect={false}
                          options={this.state.options} 
                          onChange={this.handleInputChange}
                          displayValue='text' 
                          name='skills'
                          placeholder='Choose skill interest'
                        />
                      </div>
                      <button type='submit' className='form-control rounded-pill p-4 btn btn-primary font-weight-medium'>
                      {
                            !this.state.loading ? (<div>Submit</div>) : 
                            (
                                <div>
                                    <span className="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>
                                    Loading...
                                </div>
                            )
                        }
                      </button>
                    </form>
                  </AuthCard>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Landing>
    )
  }
}

Skill.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  thisUser: state.thisUser,
  error: state.error,
  invalid: state.invalid,
  response: state.response
})
  
export default withRouter(connect(mapStateToProps, { getSkillInterest, setSkillInterest, userAction })(Skill));
