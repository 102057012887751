import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Landing from '../../../components/layouts/Landing'
import AuthCard from '../../../components/cards/AuthCard'
import Mail from '../../../images/mail-sent.svg'
import { connect } from 'react-redux'

class VerifyCompanySuccess extends Component {
  constructor (props) {
    super(props)
    this.state = {
      email: new URLSearchParams(this.props.location.search).get('email')
    }
  }

  render () {
    return (
      <Landing
        isRecruiter
        isLogoHidden
        isBgPersonHidden
      >
        <div className='d-flex align-items-start flex-column'>
          <div className='mb-auto p-2 full-width'>
            <div className='row justify-content-center'>
              <div className='col-lg-5 col-sm-8'>
                <div className='ml-auto'>
                  <AuthCard>
                    <form className='text-center'>
                      <img src={Mail} alt='mail' />
                      <h5 className='pt-4'><b>Email Verified</b></h5>
                      <p>Your email <b>{this.props.thisUser.email}</b> has been verified as company account</p>
                      <Link to='/my-company' className='form-control rounded-pill p-4 btn btn-recruiter-primary font-weight-medium'>Go to your company dashboard</Link>
                      <Link to="/home" className="goback-text">Go back to your personal account</Link>
                    </form>
                  </AuthCard>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Landing>
    )
  }
}

const mapStateToProps = state => ({
  thisUser: state.thisUser,
  auth: state.auth,
  error: state.error,
  invalid: state.invalid,
  response: state.response
})

const mapStateToDispatch = {}

export default withRouter(connect(mapStateToProps, mapStateToDispatch)(VerifyCompanySuccess))

