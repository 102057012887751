import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import IcSallyJump from '../../images/sally-jump.svg'

class DashboardModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      title: this.props.title ?? 'Welcome to Hacklab!',
      desc: this.props.desc ?? 'Let\'s open job vacancies with us, get the best talent from hacklab',
      titleButton: this.props.titlebutton ?? ['Complete a CV Now', 'Complete a profile first'],
      doubleButton: this.props.doublebutton ?? true,
      hrefButton: this.props.hrefbutton ?? ['/', '/']
    }
  }

  render () {
    return (
      <Modal
        {...this.props}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        className='text-dark medium-font'
        contentClassName='bg-transparent no-border'
        centered
      >
        <Modal.Body className='bg-recommendation rounded-lg text-white'>
          <div className='d-flex align-items-center' style={{ height: '200px' }}>
            <div>
              <img alt='sally' src={IcSallyJump} className='d-lg-block d-none' style={{ width: '350px' }} />
            </div>
            <div className='p-2 flex-grow-1'>
              <h4 className='font-weight-medium'>{this.state.title}</h4>
              <p className='medium-font font-weight-medium'>
                {this.state.desc}
              </p>
              {
                this.state.doubleButton
                  ? (
                    <div className='row align-items-center'>
                      <Link to={`${this.state.hrefButton[0]}`} className='col-md-5 mx-1 col-12 btn btn-assessment normal-font font-weight-medium rounded-pill'>{this.state.titleButton[0]}</Link>
                      <div className='col-md-1 text-center col-12 normal-font'>Or</div>
                      <Link to={`${this.state.hrefButton[1]}`} className='text-white col-md-5 mx-1 col-12 btn btn-transparent normal-font font-weight-medium rounded-pill'>{this.state.titleButton[1]}</Link>
                    </div>
                    )
                  : (
                      <Link to={`${this.state.hrefButton}`} className='btn btn-recommendation no-border w-150px normal-font font-weight-medium rounded-pill'>{this.state.titleButton[0]}</Link>
                    )
                }
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

export default DashboardModal
