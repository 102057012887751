import { Link } from 'react-router-dom'
import React, { Component } from 'react'
import 'react-multi-carousel/lib/styles.css'
import HomeLanding from '../../components/layouts/HomeLanding'
import SimpleModal from '../../components/modals/SimpleModal'

class TalentReports extends Component {
  constructor (props) {
    super(props)
    this.state = {
      modalShow: false
    }
  }

  render () {
    return (
      <HomeLanding>
        <div className='row container-padding'>
          <div className='col-12 col-md-3'>
            <div className='card custom-sidebar-summary border-none text-left pb-0 mb-3 rounded-lg'>
              <div className='card-header white-br-bottom'>
                <h6 className='pt-2'><b>Settings </b></h6>
              </div>
              <div className='card-body pb-1'>
                <div>
                  <ul type='none' className='pl-0 normal-font'>
                    <li className='pb-3' key='username'>
                      <Link to='/settings' className='link-white large-font'>Username & Password</Link>
                    </li>
                    <li className='large-font font-weight-bold' key='reports'>
                      <Link to='/reports' className='link-white large-font'>Report Problem</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-9'>
            <div className='card bg-white border-none text-left pb-0 mb-3 rounded-lg'>
              <div className='card-header'>
                <h6 className='pt-2'><b>Report Problem </b></h6>
              </div>
              <div className='card-body'>
                <form>
                  <div className='form-group'>
                    <label className='pl-3 normal-font font-weight-medium'>Subject</label>
                    <input type='text' className='p-4 form-control rounded-pill custom-textfield' placeholder='Enter a subject' />
                  </div>
                  <div className='form-group'>
                    <label className='pl-3 normal-font font-weight-medium'>Message</label>
                    <textarea rows={4} className='form-control custom-textarea rounded-md' placeholder='Write a message here' />
                  </div>

                  <div className='row pt-5'>
                    <div className='col-6 col-md-3 pr-0'>
                      <Link to='/' className='normal-font form-control py-4 btn btn-primary rounded-pill'>Send Report</Link>
                    </div>
                    <div className='col-6 col-md-3pl-1'>
                      <Link to='/' className='normal-font form-control py-4 btn btn-recommendation-reverse rounded-pill'>Reset</Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <SimpleModal
          show={this.state.modalShow}
          title='The report has been sent'
          onHide={() => this.setState({ modalShow: false })}
        />
      </HomeLanding>
    )
  }
}

export default TalentReports
