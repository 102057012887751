import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ReactComponent as IcCompany } from '../../images/company.svg'
import { ReactComponent as IcBuilding } from '../../images/building2.svg'
import { ReactComponent as IcClose } from '../../images/close.svg'
import { ReactComponent as IcLink } from '../../images/circle-link.svg'
import { ReactComponent as IcWhatsapp } from '../../images/circle-whatsapp.svg'
import { ReactComponent as IcFacebook } from '../../images/circle-facebook.svg'
import { ReactComponent as IcTwitter } from '../../images/circle-twitter.svg'
import { ReactComponent as IcEmail } from '../../images/circle-email.svg'
import { useHistory ,useLocation } from 'react-router-dom';

class ShareModal extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  handleCopy = () => {
    var el = document.createElement('textarea');
    el.value = window.location.href;
    el.setAttribute('readonly', '');
    el.style = {position: 'absolute', left: '-9999px'};
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    toast.success(() => <div>Copied to Clipboard!</div>, {
      position: toast.POSITION.TOP_CENTER
    })
  }

  render () {
    const isMobile = ( ( window.innerWidth <= 800 ) && ( window.innerHeight <= 600 ) );
    const jobDetail = this.props.jobDetail;
    return (
      <Modal
        {...this.props}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        className='text-dark medium-font'
        contentClassName='bg-transparent no-border'
        centered
      >
        <Modal.Body className='bg-white rounded-lg'>
          <IcClose className='hc-ic float-right' onClick={this.props.onHide} />
          <div className='d-flex align-items-center'>
            <div className='px-4'>
              {jobDetail?.company_detail?.logo_link ? <img src={jobDetail.company_detail.logo_link} alt="company" className="avatar rounded-circle"/>:
              <IcBuilding className='avatar rounded-circle' />}
            </div>
            <div className='px-4 flex-grow'>
              <div className='d-flex align-content-between flex-wrap'>
                <div className='full-width'>
                  <span className='medium-font font-weight-medium'>
                    {jobDetail.title}
                  </span>
                  <p className='normal-font'>Estimated Sallary IDR <span className='color-salary font-weight-medium'> {jobDetail.sallary_min} - {jobDetail.sallary_max}</span></p>
                  <p className='normal-font text-muted'>{jobDetail?.company_detail?.company_name} - {jobDetail?.city_detail?.name}, ID</p>
                </div>
              </div>
            </div>
            <div />
          </div>
          <hr className='mb-0' />
          <div className='text-center'>
            <h4 className='mt-2 medium-font'>Share this on</h4>
            <div className='d-flex justify-content-center'>
              <div className='p-2 cursor-pointer' onClick={this.handleCopy}>
                <IcLink />
                <span className='d-block small-font mt-2'>Copy Link</span>
              </div>
              <div className='p-2 cursor-pointer' onClick={() => window.open(`mailto:?subject=I wanted you to see this site&amp;body=Check out this site ${window.location.href}.`, '')}>
                <IcEmail />
                <span className='d-block small-font mt-2'>Email</span>
              </div>
              <div className='p-2 cursor-pointer' onClick={() => window.open(`${isMobile ? 'whatsapp://' : 'https://web.whatsapp.com/'}send?text=${window.location.href}`)}>
                <IcWhatsapp />
                <span className='d-block small-font mt-2'>Whatsapp</span>
              </div>
              <div className='p-2 cursor-pointer' onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`, '')}>
                <IcFacebook />
                <span className='d-block small-font mt-2'>Facebook</span>
              </div>
              <div className='p-2 cursor-pointer' onClick={() => window.open(`https://twitter.com/share?url=${window.location.href}`, '')}>
                <IcTwitter />
                <span className='d-block small-font mt-2'>Twitter</span>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

export default ShareModal
