import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { registerUser } from '../../actions';
import { toast } from 'react-toastify';
import Google from '../../images/icons_google.svg'
import 'react-toastify/dist/ReactToastify.css';
import { AuthCard, Landing, AccountCard, CustomInput } from "../../components";
import { Validator, RULES_REGISTER } from '../../rules'
import { response } from '../../reducers/responseReducer';
import { REGISTER_USER } from '../../actions/responseTypes';
import { alertErrorValidation, captionErrorValidation } from '../../utils';

let defaultRegist = {
  name: "",
  email: "",
  password: "",
  phone_number: "",
}
class Register extends Component {

  constructor(props) {
    super(props)
    this.state = {
        modalShow: true,
        form: {},
        error: false,
        loading: false,
        validation: {},
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleFormValidation = this.handleFormValidation.bind(this)
  }

  handleInputChange = (name, value) =>  {
    this.setState(prevState => ({
        form : {
            ...prevState.form,
            [name]: value
        }
      }))
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
        this.props.history.push('/home')
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.response !== this.props.response && this.props.response) {
      switch (this.props.response.type) {
        case REGISTER_USER:
          toast.success(() => <div>Congratulations! You have successfully registered. <br/>Please verify your email before signing in.</div>, {
            position: toast.POSITION.TOP_CENTER
          })
          this.setState({loading: false})
          // setTimeout(() => { this.props.history.push('/verify') }, 1000)
          this.setState({ form: defaultRegist })
          break
        default:
          break
      }

    }
    if (prevProps.error !== this.props.error && this.props.error) {
      alertErrorValidation(this.props.error)
      const check = captionErrorValidation(this.props.error, RULES_REGISTER)
      this.setState({loading: false, validation: check})
    }
    if (prevProps.auth !== this.props.auth && this.props.auth.isAuthenticated) {
        this.props.history.push('/home')
        this.setState({loading: false})
    }
  }

  handleFormValidation = async (event) => {       
    event.preventDefault()
    let rules = RULES_REGISTER
    
    let validation = new Validator(this.state.form, rules);
    let isValid = validation.passes();
    let errors = validation.errors.all()
    this.setState({validation: errors})
    if (isValid) {
        this.setState({loading: true})
        await this.props.registerUser(this.state.form)
    } else {
      Object.keys(errors).forEach(function(key) {
        errors[key].forEach((item) => {
          toast.error(item, {
            position: toast.POSITION.TOP_CENTER
          })
        })
      });
    }
	}

  render () {
    return (
      <Landing
        isLogoHidden={true}
        top={
          <div className='p-2 mt-5 d-none d-md-block'>
            <span className='text-white header-font'>
              Connect with <b>2.4 billion users</b><br />
              and <b>3 billion company</b><br />
              around the world.
            </span>
          </div>
                }
      >
        <div className='d-flex align-items-start flex-column'>
          <div className='mb-auto p-2 full-width'>
            <div className='row justify-content-center'>
              <div className='col-lg-8 col-md-7 d-none d-md-block mt-3'>
                {/* <span className='font-weight-medium'>Login as:</span>
                <AccountCard /> */}
              </div>
              <div className='col-lg-4 col-md-5 col-sm-8'>
                <div className='ml-auto half-height'>
                  <AuthCard
                    title='Create new account'
                  >
                    <div>
                      <p className='normal-font'>
                        Join Hacklab and connect with 2.4 billion users and 3 billion company around the world
                      </p>
                      <form onSubmit={this.handleFormValidation} >
                        <div className='form-group'>
                            <CustomInput
                                validation={this.state.validation['name']}
                                value={this.state.form.name}
                                placeholder="Your Name"
                                onChange={this.handleInputChange}
                                name="name"
                                id="name"
                            />
                        </div>
                        <div className='form-group'>
                            <CustomInput
                                validation={this.state.validation['phone_number']}
                                value={this.state.form.phone_number}
                                placeholder="Phone Number"
                                onChange={this.handleInputChange}
                                name="phone_number"
                                id="phone_number"
                            />
                        </div>
                        <div className='form-group'>
                            <CustomInput
                                validation={this.state.validation['email']}
                                value={this.state.form.email}
                                placeholder="Email"
                                onChange={this.handleInputChange}
                                name="email"
                                id="email"
                            />
                        </div>
                        <div className='form-group'>
                            <CustomInput
                                validation={this.state.validation['password']}
                                value={this.state.form.password}
                                onChange={this.handleInputChange}
                                placeholder="Password"
                                type="password"
                                name="password"
                                id="password"
                            />
                        </div>

                        <p className='normal-font'>
                          By creating hacklab account, you agree to our <Link to='/' className='hl-font-link font-weight-bold'>Terms, Data Policy</Link> and <Link className='hl-font-link font-weight-bold'>Cookies Policy</Link>. You may receive SMS notifications
                          from us and can opt out at any time.
                        </p>

                        <button type='submit' className='normal-font p-4 form-control btn btn-primary rounded-pill mb-3'>
                        {
                            !this.state.loading ? (<div>Create your Hacklab account</div>) : 
                            (
                                <div>
                                    <span className="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>
                                    Loading...
                                </div>
                            )
                        }
                        </button>

                        <p className='text-center full-width normal-font'>
                          Do you have an account? <Link to='/login' className='hl-font-link font-weight-bold'>Login Here</Link>
                        </p>
                        {/* <div className={"separator pb-3"}>or</div> */}
                        {/* <button className={"form-control py-3 normal-font text-center btn btn-light"}><img alt={"icon"} className={"pr-2 btn-icon"} src={Google}/>Continue with Google</button> */}
                      </form>
                    </div>
                  </AuthCard>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Landing>
    )
  }
}

Register.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    error: state.error,
    thisUser: state.thisUser,
    invalid: state.invalid,
    response: state.response
});
  
export default withRouter(connect(mapStateToProps, { registerUser })(Register));
