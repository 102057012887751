import React from 'react'

const CustomTextarea = (props) => {
  let isValid = true
  let errorMessage = ''

  const handleInputChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    if (name !== undefined) {
      props.onChange(name, value)
    }
    // props.onChange(event)
  }

  if (props.validation) {
    isValid = !((props.validation ?? []).length > 0)
    errorMessage = (props.validation ?? [''])[0]
  }

  // console.log('Not re-rendering? ', this.props.validation)

  return (

    <div className='w-100'>
      <textarea
        disabled={props.disabled}
        placeholder={props.placeholder}
        required={props.required}
        rows={props.rows}
        value={props.value}
        id={props.id}
        name={props.name}
        onChange={handleInputChange}
        className={`${props.className} form-control custom-textarea rounded-md`}
        style={{paddingTop:20}}
      />
      {(props.helperText || !isValid) && (<span className={`${isValid ? 'text-muted' : 'text-danger'} normal-font`}>{isValid ? props.helperText : errorMessage}</span>)}
    </div>

  )
}

CustomTextarea.propTypes = {}
export default (CustomTextarea)
