import { Link, withRouter } from 'react-router-dom'
import React, { Component } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import InfiniteScroll from 'react-infinite-scroll-component'
import HomeLanding from '../../components/layouts/HomeLanding'
import { ReactComponent as IcClock } from '../../images/clock.svg'
import { ReactComponent as IcCase } from '../../images/suitcase.svg'
import { ReactComponent as IcUsers } from '../../images/users.svg'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getCompanies } from '../../actions'
import { GET_COMPANY } from '../../actions/responseTypes'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { currency, capitalize } from '../../utils/stringHelpers'
import _ from 'lodash'
import { alertErrorValidation } from '../../utils'
import Select from 'react-select/async'
import axios from 'axios'


const filterSearch = async (inputValue) => {
  const token = localStorage.jwtToken
  try {
    const res = await axios.get(`${process.env.REACT_APP_BASE_API}/v1/master/location?q=${inputValue}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    const optionLocation = await res.data.map(option => {
      return {
        value: option.id,
        label: option.name,
      }
    })

    return optionLocation
  } catch (e) {
    console.log(e)
  }
}

const promiseOptions = inputValue =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(filterSearch(inputValue));
    }, 1000);
  });

class Companies extends Component {
  constructor (props) {
    super(props)
    this.state = {
      items: [],
      page: 1,
      maxPage: 1,
      search: '',
      totalCompany: 0,
      showListCompany: "1-10",
      selectedLocation: '',
    }
    this.fetchMoreData = this.fetchMoreData.bind(this)
  }

  fetchMoreData () {
    this.setState({page: (this.state.page + 1)}, () => {
      this.props.getCompanies(this.state.page, 10, this.state.search)
    })
  };

  componentDidMount = async () => {
    await this.props.getCompanies(1, 10, this.state.search)
    const res = await this.props.response
    if(res) {
      this.setState({
        items: res?.data?.data ?? [],
      })
    }
  }
  
  componentDidUpdate = async (prevProps) => {
    if (prevProps.error !== this.props.error && this.props.error) {
      alertErrorValidation(this.props.error)
      this.setState({loading: false})
    }
    if (this.props.response && prevProps.response !== this.props.response) {
      switch (this.props.response.type) {
        case GET_COMPANY:
          const data = this.props.response.data
          const isExists = (data.data.length !== 0)

          this.setState(prevState => ({
            maxPage: data.last_page,
            items: [
              ...prevState.items,
              ...data.data
            ],
            totalCompany: isExists ? data.total : 0,
            showListCompany: `1 - ${data?.to ?? "0"}`,
          }))
          break;
        default:
          break;
      }
    }
  }

  fetchSearch = async () => {
    this.setState({items: []})
    await this.props.getCompanies(1, 10, 'no', this.state.search, this.state.selectedLocation)   
  }

  selectedValue = (location) => {
    this.setState({selectedLocation: location.value})
  }

  render () {

    return (
      <HomeLanding search={false}>
        <div className='container-padding'>
          <div className='card bg-white border-none text-left pb-0 mb-3 rounded-lg'>
            <div className='card-body text-center'>
              <h5><b>Search Company</b></h5>
              <div className='row pt-3'>
                <div className='col-md-5'>
                  <div className='form-group'>
                    <input onChange={(e) => {this.setState({search : e.target.value})}} type='text' className='p-4 form-control rounded-pill custom-textfield' placeholder='Search by company name' />
                  </div>
                </div>
                <div className='col-md-5'>
                  <div className='form-group'>
                  <Select 
                    placeholder="Search by city or province"
                    className="select-dropdown-wrapper"
                    cacheOptions 
                    defaultOptions
                    loadOptions={promiseOptions}
                    onChange={this.selectedValue}
                    classNamePrefix="select-dropdown"
                  />
                    {/* <input type='text' className='p-4 form-control rounded-pill custom-textfield' placeholder='Search by city or province' /> */}
                  </div>
                </div>
                <div className='col-md-2'>
                  <div className='form-group'>
                    <button type='submit' onClick={this.fetchSearch} className='normal-font py-4 form-control px-3 btn btn-primary rounded-pill'>Search</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex'>
            <div className='p-2 font-weight-medium'>{this.state.showListCompany} of {this.state.totalCompany} company matches</div>
            <div className='p-2 flex-grow-1'><hr /></div>
          </div>
          {/* INFINITE SCROLL */}
          <InfiniteScroll
            dataLength={this.state.items.length}
            next={this.fetchMoreData}
            hasMore={this.state.page < this.state.maxPage}
            className='row'
            loader={
              <div className='col-12 d-flex align-item-center pb-4 justify-content-center'>
                <span className='medium-font text-muted'>Loading...</span>
              </div>
            }
          >
            {this.state.items.map((i, index) => (
              <div onClick={() => this.props.history.push(`/company/${i.uuid}`)} key={`list-company-${i.uuid}`} className='col-md-6 col-sm-12 cursor-pointer'>
                <div className='card bg-white border-none text-left pb-0 mb-3 rounded-lg'>
                  <div className='card-body'>
                    <div className='row'>
                      <div className='col-md-2 col-sm-12'>
                        {/* <IcCompany className='avatar-sm rounded-circle' /> */}
                        <img className="avatar-sm rounded-circle" src={ i?.logo_link ? i.logo_link : "https://hacklab.rocks/bctn/wp-content/uploads/2021/09/QxdSiwVuTEwjPDMVYr8Tl5OIeiy6RffYFsDF8Jgr-removebg-preview.png"} alt="company-logo" />
                      </div>
                      <div className='col-md-10 col-sm-12'>
                        <div>
                          <span className='medium-font'>
                            <b>
                              {i.company_name}
                            </b>
                          </span>
                          <p className='normal-font'>{i?.industry_category_detail?.industry_category ?? '-'}</p>
                          <p className='normal-font text-muted'>{capitalize(i?.province_detail?.name ?? '-')}, ID</p>
                        </div>
                        <hr />
                        <div className='d-flex py-1 text-left normal-font align-items-center justify-content-start'>
                          <IcUsers className='hc-small-ic mr-2 svg-black' /> {i?.number_of_employee_detail?.range ?? '-'} employees
                        </div>
                        <div style={{color: `${(i.available_job_post ?? 0) > 0 ? '#62C3D0' : ''}`}} className='d-flex py-1 text-left normal-font align-items-center justify-content-start'>
                          <IcCase className='hc-small-ic mr-2 svg-black' /> {(i.available_job_post ?? 0) > 0 ? `${i.available_job_post} active job vacancies` : 'No active job vacancies'} 
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='card-footer bg-transparent'>
                    <div className='row'>
                      <div className='offset-2 col-10'>
                        <div className='d-flex py-1 text-left normal-font align-items-center justify-content-start'>
                          <IcClock className='hc-small-ic mr-2 svg-black' /> Last active 2 hours ago
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </HomeLanding>
    )
  }
}

Companies.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  thisUser: state.thisUser,
  error: state.error,
  responseDashboard: state.responseDashboard,
  invalid: state.invalid,
  response: state.response,
  responseDashboard: state.responseDashboard,
})

export default withRouter(connect(mapStateToProps, { getCompanies })(Companies))
