import { Link, withRouter } from 'react-router-dom'
import React, { Component } from 'react'
import User from '../../images/user-1.svg'
import Carousel from 'react-multi-carousel'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import 'react-multi-carousel/lib/styles.css'
import InfiniteScroll from 'react-infinite-scroll-component'
import HomeLanding from '../../components/layouts/HomeLanding'
import { ReactComponent as IcStar } from '../../images/star.svg'
import { ReactComponent as IcClock } from '../../images/clock.svg'
import { ReactComponent as IcVideo } from '../../images/video.svg'
import { ReactComponent as IcCase } from '../../images/suitcase.svg'
import { ReactComponent as IcCamera } from '../../images/camera.svg'
import { ReactComponent as IcCompany } from '../../images/company.svg'
import { ReactComponent as IcApp } from '../../images/applications.svg'
import { ReactComponent as IcChevronRight } from '../../images/chevron_right.svg'
import { ReactComponent as IcAddRectangle } from '../../images/add.svg'
import companyPlaceholder from '../../images/company-placeholder.svg'
import DashboardModal from '../../components/modals/DashboardModal'
import { getJobs, getSuggestedCompany, getCurrent } from '../../actions/dashboard'
import { applyJob } from '../../actions'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash'
import { APPLY_JOB } from '../../actions/responseTypes'
import { alertErrorValidation } from '../../utils'

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [],
      itemSuggested: [],
      modalShow: false,
      page: 1,
      perPage: 10,
      pageSuggested: 1,
      perPageSuggested: 10,
      hasMore: false,
      search: '',
      isLoading: false,
      currentPosition: '',
    }
    this.fetchMoreData = this.fetchMoreData.bind(this)
  }

  componentDidMount = async () => {
    // redirect to profile for release 31-08-2021
    // this.props.history.push('/profile')
    this.setState({isLoading: true})

    const { page, perPage, pageSuggested, perPageSuggested } = this.state

    await this.props.getSuggestedCompany(pageSuggested, perPageSuggested)
    await this.props.getJobs(page, perPage, '')
    await this.props.getCurrent()
    const res = await this.props.responseDashboard
    this.setState({
      items: res?.jobs ?? [],
      hasMore: true,
      currentPosition: res?.current?.current_position,
      itemSuggested: res?.suggestedCompany ?? [],
    })
    this.setState({isLoading: false})

  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.error !== this.props.error && this.props.error) {
      alertErrorValidation(this.props.error)
    }
    if (prevProps.response !== this.props.response && this.props.response) {
      switch (this.props.response.type) {
        case APPLY_JOB:
          toast.success(() => <div>Pendaftaran berhasil!</div>, {
            position: toast.POSITION.TOP_CENTER
          })
          break
        default:
          break
      }

    }
  }

  handleApply = (item) => {
    if (item.has_quiz.quiz) {
      this.props.history.push(`/jobs/quiz/${item.uuid}`)
    } else {
      this.props.applyJob(item.uuid)
    }
  }

  handleOnClickCard = (uuid) => {
    this.props.history.push(`/jobs/${uuid}`)
  }

  fetchMoreData = async () => {
    this.setState({isLoading: true})

    const { page, perPage } = this.state

    await this.props.getJobs(page + 1, perPage)
    this.setState({ page: this.state.page + 1 })

    const res = await this.props.responseDashboard
    if (res.jobs.length <= 0) {
      this.setState({
        hasMore: false,
      })
    }
    if (res.jobs.length > 0) {
      setTimeout(() => {
        this.setState({
          items: this.state.items.concat(res.jobs),
        })
      }, 1500)
    }
    this.setState({isLoading: false})

  }

  formatMoney = amount => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  onEnterSearch = async () => {
    this.setState({isLoading: true})
    await this.props.getJobs(1, 10, this.props.responseDashboard.search)
    const res = await this.props.responseDashboard
    this.setState({
      items: res?.jobs ?? [],
      hasMore: res?.jobs?.length > 0 ? true : false
    })
    this.setState({isLoading: false})
  }

  onClickSearch = async () => {
    this.setState({isLoading: true})

    await this.props.getJobs(1, 10, this.props.responseDashboard.search)
    const res = await this.props.response
    this.setState({
      items: res?.jobs ?? [],
      hasMore: res?.jobs?.length > 0 ? true : false
    })
    this.setState({isLoading: false})

  }

  render() {
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2,
        slidesToSlide: 1, // optional, default to 1.
        partialVisibilityGutter: 40,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2, // optional, default to 1.
        partialVisibilityGutter: 30,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
        partialVisibilityGutter: 20,
      },
    }

    return (
      <HomeLanding 
        onEnterSearch={this.onEnterSearch}
        onClickSearch={this.onClickSearch}
      >
        <div className='row container-padding'>
          <div className='col-12 col-md-3'>
            <div className='wrapper-card sticky-top mt-sidebar'>
              <div className='card custom-sidebar-summary border-none text-left mb-3 rounded-lg'>
                <div className='card-body'>
                  <div className='text-center'>
                    <div>
                      <img
                        src={this.props.thisUser.image_url ?? User}
                        className='avatar rounded-circle'
                        alt='icon'
                      />
                    </div>
                    <div className='medium-font font-weight-medium mb-2 mt-2'>
                      {this.props.thisUser.name}
                    </div>
                    {this.props.thisUser.work_experience &&
                    this.props.thisUser.work_experience.length > 0 ? (
                      <div className='normal-font'>
                        {!this.state.isLoading ? 
                          this.state.currentPosition?.job_position + " at " +
                          this.state.currentPosition?.company
                          : <div></div>
                        }
                      </div>
                    ) : (
                      '-'
                    )}
                  </div>
                  {/* <hr className='bg-white' /> */}

                  {/* <div className='d-flex py-1'>
                    <div className='text-left normal-font'>Viewed profile</div>
                    <div className='ml-auto normal-font font-weight-medium'>
                      {(this.props.thisUser.profile_views.company ?? 0) +
                        (this.props.thisUser.profile_views.users ?? 0)}
                    </div>
                  </div> */}
                  {/* <div className='d-flex py-1'>
                    <div className='text-left normal-font'>
                      Views of your post
                    </div>
                    <div className='ml-auto normal-font font-weight-medium'>
                      {this.props.thisUser.job_apply ?? 0}
                    </div>
                  </div>

                  <hr className='bg-white' />

                  <div className='d-flex py-1'>
                    <div className='text-left normal-font'>
                      <IcApp className='hc-small-ic svg-white' /> My
                      Applications
                    </div>
                    <div className='ml-auto normal-font font-weight-medium'>
                      {this.props.thisUser.job_apply ?? 0}
                    </div>
                  </div> */}
                  {/* <div className="d-flex py-1">
                   <div className="text-left normal-font">
                       <IcEvent className={"hc-small-ic svg-white"}/> My Events
                   </div>
                   <div className="ml-auto normal-font font-weight-medium">0</div>
                </div>
                <div className="d-flex py-1">
                   <div className="text-left normal-font">
                       <IcCourses className={"hc-small-ic svg-white"}/> My Courses
                   </div>
                   <div className="ml-auto normal-font font-weight-medium">0</div>
                </div>
                <div className="d-flex py-1">
                   <div className="text-left normal-font">
                       <IcAssessment className={"hc-small-ic svg-white"}/> Assessment
                   </div>
                   <div className="ml-auto normal-font font-weight-medium">0</div>
                </div> */}
                </div>
              </div>
              {/* {this.props.thisUser.companies.length === 0 && (
                <>
                <Link to={'/create-company'} className='create-company-home'>
                  <div
                    className='card text-center pt-4 pb-3 align-items-center rounded-lg'
                    style={{ backgroundColor: '#62C3D0', border: 'none' }}
                  >
                    <div
                      className='d-flex mb-4'
                      style={{
                        border: '2px dashed #fff',
                        width: '80px',
                        height: '80px',
                        borderRadius: '50%',
                      }}
                    >
                      <IcAddRectangle className='hc-ic align-self-center mx-auto' />
                    </div>
                    <h6 className='font-weight-bold text-white'>
                      Create Company Page
                    </h6>
                    <p className='text-white'>
                      Create your company account <br />
                      and start recruit talent
                    </p>
                  </div>
                </Link>
                <br/>
                </>
              )}  */}
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='card bg-white border-none text-left pb-0 rounded-lg'>
              {/* <div className='card-body'>
                <form>
                  <div>
                    <textarea
                      rows={4}
                      className='form-control custom-textarea rounded-md'
                    />
                  </div>
                  <div className='row mt-3'>
                    <div className='col-6 v1 text-center cursor-pointer'>
                      <div className='d-flex align-items-center justify-content-center text-orange font-weight-medium'>
                        <IcCamera className='hc-ic mr-1' /> Photo
                      </div>
                    </div>
                    <div className='col-6 text-center cursor-pointer'>
                      <div className='d-flex align-items-center justify-content-center text-orange font-weight-medium'>
                        <IcVideo className='hc-ic mr-1' /> Video
                      </div>
                    </div>
                  </div>
                </form>
              </div> */}
            </div>
            {/* STATUS */}
            {/* <div className='card bg-recommendation border-none text-left pb-0 mb-3 rounded-lg'>
              <div className='card-body'>
                <h6>
                  <b>Suggested Company for you</b>
                </h6>
                <Carousel
                  responsive={responsive}
                  ssr
                  infinite
                  partialVisbile
                  containerClass='carousel-container'
                  removeArrowOnDeviceType={['tablet', 'mobile']}
                  deviceType={this.props.deviceType}
                >
                  {this.state.itemSuggested.map((item, i) => (
                    <Link
                      to={`/company/${item.uuid}`}
                      key={`jobs-${i}`}
                      className='card custom-sm-card-2 mr-3 text-center shadow-sm mb-2 rounded-md'
                    >
                      <div className='card-body'>
                        <div className='d-flex align-items-center flex-column'>
                          <div>
                            {item.logo_link !== null ? (
                              <img
                                className='avatar-sm mt-3 rounded-circle'
                                src={item.logo_link}
                              />
                            ) : (
                              <div className='avatar-sm mt-3 rounded-circle' />
                            )}
                          </div>
                          <div>
                            <p className='mb-0 normal-font text-dark font-weight-medium'>
                              {item.company_name}
                            </p>
                            <p className='mb-0 small-font text-dark'>
                              {item.industry_category}
                            </p>
                            <p className='mb-0 small-font text-muted'>
                              {_.startCase(
                                _.toLower(item?.province_detail?.name ?? '-'),
                              )}
                              , {item.country}
                            </p>
                          </div>
                          <div className='mt-auto p-2'>
                            <hr className='p-0 mb-0 mt-2' />
                            <small className='text-muted'>
                              {item.available_job_post} Jobs available to apply
                            </small>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </Carousel>
                <div className='d-flex align-item-center justify-content-center'>
                  <Link
                    to='/'
                    className='d-inline normal-font py-2 px-3 mx-4 btn btn-recommendation rounded-pill'
                  >
                    See more
                  </Link>
                </div>
              </div>
            </div> */}
            {/* INFINITE SCROLL */}
            <InfiniteScroll
              dataLength={this.state.items.length}
              next={this.fetchMoreData}
              hasMore={this.state.hasMore}
              loader={
                <div className='d-flex align-item-center pb-4 justify-content-center'>
                  <span className='medium-font text-muted'>Loading...</span>
                </div>
              }
            >
              {
              this.state.items.length === 0 && !this.state.isLoading ? <div className="d-flex align-self-center justify-content-center ">No Data</div> :
              this.state.items.map((item, index) => (
                <div
                  key={`feed-${item.id}`}
                  className='card bg-white border-none text-left pb-0 mb-3 rounded-lg'
                >
                  <div className='card-body' onClick={() => {this.handleOnClickCard(item.uuid)}}>
                    <div className='row'>
                      <div className='col-md-2 col-sm-12'>
                        {/* <IcCompany className='avatar-sm rounded-circle' /> */}
                        <img className="avatar-sm rounded-circle" src={ item?.company_detail?.logo_link ? item.company_detail.logo_link : companyPlaceholder} alt="company-logo" />
                      </div>
                      <div className='col-md-10 col-sm-12'>
                        <div>
                          <span className='medium-font'>
                            <b>{item?.title ?? '-'}</b>
                          </span>
                          <p className='normal-font text-muted mb-1'>
                            {_.startCase(
                              _.toLower(
                                item?.city_detail?.name ?? '-',
                              ),
                            )}
                            , {item?.province_detail?.name ?? '-'} { item.can_work_remotely ? '(Remote)' : null }
                          </p>
                          { item?.sallary_min && item?.sallary_max ? <p className='normal-font'>
                            Estimated Sallary{' IDR '}
                            <span className='color-salary font-weight-medium'>
                              {this.formatMoney(item?.sallary_min ?? '-')} -{' '}
                              {this.formatMoney(item?.sallary_max ?? '-')}
                            </span>
                          </p> : null }
                          <p className='normal-font text-muted'>
                            {item?.company_detail?.company_name ?? '-'}
                          </p>
                        </div>
                        <hr />
                        <div className='d-flex py-1 text-left normal-font align-items-center justify-content-start'>
                          <IcStar className='hc-small-ic mr-2 svg-black' />{' '}
                          {item?.job_level_detail?.level ?? '-'}
                        </div>
                        <div className='d-flex py-1 text-left normal-font align-items-center justify-content-start'>
                          <IcClock className='hc-small-ic mr-2 svg-black' />{' '}
                          {item?.job_type_detail?.type ?? '-'}
                        </div>
                        <div className='d-flex py-1 text-left normal-font align-items-center justify-content-start'>
                          <IcCase className='hc-small-ic mr-2 svg-black' />{' '}
                          {item?.job_role_detail?.job_role ?? '-'}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='card-footer bg-transparent'>
                    <div className='row'>
                      <div className='offset-2 col-10'>
                        <div
                          className='d-inline normal-font py-2  mr-2'
                        >
                          <div className="d-inline">
                          <span className="normal-font mt-1">Posted {item?.created_at_detail?.human} {'  '} • {'  '} {item?.people_applied} Applicants</span>
                          </div>
                        </div>
                        {/* <Link
                          to='/'
                          className='d-inline normal-font py-2 px-3 btn btn-recommendation-reverse rounded-pill'
                        >
                          Save Job
                        </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </InfiniteScroll>
          </div>
          <div className="col-12 col-md-3">
          {this.props.thisUser.companies.length === 0 && (
                <>
                <Link to={'/create-company'} className='create-company-home'>
                  <div
                    className='card text-center pt-4 pb-3 align-items-center rounded-lg'
                    style={{ backgroundColor: '#62C3D0', border: 'none' }}
                  >
                    <div
                      className='d-flex mb-4'
                      style={{
                        border: '2px dashed #fff',
                        width: '80px',
                        height: '80px',
                        borderRadius: '50%',
                      }}
                    >
                      <IcAddRectangle className='hc-ic align-self-center mx-auto' />
                    </div>
                    <h6 className='font-weight-bold text-white'>
                      Create Company Page
                    </h6>
                    <p className='text-white'>
                      Create your company account <br />
                      and start recruit talent
                    </p>
                  </div>
                </Link>
                <br/>
                </>
              )} 
          </div>


          {/* <div className='col-12 col-md-3'>
            <div className='card sticky-top mt-sidebar custom-sidebar-summary border-none text-left pb-0 mb-3 rounded-lg'>
              <div className='card-header'>
                <h6 className='pt-2'>
                  <b>Upcoming Events </b>
                </h6>
              </div>
              <div className='card-body'>
                <div>
                  <ol type='1' className='pl-3 normal-font font-weight-bold'>
                    {[...Array(5)].map((x, i) => (
                      <li className='pb-3' key={`event${i}`}>
                        <span>
                          <b>Webinar UX Design</b>
                          <br />
                          <span className='font-weight-normal'>
                            Stacey Gordon | 7 February 2021
                          </span>
                        </span>
                      </li>
                    ))}
                  </ol>
                </div>
                <div className='normal-font d-flex align-items-center justify-content-end font-weight-medium'>
                  <Link to='/' className='text-white'>
                    Show more assessments
                    <IcChevronRight className='hc-ic ml-2 svg-white' />
                  </Link>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <DashboardModal
          show={this.state.modalShow}
          onHide={() => this.setState({ modalShow: false })}
        />
      </HomeLanding>
    )
  }
}

Home.propTypes = {
  auth: PropTypes.object.isRequired,
  thisUser: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  auth: state.auth,
  thisUser: state.thisUser,
  response: state.response,
  responseDashboard: state.responseDashboard,
  suggestedCompany: state.suggestedCompany,
})

const mapPropsToDispatch = {
  getJobs,
  getSuggestedCompany,
  applyJob,
  getCurrent,
}

export default withRouter(connect(mapStateToProps, mapPropsToDispatch)(Home))
