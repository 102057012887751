import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import Landing from '../../components/layouts/Landing'
import AuthCard from '../../components/cards/AuthCard'
import { getCity, getProvince, createCompany, getUser } from '../../actions'
import { CREATE_COMPANY, GET_CITY, GET_PROVINCE } from '../../actions/responseTypes'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { RULES_COMPANY_REGISTER, Validator } from '../../rules'
import { connect } from 'react-redux'
import { CustomInput, CustomSelect } from '../../components'
import { alertErrorValidation, captionErrorValidation } from '../../utils'

class RecruiterRegister extends Component {

  constructor(props) {
    super(props)
    this.state = {
        modalShow: true,
        form: {
          company_name: '',
          province: '',
          city: ''
        },
        error: false,
        loading: false,
        cityLoading: false,
        validation: {},
        provinces: [
          { name: 'Loading...', id: 1 },
        ],
        cities: [
          { name: 'Loading...', id: 1 },
        ],
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleFormValidation = this.handleFormValidation.bind(this)
  }

  handleInputChange = (name, value) =>  {
    this.setState(prevState => ({
      form : {
          ...prevState.form,
          [name]: value
      }
    }))
    if (name === 'province') {
      this.props.getCity(value)
      this.setState({cityLoading: true})
    }
  }

  componentDidMount = () => {
    this.props.getProvince()
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.error !== this.props.error && this.props.error) {
      alertErrorValidation(this.props.error)
      const check = captionErrorValidation(this.props.error, RULES_COMPANY_REGISTER)
      this.setState({loading: false, validation: check})
  }
  if (prevProps.response !== this.props.response && this.props.response) {
    switch (this.props.response.type) {
      case GET_PROVINCE:
        this.setState({provinces: this.props.response.data ?? []})
        break;
      case GET_CITY:
        this.setState({cityLoading: false, cities: this.props.response.data.cities ?? []})
        break;
      case CREATE_COMPANY:
        this.setState({loading: false})
        toast.success(() => <div>Company successfully created!<br/>You will be redirected to the page <b>verification email</b>.</div>, {
          position: toast.POSITION.TOP_CENTER
        })
        setTimeout(() => { this.props.history.push(`/verify-company/${this.props.response.data.data.uuid}`) }, 1000)
        break;
      default:
        break;
    }
  }
  }

  handleFormValidation = async (event) => {       
    event.preventDefault()
    let rules = RULES_COMPANY_REGISTER
    
    let validation = new Validator(this.state.form, rules);
    let isValid = validation.passes();
    let errors = validation.errors.all()
    this.setState({validation: errors})
    if (isValid) {
        this.setState({loading: true})
        await this.props.createCompany(this.state.form)
        await this.props.getUser()
    } else {
      Object.keys(errors).forEach(function(key) {
        errors[key].forEach((item) => {
          toast.error(item, {
            position: toast.POSITION.TOP_CENTER
          })
        })
      });
    }
	}

  render () {
    return (
      <Landing
        isRecruiter
        top={
          <div className='p-2 mt-5 d-none d-md-block'>
            <span className='text-white header-font'>
              Connect with <b>2.4 billion users</b><br />
              and hire a <b>talented employee</b><br />
              from Hacklab!
            </span>
          </div>
                }
      >
        <div className='d-flex align-items-start flex-column'>
          <div className='mb-auto p-2 full-width'>
            <div className='row justify-content-center'>
              <div className='col-lg-8 col-md-7 d-none d-md-block mt-3'>
                <h3 className='font-weight-medium'>Benefit:</h3>
                <ul>
                  <li>Free Job Posting</li>
                  <li>Manage Application Easily</li>
                  <li>Join as Hackosystem Partner</li>
                  <li>Find Talent Faster using Talent Hunt Services</li>
                </ul>
              </div>
              <div className='col-lg-4 col-md-5 col-sm-8'>
                <div className='ml-auto half-height'>
                  <AuthCard
                    isLogoHidden
                    title='Create Company Page'
                  >
                    <div>
                      <p className='normal-font'>Create your company account and start recruit a talented employee from hacklab!</p>
                      <form onSubmit={this.handleFormValidation}>
                        <div className='form-group'>
                          <CustomInput
                            validation={this.state.validation['company_name']}
                            placeholder="Company Name"
                            value={this.state.form.company_name}
                            onChange={this.handleInputChange}
                            name="company_name"
                            id="company_name"
                        />
                        </div>
                        <div className='form-group'>
                          <CustomSelect
                            validation={this.state.validation['province']}
                            value={this.state.form.province}
                            data={this.state.provinces}
                            onChange={this.handleInputChange}
                            placeholder='Select Province'
                            displayValue={'name'}
                            name="province"
                            id="province"
                          />
                        </div>
                        <div className='form-group'>
                          <CustomSelect
                            disabled={(this.state.form.province === '' || this.state.cityLoading)}
                            validation={this.state.validation['city']}
                            value={this.state.form.city}
                            data={this.state.cities}
                            onChange={this.handleInputChange}
                            displayValue={'name'}
                            placeholder='Select City'
                            name="city"
                            id="city"
                          />
                        </div>

                        <p className='normal-font'>
                          By creating hacklab account, you agree to our <Link to='/' className='hl-font-link font-weight-bold'>Terms, Data Policy </Link> and <Link className='hl-font-link font-weight-bold'>Cookies Policy</Link>. You may receive SMS notifications from us and can opt out at any time.
                        </p>

                        <button type='submit' className='normal-font p-4 form-control btn btn-recruiter-primary rounded-pill mb-3'>
                        {
                            !this.state.loading ? (<div>Create your company account</div>) : 
                            (
                                <div>
                                    <span className="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>
                                    Loading...
                                </div>
                            )
                        }
                        </button>

                        <p className='text-center full-width normal-font mb-0'>
                          <Link to='/home' className='text-muted cursor-pointer'>Cancel and go back</Link>
                        </p>
                      </form>
                    </div>
                  </AuthCard>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Landing>
    )
  }
}

RecruiterRegister.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  thisUser: state.thisUser,
  auth: state.auth,
  error: state.error,
  invalid: state.invalid,
  response: state.response
})

const mapStateToDispatch = {
  getProvince,
  getCity,
  createCompany,
  getUser,
}

export default withRouter(connect(mapStateToProps, mapStateToDispatch)(RecruiterRegister))