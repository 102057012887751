import { Link } from 'react-router-dom'
import React, { Component } from 'react'
import User from '../../images/user-1.svg'
import Carousel from 'react-multi-carousel'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import 'react-multi-carousel/lib/styles.css'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ReactComponent as IcWarning } from '../../images/warning_icon.svg'
import HomeLanding from '../../components/layouts/HomeLanding'
import { ReactComponent as IcPlus } from '../../images/plus-rect.svg'
import { ReactComponent as IcStar } from '../../images/star.svg'
import { ReactComponent as IcClock } from '../../images/clock.svg'
import { ReactComponent as IcVideo } from '../../images/video.svg'
import { ReactComponent as IcCase } from '../../images/suitcase.svg'
import { ReactComponent as IcCamera } from '../../images/camera.svg'
import { ReactComponent as IcCompany } from '../../images/company.svg'
import { ReactComponent as IcApp } from '../../images/applications.svg'
import { ReactComponent as IcChevronRight } from '../../images/chevron_right.svg'
import { ReactComponent as IcAddRectangle } from '../../images/add-rectangle.svg'
import { ReactComponent as IcPencil } from '../../images/pencil.svg'
import { ReactComponent as IcProgress } from '../../images/progress.svg'
import { ReactComponent as IcHired } from '../../images/hired.svg'
import { ReactComponent as IcMore } from '../../images/more-menu.svg'
import { ReactComponent as IcDotsMore } from '../../images/dot-more.svg'
import { ReactComponent as IcAlertCircle } from '../../images/alert-circle.svg'
import { ReactComponent as IcCalendar } from '../../images/calendar-red.svg'
import DashboardModal from '../../components/modals/DashboardModal'
import Avatar from '../../images/avatar-talent.png'
import HumanLeptop from '../../images/human_leptop.svg'
import { withRouter } from 'react-router-dom'
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from 'recharts'
import { reqVerifyCompany } from '../../actions'
import { REQ_VERIFY_COMPANY } from '../../actions/responseTypes'
import { toast } from 'react-toastify'


import styled from "styled-components";
import ReactTable from 'react-table-v6'
import 'react-table-v6/react-table.css'
import Dropdown, { MenuItem } from '@trendmicro/react-dropdown'
import '@trendmicro/react-buttons/dist/react-buttons.css'
import '@trendmicro/react-dropdown/dist/react-dropdown.css'
import ReactMonthPicker from 'react-month-picker'
import 'react-month-picker/css/month-picker.css'
import { companyDashboard } from '../../actions/companies'

const MonthPicker = ({ range }) => {
  const [isVisible, setVisibility] = React.useState(false)
  const [monthYear, setMonthYear] = React.useState({})
  const showMonthPicker = event => {
    setVisibility(true)
    event.preventDefault()
  }

  const handleOnDismiss = () => {
    setVisibility(false)
  }

  const handleOnChange = (year, month) => {
    setMonthYear({ year, month })
    setVisibility(false)
  }

  const getMonthValue = () => {
    const month = monthYear && monthYear.month ? monthYear.month : 0
    const year = monthYear && monthYear.year ? monthYear.year : 0

    let renderMonth
    switch (month) {
      case 1:
        renderMonth = 'January'
        break
      case 2:
        renderMonth = 'February'
        break
      case 3:
        renderMonth = 'March'
        break
      case 4:
        renderMonth = 'April'
        break
      case 5:
        renderMonth = 'May'
        break
      case 6:
        renderMonth = 'June'
        break
      case 7:
        renderMonth = 'July'
        break
      case 8:
        renderMonth = 'August'
        break
      case 9:
        renderMonth = 'September'
        break
      case 10:
        renderMonth = 'October'
        break
      case 11:
        renderMonth = 'November'
        break
      case 12:
        renderMonth = 'December'
        break
      default:
        renderMonth = 'March'
    }

    return month && year ? `${renderMonth} ${year}` : 'March 2021'
  }

  return (
    <div className='MonthYearPicker'>
      <button className='btn-month-picker' onClick={showMonthPicker}>
        {getMonthValue()} <IcCalendar className='ml-1 ic-calendar-red hc-ic' />{' '}
      </button>

      <ReactMonthPicker
        show={isVisible}
        lang={[
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ]}
        years={range}
        value={monthYear}
        onChange={handleOnChange}
        onDismiss={handleOnDismiss}
      />
    </div>
  )
}


const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right:0;
    bottom:0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 9999;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const PopUpALert = styled.div`
    height: 231px;
    width: 671px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    background: #FDD100;
    border-radius: 20px;
    .close-back{
      margin-top:10px;
      font-size: 12px;
    }
    img{
      width: 252px;
    }
    @media only screen and (max-width: 600px) {
      flex-direction: column;
      height: auto;
      padding: 20px;
    }
`;

const ButtonVeryfy = styled.div`
    width: 260px;
    height: 40px;
    background: #62C3D0;
    border-radius: 50px;
    color: #fff;
    text-align: center;
    padding-top:6px;
    font-weight: 700;
    z-index: 9999;
  `;

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [],
      applicants: [],
      modalShow: false,
      hired: 0,
      job: 0,
      boarding: 0,
      isAlert: false, 
      loadingSent: false,
      detail: {
        company_information: {
          isshown: 1
        }
      }
    }
    this.fetchMoreData = this.fetchMoreData.bind(this)
  }

  fetchMoreData() {
    // setTimeout(() => {
    //   this.setState({
    //     items: this.state.items.concat([1, 2, 3, 4, 5]),
    //   })
    // }, 1500)
  }

  handleClickMore = () => {}

  componentDidMount = async () => {
    await this.props.companyDashboard()
    const res = await this.props.response
    if(res.data) {
      this.setState({
        hired: res?.data?.information?.HIRED,
        job: res?.data?.information?.JOB_POST,
        boarding: res?.data?.information?.ON_BOARDING,
        items: res?.data?.recruitment_progress,
        applicants: res?.data?.new_applicant,
        detail: res?.data,
      })
    }
  }

  createJob = () =>{
    // jobs/create
    const { data } = this.props.response;
    if(!data.company_information.email_verified_at){
      this.setState({isAlert: true });
    }else{
      this.props.history.push('jobs/create');
    }
  }

   resendEmail = async () =>{
    // jobs/create
    this.setState({loadingSent: true });
    const { data } = this.props.response;
    await this.props.reqVerifyCompany(data.company_information.uuid, {
      email: data.company_information.email
    })
    this.setState({isAlert: false });
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.response !== this.props.response && this.props.response) {
      switch (this.props.response.type) {
        case REQ_VERIFY_COMPANY:
          this.setState({loading: false})
          toast.success(() => <div>Email sent successfully!<br/>Please verify your email.</div>, {
            position: toast.POSITION.TOP_CENTER
          })
          break;
        default:
          break;
      }
    }
  }

  render() {
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2,
        slidesToSlide: 1, // optional, default to 1.
        partialVisibilityGutter: 40,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2, // optional, default to 1.
        partialVisibilityGutter: 30,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
        partialVisibilityGutter: 20,
      },
    }

    {
      /* <span><IcPencil className='hc-ic mr-4 mb-1 svg-white' /></span>
                  <span className='summary-count'>
                    <IcPencil className='hc-ic mr-4 mb-1 svg-white' />
                  </span>
                  <br /> Job Posting */
    }

    const data = [
      {
        name: 'Page A',
        uv: 4000,
        pv: 2400,
        amt: 2400,
      },
      {
        name: 'Page B',
        uv: 3000,
        pv: 1398,
        amt: 2210,
      },
      {
        name: 'Page C',
        uv: 2000,
        pv: 2000,
        amt: 2290,
      },
      {
        name: 'Page D',
        uv: 2780,
        pv: 3908,
        amt: 2000,
      },
      {
        name: 'Page E',
        uv: 1890,
        pv: 2000,
        amt: 2181,
      },
      {
        name: 'Page F',
        uv: 2390,
        pv: 3800,
        amt: 2500,
      },
      {
        name: 'Page G',
        uv: 3490,
        pv: 4300,
        amt: 2100,
      },
    ]


    const dataTable = this?.state?.items?.map((item, index) => {
      return {
        'no': index+1,
        'fullName': item.name,
        'position': item.applied_to_job,
        'status': item.status,
      }
    })

    // const dataTable = [
    //   {
    //     no: 1,
    //     fullName: 'Rendy Kharisma',
    //     position: 'UI/UX Designer',
    //     status: 'Hired',
    //   },
    //   {
    //     no: 2,
    //     fullName: 'Rendy Kharisma',
    //     position: 'UI/UX Designer',
    //     status: 'Hired',
    //   },
    //   {
    //     no: 3,
    //     fullName: 'Rendy Kharisma',
    //     position: 'UI/UX Designer',
    //     status: 'Hired',
    //   },
    //   {
    //     no: 4,
    //     fullName: 'Rendy Kharisma',
    //     position: 'UI/UX Designer',
    //     status: 'Rejected',
    //   },
    //   {
    //     no: 5,
    //     fullName: 'Rendy Kharisma',
    //     position: 'UI/UX Designer',
    //     status: 'On Review',
    //   },
    // ]

    const columns = [
      {
        Header: (
          <div
            className='font-weight-bold'
            style={{
              backgroundColor: '#62c3d0',
              padding: '0.8rem',
              borderTopLeftRadius: '1rem',
              borderBottomLeftRadius: '1rem',
              color: '#ffffff',
            }}
          >
            No
          </div>
        ),
        Cell: props => (
          <div
            className='font-weight-bold'
            style={{ textAlign: 'center', fontSize: '14px' }}
          >
            {props.value}
          </div>
        ),
        accessor: 'no',
        resizable: false,
        sortable: false,
        width: 100,
      },
      {
        Header: (
          <div
            className='font-weight-bold'
            style={{
              backgroundColor: '#62c3d0',
              padding: '0.8rem',
              paddingLeft: '0.2rem',
              textAlign: 'left',
              color: '#ffffff',
            }}
          >
            Full Name
          </div>
        ),
        accessor: 'fullName',
        Cell: props => (
          <span
            className='font-weight-bold'
            style={{
              textAlign: 'left',
              fontSize: '14px',
            }}
          >
            {props.value}
          </span>
        ),
        resizable: false,
        sortable: false,
        width: 240,
      },
      {
        Header: (
          <div
            className='font-weight-bold'
            style={{
              backgroundColor: '#62c3d0',
              padding: '0.8rem',
              paddingLeft: '0.2rem',
              textAlign: 'left',
              color: '#ffffff',
            }}
          >
            Position
          </div>
        ),
        Cell: props => (
          <span
            className='font-weight-bold'
            style={{
              textAlign: 'left',
              fontSize: '14px',
            }}
          >
            {props.value}
          </span>
        ),
        accessor: 'position',
        resizable: false,
        sortable: false,
        width: 180,
      },
      {
        Header: props => (
          <div
            className='font-weight-bold'
            style={{
              backgroundColor: '#62c3d0',
              padding: '0.8rem',
              paddingLeft: '0.2rem',
              textAlign: 'left',
              color: '#ffffff',
            }}
          >
            Status
          </div>
        ),
        Cell: props => (
          <span
            className='font-weight-bold'
            style={{
              textAlign: 'left',
              fontSize: '14px',
            }}
          >
            <div
              style={{
                borderRadius: '50px',
                backgroundColor:
                  props.value === 'Hired'
                    ? '#29C973'
                    : props.value === 'On Review'
                    ? '#FDD100'
                    : '#E04F5F',
                width: 'fit-content',
                padding: '5px 15px',
                color: props.value === 'On Review' ? '#000000' : '#ffffff',
              }}
            >
              {props.value}
            </div>
          </span>
        ),
        accessor: 'status',
        width: 160,
        resizable: false,
        sortable: false,
      },
      {
        Header: props => (
          <div
            style={{
              backgroundColor: '#62c3d0',
              padding: '0.8rem',
              borderTopRightRadius: '1rem',
              borderBottomRightRadius: '1rem',
              color: '#62c3d0',
            }}
          >
            .
          </div>
        ),
        accessor: 'fullName',
        // Cell: props => (
        //   <span className='number' style={{ textAlign: 'right' }}>
        //     {/* <img src={IcMore} /> */}
        //     <div class='dropdown'>
        //       <IcMore className='ic-dashboard' onClick={this.handleClickMore} />
        //       <div class='dropdown-content'>
        //         <a href='#'>Link 1</a>
        //         <a href='#'>Link 2</a>
        //         <a href='#'>Link 3</a>
        //       </div>
        //     </div>
        //   </span>
        // ),
        Cell: ({ original }) => (
          <div className='cursor__pointer '>
            {/* <Dropdown onSelect={eventKey => {}}>
              <Dropdown.Toggle btnStyle='link' noCaret>
                <IcMore
                  className='ic-dashboard'
                  onClick={()=>alert(JSON.stringify(original))}
                />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <MenuItem header>Header</MenuItem>
                <MenuItem eventKey={1}>link</MenuItem>
                <MenuItem divider />
                <MenuItem header>Header</MenuItem>
                <MenuItem eventKey={2}>link</MenuItem>
              </Dropdown.Menu>
            </Dropdown> */}
          </div>
        ),
        width: 45,
        resizable: false,
        sortable: false,
      },
    ]

    const range = {
      min: { year: 2020, month: 3 },
      max: { year: 2025, month: 2 },
    }

    return (
      <HomeLanding>
        {
          this.state.isAlert && (
            <Backdrop>
              <PopUpALert>
                  <img style={{marginTop: -50}}  src={HumanLeptop} alt="human"/>
                  <div>
                    <h3>Uh..Oh!</h3>
                    <p>you use a different email for your company account, you can't create job vacancies before verifying company email</p>
                    <ButtonVeryfy onClick={()=>this.resendEmail()}>
                    {this.state.loadingSent ? (
                                                        <div>
                                                        <span className="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>
                                                        Loading...
                                                    </div>
                      ):<div>Resend Email Verification </div>}
                    </ButtonVeryfy>
                    <div onClick={()=>this.setState({ isAlert: false })} className="close-back">
                      Close and go back
                      </div>
                  </div>
              </PopUpALert>
            </Backdrop>
          )
        }
        <div className='row pt-3'>
          <div className='col-12'>
          {!this.state.detail?.company_information?.isshown ?
                <div class="alert alert-warning" role="alert">
                  <a href="#" class="alert-link"><IcWarning />  For now your job vacancies and company page will not be seen by talent, please contact us so that your company can immediately get the best talent from hacklab.</a> <a href="http://t.me/hacklabrocksid" className="alert-link-active">Click this link to contact us</a>
                </div> : null}
          <div className='d-flex align-items-center pb-4'>
       
                <div className='header-font font-weight-medium'>
                  Dashboard
                </div>
                <div className='ml-auto'>
                  <Link to='/jobs/create' className='w-150px normal-font py-2 rounded-pill font-weight-medium btn btn-post-job'>
                    <IcPlus className='hc-ic mr-2 svg-white' /> Post a Job
                  </Link>
                </div>
              </div>

            {/* <div className='card bg-white rounded-lg' style={{ height: '20%' }}>
              <div className='d-flex justify-content-between pr-4 pl-4 pt-2 pb-2'>
                <div>
                  <span className='font-weight-bold mr-2'>Total visits</span>
                  <span>
                    <IcAlertCircle className='ic-dashboard' />
                  </span>
                </div>
                <div className='d-flex'>
                  <span className='mr-3'>
                    <MonthPicker range={range} />
                  </span>
                  <IcDotsMore className='ic-dashboard' />
                </div>
              </div>
              <ResponsiveContainer width='100%' height='100%' minHeight='15%'>
                <LineChart
                  width={500}
                  height={300}
                  data={data}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                > */}
                  {/* <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='name' />
                  <YAxis /> */}
                  {/* <Tooltip /> */}
                  {/* <Legend /> */}
                  {/* <Line
                    type='monotone'
                    dataKey='pv'
                    stroke='#8884d8'
                    activeDot={{ r: 8 }}
                  />
                  <Line type='monotone' dataKey='uv' stroke='#82ca9d' />
                </LineChart>
              </ResponsiveContainer>
            </div> */}

            <div className='card bg-white border-none text-left pb-0 mb-3 mt-3 rounded-lg'>
              <div className='row card-body justify-content-between'>
                <div className='col-md-4 col-sm-12 my-2'>
                  <div className='row dashboard-summary rounded-md ml-0 mr-0'>
                    <div className='offset-2'>
                      <IcPencil className='ic-dashboard mt-2 mb-1 svg-white' />
                    </div>
                    <div className='col-8 mt-1 pb-2'>
                      <span className='company-summary-font font-weight-bold'>
                        {this.state.job}
                      </span>
                      <p className='br-no-space'></p>
                      <span className='font-weight-bold mt-0 pt-0'>
                        Job Posting
                      </span>
                    </div>
                  </div>
                </div>
                <div className='col-md-4 col-sm-12 my-2'>
                  <div className='row dashboard-summary rounded-md ml-0 mr-0'>
                    <div className='offset-2'>
                      <IcProgress className='ic-dashboard mt-2 mb-1 svg-white' />
                    </div>
                    <div className='col-8 mt-1 pb-2'>
                      <span className='company-summary-font font-weight-bold'>
                      {this.state.boarding}
                      </span>
                      <p className='br-no-space'></p>
                      <span className='font-weight-bold mt-0 pt-0'>
                        On Boarding
                      </span>
                    </div>
                  </div>
                </div>
                <div className='col-md-4 col-sm-12 my-2'>
                  <div className='row dashboard-summary rounded-md ml-0 mr-0'>
                    <div className='offset-2'>
                      <IcHired className='ic-dashboard mt-2 mb-1 svg-white' />
                    </div>
                    <div className='col-8 mt-1 pb-2'>
                      <span className='company-summary-font font-weight-bold'>
                      {this.state.hired}
                      </span>
                      <p className='br-no-space'></p>
                      <span className='font-weight-bold mt-0 pt-0'>Hired</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row justify-content-between mr-0 mb-3 mt-4'>
              <h4 className='col-10 font-weight-bold'>Recruitment Progress</h4>
              <p className='col-0' style={{color: '#000'}}><Link
                to='/recruiter/jobs/progress'
                className='text-black d-flex justify-content-between'
                style={{color: '#000'}}
              >See all</Link></p>
            </div>
            {/* STATUS */}

            <div className='card bg-white pt-3 pb-3 px-5 rounded-lg'>
              <ReactTable
                data={dataTable}
                columns={columns}
                showPagination={true}
                defaultPageSize={5}
              />
            </div>
            {/* INFINITE SCROLL */}
            {/* <InfiniteScroll
              dataLength={this.state.items.length}
              next={this.fetchMoreData}
              hasMore
              loader={
                <div className='d-flex align-item-center pb-4 justify-content-center'>
                  <span className='medium-font text-muted'>Loading...</span>
                </div>
              }
            >
              {this.state.items.map((i, index) => (
                <div
                  key={`feed-${i}`}
                  className='card bg-white border-none text-left pb-0 mb-3 rounded-lg'
                >
                  <div className='card-body'>
                    <div className='row'>
                      <div className='col-2'>
                        <IcCompany className='avatar-sm rounded-circle' />
                      </div>
                      <div className='col-10'>
                        <div>
                          <span className='medium-font'>
                            <b>
                              {index} Cities & Marketing Projects Senior
                              Manager, GrabFood
                            </b>
                          </span>
                          <p className='normal-font'>
                            Estimated Sallary{' '} {' IDR '}
                            <span className='color-salary font-weight-medium'>
                              5,000,000 - 7,000,000
                            </span>
                          </p>
                          <p className='normal-font text-muted'>
                            Grab - Jakarta, ID
                          </p>
                        </div>
                        <hr />
                        <div className='d-flex py-1 text-left normal-font align-items-center justify-content-start'>
                          <IcStar className='hc-small-ic mr-2 svg-black' />{' '}
                          Mid-Senior Level
                        </div>
                        <div className='d-flex py-1 text-left normal-font align-items-center justify-content-start'>
                          <IcClock className='hc-small-ic mr-2 svg-black' />{' '}
                          Full-time
                        </div>
                        <div className='d-flex py-1 text-left normal-font align-items-center justify-content-start'>
                          <IcCase className='hc-small-ic mr-2 svg-black' />{' '}
                          Public Relations, Marketing, Information Technology
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='card-footer bg-transparent'>
                    <div className='row'>
                      <div className='offset-md-2 col-md-10'>
                        <Link
                          to='/'
                          className='d-inline normal-font py-2 px-3 btn btn-primary rounded-pill mr-2'
                        >
                          Apply Now
                        </Link>
                        <Link
                          to='/'
                          className='d-inline normal-font py-2 px-3 btn btn-recommendation-reverse rounded-pill'
                        >
                          Save Job
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </InfiniteScroll>
           */}
          </div>
          <div className='col-3'>
            <div className='wrapper-card sticky-top mt-sidebar'>
              {/* <div className='card custom-sidebar-two border-none text-left pb-0 mb-3 rounded-lg'>
                <div className='card-header'>
                  <h6 className='pt-2'>
                    <b>Upcoming Events </b>
                  </h6>
                </div>
                <div className='card-body'>
                  <div>
                    <ol type='1' className='pl-3 normal-font font-weight-bold'>
                      {[...Array(5)].map((x, i) => (
                        <li className='pb-3' key={`event${i}`}>
                          <span>
                            <b>Webinar UX Design</b>
                            <br />
                            <span className='font-weight-normal'>
                              Stacey Gordon | 7 February 2021
                            </span>
                          </span>
                        </li>
                      ))}
                    </ol>
                  </div>
                  <div className='normal-font d-flex align-items-center justify-content-end font-weight-medium'>
                    <Link to='/' className='text-white'>
                      Show more assessments
                      <IcChevronRight className='hc-ic ml-2 svg-white' />
                    </Link>
                  </div>
                </div>
              </div>
               */}
              {/* <div className='card custom-sidebar-two border-none text-left pb-0 mb-3 rounded-lg'>
                <div className='card-header card-header-company'>
                  <h6 className='pt-2'>
                    <b>Top Talent </b>
                  </h6>
                </div>
                <div className='card-body pl-0 pr-0'>
                  <div>
                    <ul className='pl-0 normal-font font-weight-bold'>
                      {[...Array(5)].map((x, i) => (
                        <li
                          className='pb-3 pt-2 card-item-company justify-content-center d-flex'
                          key={`event${i}`}
                        >
                          <img src={Avatar} />
                          <div className='ml-2 mt-1'>
                            Rahmadi Susanto
                            <br />
                            <span className='font-weight-normal mt-0 pt-0'>
                              Applied for Java Programmer
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className='normal-font card-body pt-0 pb-1 d-flex justify-content-around font-weight-medium'>
                    <Link
                      to='/'
                      className='text-white d-flex justify-content-between'
                    >
                      <span>See all new applicant</span>
                      <IcChevronRight className='hc-ic ml-5 svg-white' />
                    </Link>
                  </div>
                </div>
              </div> */}
              {/* <div className='card custom-sidebar-two border-none text-left pb-0 mb-3 rounded-lg'>
                <div className='card-header card-header-company'>
                  <h6 className='pt-2'>
                    <b>New Applicant </b>
                  </h6>
                </div>
                <div className='card-body pl-0 pr-0'>
                  <div>
                    <ul className='pl-0 normal-font font-weight-bold'>
                      {this.state?.applicants?.map((item, i) => (
                        <li
                          className='pb-3 pt-2 card-item-company pl-3 d-flex'
                          key={`event${i}`}
                        >
                          <img src={item?.profile_picture ? item?.profile_picture : Avatar } />
                          <div className='ml-2 mt-1'>
                            {item?.name}
                            <br />
                            <span className='font-weight-normal mt-0 pt-0'>
                              Applied for {item?.applied_to_job}
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className='normal-font card-body pt-0 pb-1 d-flex justify-content-around font-weight-medium'>
                    <Link
                      to='/recruiter/jobs/progress'
                      className='text-white d-flex justify-content-between'
                    >
                      <span>See all new applicant</span>
                      <IcChevronRight className='hc-ic ml-5 svg-white' />
                    </Link>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <DashboardModal
          show={this.state.modalShow}
          onHide={() => this.setState({ modalShow: false })}
        />
      </HomeLanding>
    )
  }
}

Home.propTypes = {
  auth: PropTypes.object.isRequired,
  thisUser: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
  return{
    auth: state.auth,
    thisUser: state.thisUser,
    response: state.response,
  }
}

const mapStateToDispatch = {
  reqVerifyCompany,
  companyDashboard
}

export default withRouter(connect(mapStateToProps, mapStateToDispatch)(Home))
