import React, { Component } from "react";
import {ReactComponent as IcOption} from "../../images/option.svg";
import {Link} from "react-router-dom";

class OptionButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hrefReportComment: this.props.hrefReport ?? "#",
            hrefReportUser: this.props.hrefReportUser ?? "#",
            hrefProfile: this.props.hrefProfile ?? "#",
        }
    }

    render() {
        return (
            <div className="dropdown-hover dropdown ml-3 float-right">
                <Link className="medium-font font-weight-medium d-flex align-items-center justify-content-center" to="#" id="navbarDropdown" role="button"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <IcOption className={"hc-ic"}/>
                </Link>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                    <Link className="dropdown-item" to={`${this.state.hrefReportComment}`}>Report this comment</Link>
                    <Link className="dropdown-item" to={`${this.state.hrefReportUser}`}>Report user</Link>
                    <div className="dropdown-divider"/>
                    <Link className="dropdown-item" to={`${this.state.hrefProfile}`}>View user profile</Link>
                </div>
            </div>
        );
    }
}

export default OptionButton;
