import { SET_ERROR, SET_INVALID, SET_LOADING } from '../actions/types'

const initState = null

export const error = (state = initState, action) => {
  switch (action.type) {
    case SET_ERROR:
      return action.payload || null
    default:
      return state
  }
}

export const invalid = (state = initState, action) => {
  switch (action.type) {
    case SET_INVALID:
      return action.payload || null
    default:
      return state
  }
}

export const loading = (state = initState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return action.payload || false
    default:
      return state
  }
}

const Utils = {
  error,
  invalid,
  loading
}

export default Utils
