import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Landing from '../../../components/layouts/Landing'
import AuthCard from '../../../components/cards/AuthCard'
import Mail from '../../../images/mail-sent.svg'
import { CustomCheckbox, CustomInput } from '../../../components'
import { RULES_REQ_VERIFY_COMPANY, Validator } from '../../../rules'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { reqVerifyCompany } from '../../../actions'
import { REQ_VERIFY_COMPANY } from '../../../actions/responseTypes'
import { alertErrorValidation, captionErrorValidation } from '../../../utils'

class VerifyCompany extends Component {

  constructor(props) {
    super(props)
    this.state = {
        modalShow: true,
        form: {},
        error: false,
        loading: false,
        validation: {},
        isCustom: false,
        uuid: this.props.match.params.uuid
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleFormValidation = this.handleFormValidation.bind(this)
    this.handleCustomEmail = this.handleCustomEmail.bind(this)
  }

  handleInputChange = (name, value) =>  {
    this.setState(prevState => ({
      form : {
          ...prevState.form,
          [name]: value
      }
    }))
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.error !== this.props.error && this.props.error) {
      alertErrorValidation(this.props.error)
      const check = captionErrorValidation(this.props.error, RULES_REQ_VERIFY_COMPANY)
      this.setState({loading: false, validation: check})
    }
    if (prevProps.response !== this.props.response && this.props.response) {
      switch (this.props.response.type) {
        case REQ_VERIFY_COMPANY:
          this.setState({loading: false})
          toast.success(() => <div>Email sent successfully!<br/>Please verify your email</div>, {
            position: toast.POSITION.TOP_CENTER
          })
          setTimeout(() => { this.props.history.push(this.state.isCustom ? `/home`:`/verify-company-success`) }, 1000)
          break;
        default:
          break;
      }
    }
  }

  handleFormValidation = async (event) => {       
    event.preventDefault()
    let rules = RULES_REQ_VERIFY_COMPANY
    if (this.state.isCustom) {
      let validation = new Validator(this.state.form, rules);
      let isValid = validation.passes();
      let errors = validation.errors.all()
      this.setState({validation: errors})
      if (isValid) {
          this.setState({loading: true})
          await this.props.reqVerifyCompany(this.state.uuid, this.state.form)
      } else {
        Object.keys(errors).forEach(function(key) {
          errors[key].forEach((item) => {
            toast.error(item, {
              position: toast.POSITION.TOP_CENTER
            })
          })
        });
      }
    } else {
      this.setState({loading: true})
      await this.props.reqVerifyCompany(this.state.uuid)
    }
	}

  handleCustomEmail = () => {
    if (!this.state.isCustom) {
      this.setState({form: {}})
    }
    this.setState({isCustom: !this.state.isCustom})
  }

  render () {
    return (
      <Landing
        isLogoHidden
        isRecruiter
        isBgPersonHidden
      >
        <div className='d-flex align-items-start flex-column'>
          <div className='mb-auto p-2 full-width'>
            <div className='row justify-content-center'>
              <div className='col-lg-5 col-sm-8'>
                <div className='ml-auto'>
                  <AuthCard>
                    <form onSubmit={this.handleFormValidation} className='text-center'>
                      <img src={Mail} alt='mail' />
                      <h5 className='pt-4'><b>Verify Company Account</b></h5>
                      <p>
                        You’ve entered <b>{this.props.thisUser.email}</b> as the email address
                        Please verify this email by clicking button bellow
                      </p>
                        {
                          this.state.isCustom && (
                            <CustomInput
                              className='mb-3'
                              validation={this.state.validation['email']}
                              placeholder="Your Company Email"
                              value={this.state.form.email}
                              onChange={this.handleInputChange}
                              name="email"
                              id="email"
                            />
                          )
                        }
                        <CustomCheckbox
                          widthFit
                          display='content'
                          checked={this.state.isCustom}
                          onClick={this.handleCustomEmail}
                          checkboxLabel='Use another email'
                        />
                        <button type='submit' className='mt-3 form-control rounded-pill p-4 btn btn-recruiter-primary font-weight-medium'>
                        {
                            !this.state.loading ? (<div>Verify Your Email</div>) : 
                            (
                                <div>
                                    <span className="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>
                                    Loading...
                                </div>
                            )
                        }
                        </button>
                    </form>
                  </AuthCard>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Landing>
    )
  }
}

VerifyCompany.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  thisUser: state.thisUser,
  auth: state.auth,
  error: state.error,
  invalid: state.invalid,
  response: state.response
})

const mapStateToDispatch = {
  reqVerifyCompany
}

export default withRouter(connect(mapStateToProps, mapStateToDispatch)(VerifyCompany))
