import { GET_RESPONSE } from './types'
import {APPLY_JOB, GET_JOB_DETAILS, GET_LIST_JOBS} from './responseTypes'

import { httpPost, httpGet } from './utils'

export const applyJob = (uuid, quiz = {}) => async dispatch => {
  const token = localStorage.jwtToken
  const res = await httpPost(dispatch, `/v1/job-apply/${uuid}`, quiz, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (!res) return null
  
  dispatch({
    type: GET_RESPONSE,
    payload: {
      type: APPLY_JOB,
      data: res
    }
  })
  return res;
}

export const updateJob = (uuid, data = {}) => async dispatch => {
  const token = localStorage.jwtToken
  const res = await httpPost(dispatch, `/v1/job-update/${uuid}`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (!res) return null
  return res;
}

export const jobStatus = (uuid = {}) => async dispatch => {
  const token = localStorage.jwtToken
  const res = await httpPost(dispatch, `/v1/job-status/${uuid}`, {uuid: uuid}, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (!res) return null

  dispatch({
    type: GET_RESPONSE,
    payload: {
      type: APPLY_JOB,
      data: res
    }
  })
}

export const getJobDetails = (uuid) => async dispatch => {
  const token = localStorage.jwtToken
  const res = await httpGet(dispatch, `/v1/job-detail/${uuid}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (!res) return null

  dispatch({
    type: GET_RESPONSE,
    payload: {
      type: GET_JOB_DETAILS,
      data: res
    }
  })
  return res;
}


export const getJobAplicantLite = (uuid, uuidCompany) => async dispatch => {
  const token = localStorage.jwtToken
  const res = await httpGet(dispatch, `/v1/job-applicant-lite/${uuid}/${uuidCompany}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (!res) return null
  return res;
}

export const getJobAplicantDetail = (uuid_job, id) => async dispatch => {
  const token = localStorage.jwtToken
  const res = await httpGet(dispatch, `/v1/job-applicant-detail/${uuid_job}/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (!res) return null
  return res;
}

export const myApplications = (page, perpage) => async dispatch => {
  const token = localStorage.jwtToken
  const res = await httpGet(dispatch, `/v1/my-application?page=${page}&perpage=${perpage}&field=created_at&state=desc`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (!res) return null

  dispatch({
    type: GET_RESPONSE,
    payload: res,
  })
}

export const getCompanyJobs = (page = 1, perpage = 10) => async dispatch => {
  const token = localStorage.jwtToken
  const company = JSON.parse(localStorage.getItem('company'))
  const res = await httpGet(dispatch, `/v1/job/${company.uuid}?page=${page}&perpage=${perpage}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (!res) return null

  dispatch({
    type: GET_RESPONSE,
    payload: {
      type: GET_LIST_JOBS,
      data: res
    }
  })
}

export const getJobsPosting = (page = 1, perpage = 100) => async dispatch => {
  const token = localStorage.jwtToken
  const company = JSON.parse(localStorage.getItem('company'))
  const res = await httpGet(dispatch, `/v1/job-posting-information/${company.uuid}?page=${page}&perpage=${perpage}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (!res) return null

  dispatch({
    type: GET_RESPONSE,
    payload: {
      type: GET_LIST_JOBS,
      data: res
    }
  })
}
