import { Link } from 'react-router-dom'
import React, { Component } from 'react'
import 'react-multi-carousel/lib/styles.css'
import User from '../../images/camera.svg'
import { ReactComponent as IcClose } from '../../images/close.svg'
import HomeLanding from '../../components/layouts/HomeLanding'
import InfiniteScroll from 'react-infinite-scroll-component'
import AdminSettingModal from '../../components/modals/AdminSettingModal'

class RecruiterAdminSettings extends Component {
  constructor (props) {
    super(props)
    this.state = {
      uploadFile: null,
      image: null,
      modalShow: false,
    }
    this.onChangeFile = this.onChangeFile.bind(this)
    this.deleteImage = this.deleteImage.bind(this)
  }

  onChangeFile (event) {
    event.stopPropagation()
    event.preventDefault()
    const file = event.target.files[0]
    if (file !== null) {
      this.setState({ uploadFile: file, image: URL.createObjectURL(file) }) /// if you want to upload latter
    }
  }

  fetchMoreData = async () => {
  
  }

  deleteImage (event) {
    this.setState({ uploadFile: null, image: null })
  }

  render () {
    return (
      <HomeLanding isRecruiter>
        <div className='row pt-3'>
          <div className='col-3'>
            <div className='card bg-recommendation border-none text-left pb-0 mb-3 rounded-lg'>
              <div className='card-header white-br-bottom'>
                <h6 className='pt-2'><b>Settings </b></h6>
              </div>
              <div className='card-body pb-1'>
                <div>
                  <ul type='none' className='pl-0 normal-font'>
                    <li className='pb-3' key='username'>
                      <Link to='/recruiter/settings' className='link-white large-font'>Username & Password</Link>
                    </li>
                    <li className='pb-3 font-weight-bold' key='admin'>
                      <Link to='/recruiter/admin-settings' className='link-white large-font'>Admin Settings</Link>
                    </li>
                    <li className='' key='reports'>
                      <Link to='/recruiter/reports' className='link-white large-font'>Report Problem</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='col-9'>
            <div className='card bg-white border-none text-left pb-0 mb-3 rounded-lg'>
              <div className='card-header'>
                <h6 className='pt-2'><b>Admin Settings </b></h6>
              </div>
              <div className='card-body'>
                <form>
                  {/* <h6 className='font-weight-medium'>Admin</h6> */}
                  {/* <input
                    id='myInput'
                    type='file'
                    ref={(ref) => this.upload = ref}
                    style={{ display: 'none' }}
                    value={this.uploadFile}
                    onChange={this.onChangeFile}
                  /> */}

                  {/* <div onClick={() => { !this.state.image && this.upload.click() }} className='my-3 position-relative fit-width'>
                    <img src={this.state.image ?? User} className={`avatar bg-secondary ${this.state.image === null ? 'scale-down cursor-pointer' : 'img-cover'} rounded-circle`} alt='icon' />
                    <div onClick={this.deleteImage} className={`${this.state.image === null ? ('d-none') : ('d-block')}`}>
                      <IcClose className='hc-ic close-ic' />
                    </div>
                  </div> */}

                  <h6 className='font-weight-medium'>Add New Admin</h6>
                  {/* <div className='form-group'>
                    <label className='pl-3 normal-font font-weight-medium'>Enter Username</label>
                    <input type='text' className='p-4 form-control rounded-pill custom-textfield' placeholder='Enter Username' />
                  </div> */}
                  <div className='form-group'>
                    <div className="row">
                      <div className="col-md-8">
                        <label className='pl-3 normal-font font-weight-medium'>Email Address</label>
                        <input type='email' className='p-4 form-control rounded-pill custom-textfield' placeholder='Enter Email Address' />
                      </div>
                      <div className="col-md-4 ">
                        <button  className='normal-font form-control py-4 btn btn-primary rounded-pill' style={{marginTop: "1.8rem"}}>Save Changes</button>
                      </div>
                    </div>
                  </div>

                  <h6 className='font-weight-medium mt-5'>Add New Admin</h6>
                  <div className='mb-3 container btn-group-toggle row my-3' data-toggle='buttons'>
                    <label className='col-3 py-2 m-2 btn btn-recommendation-reverse rounded-pill active'>
                      <input type='radio' name='options' id='option1' autocomplete='off' checked /> Admin (3)
                    </label>
                    <label className='col-3 py-2 m-2 btn btn-recommendation-reverse rounded-pill'>
                      <input type='radio' name='options' id='option2' autocomplete='off' /> Invite (2)
                    </label>
                  </div>
                  <InfiniteScroll
                    dataLength={10}
                    next={this.fetchMoreData}
                    // hasMore={this.state.hasMore}
                    loader={
                      <div className='d-flex align-item-center pb-4 justify-content-center'>
                        <span className='medium-font text-muted'>Loading...</span>
                      </div>
                    }
                  >
                    <div style={{borderBottom: '1px solid #C4C4C4', display:'flex', alignItems: "center"}}>
                      <img src={this.state.image ?? User} className={`avatar-xs bg-secondary ${this.state.image === null ? 'scale-down cursor-pointer' : 'img-cover'} rounded-circle`} alt='icon' />
                      <p className="ml-4 mt-4 font-weight-medium medium-font">Ester Howard</p>
                      <IcClose className='mt-2' style={{marginLeft: 'auto', height: '32px', width: '32px'}} />
                    </div>
                    <div style={{borderBottom: '1px solid #C4C4C4', display:'flex', alignItems: "center"}}>
                      <img src={this.state.image ?? User} className={`avatar-xs bg-secondary ${this.state.image === null ? 'scale-down cursor-pointer' : 'img-cover'} rounded-circle`} alt='icon' />
                      <p className="ml-4 mt-4 font-weight-medium medium-font">Ester Howard</p>
                      <IcClose className='mt-2' style={{marginLeft: 'auto', height: '32px', width: '32px'}} />
                    </div>
                    <div style={{borderBottom: '1px solid #C4C4C4', display:'flex', alignItems: "center"}}>
                      <img src={this.state.image ?? User} className={`avatar-xs bg-secondary ${this.state.image === null ? 'scale-down cursor-pointer' : 'img-cover'} rounded-circle`} alt='icon' />
                      <p className="ml-4 mt-4 font-weight-medium medium-font">Ester Howard</p>
                      <IcClose className='mt-2' style={{marginLeft: 'auto', height: '32px', width: '32px'}} />
                    </div>
                  </InfiniteScroll>


                  {/* <div className='row pt-5'>
                    <div className='col-3 pr-0'>
                      <Link to='/' className='normal-font form-control py-4 btn btn-primary rounded-pill'>Save Changes</Link>
                    </div>
                    <div className='col-3 pl-1'>
                      <Link to='/' className='normal-font form-control py-4 btn btn-recommendation-reverse rounded-pill'>Reset</Link>
                    </div>
                  </div> */}
                <AdminSettingModal
                    show={this.state.modalShow}
                    background={"bg-recommendation"}
                    onHide={() => this.setState({modalShow: false})}
                />
                </form>
              </div>
            </div>
          </div>
        </div>
      </HomeLanding>
    )
  }
}

export default RecruiterAdminSettings
