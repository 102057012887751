import { publicRoutes } from './public'
import { recruiterRoutes } from './recruiter'
import { talentRoutes } from './talent'
import { adminRoutes } from './admin'

const privateGroup = [
  ...recruiterRoutes,
  ...talentRoutes,
  ...adminRoutes,
]

const publicGroup = [
  ...publicRoutes
]

export {
  privateGroup,
  publicGroup
}