import { Link } from 'react-router-dom'
import React, { Component } from 'react'
import 'react-multi-carousel/lib/styles.css'
import HomeLanding from '../../components/layouts/HomeLanding'
import SimpleModal from '../../components/modals/SimpleModal'
import { reportProblem } from '../../actions/companies'
import { connect } from 'react-redux'
class RecruiterReports extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalShow: false,
      subject: '',
      message: '',
    }
  }

  onChangeText = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = () => {
    this.props.reportProblem(this.state.subject, this.state.message)
    const res = this.props.response
    if (res.data) {
      this.setState({ modalShow: true })
    }
  }

  handleReset = () => {
    this.setState({
      subject: '',
      message: '',
    })
  }

  render() {
    return (
      <HomeLanding isRecruiter={true}>
        <div className={'row pt-3'}>
          <div className={'col-3'}>
            <div
              className={
                'card bg-recommendation border-none text-left pb-0 mb-3 rounded-lg'
              }
            >
              <div className={'card-header white-br-bottom'}>
                <h6 className={'pt-2'}>
                  <b>Settings </b>
                </h6>
              </div>
              <div className={'card-body pb-1'}>
                <div>
                  <ul type={'none'} className={'pl-0 normal-font'}>
                    {/* <li className={'pb-3'} key={`username`}>
                      <Link
                        to={'/recruiter/settings'}
                        className={'link-white large-font'}
                      >
                        Username & Password
                      </Link>
                    </li> */}
                    {/* <li className={'pb-3'} key={`admin`}>
                      <Link
                        to={'/recruiter/admin-settings'}
                        className={'link-white large-font'}
                      >
                        Admin Settings
                      </Link>
                    </li> */}
                    <li
                      className={'large-font font-weight-bold'}
                      key={`reports`}
                    >
                      <Link
                        to={'/recruiter/reports'}
                        className={'link-white large-font'}
                      >
                        Report Problem
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className={'col-9'}>
            <div
              className={
                'card bg-white border-none text-left pb-0 mb-3 rounded-lg'
              }
            >
              <div className={'card-header'}>
                <h6 className={'pt-2'}>
                  <b>Report Problem </b>
                </h6>
              </div>
              <div className={'card-body'}>
                <form>
                  <div className='form-group'>
                    <label className={'pl-3 normal-font font-weight-medium'}>
                      Subject
                    </label>
                    <input
                      type='text'
                      name='subject'
                      onChange={this.onChangeText}
                      value={this.state.subject}
                      className='p-4 form-control rounded-pill custom-textfield'
                      placeholder='Enter a subject'
                    />
                  </div>
                  <div className='form-group'>
                    <label className={'pl-3 normal-font font-weight-medium'}>
                      Message
                    </label>
                    <textarea
                      rows={4}
                      name='message'
                      onChange={this.onChangeText}
                      value={this.state.message}
                      className={'form-control custom-textarea rounded-md'}
                      placeholder='Write a message here'
                    />
                  </div>

                  <div className={'row pt-5'}>
                    <div className={'col-3 pr-0'}>
                      <Link
                        onClick={this.handleSubmit}
                        className='normal-font form-control py-4 btn btn-primary rounded-pill'
                      >
                        Send Report
                      </Link>
                    </div>
                    <div className={'col-3 pl-1'}>
                      <Link
                        onClick={this.handleReset}
                        className='normal-font form-control py-4 btn btn-recommendation-reverse rounded-pill'
                      >
                        Reset
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <SimpleModal
          show={this.state.modalShow}
          background={'bg-recommendation'}
          title={'The report has been sent'}
          onHide={() => this.setState({ modalShow: false })}
        />
      </HomeLanding>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  thisUser: state.thisUser,
  error: state.error,
  invalid: state.invalid,
  response: state.response,
})

export default connect(mapStateToProps, { reportProblem })(RecruiterReports)
