import {Link} from "react-router-dom";
import React, {Component} from 'react';
import {ReactComponent as IcPlus} from '../../images/plus-rect.svg'
import HomeLanding from "../../components/layouts/HomeLanding";

class EditJobs extends Component {
    constructor(props) {
        super(props)
        const qTemp = {
            question: "",
            answers: [
                {
                    answer: "",
                    isCorrect: false
                },
                {
                    answer: "",
                    isCorrect: false
                },
                {
                    answer: "",
                    isCorrect: false
                },
                {
                    answer: "",
                    isCorrect: false
                }
            ]
        }

        this.state = {
            questions: [qTemp],
            page: 1,
            title: [
                'Job Desctiption',
                'Job Requirements',
                'Sallary & Benefits',
                'Skill Test (Optional)'
            ]
        }

        this.onAddQuestion = this.onAddQuestion.bind(this)
        this.onNext = this.onNext.bind(this)
        this.onPrevious = this.onPrevious.bind(this)
        this.onReset = this.onReset.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onChangePage = this.onChangePage.bind(this)
    }

    onChangePage(e, p) {
        this.setState({page: p})
    }

    onNext(e) {
        let p = this.state.page + 1
        this.setState({page: p})
    }

    onPrevious(e) {
        let p = this.state.page - 1
        this.setState({page: p})
    }

    onReset(e) {
        this.setState({questions: [this.qTemp]})
    }

    onSubmit(e) {
        //code here
    }

    onAddQuestion(e) {
        e.preventDefault()
        let q = this.state.questions
        if(q.length >= 5) {
            return
        }
        q.push(this.qTemp)
        this.setState({questions: q})
    }

    render() {
        return (
            <HomeLanding isRecruiter={true}>
                <div className={"row pt-3"}>
                    <div className={"col-3"}>
                        <div className={"card bg-recommendation border-none text-left pb-0 mb-3 rounded-lg"}>
                            <div className={"card-header white-br-bottom"}>
                                <h6 className={"pt-2"}><b>Job Posting </b></h6>
                            </div>
                            <div className={"card-body pb-1"}>
                                <div>
                                    <ul type={"none"} className={"pl-0 normal-font"}>
                                        <li className={`pb-3 ${this.state.page === 1 && 'font-weight-bold'}`} key={`job-description`}>
                                            <Link onClick={(e) => this.onChangePage(e, 1)} to={"#job-desc"} className={"link-white large-font"}>Job Description</Link>
                                        </li>
                                        <li className={`pb-3 ${this.state.page === 2 && 'font-weight-bold'}`} key={`job-requirements`}>
                                            <Link onClick={(e) => this.onChangePage(e, 2)} to={"#job-requirements"} className={"link-white large-font"}>Job Requirements</Link>
                                        </li>
                                        <li className={`pb-3 ${this.state.page === 3 && 'font-weight-bold'}`} key={`salary-benefits`}>
                                            <Link onClick={(e) => this.onChangePage(e, 3)} to={"#salary-benefits"} className={"link-white large-font"}>Salary & Benefits</Link>
                                        </li>
                                        <li className={`${this.state.page === 4 && 'font-weight-bold'}`} key={`skill-test`}>
                                            <Link onClick={(e) => this.onChangePage(e, 4)} to={"#skill-test"} className={"link-white large-font"}>Skill Test (Optional)</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-9"}>
                    <div className={"card bg-white border-none text-left pb-0 mb-3 rounded-lg"}>
                            <div className={"card-header"}>
                                <h6 className={"pt-2"}><b>{this.state.title[this.state.page - 1]} </b></h6>
                            </div>
                            <div className={"card-body"}>
                                <form>
                                    {
                                        this.state.page === 1 && (
                                            <div id="form-1">
                                                <div className="form-group">
                                                    <label className={"pl-3 normal-font font-weight-medium"}>Job Role</label>
                                                    <select className="form-control rounded-pill custom-textfield" defaultValue={""}>
                                                        <option value="">Select Job Role</option>
                                                        <option value="Indonesia">Developer</option>
                                                        <option value="Indonesia">System Analyst</option>
                                                        <option value="Indonesia">Business Analyst</option>
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label className={"pl-3 normal-font font-weight-medium"}>Job Type</label>
                                                    <select className="form-control rounded-pill custom-textfield" defaultValue={""}>
                                                        <option value="">Select Job Type</option>
                                                        <option value="Indonesia">Fulltime</option>
                                                        <option value="Indonesia">Part-time</option>
                                                        <option value="Indonesia">Contract</option>
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label className={"pl-3 normal-font font-weight-medium"}>Job Level</label>
                                                    <select className="form-control rounded-pill custom-textfield" defaultValue={""}>
                                                        <option value="">Select Job Level</option>
                                                        <option value="Indonesia">Beginner</option>
                                                        <option value="Indonesia">Intermediet</option>
                                                        <option value="Indonesia">Expert</option>
                                                    </select>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-md-12">
                                                        <label className={"pl-3 normal-font font-weight-medium"}>Work Location</label>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <select className="form-control rounded-pill custom-textfield" defaultValue={""}>
                                                            <option value="">Choose Country</option>
                                                            <option value="Indonesia">Indonesia</option>
                                                            <option value="Singapore">Singapore</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <select className="form-control rounded-pill custom-textfield" defaultValue={""}>
                                                            <option value="">Choose City</option>
                                                            <option value="Indonesia">Yogyakarta</option>
                                                            <option value="Singapore">Sleman</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className={"pl-3 normal-font font-weight-medium"}>Job Description</label>
                                                    <textarea rows={4} className={"form-control custom-textarea rounded-md"} placeholder="Write job description here"/>
                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        this.state.page === 2 && (
                                            <div id="form-2">
                                                <div className="form-group">
                                                    <label className={"pl-3 normal-font font-weight-medium"}>Key Responsibilities</label>
                                                    <textarea rows={4} className={"form-control custom-textarea rounded-md"} placeholder="Write job responsibilities here"/>
                                                </div>

                                                <div className="form-group">
                                                    <label className={"pl-3 normal-font font-weight-medium"}>Requirements</label>
                                                    <textarea rows={4} className={"form-control custom-textarea rounded-md"} placeholder="Write requirements here"/>
                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        this.state.page === 3 && (
                                            <div id="form-3">
                                                <div className="form-row">
                                                    <div className="col-md-12">
                                                        <label className={"pl-3 normal-font font-weight-medium"}>Estimated Sallary</label>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <input type="text" className="p-4 form-control rounded-pill custom-textfield" placeholder="Start from"/>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <input type="text" className="p-4 form-control rounded-pill custom-textfield" placeholder="Up to"/>
                                                    </div>
                                                </div>


                                                <div className="form-group">
                                                    <label className={"pl-3 normal-font font-weight-medium"}>Benefits</label>
                                                    <textarea rows={4} className={"form-control custom-textarea rounded-md"} placeholder="Write benefits here"/>
                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        this.state.page === 4 && (
                                            <div id="form-4">
                                                <label className={"normal-font pb-2"}>To help you filter out potential candidates, make some specific questions about the position you are opening (Maximum 5 questions)</label>
                                                {
                                                    this.state.questions.map((item, i) => {
                                                        return (
                                                            <div className={"shadow-sm card bg-white border-none text-left pb-0 mb-3 rounded-lg"}>
                                                                <div className={"card-header"}>
                                                                    <h6 className={"pt-2"}><b>Question {i + 1}</b></h6>
                                                                </div>
                                                                <div className={"card-body pb-1"}>
                                                                    <div className="form-group">
                                                                        <label className={"pl-3 normal-font font-weight-medium"}>Question</label>
                                                                        <textarea rows={4} className={"form-control custom-textarea rounded-md"} placeholder="Write benefits here"/>
                                                                    </div>
                                                                    <div className="form-row">
                                                                        <div className="col-md-9">
                                                                            <label className={"pl-3 normal-font font-weight-medium"}>Answer Option</label>
                                                                        </div>
                                                                        <div className="col-md-3 text-center">
                                                                            <label className={"normal-font font-weight-medium"}>Correct Answer</label>
                                                                        </div>
                                                                        <div className="form-group col-md-9">
                                                                            <input type="text" className="p-4 form-control rounded-pill custom-textfield" placeholder="Input a answer option"/>
                                                                        </div>
                                                                        <div className="custom-check col-md-3 align-items-center d-flex justify-content-center">
                                                                            <input type="radio" name="radio" value="1"/>
                                                                            <span className="checkmark"></span>
                                                                        </div>
                                                                        <div className="form-group col-md-9">
                                                                            <input type="text" className="p-4 form-control rounded-pill custom-textfield" placeholder="Input a answer option"/>
                                                                        </div>
                                                                        <div className="custom-check col-md-3 align-items-center d-flex justify-content-center">
                                                                            <input type="radio" name="radio" value="2"/>
                                                                            <span className="checkmark"></span>
                                                                        </div>
                                                                        <div className="form-group col-md-9">
                                                                            <input type="text" className="p-4 form-control rounded-pill custom-textfield" placeholder="Input a answer option"/>
                                                                        </div>
                                                                        <div className="custom-check col-md-3 align-items-center d-flex justify-content-center">
                                                                            <input type="radio" name="radio" value="3"/>
                                                                            <span className="checkmark"></span>
                                                                        </div>
                                                                        <div className="form-group col-md-9">
                                                                            <input type="text" className="p-4 form-control rounded-pill custom-textfield" placeholder="Input a answer option"/>
                                                                        </div>
                                                                        <div className="custom-check col-md-3 align-items-center d-flex justify-content-center">
                                                                            <input type="radio" name="radio" value="4"/>
                                                                            <span className="checkmark"></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <button onClick={this.onAddQuestion} className="btn btn-transparent d-flex align-items-center font-weight-medium"><IcPlus className="hc-ic mr-2"/> Add more question</button>
                                            </div>
                                        )
                                    }

                                    <div className="d-flex pt-5">
                                        {
                                            this.state.page > 1 && (
                                                <div className="p-2">
                                                    <button onClick={this.onPrevious} type="button" className="normal-font w-150px form-control py-4 btn btn-recommendation-reverse rounded-pill">Previous</button>
                                                </div>
                                            )
                                        }
                                        {
                                            this.state.page < 4 && (
                                                <div className="p-2">
                                                    <button onClick={this.onNext} type="button"  to={"/"} className="normal-font w-150px form-control py-4 btn btn-primary rounded-pill">Next</button>
                                                </div>
                                            )
                                        }
                                        {
                                            this.state.page === 4 && (
                                                <div className="p-2">
                                                    <button onClick={this.onReset} type="button" className="normal-font w-150px form-control py-4 btn btn-gray rounded-pill">Reset All</button>
                                                </div>
                                            )
                                        }
                                        
                                        {
                                            this.state.page === 4 && (
                                                <div className="ml-auto p-2 bd-highlight">
                                                    <button onClick={this.onSubmit} type="button" className="normal-font w-150px form-control py-4 btn btn-orange rounded-pill">Post a Job Now</button>
                                                </div>
                                            )
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </HomeLanding>
        );
    }
}

export default EditJobs
