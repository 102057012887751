import React, { Component } from "react";
import Background from "../../images/bg-user-profile.svg";
import HomeLanding from "../../components/layouts/HomeLanding";
import { ReactComponent as IcCamera } from "../../images/camera.svg";
// import EditButton from '../../components/buttons/EditButton';
import Carousel from "react-multi-carousel";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-multi-carousel/lib/styles.css";
import Company from "../../images/building2.svg";
import axios from "axios";

// import CompanyPhoto from "../../images/company.svg";
import ImageModal from "../../components/modals/ImageModal";
import SimpleModal from "../../components/modals/UploadModal";
import UploadLogo from "../../components/modals/UploadLogo";
import {
  updateCompanyProfile,
  getDetailsCompany,
  getCompanyGallery,
  setImageGallery,
  setImageGalleryList,
  setImageCaption,
  setImageLoading,
} from "../../actions/companies";
import {
  GET_COMPANY_DETAILS,
  GET_CITY,
  GET_EMPLOYEE,
  GET_INDUSTRY,
  GET_PROVINCE,
  UPDATE_COMPANY_PROFILE,
} from "../../actions/responseTypes";
import { getProvince, getCity, getIndustry, getEmployee } from "../../actions";
import { connect } from "react-redux";
// import { storageBaseUrl } from '../../actions/utils'
import { alertErrorValidation, captionErrorValidation } from "../../utils";
import { CustomInput, CustomSelect, CustomTextarea } from "../../components";
import { RULES_EDIT_COMPANY, Validator } from "../../rules";
import { withRouter } from "react-router-dom";

class EditProfile extends Component {
  constructor(props) {
    super(props);
    const company = JSON.parse(localStorage.getItem("company"));
    this.state = {
      isLogoModal: false,
      companyGallery: [],
      error: false,
      loading: false,
      company: company,
      companyDetail: {
        logo_link: "",
      },
      uuid: company.uuid,
      form: {
        company_name: "",
        province: "",
        city: "",
        about_company: "",
        company_address: "",
        industry_category: "",
        website_address: "",
        number_of_employee: "",
        benefit: "",
      },
      items: [1, 2, 3, 4, 5],
      validation: {},
      modalShow: false,
      uploadModal: false,
      provinces: [{ name: "Loading...", id: 1 }],
      cities: [{ name: "Loading...", id: 1 }],
      options: [{ text: "Loading...", id: 1 }],
      categories: [],
      isLoading: true,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormValidation = this.handleFormValidation.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);
  }

  onImageClick = async () => {
    this.setState({ uploadModal: true });
  };

  onUploadData = async (e) => {
    await this.props.getCompanyGallery();
    const res = await this.props.response;
    if (res.data.data.length > 0) {
      this.props.setImageGallery(this.props.response.data.data);
      this.props.setImageGalleryList(this.props.response.data.data);
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.error !== this.props.error && this.props.error) {
      alertErrorValidation(this.props.error);
      const check = captionErrorValidation(
        this.props.error,
        RULES_EDIT_COMPANY
      );
      this.setState({ loading: false, validation: check });
    }
    if (prevProps.response !== this.props.response && this.props.response) {
      switch (this.props.response.type) {
        case GET_INDUSTRY:
          this.setState({ categories: this.props.response.data ?? [] });
          break;
        case GET_PROVINCE:
          this.setState({ provinces: this.props.response.data ?? [] });
          await this.props.getCity(this.state.form.province ?? "");
          break;
        case GET_CITY:
          this.setState({ cities: this.props.response.data.cities ?? [] });
          break;
        case GET_EMPLOYEE:
          this.setState({ options: this.props.response.data ?? [] });
          break;
        case GET_COMPANY_DETAILS:
          const data = this.props.response.data ?? {};
          // console.log('data', data)
          this.setState({ companyDetail: data.company_information });
          this.resetData(data);
          await this.props.getProvince();
          break;
        case UPDATE_COMPANY_PROFILE:
          this.setState({ loading: false }, () => {
            toast.success("Data successfully changed!", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
          this.props.history.push("/recruiter/profile");
          break;
        default:
          break;
      }
    }
  };

  componentDidMount = async () => {
    await this.setState({ isLoading: true });
    await this.props.getDetailsCompany(this.state.uuid);
    await this.props.getIndustry();
    await this.props.getEmployee();
    this.props.setImageLoading(true);
    await this.props.setImageGallery([]);
    await this.props.setImageCaption("");
    await this.props.getCompanyGallery();
    const res = await this.props.response;
    if (res) {
      res.data[0] && (await this.props.setImageCaption(res.data[0].caption));
      await this.props.setImageGallery(res.data);
      await this.props.getCompanyGallery();
      await this.props.setImageGalleryList(res.data);
    }

    await this.props.setImageLoading(false);
    // if(res.data.data.length > 0) {
    //     await this.props.setImageGallery(this.props.response.data.data)
    //     await this.props.setImageGalleryList(this.props.response.data.data)
    // }
    // this.setState({isLoading: false})
    // this.setState({loading: false})
  };

  resetData = (data) => {
    this.setState({
      form: {
        company_name: data?.company_information?.company_name ?? "",
        province: data?.company_information?.province,
        city: data?.company_information?.city,
        about_company: data?.company_information?.about ?? "",
        company_address: data?.company_information?.address ?? "",
        industry_category: data?.company_information?.industry_category ?? "",
        website_address: data?.company_information?.website ?? "",
        number_of_employee: data?.company_information?.number_of_employee ?? "",
        benefit: data?.company_information?.benefit ?? "",
      },
    });
  };

  handleInputChange = (name, value) => {
    if (name === "province") {
      this.props.getCity(value);
    }

    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [name]: value,
      },
    }));
  };

  handleFormValidation = async (event) => {
    try {
      event.preventDefault();
      let rules = RULES_EDIT_COMPANY;

      let validation = new Validator(this.state.form, rules);
      let isValid = validation.passes();
      let errors = validation.errors.all();
      this.setState({ validation: errors });
      if (isValid) {
        this.setState({ loading: true });
        await this.props.updateCompanyProfile(this.state.form);
        toast.success('Data successfully changed!', {
          position: toast.POSITION.TOP_CENTER
        });
        if (this.state.avatarUpdated) {
          const reader = new FileReader();
          reader.readAsDataURL(this.state.file);
          reader.onload = async () => {
            let base64Image = reader.result.split(",")[1];
            const company = JSON.parse(localStorage.getItem("company"));
            let token = localStorage.jwtToken;
            await axios.post(
              `${process.env.REACT_APP_BASE_API}/v1/my-company-logo/${company.uuid}`,
              { image: base64Image },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            await this.props.getDetailsCompany(company.uuid);
          };
          reader.onerror = function (error) {
            toast.error("Gagal memperbarui avatar", {
              position: toast.POSITION.TOP_CENTER,
            });
          };
        }
      } else {
        Object.keys(errors).forEach(function (key) {
          errors[key].forEach((item) => {
            toast.error(item, {
              position: toast.POSITION.TOP_CENTER,
            });
          });
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  onChangeFile = async (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    const file = ev.target.files[0];
    if (file !== null) {
      this.setState({
        companyDetail: {
          ...this.state.companyDetail,
          logo_link: URL.createObjectURL(file),
        },
        avatarUpdated: true,
        file: file,
      });
    }
  };

  render() {
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 6,
        slidesToSlide: 1, // optional, default to 1.
        partialVisibilityGutter: 40,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        slidesToSlide: 2, // optional, default to 1.
        partialVisibilityGutter: 30,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
        slidesToSlide: 1, // optional, default to 1.
        partialVisibilityGutter: 20,
      },
    };

    return (
      <HomeLanding>
        <form onSubmit={this.handleFormValidation} className="py-3">
          <div
            className={
              "card bg-white border-none text-left pb-0 mb-3 rounded-lg"
            }
          >
            <div className="card-header">
              <h6 className={"pt-2"}>
                <b>Company Profile </b>
              </h6>
            </div>
            <ul className="list-group list-group-flush">
              <li
                className="list-group-item"
                style={{
                  height: "200px",
                  backgroundImage: `url(${Background})`,
                  objectFit: "cover",
                }}
              >
                {/* <EditButton/> */}
              </li>
            </ul>
            <div className={"card-body text-center pt-0"}>
              <div>
                <div
                  style={{ transform: "translateY(-110px)" }}
                  className="center-absolute d-flex justify-content-center"
                >
                  <input
                    id="myInput"
                    type="file"
                    accept="image/*"
                    ref={(ref) => {
                      return (this.upload = ref);
                    }}
                    style={{ display: "none" }}
                    onChange={this.onChangeFile}
                  />
                  <div
                    className="position-relative fit-width"
                    onClick={() => this.upload.click()}
                  >
                    <img
                      src={
                        this.state.companyDetail.logo_link
                          ? this.state.companyDetail.logo_link
                          : Company
                      }
                      className={`avatar scale-down cursor-pointer rounded-circle`}
                      alt={"icon"}
                    />
                    <div>
                      <IcCamera className={"hc-ic camera-ic svg-white"} />
                    </div>
                  </div>
                </div>

                <div
                  className={"row text-left medium-font mb-2"}
                  style={{ marginTop: "53px" }}
                >
                  <div className="col-md-8 offset-md-2">
                    <div className="form-group">
                      <label className={"pl-3 normal-font font-weight-medium"}>
                        Company Name
                      </label>
                      <CustomInput
                        validation={this.state.validation["company_name"]}
                        placeholder="Enter Company Name"
                        value={this.state.form.company_name}
                        onChange={this.handleInputChange}
                        name="company_name"
                        id="company_name"
                      />
                    </div>
                    <div className="form-row">
                      <div className="col-md-12">
                        <label
                          className={"pl-3 normal-font font-weight-medium"}
                        >
                          Location
                        </label>
                      </div>
                      <div className="form-group col-md-6">
                        <CustomSelect
                          validation={this.state.validation["province"]}
                          value={this.state.form.province}
                          data={this.state.provinces}
                          onChange={this.handleInputChange}
                          placeholder="Choose a province"
                          displayValue={"name"}
                          name="province"
                          id="province"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <CustomSelect
                          disabled={this.state.form.province === ""}
                          validation={this.state.validation["city"]}
                          value={this.state.form.city}
                          data={this.state.cities}
                          onChange={this.handleInputChange}
                          displayValue={"name"}
                          placeholder="Choose a city"
                          name="city"
                          id="city"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              "card bg-white border-none text-left pb-0 mb-3 rounded-lg"
            }
          >
            <div className={"card-body"}>
              <div className="d-flex justify-content-between">
                <h6>
                  <b>Life at The Company</b>
                </h6>
                <button
                  type="button"
                  onClick={this.onImageClick}
                  className="btn btn-upload-image rounded-lg pl-4 pr-4"
                >
                  <IcCamera className="hc-ic mr-2 svg-white" />
                  Upload Photo
                </button>
              </div>
              <hr />
              {this.props.imageGallery.loading === true ? (
                <div>...Loading</div>
              ) : (
                <Carousel
                  responsive={responsive}
                  ssr={true}
                  infinite={false}
                  partialVisbile={true}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  deviceType={this.props.deviceType}
                >
                  {this.props.imageGallery.imageList.map((item, i) => (
                    <div>
                      <img
                        src={item.image_link}
                        alt="company logo"
                        className="company-photo rounded-lg"
                      />
                    </div>
                  ))}
                </Carousel>
              )}
            </div>
          </div>

          <div className={"card bg-white border-none text-left rounded-lg"}>
            <div className="card-header">
              <h6 className={"pt-2"}>
                <b>Company Overview </b>
              </h6>
            </div>
            <div className={"card-body text-center pt-0"}>
              <div>
                <div className={"row text-left medium-font mb-2"}>
                  <div className="col-md-8 offset-md-2">
                    <h6 className={"pt-4"}>
                      <b>Summary </b>
                    </h6>
                    <hr />
                    <div className="form-group">
                      <label className={"pl-3 normal-font font-weight-medium"}>
                        About Company
                      </label>
                      <CustomTextarea
                        id="about_company"
                        name="about_company"
                        onChange={this.handleInputChange}
                        placeholder="Write about company here"
                        validation={this.state.validation["about_company"]}
                        rows={4}
                        value={this.state.form?.about_company ?? ""}
                      />
                    </div>

                    <h6 className={"pt-4"}>
                      <b>Basic Info </b>
                    </h6>
                    <hr />
                    <div className="form-group">
                      <label className={"pl-3 normal-font font-weight-medium"}>
                        Company Address
                      </label>
                      <CustomInput
                        validation={this.state.validation["company_address"]}
                        placeholder="Enter Company Address"
                        value={this.state.form.company_address}
                        onChange={this.handleInputChange}
                        name="company_address"
                        id="company_address"
                      />
                    </div>
                    <div className="form-group">
                      <label className={"pl-3 normal-font font-weight-medium"}>
                        Industry{" "}
                      </label>
                      <CustomSelect
                        validation={this.state.validation["industry_category"]}
                        value={this.state.form.industry_category}
                        data={this.state.categories}
                        onChange={this.handleInputChange}
                        placeholder="Choose a Industry"
                        displayValue={"text"}
                        name="industry_category"
                        id="industry_category"
                      />
                    </div>
                    <div className="form-group">
                      <label className={"pl-3 normal-font font-weight-medium"}>
                        Website Address
                      </label>
                      <CustomInput
                        validation={this.state.validation["website_address"]}
                        placeholder="Enter Website Address"
                        value={this.state.form.website_address}
                        onChange={this.handleInputChange}
                        name="website_address"
                        id="website_address"
                        helperText="Example: https://hacklab.rocks"
                      />
                    </div>
                    <div className="form-group">
                      <label className={"pl-3 normal-font font-weight-medium"}>
                        Number of Employees
                      </label>
                      <CustomSelect
                        validation={this.state.validation["number_of_employee"]}
                        value={this.state.form.number_of_employee}
                        data={this.state.options}
                        onChange={this.handleInputChange}
                        placeholder="Choose an Estimation"
                        displayValue={"text"}
                        name="number_of_employee"
                        id="number_of_employee"
                      />
                    </div>

                    <h6 className={"pt-4"}>
                      <b>Benefit </b>
                    </h6>
                    <hr />
                    <div className="form-group">
                      <label className={"pl-3 normal-font font-weight-medium"}>
                        Employees Benefit
                      </label>
                      <CustomTextarea
                        id="benefit"
                        name="benefit"
                        onChange={this.handleInputChange}
                        placeholder="Write a benefits"
                        validation={this.state.validation["benefit"]}
                        rows={4}
                        value={this.state.form?.benefit ?? ""}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"row py-5"}>
            <div className={"col-md-3 pr-0 offset-md-3"}>
              <div
                onClick={() => this.props.getDetailsCompany(this.state.uuid)}
                className="normal-font form-control py-4 btn btn-recommendation-reverse rounded-pill"
              >
                Reset
              </div>
            </div>
            <div className={"col-md-3 pl-1"}>
              <button
                type="submit"
                className="normal-font form-control py-4 btn btn-primary rounded-pill"
              >
                Save Changes
              </button>
            </div>
          </div>
        </form>
        <UploadLogo
          isLogoModal={this.state.isLogoModal}
          show={this.state.isLogoModal}
          onHide={() => this.setState({ isLogoModal: false })}
        ></UploadLogo>
        <SimpleModal
          show={this.state.uploadModal}
          onHide={() => this.setState({ uploadModal: false })}
          onUploadData={this.onUploadData}
          // onInitOpenModal={this.onInitOpenModal}
          companyGallery={this.state.companyGallery}
          caption={this.props.imageGallery.caption}
          // loading={this.state.loading}
        />
        <ImageModal
          show={this.state.modalShow}
          desc={
            "You cannot apply to this job vacancy before completing your CV"
          }
          titlebutton={["Complete a CV Now"]}
          onHide={() => this.setState({ modalShow: false })}
        />
      </HomeLanding>
    );
  }
}

const mapStateToProps = (state) => ({
  thisUser: state.thisUser,
  auth: state.auth,
  error: state.error,
  invalid: state.invalid,
  response: state.response,
  dispatch: state.dispatch,
  imageGallery: state.imageGallery,
});

const mapStateToDispatch = {
  getCompanyGallery,
  setImageGallery,
  setImageGalleryList,
  getProvince,
  getCity,
  getIndustry,
  getEmployee,
  setImageCaption,
  setImageLoading,
  getDetailsCompany,
  updateCompanyProfile,
};

export default withRouter(
  connect(mapStateToProps, mapStateToDispatch)(EditProfile)
);
