import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import CompanyPhoto from '../../images/company-photo-2.svg'
import ImageUploading from 'react-images-uploading'
import { ReactComponent as IcCamera } from '../../images/camera.svg'
import { connect } from 'react-redux'
import {
  uploadCompanyGallery,
  getCompanyGallery,
  setImageGallery,
  updateCompanyGalleryImage,
  setImageCaption,
  setImageGalleryList,
  setImageLoading,
} from '../../actions/companies'
import { toast } from 'react-toastify'
import { storageBaseUrl } from '../../actions/utils'
import { imageGallery } from '../../reducers/imageGalleryReducer'
import imageToBase64 from 'image-to-base64/browser'
import axios from 'axios'
class ImageModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      images: [],
      caption: '',
      loading: false,
    }
  }

  onChange = (imageList, addUpdateIndex) => {
    this.props.setImageGallery(imageList)
  }

  onChangeTextArea = e => {
    this.props.setImageCaption(e.target.value)
  }

  onCancel = e => {
    this.props.onHide()
  }

  getBase64FromUrl = async url => {
    imageToBase64(url).then(response => {
      return response
    })
  }

  handleUploadPhoto = async () => {
    const { images, imageList } = this.props.imageGallery
    const caption = this.props.caption

    let uploadedImage = []
    images.filter(async item => {
      if (item.image_link) {
        await imageToBase64(item.image_link).then(res => {
          const obj = {
            img: res,
            caption,
          }
          uploadedImage.push(obj)
        })
      } else {
        const obj = {
          img: item.data_url.split(',').pop(),
          caption,
        }
        uploadedImage.push(obj)
      }
    })

    setTimeout(() => {
      let token = localStorage.jwtToken
      const company = JSON.parse(localStorage.getItem('company'))
      this.props.setImageLoading(true)
      axios
        .post(
          process.env.REACT_APP_BASE_API +
            `/v1/upload-company-gallery/${company.uuid}`,
          { photos: uploadedImage },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then(async res => {
          if (res) {
            if (res.data.status === 'Success')
              toast.success('Data added successfully!', {
                position: toast.POSITION.TOP_CENTER,
              })
            this.props.onHide()
            this.setState({ caption: '', images: [] })
            await this.props.setImageGalleryList(res.data.data)
            this.props.setImageLoading(false)
          }
        }).catch(() => {
          this.props.onHide();
          this.props.setImageLoading(false)
        })
    }, 1000)
  }

  render() {
    const maxNumber = 69

    return (
      <Modal
        {...this.props}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        className={'text-dark medium-font'}
        contentClassName={'bg-transparent no-border'}
        centered
      >
        <Modal.Body className={'bg-white rounded-lg p-0'}>
          <div className='container-fluid p-0'>
            <div className='row mx-0'>
              <div className='col-md-12 mt-4'>
                <span className='pl-4 font-weight-bold'>Upload Photo</span>
                <hr />
              </div>
              <div className='col-md-12 pl-5 pr-5 mt-4'>
                <ImageUploading
                  multiple
                  value={this.props.imageGallery.images}
                  onChange={this.onChange}
                  maxNumber={maxNumber}
                  dataURLKey='data_url'
                  maxFileSize={2097152}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                    errors,
                    maxFileSize,
                  }) => (
                    // write your building UI
                    <div className='upload__image-wrapper' style={{ display: 'flex', position:'relative' }}>
                      {imageList.length <= 0 && (
                        <button
                          style={isDragging ? { color: 'red' } : null}
                          onClick={onImageUpload}
                          {...dragProps}
                          className='btn-dropzone'
                        >
                          <IcCamera
                            className={
                              'mb-5 hc-ic-lg camera-ic-lg svg-sky-blue'
                            }
                          />
                          <br />
                          <span className='mt-3 upload__dropzone-text font-weight-bold'>
                            Drop your image here or{' '}
                            <span style={{ color: '#62C3D0' }}>browse</span>
                          </span>
                          <br />
                          <span className='upload__support-file'>
                            Support multiple image JPG, JPEG, PNG (Max 2 MB)
                          </span>
                        </button>
                      )}
                      &nbsp;
                      <div style={{ display: 'none' }}>
                        {errors &&
                          errors.maxFileSize &&
                          toast.error('Ukuran gambar max 2MB!', {
                            position: toast.POSITION.TOP_CENTER,
                          })}
                      </div>
                      {imageList.map((image, index) => (
                        <>
                          <div key={index} className='image-item mt-3'>
                            <img
                              className='image-item-preview rounded-lg ml-3'
                              src={
                                image.hasOwnProperty('image_link')
                                  ? image.image_link
                                  : image.data_url
                              }
                              // src={image.data_url}

                              alt=''
                              width='100'
                            />
                            <span
                              onClick={e => {
                                onImageRemove(index)
                              }}
                              className='close'
                            ></span>
                          </div>
                        </>
                      ))}
                      {imageList.length > 0 && imageList.length < 5 && (
                        <div
                          className='dropzone-card-wrapper'
                          style={{
                            top: 130,
                            marginLeft: `calc(${imageList.length} * 140px)`,
                            position: 'absolute'
                          }}
                        >
                          <button
                            style={isDragging ? { color: 'red' } : null}
                            onClick={onImageUpload}
                            {...dragProps}
                            className='ml-2 pt-3 btn-dropzone-card rounded-lg'
                          >
                            <IcCamera
                              className={
                                'mb-1 hc-ic-lg camera-ic-lg-card svg-sky-blue'
                              }
                            />
                            <br />
                            <span className='mt-1 upload__dropzone-text font-weight-bold'>
                              Upload More
                            </span>
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </ImageUploading>
              </div>
              <div className='col-md-12 mt-3 mb-4'>
                <div className='px-4'>
                  <span className='ml-3 font-weight-bold'>Photo Caption</span>
                  <br />
                  <textarea
                    cols={96}
                    rows={5}
                    onChange={this.onChangeTextArea}
                    value={this.props.caption}
                    placeholder='Write a caption here'
                    className='mt-2 photo-caption-text-area px-4 full-width placeholder-message'
                  ></textarea>
                </div>
              </div>
              <div className='row col-md-9 pb-3 mx-auto justify-content-center'>
                <div className={'col-6'}>
                  <button
                    onClick={this.onCancel}
                    className='normal-font form-control btn btn-cancel-reverse rounded-pill'
                  >
                    Cancel
                  </button>
                </div>
                <div className={'col-6'}>
                  <button
                    onClick={this.handleUploadPhoto}
                    className='normal-font form-control btn btn-upload-image rounded-pill'
                  >
                    Upload Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  thisUser: state.thisUser,
  auth: state.auth,
  error: state.error,
  invalid: state.invalid,
  response: state.response,
  imageGallery: state.imageGallery,
})

const mapStateToDispatch = {
  uploadCompanyGallery,
  getCompanyGallery,
  setImageGallery,
  setImageGalleryList,
  updateCompanyGalleryImage,
  setImageCaption,
  setImageLoading,
}

export default connect(mapStateToProps, mapStateToDispatch)(ImageModal)
