/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux'
import { httpPost } from '../../actions/utils'
import Iframe from 'react-iframe'

const Assesment = ()=> {
    const [ url, setUrl ] = useState('');
    const dispatch = useDispatch();

    useEffect(async ()=>{
        let token = localStorage.jwtToken
        const res = await httpPost(dispatch, '/v1/skillana/login', null, {
                headers: {
                Authorization: `Bearer ${token}`
                }
            });
        setUrl(res.data.url);
    },[]);

    return (
        <div style={{height:'100vh'}}>
            {url && 
            <Iframe url={url}
            width="100%"
            height="100%"
            id="myIframe"
            className="myClassname"
            display="initial"
            position="relative"
            styles={{ height: '100vh' }}/>}
        </div>
    )
}

export default  connect()(Assesment)
