import { combineReducers } from 'redux'

import usersReducer from './userReducer'
import utilsReducer from './utilReducer'
import responseReducer from './responseReducer'
import dashboardReducer from './dashboardReducer'
import responseImageGallery from './imageGalleryReducer'

export default combineReducers({
  thisUser: usersReducer.thisUser,
  auth: usersReducer.loggedInUser,
  error: utilsReducer.error,
  invalid: utilsReducer.invalid,
  loading: utilsReducer.loading,
  response: responseReducer.response,
  responseDashboard: dashboardReducer.responseDashboard,
  imageGallery: responseImageGallery.imageGallery
})
