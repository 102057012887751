import {
  GET_JOBS,
  GET_SUGGESTED_COMPANY,
  SET_JOBS,
  SET_SUGGESTED_COMPANY,
  SEARCH_JOBS,
  SET_CURRENT,
} from './responseTypes'
import { httpGet, httpPost } from './utils'

export const getJobs = (page, perpage, search) => async dispatch => {
  let token = localStorage.jwtToken
  const res = await httpGet(
    dispatch,
    `/v1/job?page=${page}&perpage=${perpage}&q=${search}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
  if (!res) return null

  dispatch({
    type: SET_JOBS,
    payload: res.data,
  })
}

export const getSuggestedCompany = (p = 1, item = 10, s = 'no', q = '', location = '') => async dispatch => {
  let token = localStorage.jwtToken
  const res = await httpGet(
    dispatch,
    `/v1/company?q=${q}&location=${location}&page=${p}&perpage=${item}&suggested=${s}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  if (!res) return null

  dispatch({
    type: SET_SUGGESTED_COMPANY,
    payload: res.data
  })
}

export const getCurrent = () => async dispatch => {
  let token = localStorage.jwtToken
  const res = await httpGet(
    dispatch,
    `/v1/auth/position-education`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
  if (!res) return null
  dispatch({
    type: SET_CURRENT,
    payload: res,
  })
}


export const setSearchJobs = search => dispatch => {
  dispatch({ type: SEARCH_JOBS, payload: search })
}
