import { GET_USER, SET_AUTH, SET_LOGOUT } from '../actions/types'
const isEmpty = require('is-empty');

const initState = {
  thisUser: null,
  loggedInUser: {
    isAuthenticated: false,
    user: {},
    resData: {},
    loading: false
  }
}

export const loggedInUser = (state = initState.loggedInUser, action) => {
  switch (action.type) {
    case SET_AUTH:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload
      }

    case SET_LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: {}
      }

    default:
      return state
  }
}

export const thisUser = (state = initState.thisUser, action) => {
  switch (action.type) {
    case GET_USER:
      return action.payload
    default:
      return state
  }
}

const Users = {
  thisUser,
  loggedInUser
}

export default Users
