import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Landing from '../../../components/layouts/Landing'
import AuthCard from '../../../components/cards/AuthCard'
import Mail from '../../../images/mail-sent.svg'
import { capitalize } from '../../../utils/stringHelpers'

class VerifyAccountSuccess extends Component {
  constructor (props) {
    super(props)
    const msg = new URLSearchParams(this.props.location.search).get('message')
    this.state = {
      email: new URLSearchParams(this.props.location.search).get('email'),
      message: capitalize(msg.split('-').join(' '))
    }
  }

  render () {
    return (
      <Landing
        isLogoHidden
        isBgPersonHidden
      >
        <div className='d-flex align-items-start flex-column'>
          <div className='mb-auto p-2 full-width'>
            <div className='row justify-content-center'>
              <div className='col-lg-5 col-sm-8'>
                <div className='ml-auto'>
                  <AuthCard>
                    <form className='text-center'>
                      <img src={Mail} alt='mail' />
                      <h5 className='pt-4'><b>{this.state.message}</b></h5>
                      <p>Your email <b>{this.state.email}</b> has been verified Please  click button bellow to login</p>
                      <Link to='/login' className='form-control rounded-pill p-4 btn btn-primary font-weight-medium'>Continue Login</Link>
                    </form>
                  </AuthCard>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Landing>
    )
  }
}

export default withRouter(VerifyAccountSuccess)
