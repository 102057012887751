import { TALENT, RECRUITER, ADMIN } from '../constants'

export const getFormattedUser = (user) => {
  const industry = user.user_has_industry_category && user.user_has_industry_category.map((item) => {
    return {
      id: item.industry_category,
      title: item.detail.industry_category
    }
  })

  const jobRole = user.user_has_job_role && user.user_has_job_role.map((item) => {
    return {
      id: item.job_role,
      title: item.detail.job_role
    }
  })

  const skillInterest = user.user_has_skill_interest && user.user_has_skill_interest.map((item) => {
    return {
      id: item.skill_interest,
      title: item.detail.skill_interest
    }
  })

  const workExp = user.user_has_work_experience && user.user_has_work_experience.map((item) => {
    return {
      id: item.id,
      job_position: item.job_position,
      company: item.company,
      start_date: item.start_date,
      end_date: item.end_date === '0000-00-00' ? '' : item.end_date,
      currently_work_here: item.currently_work_here,
      job_description: item.description ?? ''
    }
  })

  const imageUrl = user.user_detail.profile_picture_link
  // user.user_detail.profile_picture_link.replace('http', 'https')

  // const isComplete = !((workExp?.length ?? 0) === 0 || (user?.user_has_educational_background?.length ?? 0) === 0)
  // const isComplete = user?.user_has_educational_background?.length === 0 || !user?.user_has_resume?.file_name ? 0 : 1;
  const isComplete = !user?.user_has_resume?.file_name ? 0 : 1;

  let roles = [TALENT]

  if (user.user_has_company && user.user_has_company.length > 0) {
    roles.push(RECRUITER)
  }
  if(Number(user.roles)===2){
    roles = [ADMIN];
  }


  const res = {
    ...user,
    id: user.id,
    uuid: user.uuid,
    name: user.name,
    phone: user.phone_number,
    roles: roles,
    email: user.email,
    email_verified_at: user.email_verified_at,
    active: user.active,
    created_at: user.created_at,
    job_apply: user.job_apply,
    recent_view: user.recent_view,
    industry_category: industry,
    job_role: jobRole,
    skill_interest: skillInterest,
    profile_views: {
      company: user.user_has_profile_views.company,
      users: user.user_has_profile_views.users
    },
    connection: {
      type: user.user_has_connection.type,
      followers: user.user_has_connection.followers,
      following: user.user_has_connection.following
    },
    province: user.user_detail.province,
    city: user.user_detail.city,
    marital_status: user.user_detail.marital_status,
    about_you: user.user_detail.about_you,
    image: user.user_detail.profile_picture,
    image_url: imageUrl,
    province_name: user.user_detail.province_detail?.name,
    city_name: user.user_detail.city_detail?.name,
    work_experience: workExp,
    companies: user.user_has_company ?? [],
    educational_background: user.user_has_educational_background,
    is_complete: isComplete
  }
  localStorage.setItem('user', JSON.stringify(res))

  return res
}