import Validator from 'validatorjs'
import {
  RULES_SKILLS,
  RULES_CATEGORY_INTEREST,
  RULES_LOGIN,
  RULES_REGISTER,
  RULES_FORGOT_PASSWORD,
  RULES_RESET_PASSWORD,
  RULES_COMPANY_REGISTER,
  RULES_REQ_VERIFY_COMPANY
} from './authRules'
import {
  RULES_PROFILE
} from './profileRules'
import {
  RULES_JOB_CREATE,
  RULES_EDIT_COMPANY,
} from './companyRules'

Validator.setAttributeFormatter(function(attribute) {
  let res = attribute.split('.')
  let count = res.length
  return res[count - 1].replace(/_/g, ' ');
});

Validator.register('min_skills', function (value, requirement, attribute) {
  return value.length >= 3
}, 'Please select at least 3 :attribute.')

export {
  Validator,
  // PROFILE RULES
  RULES_PROFILE,
  // AUTH RULES
  RULES_SKILLS,
  RULES_CATEGORY_INTEREST,
  RULES_LOGIN,
  RULES_REGISTER,
  RULES_FORGOT_PASSWORD,
  RULES_RESET_PASSWORD,
  RULES_COMPANY_REGISTER,
  RULES_REQ_VERIFY_COMPANY,
  // COMPANY RULES
  RULES_JOB_CREATE,
  RULES_EDIT_COMPANY
}