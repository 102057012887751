export const capitalize = (word) => {
  return word
    .split(' ')
    .map((letter) => {
      const loweredCase = letter.toLowerCase()
      return letter[0].toUpperCase() + loweredCase.slice(1)
    })
    .join(' ')
}

export const currency = (str) => {
  const tmp = (parseInt(str)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  return tmp.split('.')[0]
}