import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const PrivateRoute = ({ component: Component, auth, thisUser, role, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (!auth.isAuthenticated) {
        return <Redirect to='/login' />
      }

      // if (role && thisUser.roles.indexOf(role) === -1) {
      //   return <Redirect to={{ pathname: '/' }} />
      // }
      if (role) {
        if(!thisUser.roles.includes(role)){
          return <Redirect to={{ pathname: '/404' }} />
        }
      }
      return <Component {...props} />
    }}
  />
)

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
  thisUser: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  thisUser: state.thisUser
})

export default connect(mapStateToProps)(PrivateRoute)
