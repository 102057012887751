export const RULES_SKILLS = {
  skills: 'required|array|min_skills'
}

export const RULES_CATEGORY_INTEREST = {
  job_role: 'required|array|min_skills',
  company_segment: 'required|array|min_skills'
}

export const RULES_REGISTER = {
  name: 'required',
  phone_number: 'required|numeric',
  email: 'required|email',
  password: 'required|min:8'
}

export const RULES_LOGIN = {
  email: 'required|email',
  password: 'required|min:8'
}

export const RULES_FORGOT_PASSWORD = {
  email: 'required|email'
}

export const RULES_RESET_PASSWORD = {
  password: 'required|confirmed|min:8',
  password_confirmation: 'required',
  encrypted_key: 'required'
}

export const RULES_COMPANY_REGISTER = {
  company_name: 'required',
  province: 'required|numeric',
  city: 'required|numeric'
}

export const RULES_REQ_VERIFY_COMPANY = {
  email: 'required'
}
