import { Link } from 'react-router-dom'
import React, { Component } from 'react'
import 'react-multi-carousel/lib/styles.css'
import HomeLanding from '../../components/layouts/HomeLanding'
import SimpleModal from '../../components/modals/SimpleModal'
import { StatusButton } from '../../components/buttons/StatusButton'
import { myApplications } from '../../actions/jobs'
import { connect } from 'react-redux'
import _ from 'lodash'
import { ReactComponent as IcCalendar } from '../../images/calendar.svg'
import { ReactComponent as IcCompany } from '../../images/building2.svg'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component'

class MyApplicant extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalShow: false,
      status: 'All',
      totalApplications: 0,
      items: [],
      page: 1,
      perpage: 10,
      hasMore: false,
    }
  }

  componentDidMount = async () => {
    await this.props.myApplications(this.state.page, this.state.perpage)
    const res = this.props.response
    if (res) {
      this.setState({ items: res.data })
    }
  }

  handleFetch = async e => {
    await this.props.myApplications(this.state.page, this.state.perpage)
    this.setState({ status: e.target.dataset.id })
    const res = this.props.response

    if (res) {
      const data = res.data.filter((record, id) => {
        if (e.target.dataset.id === 'All') {
          return record
        } else if (
          record.status_detail.talent === 'Submitted' &&
          e.target.dataset.id === 'Submitted'
        ) {
          return record
        } else if (
          record.status_detail.talent === 'On Review' &&
          e.target.dataset.id === 'On Review'
        ) {
          return record
        } else if (
          record.status_detail.talent === 'On Review' &&
          e.target.dataset.id === 'Proceed Next Step'
        ) {
          return record
        } else if (
          record.status_detail.talent === 'On Review' &&
          e.target.dataset.id === 'Rejected'
        ) {
          return record
        }
      })
      this.setState({ items: data })
    }
  }

  fetchMoreData = async () => {
    const { page, perPage } = this.state

    await this.props.myApplications(page + 1, perPage)
    this.setState({ page: this.state.page + 1 })

    const res = await this.props.response
    if (res) {
      if (res.data.length > 0) {
        this.setState({
          hasMore: true,
        })

        const data = res.data.filter((record, id) => {
          if (this.state.status === 'All') {
            return record
          } else if (
            record.status_detail.talent === 'Submitted' &&
            this.state.status === 'Submitted'
          ) {
            return record
          } else if (
            record.status_detail.talent === 'On Review' &&
            this.state.status === 'On Review'
          ) {
            return record
          } else if (
            record.status_detail.talent === 'On Review' &&
            this.state.status === 'Proceed Next Step'
          ) {
            return record
          } else if (
            record.status_detail.talent === 'On Review' &&
            this.state.status === 'Rejected'
          ) {
            return record
          }
        })

        setTimeout(() => {
          this.setState({
            items: this.state.items.concat(data),
          })
        }, 1500)
      }

      if (res.data.length <= 0) {
        this.setState({
          hasMore: false,
        })
      }
    }
  }

  render() {
    return (
      <HomeLanding>
        <div className='row container-padding'>
          <div className='col-12 col-md-3'>
            <div className='card sticky-top mt-sidebar custom-sidebar-summary border-none text-left pb-0 mb-3 rounded-lg'>
              <div className='card-header white-br-bottom'>
                <h6 className='pt-2'>
                  <b>My Applications </b>
                </h6>
              </div>
              <div className='card-body pb-1'>
                <div>
                  <ul type='none' className='pl-0 normal-font'>
                    <li
                      className={`${
                        this.state.status === 'All'
                          ? 'pb-3 font-weight-bold'
                          : 'pb-3'
                      }`}
                      value='all'
                      key='all'
                      data-id={'All'}
                      onClick={this.handleFetch}
                    >
                      All
                      {/* <Link onClick={this.handleFetch} className='link-white large-font'>All</Link> */}
                    </li>
                    <li
                      className={`${
                        this.state.status === 'Submitted'
                          ? 'pb-3 font-weight-bold'
                          : 'pb-3'
                      }`}
                      key='submitted'
                      data-id={'Submitted'}
                      onClick={this.handleFetch}
                    >
                      Submitted
                      {/* <Link className='link-white large-font'>Submitted</Link> */}
                    </li>
                    <li
                      className={`${
                        this.state.status === 'On Review'
                          ? 'pb-3 font-weight-bold'
                          : 'pb-3'
                      }`}
                      key='review'
                      data-id={'On Review'}
                      onClick={this.handleFetch}
                    >
                      On Review
                      {/* <Link className='link-white large-font'>On Review</Link> */}
                    </li>
                    <li
                      className={`${
                        this.state.status === 'Proceed'
                          ? 'pb-3 font-weight-bold'
                          : 'pb-3'
                      }`}
                      key='proceed'
                      data-id={'Proceed'}
                      onClick={this.handleFetch}
                    >
                      {/* <Link className='link-white large-font'> */}
                      Proceed Next Step
                      {/* </Link> */}
                    </li>
                    <li
                      className={`${
                        this.state.status === 'Rejected'
                          ? 'pb-3 font-weight-bold'
                          : 'pb-3'
                      }`}
                      key='rejected'
                      data-id={'Rejected'}
                      onClick={this.handleFetch}
                    >
                      Rejected
                      {/* <Link className='link-white large-font'>Rejected</Link> */}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-9'>
            <div className='card bg-white border-none text-left pb-0 mb-3 rounded-lg'>
              <div className='card-header mb-3'>
                <h6 className='pt-2'>
                  <b>{this.state.items.length} Applications </b>
                </h6>
              </div>

              <div className='card-body'>
                <InfiniteScroll
                  dataLength={this.state.items.length}
                  next={this.fetchMoreData}
                  hasMore={this.state.hasMore}
                  loader={
                    <div className='d-flex align-item-center pb-4 justify-content-center'>
                      <span className='medium-font text-muted'>Loading...</span>
                    </div>
                  }
                >
                  {this.state.items.map((item, id) => {
                    return (
                      <div className='card bg-gray-shadow border-none rounded-lg mb-4'>
                        <div className='card-body'>
                          <div className='row'>
                            <div className='col-md-2 col-sm-12 d-flex'>
                              {item?.detail_information?.logo_url ? (
                                <img
                                  src={item?.detail_information?.logo_url}
                                  className='mt-2 avatar-sm rounded-circle'
                                />
                              ) : (
                                <IcCompany className='mt-2 avatar-sm rounded-circle' />
                              )}
                            </div>
                            <div className='col-md-10 col-sm-12'>
                              <span className='font-weight-bold'>
                                {item?.detail_information?.job_title ?? '-'}
                              </span>
                              <p className='small'>
                                {item?.detail_information?.company_name ?? '-'}{' '}
                                -{' '}
                                {_.startCase(
                                  _.toLower(
                                    item?.detail_information?.province ?? '-',
                                  ),
                                )}
                                , {item?.detail_information?.country ?? '-'}
                              </p>
                              <hr />
                              <div className='d-flex'>
                                <IcCalendar className='hc-ic mr-2' />
                                <p>
                                  Submitted on{' '}
                                  {moment(item?.created_at.original).format(
                                    'MMMM D YYYY, h:mm a',
                                  )}
                                </p>
                              </div>
                              <StatusButton
                                status={
                                  item?.status_detail?.talent ?? 'Submitted'
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </HomeLanding>
    )
  }
}

const mapStateToProps = state => ({
  thisUser: state.thisUser,
  auth: state.auth,
  error: state.error,
  invalid: state.invalid,
  response: state.response,
})

const mapStateToDispatch = {
  myApplications,
}
export default connect(mapStateToProps, mapStateToDispatch)(MyApplicant)
