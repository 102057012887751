import React from 'react'
import { Multiselect } from 'multiselect-react-dropdown'

const CustomMultiSelect = (props) => {
  let isValid = true
  let errorMessage = ''
  const [placeholder, setPlaceholder] = React.useState((props.selectedValues ?? []).length > 0 ? '' : props.placeholder)

  const handleInputChange = (selectedList) => {
    const name = props.name
    const value = selectedList.map((item) => item[props.returnValue ?? 'id'])
    if (selectedList.length > 0) {
      setPlaceholder('')
    } else {
      setPlaceholder(props.placeholder)
    }
    if (name !== undefined) {
      props.onChange(name, value)
    }
    // props.onChange(event)
  }

  const preselected = props.selectedValues && props.options.reduce(function (filtered, option) {
    if (props.selectedValues.includes(option.id)) {
      filtered.push({ id: option.id, [props.displayValue]: option[props.displayValue] })
    }
    return filtered
  }, [])

  if (props.validation) {
    isValid = !((props.validation ?? []).length > 0)
    errorMessage = (props.validation ?? [''])[0]
  }

  return (

    <div className='w-100'>
      <Multiselect
        closeIcon='circle'
        name={props.name}
        id={props.id}
        selectedValues={preselected}
        showCheckbox={props.showCheckbox}
        avoidHighlightFirstOption={props.avoidHighlightFirstOption}
        closeOnSelect={props.closeOnSelect}
        options={props.options} // Options to display in the dropdown
        onSelect={handleInputChange} // Function will trigger on select event
        onRemove={handleInputChange} // Function will trigger on remove event
        displayValue={props.displayValue} // Property name to display in the dropdown options
        placeholder={placeholder}
      />
      {(props.helperText || !isValid) && (<span className={`${isValid ? 'text-muted' : 'text-danger'} normal-font`}>{isValid ? props.helperText : errorMessage}</span>)}
    </div>

  )
}

CustomMultiSelect.propTypes = {}
export default (CustomMultiSelect)
