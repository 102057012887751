import React, { Component } from 'react'
import Background from '../../images/background.png'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import HomeLanding from '../../components/layouts/HomeLanding'
import Company from '../../images/building2.svg'
import CompanyPhoto from '../../images/company-photo.png'
import OptionButton from '../../components/buttons/OptionButton'
import ImageModal from '../../components/modals/ImageModal'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import { ReactComponent as IcPencil } from '../../images/pencil.svg'
import { getDetailsCompany, getCompanyGallery, setImageGallery, getImageGallery, setImageGalleryList, setImageCaption, setImageLoading } from '../../actions/companies';
import { GET_COMPANY_DETAILS, GET_COMPANY_GALLERY, GET_CITY, GET_EMPLOYEE, GET_INDUSTRY, GET_PROVINCE, SET_IMAGE_GALLERY, UPDATE_PROFILE } from '../../actions/responseTypes';
import { alertErrorValidation } from '../../utils'

class CompanyProfile extends Component {
  constructor (props) {
    super(props)
    const { id } = props.match.params
    this.state = {
      companyGallery: [],
      items: [],
      uuid: id,
      modalShow: false,
      company: {},
      index: 0,
      loading: false
    }
  }

  componentDidMount = async () => {
    await this.props.getDetailsCompany(this.state.uuid)
    await this.props.getCompanyGallery(this.state.uuid)
    await this.props.setImageLoading(true)

    await this.props.setImageGallery([])
    await this.props.setImageCaption('')
    await this.props.getCompanyGallery(this.state.uuid)
    const res = await this.props.response
    if(res) {
        await this.props.setImageCaption(res.data[0]?.caption)
        await this.props.setImageGallery(res.data)
        await this.props.setImageGalleryList(res.data)
        await this.props.getCompanyGallery(this.state.uuid)
    }
    
    await this.props.setImageLoading(false)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.response !== this.props.response && this.props.response) {
      switch (this.props.response.type) {
        case GET_COMPANY_DETAILS:
            this.setState({ company: this.props.response.data })
            break
        case GET_COMPANY_GALLERY:
          console.log("QWE", this.props.response)
            this.setState({ items: this.props.response.data.data })
            break
        default:
          break
      }

    }
    if (prevProps.error !== this.props.error && this.props.error) {
      alertErrorValidation(this.props.error)
    }
    // console.log(this.state)
  }

  handleModal = (index) => {
      this.setState({index: index}, () => {
          this.setState({modalShow: true})
          // console.log(this.state.items[this.state.index])
      })
  }

  isUrl = (url) =>{
    if(url.includes('https://') || url.includes('http://')){
      return url; 
    }else{
      return 'http://'+url;
    }
  }

  render () {
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 6,
        slidesToSlide: 1, // optional, default to 1.
        partialVisibilityGutter: 40
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        slidesToSlide: 2, // optional, default to 1.
        partialVisibilityGutter: 30
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
        slidesToSlide: 1, // optional, default to 1.
        partialVisibilityGutter: 20
      }
    }
    return (
      <HomeLanding>
        <div className='py-3'>
          <div className='card bg-white border-none text-left pb-0 mb-3 rounded-lg'>
            <div className='card-header rounded-lg-top' style={{ height: '200px', backgroundImage: `url(${Background})`, objectFit: 'cover' }} />
            <div className='card-body text-center pt-0'>
              <div>
                <div style={{ transform: 'translateY(-110px)' }} className='center-absolute'>
                <img src={this.state.company?.company_information?.logo_link ?this.state.company?.company_information?.logo_link :Company} alt='company logo' className='avatar rounded-circle' />
                </div>
                <div className='medium-font mb-2' style={{ marginTop: '53px' }}>
                  <b>
                    {this.state.company?.company_information?.company_name ?? '-'}
                  </b>
                </div>
                <span className='medium-font company-address font-weight-medium'>{this.state.company?.company_information?.province_detail?.name ?? '-'}, {this.state.company?.company_information?.country ?? '-'}</span>
                {/* <p className='normal-font'>5,000 - 10,000 Employees</p> */}
              </div>
            </div>
          </div>
          <div className={"card bg-white border-none text-left pb-0 mb-3 rounded-lg"}>
              <div className={"card-body"}>
                  <div className="d-flex justify-content-between">
                  <h6><b>Life at The Company</b></h6>
                  </div>
                  <hr/>
                  {this.props.imageGallery.loading === true ? <div>...Loading</div> : (
                      <Carousel
                          responsive={responsive}
                          ssr={true}
                          infinite={false}
                          partialVisbile={true}
                          containerClass="carousel-container"
                          removeArrowOnDeviceType={["tablet", "mobile"]}
                          deviceType={this.props.deviceType}
                      >
                          {this.props.imageGallery.imageList.map((item, i) =>
                              <div>
                                  <img onClick={()=>this.handleModal(i)} src={item.image_link} alt="company logo" className="company-photo rounded-lg"/>
                              </div>
                          )}
                      </Carousel>
                      )
                  }
              </div>
          </div>
          <div className='card bg-white border-none text-left pb-0 rounded-lg'>
            <div className='card-body'>
              <h6><b>Overview</b></h6>
              <hr />
              {/* <OptionButton /> */}
              <span className='font-weight-medium'>Summary</span>
              <br /><br />
              <span>
              {this.state.company?.company_information?.about ?? '-'}
              </span>
              <hr />
              {/* <OptionButton /> */}
              <span className='font-weight-medium'>Basic Info</span>
              <br /><br />
              <div className='row'>
                <div className='col-md-4 col-xs-6'>
                  Office Address
                </div>
                <div className='col-md-8 col-xs-6 font-weight-medium'>
                  <span className='d-md-inline d-none'>:&nbsp;</span>{this.state.company?.company_information?.address ?? '-'}
                </div>

                <div className='col-md-4 col-xs-6'>
                  Industry
                </div>
                <div className='col-md-8 col-xs-6 font-weight-medium'>
                  <span className='d-md-inline d-none'>:&nbsp;</span>{this.state.company?.company_information?.industry_category_detail?.industry_category ?? '-'}
                </div>

                <div className='col-md-4 col-xs-6'>
                  Website Address
                </div>
                <div className='col-md-8 col-xs-6 font-weight-medium'>
                  <span className='d-md-inline d-none'>:&nbsp;</span><a target="_blank" href={this.isUrl(this.state.company?.company_information?.website  ?? '/home')}>{this.state.company?.company_information?.website ?? '-'}</a>
                </div>

                {/* <div className='col-md-4 col-xs-6'>
                  Number of Employees
                </div>
                <div className='col-md-8 col-xs-6 font-weight-medium'>
                  <span className='d-md-inline d-none'>:&nbsp;</span>100 - 200
                </div> */}
              </div>
              <hr />
              {/* <OptionButton /> */}
              <span className='font-weight-medium'>Benefit</span>
              <div className='row'>
                <div className='col-md-4 col-xs-6'>
                  Employees Benefit
                </div>
                <div className='col-md-8 col-xs-6 font-weight-medium'>
                {this.state.company?.company_information?.benefit ?? '-'}
                </div>
              </div>

            </div>
          </div>
        </div>
        <ImageModal
          show={this.state.modalShow}
          caption={this.props.imageGallery.imageList[this.state.index]?.caption ?? ''}
          imageLink={this.props.imageGallery.imageList[this.state.index]?.image_link ?? ''}
          onHide={() => this.setState({ modalShow: false })}
          setPrev={()=>this.handleModal( this.state.index > 0 ? (this.state.index - 1 ) : 0)}
          setNext={()=>this.handleModal((this.props.imageGallery.imageList.length-1) > this.state.index ? this.state.index + 1: this.state.index)}
        />
      </HomeLanding>
    )
  }
}


const mapStateToProps = state => ({
  thisUser: state.thisUser,
  auth: state.auth,
  error: state.error,
  invalid: state.invalid,
  response: state.response,
  dispatch: state.dispatch,
  imageGallery: state.imageGallery
})

const mapStateToDispatch =  {
  getCompanyGallery,
  setImageGallery,
  setImageGalleryList,
  setImageCaption,
  setImageLoading,
  getDetailsCompany,
}

export default withRouter(connect(mapStateToProps, mapStateToDispatch)(CompanyProfile))
    