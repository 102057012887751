export const RULES_PROFILE = {
  fullname: 'required',
  province: 'required',
  city: 'required',
  marital_status: 'required',
  phone_number: 'required|numeric',
  job_role: 'required|array|min_skills',
  industry_category: 'required|array|min_skills',
  skill_interest: 'required|array|min_skills',
  about_you: 'required',
  'work_experience.*.job_position': 'required',
  'work_experience.*.company': 'required',
  'work_experience.*.start_date': ['required', 'date'],
  'educational_background.*.degree': 'required',
  'educational_background.*.school': 'required',
  'educational_background.*.start_date': ['required', 'date'],
  'educational_background.*.end_date': ['required', 'date'],
  // 'educational_background.*.title': 'required'
}
