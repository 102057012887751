import React, {Component} from 'react';
import {Modal} from 'react-bootstrap'
import {Link} from "react-router-dom";
import IcSallyOvo from '../../images/saly-ovo.svg'

class SignInModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title ?? "Welcome to Hacklab!",
            titleButton: this.props.titlebutton ?? ["Complete a CV Now", "Complete Later"],
            doubleButton: this.props.doublebutton ?? false,
            subtitleButton: this.props.subtitlebutton ?? "*Terms and conditions apply",
            hrefButton: this.props.hrefbutton ?? ["/", "/"]
            
        }
    }

    render() {
        return (
            <Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            className={"text-dark medium-font"}
            contentClassName={"bg-transparent no-border"}
            centered
            >
                <Modal.Body className={"bg-orange-base rounded-lg text-white"}>
                    <div className="d-flex align-items-center">
                        <div>
                            <img alt="sally" src={IcSallyOvo} className="d-lg-block d-none" style={{transform:"translateY(-40px)"}}/>
                        </div>
                        <div className="p-2 flex-grow-1">
                            <h4 className="font-weight-medium">{this.state.title}</h4>
                            <p className="medium-font">
                            Get an <b>OVO</b> balance of 50,000 if you create or complete a CV at Hacklab*
                            </p>
                            {
                                this.state.doubleButton ?
                                (
                                    <div className="row align-items-center mt-4">
                                        <Link to={`${this.state.hrefButton[0]}`} className="col-md-5 mx-1 col-12 no-border btn btn-recommendation normal-font font-weight-medium rounded-pill">{this.state.titleButton[0]}</Link> 
                                        <div className={"col-md-1 text-center col-12 normal-font"}>Or</div>
                                        <Link to={`${this.state.hrefButton[1]}`} className="text-white col-md-5 mx-1 col-12 btn btn-transparent normal-font font-weight-medium rounded-pill">{this.state.titleButton[1]}</Link>
                                    </div>
                                ) : (
                                    <Link to={`${this.state.hrefButton}`} className="btn btn-recommendation no-border w-150px normal-font font-weight-medium rounded-pill">{this.state.titleButton[0]}</Link>
                                )
                            }
                            <br/>
                            <span className="normal-font">{this.state.subtitleButton}</span>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

export default SignInModal
