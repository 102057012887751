import { Link } from 'react-router-dom'
import React, { Component } from 'react'
import User from '../../images/user-1.svg'
import Carousel from 'react-multi-carousel'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import 'react-multi-carousel/lib/styles.css'
import { ReactComponent as IcWarning } from '../../images/warning_icon.svg'
import InfiniteScroll from 'react-infinite-scroll-component'
import HomeLanding from '../../components/layouts/HomeLanding'
import { ReactComponent as IcStar } from '../../images/star.svg'
import { ReactComponent as IcClock } from '../../images/clock.svg'
import { ReactComponent as IcVideo } from '../../images/video.svg'
import { ReactComponent as IcCase } from '../../images/suitcase.svg'
import { ReactComponent as IcCamera } from '../../images/camera.svg'
import { ReactComponent as IcCompany } from '../../images/company.svg'
import { ReactComponent as IcApp } from '../../images/applications.svg'
import { ReactComponent as IcChevronRight } from '../../images/chevron_right.svg'
import { ReactComponent as IcAddRectangle } from '../../images/add-rectangle.svg'
import { ReactComponent as IcPencil } from '../../images/pencil.svg'
import { ReactComponent as IcMore } from '../../images/more-menu.svg'
import { ReactComponent as IcDotsMore } from '../../images/dot-more.svg'
import { ReactComponent as IcAlertCircle } from '../../images/alert-circle.svg'
import { ReactComponent as IcCalendar } from '../../images/calendar-red.svg'
import DashboardModal from '../../components/modals/DashboardModal'
import Avatar from '../../images/avatar-talent.png'
import { withRouter } from 'react-router-dom'
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from 'recharts'
import ReactTable from 'react-table-v6'
import 'react-table-v6/react-table.css'
import Dropdown, { MenuItem } from '@trendmicro/react-dropdown'
import '@trendmicro/react-buttons/dist/react-buttons.css'
import '@trendmicro/react-dropdown/dist/react-dropdown.css'
import ReactMonthPicker from 'react-month-picker'
import 'react-month-picker/css/month-picker.css'
import { companyDashboard } from '../../actions/companies'

const MonthPicker = ({ range }) => {
  const [isVisible, setVisibility] = React.useState(false)
  const [monthYear, setMonthYear] = React.useState({})

  const showMonthPicker = event => {
    setVisibility(true)
    event.preventDefault()
  }

  const handleOnDismiss = () => {
    setVisibility(false)
  }

  const handleOnChange = (year, month) => {
    setMonthYear({ year, month })
    setVisibility(false)
  }

  const getMonthValue = () => {
    const month = monthYear && monthYear.month ? monthYear.month : 0
    const year = monthYear && monthYear.year ? monthYear.year : 0

    let renderMonth
    switch (month) {
      case 1:
        renderMonth = 'January'
        break
      case 2:
        renderMonth = 'February'
        break
      case 3:
        renderMonth = 'March'
        break
      case 4:
        renderMonth = 'April'
        break
      case 5:
        renderMonth = 'May'
        break
      case 6:
        renderMonth = 'June'
        break
      case 7:
        renderMonth = 'July'
        break
      case 8:
        renderMonth = 'August'
        break
      case 9:
        renderMonth = 'September'
        break
      case 10:
        renderMonth = 'October'
        break
      case 11:
        renderMonth = 'November'
        break
      case 12:
        renderMonth = 'December'
        break
      default:
        renderMonth = 'March'
    }

    return month && year ? `${renderMonth} ${year}` : 'March 2021'
  }

  return (
    <div className='MonthYearPicker'>
      <button className='btn-month-picker' onClick={showMonthPicker}>
        {getMonthValue()} <IcCalendar className='ml-1 ic-calendar-red hc-ic' />{' '}
      </button>

      <ReactMonthPicker
        show={isVisible}
        lang={[
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ]}
        years={range}
        value={monthYear}
        onChange={handleOnChange}
        onDismiss={handleOnDismiss}
      />
    </div>
  )
}

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [],
      modalShow: true,
      detail: null
    }
    this.fetchMoreData = this.fetchMoreData.bind(this)
  }

  componentDidMount = async () => {
    await this.props.companyDashboard()
    const res = await this.props.response
    if(res.data) {
      this.setState({
        detail: res?.data,
        items: res?.data?.recruitment_progress,
      })
    }
  }

  fetchMoreData() {
    // setTimeout(() => {
    //   this.setState({
    //     items: this.state.items.concat([1, 2, 3, 4, 5]),
    //   })
    // }, 1500)
  }

  handleClickMore = () => {}

  render() {
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2,
        slidesToSlide: 1, // optional, default to 1.
        partialVisibilityGutter: 40,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2, // optional, default to 1.
        partialVisibilityGutter: 30,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
        partialVisibilityGutter: 20,
      },
    }

    // const dataTable = [
    //   {
    //     no: 1,
    //     fullName: 'Rendy Kharisma',
    //     position: 'UI/UX Designer',
    //     status: 'Hired',
    //   },
    //   {
    //     no: 2,
    //     fullName: 'Rendy Kharisma',
    //     position: 'UI/UX Designer',
    //     status: 'Hired',
    //   },
    //   {
    //     no: 3,
    //     fullName: 'Rendy Kharisma',
    //     position: 'UI/UX Designer',
    //     status: 'Hired',
    //   },
    //   {
    //     no: 4,
    //     fullName: 'Rendy Kharisma',
    //     position: 'UI/UX Designer',
    //     status: 'Rejected',
    //   },
    //   {
    //     no: 5,
    //     fullName: 'Rendy Kharisma',
    //     position: 'UI/UX Designer',
    //     status: 'On Review',
    //   },
    //   {
    //     no: 6,
    //     fullName: 'Rendy Kharisma',
    //     position: 'UI/UX Designer',
    //     status: 'On Review',
    //   },
    //   {
    //     no: 7,
    //     fullName: 'Rendy Kharisma',
    //     position: 'UI/UX Designer',
    //     status: 'On Review',
    //   },
    //   {
    //     no: 8,
    //     fullName: 'Rendy Kharisma',
    //     position: 'UI/UX Designer',
    //     status: 'On Review',
    //   },
    //   {
    //     no: 9,
    //     fullName: 'Rendy Kharisma',
    //     position: 'UI/UX Designer',
    //     status: 'On Review',
    //   },
    //   {
    //     no: 10,
    //     fullName: 'Rendy Kharisma',
    //     position: 'UI/UX Designer',
    //     status: 'On Review',
    //   },
    // ]
    const dataTable = this?.state?.items?.map((item, index) => {
      return {
        'no': index+1,
        'fullName': item.name,
        'position': item.applied_to_job,
        'status': item.status,
      }
    })

    const columns = [
      {
        Header: (
          <div
            className='font-weight-bold'
            style={{
              backgroundColor: '#62c3d0',
              padding: '0.8rem',
              borderTopLeftRadius: '1rem',
              borderBottomLeftRadius: '1rem',
              color: '#ffffff',
            }}
          >
            No
          </div>
        ),
        Cell: props => (
          <div
            className='font-weight-bold'
            style={{ textAlign: 'center', fontSize: '14px' }}
          >
            {props.value}
          </div>
        ),
        accessor: 'no',
        resizable: false,
        sortable: false,
        width: 100,
      },
      {
        Header: (
          <div
            className='font-weight-bold'
            style={{
              backgroundColor: '#62c3d0',
              padding: '0.8rem',
              paddingLeft: '0.2rem',
              textAlign: 'left',
              color: '#ffffff',
            }}
          >
            Full Name
          </div>
        ),
        accessor: 'fullName',
        Cell: props => (
          <span
            className='font-weight-bold'
            style={{
              textAlign: 'left',
              fontSize: '14px',
            }}
          >
            {props.value}
          </span>
        ),
        resizable: false,
        sortable: false,
        width: 360,
      },
      {
        Header: (
          <div
            className='font-weight-bold'
            style={{
              backgroundColor: '#62c3d0',
              padding: '0.8rem',
              paddingLeft: '0.2rem',
              textAlign: 'left',
              color: '#ffffff',
            }}
          >
            Position
          </div>
        ),
        Cell: props => (
          <span
            className='font-weight-bold'
            style={{
              textAlign: 'left',
              fontSize: '14px',
            }}
          >
            {props.value}
          </span>
        ),
        accessor: 'position',
        resizable: false,
        sortable: false,
        width: 360,
      },
      {
        Header: props => (
          <div
            className='font-weight-bold'
            style={{
              backgroundColor: '#62c3d0',
              padding: '0.8rem',
              paddingLeft: '0.2rem',
              textAlign: 'left',
              color: '#ffffff',
            }}
          >
            Status
          </div>
        ),
        Cell: props => (
          <span
            className='font-weight-bold'
            style={{
              textAlign: 'left',
              fontSize: '14px',
            }}
          >
            <div
              style={{
                borderRadius: '50px',
                backgroundColor:
                  props.value === 'Hired'
                    ? '#29C973'
                    : props.value === 'On Review'
                    ? '#FDD100'
                    : '#E04F5F',
                width: 'fit-content',
                padding: '5px 15px',
                color: props.value === 'On Review' ? '#000000' : '#ffffff',
              }}
            >
              {props.value}
            </div>
          </span>
        ),
        accessor: 'status',
        width: 190,
        resizable: false,
        sortable: false,
      },
      {
        Header: props => (
          <div
            style={{
              backgroundColor: '#62c3d0',
              padding: '0.8rem',
              borderTopRightRadius: '1rem',
              borderBottomRightRadius: '1rem',
              color: '#62c3d0',
            }}
          >
            .
          </div>
        ),
        accessor: 'fullName',
        Cell: ({ original }) => (
          <div className='cursor__pointer '>
            {/* <Dropdown onSelect={eventKey => {}}>
              <Dropdown.Toggle btnStyle='link' noCaret>
                <IcMore
                  className='ic-dashboard'
                  onClick={this.handleClickMore}
                />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <MenuItem header>Header</MenuItem>
                <MenuItem eventKey={1}>link</MenuItem>
                <MenuItem divider />
                <MenuItem header>Header</MenuItem>
                <MenuItem eventKey={2}>link</MenuItem>
              </Dropdown.Menu>
            </Dropdown> */}
          </div>
        ),
        width: 45,
        resizable: false,
        sortable: false,
      },
    ]

    return (
      <HomeLanding>
        <div className='row pt-3 pb-3'>
          <div className='col-12'>
          {!this.state.detail?.company_information?.isshown ?
                <div class="alert alert-warning" role="alert">
                  <a href="#" class="alert-link"><IcWarning />  For now your job vacancies and company page will not be seen by talent, please contact us so that your company can immediately get the best talent from hacklab.</a> <a href="http://t.me/hacklabrocksid" className="alert-link-active">Click this link to contact us</a>
                </div> : null}
            <div className='row justify-content-between align-items-center mr-0 mb-3 mt-4'>
              <h4 className='col-5 font-weight-bold'>Recruitment Progress</h4>
            </div>
            {/* <div className='row justify-content-between mr-0 mb-3 mt-4'>
            <div className='mb-3 btn-group-toggle row col-12' data-toggle='buttons' style={{width: "50%"}}>
                <label className='col-2 p-3 ml-5 btn btn-recommendation-reverse rounded-pill active'>
                  <input type='radio' name='options' id='option1' autocomplete='off' checked /> All (3)
                </label>
                <label className='col-2 p-3 ml-2 btn btn-recommendation-reverse rounded-pill'>
                  <input type='radio' name='options' id='option2' autocomplete='off' /> Hired (3)
                </label>
                <label className='col-2 p-3 ml-2 btn btn-recommendation-reverse rounded-pill'>
                  <input type='radio' name='options' id='option3' autocomplete='off' /> On Review (2)
                </label>
                <label className='col-2 p-3 ml-2 btn btn-recommendation-reverse rounded-pill'>
                  <input type='radio' name='options' id='option3' autocomplete='off' /> Rejected (1)
                </label>
              </div> 
            </div>*/}

            {/* <div className='card bg-white rounded-lg normal-font mb-3'>
                <div className='card-body'>
                  <div className='row justify-content-between'>
                    <div className='col-lg-4 col-xs-12'>
                      <input type='text' className='p-4 m-2 form-control rounded-pill custom-textfield' placeholder='Search by job title' />
                    </div>
                    <div className='col-lg-3 col-xs-12'>
                      <div className='dropdown m-2 custom-select-bw d-flex'>
                        <b>Sort by :</b>
                        <select className='cursor-pointer form-select select-simple' aria-label='Default select example'>
                          <option selected> Posting date</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

            <div className='card bg-white pt-3 pb-3 px-4 rounded-lg'>
              <ReactTable
                data={dataTable}
                columns={columns}
                showPagination={false}
                defaultPageSize={8}
              />
            </div>
          </div>
        </div>
      </HomeLanding>
    )
  }
}

const mapStateToProps = state => {
  return{
    auth: state.auth,
    thisUser: state.thisUser,
    response: state.response,
  }
}

const mapStateToDispatch = {
  companyDashboard
}

export default withRouter(connect(mapStateToProps, mapStateToDispatch)(Home))
