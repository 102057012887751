import React, { Component } from "react";
import User from "../../images/user-1.svg";
import {Link} from "react-router-dom";
import Plus from "../../images/plus.svg";

class AccountCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isRecruiter: this.props.isRecruiter ?? false
        }
    }

    render() {
        return (
            <div className="d-flex flex-row mt-3">
                <div className="p-2 bd-highlight">
                    <div className={`card ${this.state.isRecruiter ? "custom-recruiter-sm-card" : "custom-sm-card"} text-center shadow-sm mb-2 rounded-md`}>
                        <div className={"card-body"}>
                            <img src={User} className={"avatar mt-3 rounded-circle"} alt={"icon"}/>
                            <span className={"normal-font font-weight-medium"}>Valerie Regina Pola</span>
                            <hr className={"p-0 mb-0 mt-2"}/>
                            <small className={"text-muted"}>Active 1 days ago</small>
                        </div>
                    </div>
                </div>
                <div className="p-2 bd-highlight">
                    <Link to={"/"} className={"text-decoration-none"}>
                        <div className={`card ${this.state.isRecruiter ? "custom-recruiter-full-sm-card" : "custom-full-sm-card"} shadow-sm p-3 mb-2 rounded-md`}>
                            <div className={"card-body text-white"}>
                                <img src={Plus} className={"icon"} alt={"icon"}/>
                                <p className={"normal-font pt-2 font-weight-medium"}>Add Account</p>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        );
    }
}

export default AccountCard;
