import React, {Component} from 'react'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Google from '../../images/icons_google.svg'
import { loginUser, getUser } from '../../actions'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { SignInModal, AuthCard, Landing, CustomInput } from '../../components'
import { Validator, RULES_LOGIN } from '../../rules'
import { alertErrorValidation, captionErrorValidation } from '../../utils'
class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modalShow: false,
            form: {},
            validation: {},
            error: false,
            loading: false,
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleFormValidation = this.handleFormValidation.bind(this)
    }

    handleInputChange = (name, value) =>  {
        this.setState(prevState => ({
            form : {
                ...prevState.form,
                [name]: value
            }
          }))
    }

    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            this.props.history.push('/home')
            // this.props.history.push('/profile')
        }
    }

    componentDidUpdate = async (prevProps) => {
        if (prevProps.error !== this.props.error && this.props.error) {
            alertErrorValidation(this.props.error)
            const check = captionErrorValidation(this.props.error, RULES_LOGIN)
            this.setState({loading: false, validation: check})
        }
        if (prevProps.auth !== this.props.auth && this.props.auth.isAuthenticated) {
            await this.props.getUser()
        }
        if (prevProps.thisUser !== this.props.thisUser && this.props.thisUser) {
            this.setState({loading: false}, () => {
                if(this.props.thisUser.roles[0]==="admin"){
                    this.props.history.push('/admin/company')
                }else if (this.props.thisUser.job_role.length === 0) {
                    this.props.history.push('/category')
                } else if (this.props.thisUser.skill_interest.length === 0) {
                    this.props.history.push('/skill')
                } else if (
                    this.props.thisUser.educational_background.length === 0 ||  
                    this.props.thisUser.imageUrl === null ||
                    this.props.thisUser.province === null ||
                    this.props.thisUser.city === null ||
                    this.props.thisUser.marital_status === null
                ) {
                    this.props.history.push('/profile/edit')
                } else {
                    this.props.history.push('/home')
                    // this.props.history.push('/profile')
                }
            })
        }
    }

    handleFormValidation = async (event) => {

        event.preventDefault()
        let rules = RULES_LOGIN
        
        let validation = new Validator(this.state.form, rules);
        let isValid = validation.passes();
        let errors = validation.errors.all()
        this.setState({validation: errors})
        if (isValid) {
            this.setState({loading: true})
            await this.props.loginUser(this.state.form)
        } else {
          Object.keys(errors).forEach(function(key) {
            errors[key].forEach((item) => {
              toast.error(item, {
                position: toast.POSITION.TOP_CENTER
              })
            })
          });
        }
        
	}

    render() {
        return (
            <Landing
                isLogoHidden={true}
                isBgPersonHidden={true}
                >
                    <div className={"d-flex align-items-start flex-column"}>
                        <div className="mb-auto p-2 full-width">
                            <div className="row justify-content-center">
                                <div className="col-lg-5 col-sm-8">
                                    <div className="ml-auto">
                                        <AuthCard
                                            title={"Login"}
                                            footer={
                                                <div className="d-flex">
                                                    <div className="p-2">
                                                    {/* <span className={"normal-font text-left"}>
                                                        <Link to={"/"} className={"hl-font-link font-weight-bold"}>Login here</Link> for company or business
                                                    </span> */}
                                                    </div>
                                                    <div className="ml-auto p-2">
                                                    <span className={"normal-font text-right"}>
                                                        <Link to={"/forgot-password"} className={"hl-font-link font-weight-bold"}>Forgot Password?</Link>
                                                    </span>
                                                    </div>
                                                </div>
                                            }
                                        >
                                            <form onSubmit={this.handleFormValidation}>
                                                <div className="form-group">
                                                    <CustomInput
                                                        validation={this.state.validation['email']}
                                                        placeholder="Email or phone number"
                                                        onChange={this.handleInputChange}
                                                        name="email"
                                                        id="email"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <CustomInput
                                                        validation={this.state.validation['password']}
                                                        onChange={this.handleInputChange}
                                                        placeholder="Password"
                                                        type="password"
                                                        name="password"
                                                        id="password"
                                                    />
                                                </div>

                                                <button type="submit" disabled={this.state.loading} className="normal-font p-4 form-control btn btn-primary rounded-pill mb-3">
                                                    
                                                    {
                                                        !this.state.loading ? (<div>Login</div>) : 
                                                        (
                                                            <div>
                                                                <span className="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>
                                                                Loading...
                                                            </div>
                                                        )
                                                    }
                                                </button>

                                                <p className={"text-center full-width normal-font"}>
                                                    Not have an account? <Link to={"/"} className={"hl-font-link font-weight-bold"}>Register Here</Link>
                                                </p>
                                                {/* <div className={"separator pb-3"}>or</div> */}
                                                {/* <button className={"form-control py-3 normal-font text-center btn btn-light"}><img alt={"icon"} className={"pr-2 btn-icon"} src={Google}/>Continue with Google</button> */}
                                            </form>
                                        </AuthCard>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SignInModal
                        show={this.state.modalShow}
                        desc={"You cannot apply to this job vacancy before completing your CV"}
                        titlebutton={["Complete a CV Now"]}
                        onHide={() => this.setState({modalShow: false})}
                    />
                </Landing>
        );
    }
}

Login.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    thisUser: state.thisUser,
    error: state.error,
    invalid: state.invalid,
});
  
export default withRouter(connect(mapStateToProps, { loginUser, getUser }) (Login));
