import React, { useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";

const CustomDate = (props) => {
  let isValid = true
  let errorMessage = ''
  const [startDate, setStartDate] = useState(new Date());

  const handleInputChange = (date) => {
    setStartDate(date)
    if (props.name !== undefined) {
      props.onChange(props.name, moment(date).format('YYYY-MM-DD'))
    }
  }

  if (props.validation) {
    isValid = !((props.validation ?? []).length > 0)
    errorMessage = (props.validation ?? [''])[0]
  }

  return (
    <div className='w-100'>
      <DatePicker
        className={`${props.className} form-control rounded-pill custom-textfield`}
        selected={startDate}
        onChange={(date) => handleInputChange(date)}
        value={props.value}
        required={props.required}
        id={props.id}
        name={props.name}
        dateFormat="yyyy-mm-dd"
        showMonthDropdown
        showYearDropdown
        maxDate={props.maxDate}
        showDisabledMonthNavigation={props.showDisabledMonthNavigation}
        yearDropdownItemNumber={71}
        scrollableYearDropdown
        disabled={props.disabled}
      />
      {(props.helperText || !isValid) && (<span className={`${isValid ? 'text-muted' : 'text-danger'} normal-font`}>{isValid ? props.helperText : errorMessage}</span>)}
    </div>
  );
};

export default CustomDate;