import { Link, withRouter } from "react-router-dom";
import React, { Component } from "react";
import { connect } from "react-redux";
import HomeLanding from "../../components/layouts/HomeLanding";
import { ReactComponent as IcStar } from "../../images/star.svg";
import { ReactComponent as IcClock } from "../../images/clock.svg";
import { ReactComponent as IcCase } from "../../images/suitcase.svg";
import { ReactComponent as IcCompany } from "../../images/company.svg";
import { ReactComponent as IcShare } from "../../images/share.svg";
import { ReactComponent as IcRock } from "../../images/rock.svg";
import { ReactComponent as IcReply } from "../../images/reply.svg";
import { ReactComponent as IcOption } from "../../images/option.svg";
import { ReactComponent as IcPlus } from "../../images/plus-rect.svg";
import User from "../../images/company-2.svg";
import DefaultUser from "../../images/default-picture.svg";

import {
  getJobAplicantLite,
  getJobAplicantDetail,
  jobStatus,
  getJobDetails,
} from "../../actions/jobs";

class JobDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailData: {},
    };
  }

  componentDidMount = async () => {
    const res1 = await this.props.getJobDetails(this.props.match.params.id);
    this.setState({ detailData: res1 });
    console.log(res1);
  };

  async OnToggle(uuid) {
    await this.props.jobStatus(uuid);
    const res1 = await this.props.getJobDetails(this.props.match.params.id);
    this.setState({ detailData: res1 });
  }

  formatMoney = amount => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  render() {
    const { detailData } = this.state;
    return (
      <HomeLanding isRecruiter={true}>
        <div className={"container py-3"}>
          <div className="d-flex align-items-center pb-4">
            <div className="header-font font-weight-medium">Job Detail</div>
            <div className="ml-auto">
              <Link
                to="/jobs/create"
                className="w-150px normal-font py-2 rounded-pill btn btn-recruiter-primary"
              >
                <IcPlus className={"hc-ic mr-2 svg-white"} /> Post a Job
              </Link>
            </div>
          </div>
          <div className="card bg-white rounded-lg normal-font">
            <div className="card-header bg-transparent">
              <span>
                <div className={"d-flex align-items-center flex-wrap"}>
                  <div className={"p-2"}>
                    <img className="avatar-lg rounded-circle" src={ detailData?.company_detail?.logo_link ? detailData.company_detail.logo_link : "https://hacklab.rocks/bctn/wp-content/uploads/2021/09/QxdSiwVuTEwjPDMVYr8Tl5OIeiy6RffYFsDF8Jgr-removebg-preview.png"} alt="icon" />
                  </div>
                  <div className={"p-2 flex-grow"}>
                    <div className="d-flex align-content-between flex-wrap">
                      <div className={"full-width"}>
                        <span className={"medium-font font-weight-medium"}>
                          {detailData.title}
                        </span>
                        { detailData.sallary_min && detailData.sallary_max ? <p className={"normal-font"}>
                          Estimated Sallary{" "} {' IDR '}
                          <span className={"color-salary font-weight-medium"}>
                            {this.formatMoney(detailData.sallary_min)} - {this.formatMoney(detailData.sallary_max)}
                          </span>
                        </p> : null }
                        <p className={"normal-font text-muted"}>
                          {detailData?.company_detail?.company_name} -{" "}
                          {detailData?.city_detail?.name}, ID
                        </p>
                      </div>
                      <div className={"full-width"}>
                        <div className={"d-flex"}>
                          <div className={""}>
                            
                          <select
                              onChange={() => this.OnToggle(detailData.uuid)}
                              style={{
                                backgroundColor: detailData.status
                                  ? "#29C973"
                                  : "#E04F5F",
                                color: "#fff",
                                maxHeight: 38
                              }}
                              className="m-2 normal-font w-120px rounded-pill custom-select-dropdown mt-3"
                            >
                              <option
                                value={1}
                                selected={detailData.status === 1}
                              >
                                Open
                              </option>
                              <option
                                value={0}
                                selected={detailData.status === 0}
                              >
                                Close
                              </option>
                            </select>
                          </div>
                          <div className={""}>
                            <Link
                              to={`/jobs/edit/${detailData.uuid}`}
                              className="w-120px normal-font py-2 px-3 btn btn-primary rounded-pill mr-2 mt-3"
                            >
                              Edit
                            </Link>
                          </div>
                          <div className={""}>
                            {/* <Link
                              to={"/"}
                              className="w-120px d-inline-block float-right normal-font py-2 px-3 btn btn-recommendation-reverse rounded-pill mt-3"
                            >
                              <IcShare className={"hc-ic mr-2"} /> Share
                            </Link> */}
                          </div>
                          <div className={"ml-auto"}>
                          <Link
                              to={`/recruiter/jobs/applicant/${this.props.match.params.id}`}
                              className=" normal-font py-2 px-3 btn btn-primary rounded-pill mt-3"
                            >
                              See all applicant ({detailData.people_applied})
                            </Link>
                            
                          </div>
                        </div>
                        {/* <div className="d-flex py-1 text-left normal-font align-items-center justify-content-start">
                          <p className={"normal-font text-muted mb-0"}>
                            Posted 1 weeks ago - 1456 people applied
                          </p>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </span>
            </div>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                {
                  detailData?.company_detail?.ishighlight ?
                  (<span className={"font-weight-medium"}>Company Highlight</span>) : null
                }
                <br />
                <br />
                <span>
                  <div className={"row"}>
                    <div className={"col-md-6 col-xs-12"}>
                      <span>
                        {detailData?.company_detail?.about}
                      </span>
                      <br />
                      <br />
                      Employee : <b>{detailData?.company_detail?.number_of_employee_detail?.range} employees</b>
                    </div>
                    <div className={"col-md-6 col-xs-12"}>
                      <div className="d-flex pb-1 text-left align-items-center justify-content-start">
                        <IcStar className={"hc-small-ic mr-2 svg-black"} />{" "}
                        {detailData?.job_level_detail?.level}
                      </div>
                      <div className="d-flex py-1 text-left align-items-center justify-content-start">
                        <IcClock className={"hc-small-ic mr-2 svg-black"} />{" "}
                        {detailData?.job_type_detail?.type} &nbsp;&nbsp; {detailData.can_work_remotely ? <div style={{ background:'#ffd865', borderRadius: 10, padding: 2, paddingLeft: 6,paddingRight: 6}}>Remote possible</div> : null}
                      </div>
                      <div className="d-flex py-1 text-left align-items-center justify-content-start">
                        <IcCase className={"hc-small-ic mr-2 svg-black"} />{" "}
                        {detailData?.job_role_detail?.job_role}
                      </div>
                    </div>
                  </div>
                </span>
              </li>
              <li className="list-group-item">
                <span className={"font-weight-medium"}>Job Description</span>
                <br />
                <br />
                <span>
                  {detailData.description}
                </span>
                <br />
                <br />
                <span className={"font-weight-medium"}>
                  Key Responsibilities:
                </span>
                <ul className={"list-disc"}>
                      <li>  {detailData.key_responsibilitis}</li>
                </ul>
                <br />
                <span className={"font-weight-medium"}>Requirements:</span>
                <ul className={"list-disc"}>
                      <li>  {detailData.requirements}</li>
                </ul>
              </li>
              <li className="list-group-item rounded-lg pb-4">
                <span className={"font-weight-medium"}>Benefit:</span>
                <ul className={"list-disc"}>
                  
                  <li>  {detailData.benefit}</li>
                </ul>
              </li>
            </ul>
          </div>

          {/* <div className="card bg-white rounded-lg mt-3">
                        <div className="card-body">
                            <div className={"d-flex"}>
                                <div className={"flex-grow-1"}>
                                    <input type="text" className="p-4 form-control rounded-pill custom-textfield" placeholder="Any question? Let’s start discuss with others job seekers or admin"/>
                                </div>
                                <div className={"ml-2"}>
                                    <Link to={"/"} className="normal-font py-4 form-control px-3 btn btn-primary rounded-pill">Comment</Link>
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="p-2 flex-grow-1"><hr/></div>
                                <div className="p-2 normal-font">
                                    <div className="dropdown" >
                                        Sort by :
                                        <select className="form-select select-simple font-weight-medium" aria-label="Default select example">
                                            <option selected>Top</option>
                                            <option value="1">Latest</option>
                                            <option value="2">Oldest</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="card bg-white rounded-lg normal-font">
                            
                                <ul className="list-group list-group-flush card-body p-0 rounded-lg">
                                    <li className="list-group-item bg-primary-reply">
                                        <div className={"d-flex align-items-start"}>
                                            <div className={""}>
                                                <img src={User} className={"avatar-sm rounded-circle"} alt={"icon"}/>
                                            </div>
                                            <div className={"px-2 flex-grow"}>
                                                <div className="d-flex align-content-between flex-wrap">
                                                    <div className={"full-width"}>
                                                        <div className="dropdown-hover dropdown ml-3 float-right">
                                                            <Link className="medium-font font-weight-medium d-flex align-items-center justify-content-center" to="#" id="navbarDropdown" role="button"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <IcOption className={"hc-ic"}/>
                                                            </Link>
                                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                                                                <Link className="dropdown-item" to="#">Report this comment</Link>
                                                                <Link className="dropdown-item" to="#">Report user</Link>
                                                                <div className="dropdown-divider"/>
                                                                <Link className="dropdown-item" to="#">View user profile</Link>
                                                            </div>
                                                        </div>
                                                        <span className={"medium-font font-weight-medium"}>
                                                        Fanisha Arafah 
                                                        </span>
                                                        <br/>
                                                        <span className={"normal-font"}>UI / UX Designer</span>
                                                        <p className={"normal-font text-muted"}>5 hours ago</p>
                                                        <p className={"normal-font"}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                    </div>
                                                    <div className={"full-width"}>
                                                        <div className={"d-flex"}>
                                                            <div className={""}>
                                                                <div className="nav-link pl-0 normal-font font-weight-medium color-navbar d-flex align-items-center justify-content-center">
                                                                    <IcRock className={"mr-1"}/> Rock
                                                                </div>
                                                            </div>
                                                            <div className={""}>
                                                                <div className="nav-link normal-font font-weight-medium color-navbar d-flex align-items-center justify-content-center">
                                                                    <IcReply className={"mr-1"}/> Reply
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item bg-secondary-reply">
                                        <div className={"d-flex align-items-start"}>
                                            <div className={"reply-margin"}>
                                                <img src={DefaultUser} className={"avatar-xs rounded-circle"} alt={"icon"}/>
                                            </div>
                                            <div className={"px-2 flex-grow"}>
                                                <div className="d-flex align-content-between flex-wrap">
                                                    <div className={"full-width"}>
                                                        <div className="dropdown-hover dropdown ml-3 float-right">
                                                            <Link className="medium-font font-weight-medium d-flex align-items-center justify-content-center" to="#" id="navbarDropdown" role="button"
                                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <IcOption className={"hc-ic"}/>
                                                            </Link>
                                                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                                                                <Link className="dropdown-item" to="#">Report this comment</Link>
                                                                <Link className="dropdown-item" to="#">Report user</Link>
                                                                <div className="dropdown-divider"/>
                                                                <Link className="dropdown-item" to="#">View user profile</Link>
                                                            </div>
                                                        </div>
                                                        <span className={"medium-font font-weight-medium"}>
                                                        Mr. Rockers
                                                        </span>
                                                        <br/>
                                                        <span className={"normal-font"}>UI / UX Designer</span>
                                                        <p className={"normal-font text-muted"}>5 hours ago</p>
                                                        <p className={"normal-font"}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                    </div>
                                                    <div className={"full-width mb-2"}>
                                                        <div className={"d-flex"}>
                                                            <div className={""}>
                                                                <div className="nav-link pl-0 normal-font font-weight-medium color-navbar d-flex align-items-center justify-content-center">
                                                                    <IcRock className={"mr-1"}/> Rock
                                                                </div>
                                                            </div>
                                                            <div className={""}>
                                                                <div className="nav-link normal-font font-weight-medium color-navbar d-flex align-items-center justify-content-center">
                                                                    <IcReply className={"mr-1"}/> Reply
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={"d-flex full-width"}>
                                                        <div className={"flex-grow-1"}>
                                                            <input type="text" className="p-4 form-control rounded-pill custom-textfield-white" placeholder="Write a comment"/>
                                                        </div>
                                                        <div className={"ml-2"}>
                                                            <Link to={"/"} className="normal-font py-4 form-control px-5 btn btn-primary rounded-pill">Reply</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> */}
        </div>
      </HomeLanding>
    );
  }
}

const mapStateToProps = (state) => ({
  thisUser: state.thisUser,
  auth: state.auth,
  error: state.error,
  invalid: state.invalid,
  response: state.response,
});
const mapStateToDispatch = {
  getJobAplicantLite,
  getJobAplicantDetail,
  jobStatus,
  getJobDetails,
};

export default withRouter(
  connect(mapStateToProps, mapStateToDispatch)(JobDetails)
);
