import { GET_USER, SET_AUTH, SET_LOGOUT, GET_RESPONSE } from './types'
import { SET_PROFILE_PICTURE, UPDATE_PROFILE, REGISTER_USER } from './responseTypes'
import { TALENT } from '../constants'
import { getFormattedUser } from '../utils'
import jwt_decode from 'jwt-decode'

import { httpGet, httpPost } from './utils'

export const loginUser = (user = {}) => async dispatch => {
  const res = await httpPost(dispatch, '/v1/auth/login', user)
  if (!res) return null
  const { access_token } = res.data
  localStorage.setItem('jwtToken', access_token)
  const decoded = jwt_decode(access_token)

  dispatch({
    type: SET_AUTH,
    payload: decoded
  })
}

export const userAction = (user = {}) => async dispatch => {
  const formatted = getFormattedUser(user)

  return dispatch({
    type: GET_USER,
    payload: formatted
  })
}

export const logoutUser = () => async dispatch => {
  let token = localStorage.jwtToken
  httpPost(dispatch, '/v1/auth/logout', {}, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  localStorage.removeItem('jwtToken')
  localStorage.removeItem('user')
  localStorage.removeItem('company')

  dispatch({
    type: SET_LOGOUT,
    payload: null
  })
}

export const registerUser = (user = {}) => async dispatch => {
  user.password_confirmation = user.password
  user.state = TALENT
  const res = await httpPost(dispatch, '/v1/auth/register', user)
  if (!res) return null

  dispatch({
    type: GET_RESPONSE,
    payload: {
      type: REGISTER_USER,
      payload: res
    }
  })
}

export const getUser = () => async dispatch => {
  let token = localStorage.jwtToken
  const user = await httpGet(dispatch, '/v1/auth/user', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (!user) return null
  const formatted = getFormattedUser(user)
  localStorage.setItem('user', JSON.stringify(formatted))
  dispatch({
    type: GET_USER,
    payload: formatted
  })
}

export const uploadProfilePicture = (body = {}) => async dispatch => {
  let token = localStorage.jwtToken
  const res = await httpPost(dispatch, '/v1/user/upload-images', body, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (!res) return null

  dispatch({
    type: GET_RESPONSE,
    payload: {
      type: SET_PROFILE_PICTURE,
      data: res
    }
  })
}

export const uploadProfileCV = (data) => async dispatch => {
  let token = localStorage.jwtToken
  const res = await httpPost(dispatch, '/v1/user/upload-resume', data, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data"
    }
  })
  if (!res) return null
  return res;
}

export const updateProfile = (body = {}) => async dispatch => {
  let token = localStorage.jwtToken
  const res = await httpPost(dispatch, '/v1/user/update-profile', body, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  if (!res) return null

  dispatch({
    type: GET_RESPONSE,
    payload: {
      type: UPDATE_PROFILE,
      data: res
    }
  })
}
