import React, {Component} from 'react';
import { Link } from "react-router-dom";
import Landing from "../../components/layouts/Landing";
import AuthCard from "../../components/cards/AuthCard";

class RecruiterLogin extends Component {
    render() {
        return (
            <Landing
                isRecruiter={true}
                isLogoHidden={true}
                isBgPersonHidden={true}
                >
                    <div className={"d-flex align-items-start flex-column"}>
                        <div className="mb-auto p-2 full-width">
                            <div className="row justify-content-center">
                                <div className="col-lg-5 col-sm-8">
                                    <div className="ml-auto">
                                        <AuthCard
                                            title={"Login"}
                                            footer={
                                                <div className="d-flex justify-content-center">
                                                    <div className="p-2">
                                                        <span className={"normal-font text-left"}>
                                                            <Link to={"/"} className={"hl-font-link font-weight-bold"}>Login here</Link> for talent
                                                        </span>
                                                    </div>
                                                </div>
                                            }
                                        >
                                            <form>
                                                <div className="form-group">
                                                    <input type="text" className="p-4 form-control rounded-pill custom-textfield" placeholder="Email or phone number"/>
                                                </div>
                                                <div className="form-group">
                                                    <input type="password" className="p-4 form-control rounded-pill custom-textfield" placeholder="Password"/>
                                                </div>

                                                <button type="submit" className="normal-font p-4 form-control btn btn-recruiter-primary rounded-pill mb-3">Login</button>

                                                <p className={"text-center full-width normal-font"}>
                                                    Not have an account? <Link to={"/"} className={"hl-font-link font-weight-bold"}>Register Here</Link>
                                                </p>
                                            </form>
                                        </AuthCard>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Landing>
        );
    }
}

export default RecruiterLogin
