import React from 'react'

const CustomRadio = (props) => {
  let isValid = true
  let errorMessage = ''

  const handleInputChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    if (name !== undefined) {
      props.onChange(name, value)
    }
    // props.onChange(event)
  }

  if (props.validation) {
    isValid = !((props.validation ?? []).length > 0)
    errorMessage = (props.validation ?? [''])[0]
  }

  // console.log('Not re-rendering? ', this.props.validation)

  return (
    <>
      <div className={`custom-check ${props.className} align-items-center d-flex justify-content-center`}>
        <input
          type='radio'
          value={props.value ?? '1'}
          required={props.required}
          id={props.id}
          name={props.name}
          onChange={handleInputChange}
          disabled={props.disabled}
        />
        <span className='checkmark' />
      </div>
      {(props.helperText || !isValid) && (<span className={`${isValid ? 'text-muted' : 'text-danger'} normal-font`}>{isValid ? props.helperText : errorMessage}</span>)}
    </>

  )
}

CustomRadio.propTypes = {}
export default (CustomRadio)
