import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { resetPassword } from '../../../actions'
import { REQ_PASSWORD } from '../../../actions/responseTypes'
import { AuthCard, Landing, CustomInput } from "../../../components";
import { Validator, RULES_RESET_PASSWORD } from '../../../rules'
import { alertErrorValidation, captionErrorValidation } from '../../../utils';

const resetDefault = {
    password: '',
    password_confirmation: ''
}
class ResetPassword extends Component {
    constructor(props) {
        super(props)
        const { key } = props.match.params;
        
        this.state = {
            modalShow: true,
            form: {
                password: '',
                password_confirmation: '',
                encrypted_key: key
            },
            error: false,
            key: key,
            loading: false,
            validation: {}
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleFormValidation = this.handleFormValidation.bind(this)
    }

    componentDidUpdate = async (prevProps) => {
        if (prevProps.error !== this.props.error && this.props.error) {
            const check = captionErrorValidation(this.props.error, RULES_RESET_PASSWORD)
            this.setState({loading: false, validation: check})
        }
        if (this.props.response && prevProps.response !== this.props.response) {
          switch (this.props.response.type) {
            case REQ_PASSWORD:
              this.setState({loading: false, form: resetDefault})
              toast.success(() => <div>Password berhasil diubah!</div>, {
                position: toast.POSITION.TOP_CENTER
              })
              this.props.history.push('/reset-password-success')
              break;
            default:
              break;
          }
        }
      }

    handleInputChange = (name, value) =>  {
        this.setState(prevState => ({
          form : {
              ...prevState.form,
              [name]: value
          }
        }))
      }

    handleFormValidation = async (event) => {

        event.preventDefault()
        let rules = RULES_RESET_PASSWORD
        
        let validation = new Validator(this.state.form, rules);
        let isValid = validation.passes();
        let errors = validation.errors.all()
        this.setState({validation: errors})
        if (isValid) {
            this.setState({loading: true})
            await this.props.resetPassword(this.state.form)
        } else {
            Object.keys(errors).forEach(function(key) {
            errors[key].forEach((item) => {
                toast.error(item, {
                position: toast.POSITION.TOP_CENTER
                })
            })
            });
        }
	}

    render() {
        return (
            <Landing
                isLogoHidden={true}
                isBgPersonHidden={true}
                >
                    <div className={"d-flex align-items-start flex-column"}>
                        <div className="mb-auto p-2 full-width">
                            <div className="row justify-content-center">
                                <div className="col-lg-5 col-sm-8">
                                    <div className="ml-auto">
                                        <AuthCard
                                            title={"Reset Password"}
                                        >
                                            <form onSubmit={this.handleFormValidation} className={"text-left"}>
                                                <p>
                                                    Please enter new password  and confirm  your password
                                                    to finish this setup
                                                </p>
                                                <div className="form-group">
                                                    <CustomInput
                                                        validation={this.state.validation['password']}
                                                        type="password"
                                                        value={this.state.form.password}
                                                        placeholder="New Password"
                                                        onChange={this.handleInputChange}
                                                        name="password"
                                                        id="password"
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <CustomInput
                                                        validation={this.state.validation['password_confirmation']}
                                                        type="password"
                                                        value={this.state.form.password_confirmation}
                                                        placeholder="Confirm new password"
                                                        onChange={this.handleInputChange}
                                                        name="password_confirmation"
                                                        id="password_confirmation"
                                                    />
                                                </div>
                                                <button type="submit" className={"form-control rounded-pill p-4 btn btn-primary font-weight-medium"}>
                                                {
                                                    !this.state.loading ? (<div>Done</div>) : 
                                                    (
                                                        <div>
                                                            <span className="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>
                                                            Loading...
                                                        </div>
                                                    )
                                                }
                                                </button>
                                            </form>
                                        </AuthCard>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Landing>
        );
    }
}

ResetPassword.propTypes = {
    auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    thisUser: state.thisUser,
    error: state.error,
    invalid: state.invalid,
    response: state.response
})

export default withRouter(connect(mapStateToProps, { resetPassword })(ResetPassword));
