import RegisterPage from '../pages/auth/Register'
import LoginPage from '../pages/auth/Login'

import RecruiterLoginPage from '../recruiter-pages/auth/RecruiterLogin'

import VerifyCompanySuccessPage from '../recruiter-pages/auth/verification/VerifyCompanySuccess'

import VerificationPage from '../pages/auth/verification/VerifyAccount'
import VerificationSuccessPage from '../pages/auth/verification/VerifyAccountSuccess'

import ForgotPasswordPage from '../pages/auth/forgot-password/ForgotPassword'
import ResetPasswordPage from '../pages/auth/forgot-password/ResetPassword'
import ResetPasswordSuccessPage from '../pages/auth/forgot-password/ResetPasswordSuccess'
import Redirect from '../pages/Redireact'



export const publicRoutes = [
  // AUTHENTICATION
  {
    exact: true,
    path: '/',
    component: Redirect
  },
  {
    exact: true,
    path: '/register',
    component: RegisterPage
  },
  {
    exact: true,
    path: '/login',
    component: LoginPage
  },
  {
    exact: true,
    path: '/recruiter/login',
    component: RecruiterLoginPage
  },
  // VERIFICATION
  {
    exact: true,
    path: '/verify',
    component: VerificationPage
  },
  {
    exact: true,
    path: '/verify-success',
    component: VerificationSuccessPage
  },
  {
    exact: true,
    path: '/verify-company-success',
    component: VerifyCompanySuccessPage
  },
  // FORGOT PASSWORD
  {
    exact: true,
    path: '/forgot-password',
    component: ForgotPasswordPage
  },
  {
    exact: true,
    path: '/reset-password/:key',
    component: ResetPasswordPage
  },
  {
    exact: true,
    path: '/reset-password-success',
    component: ResetPasswordSuccessPage
  },
]
