import React, { useEffect } from "react";

function Redirect() {

  useEffect(() => {
    window.location.href = "//hacklab.rocks/bctn";
  }, []);

  return <div/>
}

export default Redirect;