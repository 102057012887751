import React from "react";
import ImageUploading from "react-images-uploading";
import { Modal } from "react-bootstrap";
import { ReactComponent as IcCamera } from "../../images/camera.svg";
import axios from "axios";
import { setImageLoading, getDetailsCompany } from "../../actions/companies";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import imageToBase64 from 'image-to-base64/browser'

export default function UploadLogo({ onHide, isLogoModal, updateLogo }) {
  const [images, setImages] = React.useState([]);
  const dispatch = useDispatch();

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
  };

  const onCancel = (e) => {
    onHide();
  };

  const handleUploadPhoto = async () => {
    try {
      dispatch(setImageLoading(true));
      let token = localStorage.jwtToken;
      const company = JSON.parse(localStorage.getItem("company"));
      const imgItem =  await imageToBase64(images[0].data_url);
      const data = await axios.post(`${process.env.REACT_APP_BASE_API}/v1/my-company-logo/${company.uuid}`,
        { image: imgItem },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(getDetailsCompany(company.uuid));
      dispatch(setImageLoading(false));
      onHide();
    } catch (error) {
      toast.error(error.message);
      onHide();
    }
  };
  return (
    <Modal show={isLogoModal}>
      <Modal.Body>
        <ImageUploading
          value={images}
          onChange={onChange}
          dataURLKey="data_url"
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            // write your building UI
            <div className="upload__image-wrapper">
              {imageList.length <= 0 && (
                <button
                  style={isDragging ? { color: "red" } : null}
                  onClick={onImageUpload}
                  {...dragProps}
                  className="btn-dropzone"
                >
                  <IcCamera
                    className={"mb-5 hc-ic-lg camera-ic-lg svg-sky-blue"}
                  />
                  <br />
                  <span className="mt-3 upload__dropzone-text font-weight-bold">
                    Drop your image here or{" "}
                    <span style={{ color: "#62C3D0" }}>browse</span>
                  </span>
                  <br />
                  <span className="upload__support-file">
                    Support multiple image JPG, JPEG, PNG (Max 2 MB)
                  </span>
                </button>
              )}
              {imageList.map((image, index) => (
                <>
                  <div key={index} className="image-item mt-3">
                    <img
                      className="image-item-preview rounded-lg ml-3"
                      src={
                        image.hasOwnProperty("image_link")
                          ? image.image_link
                          : image.data_url
                      }
                      // src={image.data_url}

                      alt=""
                      width="100"
                    />
                    <span
                      onClick={(e) => {
                        onImageRemove(index);
                      }}
                      className="close"
                    ></span>
                  </div>
                </>
              ))}
            </div>
          )}
        </ImageUploading>
        <div className="row col-md-12 pb-3 mt-4 mx-auto justify-content-center">
          <div className={"col-6"}>
            <button
              onClick={onCancel}
              className="normal-font form-control btn btn-cancel-reverse rounded-pill"
            >
              Cancel
            </button>
          </div>
          <div className={"col-6"}>
            <button
              onClick={handleUploadPhoto}
              className="normal-font form-control btn btn-upload-image rounded-pill"
            >
              Upload Now
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
