import React from 'react'

const CustomCheckbox = (props) => {
  let isValid = true
  let errorMessage = ''

  const handleInputChange = (event) => {
    const name = event.target.name
    const checked = event.target.checked
    const value = event.target.value
    if (name !== undefined) {
      props.onChange(name, checked ? value : props.emptyValue)
    }
    // props.onChange(event)
  }

  if (props.validation) {
    isValid = !((props.validation ?? []).length > 0)
    errorMessage = (props.validation ?? [''])[0]
  }

  // console.log('Not re-rendering? ', this.props.validation)

  return (

    <div className={`${props.widthFit ? '' : 'w-100'}`}>
      <div onClick={() => props.onClick && props.onClick()} className={`${props.className} custom-control custom-checkbox d-${props.display ?? 'flex'} z-first`}>
        <input
          type={'checkbox'}
          className={`custom-control-input`}
          placeholder={props.placeholder}
          value={props.value}
          checked={props.checked}
          required={props.required}
          id={props.id}
          name={props.name}
          onChange={handleInputChange}
          disabled={props.disabled}
        />
        <label className="custom-control-label normal-font font-weight-medium" htmlFor={props.id}>
          {props.checkboxLabel}
        </label>
      </div>
      {(props.helperText || !isValid) && (<span className={`${isValid ? 'text-muted' : 'text-danger'} normal-font`}>{isValid ? props.helperText : errorMessage}</span>)}
    </div>

  )
}

CustomCheckbox.propTypes = {}
export default (CustomCheckbox)
