import { Link, withRouter } from 'react-router-dom'
import React, { Component } from 'react'
import HomeLanding from '../../components/layouts/HomeLanding'

import { ReactComponent as IcPlus } from '../../images/plus-rect.svg'
import { ReactComponent as IcWarning } from '../../images/warning_icon.svg'
import { ReactComponent as IcChevronRight } from '../../images/chevron_right.svg'
import { toast } from "react-toastify";

import User2 from '../../images/user-3.svg'
import InfiniteScroll from 'react-infinite-scroll-component'

import PropTypes from 'prop-types'
import Avatar from '../../images/avatar-talent.png'

import { getJobsPosting, jobStatus } from '../../actions'
import { companyDashboard } from '../../actions/companies'
import { GET_LIST_JOBS } from '../../actions/responseTypes'
import { connect } from 'react-redux'
import { alertErrorValidation } from '../../utils'
import moment from 'moment'

class JobList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [],
      page: 1,
      hasMore: false,
      applicants: [],
      detail: {
        company_information: {
          isshown: 1
        }
      }
    }
    this.fetchMoreData = this.fetchMoreData.bind(this)
  }

  componentDidMount = async () => {
    await this.props.companyDashboard();
    const res = await this.props.response;
    if (res.data) {
      this.setState({
        applicants: res?.data?.new_applicant,
        detail: res?.data,
      })
    }
    this.props.getJobsPosting()
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.error !== this.props.error && this.props.error) {
      alertErrorValidation(this.props.error)
      this.setState({ loading: false })
    }
    if (this.props.response && prevProps.response !== this.props.response) {
      switch (this.props.response.type) {
        case GET_LIST_JOBS:
          this.setState(prevState => ({
            hasMore: this.state.page < this.props.response.data.last_page,
            items: [
              // ...prevState.items,  
              ...this.props.response.data.data
            ]
          }))
          break;
        default:
          break;
      }
    }
  }

  fetchMoreData() {
    const page = this.state.page + 1
    this.props.getJobsPosting(page)
    this.setState({ page: page })
  };

  async OnToggle(uuid) {
    await this.props.jobStatus(uuid);
    toast.success('Data successfully changed!', {
      position: toast.POSITION.TOP_CENTER
    });
    await this.props.companyDashboard();
    const res = await this.props.response;
    if (res.data) {
      this.setState({
        applicants: res?.data?.new_applicant
      })
    }
    this.props.getJobsPosting()
    const newData = this.state.items.map((res) => {
      if (uuid === res.uuid) {
        res.status = !res.status;
      }
      return res;
    });
    this.setState({ items: newData });
  }

  render() {
    console.log(this.state?.applicants);
    return (
      <HomeLanding isRecruiter>
        <div className='container py-3'>

          <div className='row'>
            <div className='col-md-9 col-xs-12'>
              {!this.state.detail?.company_information?.isshown ?
                <div class="alert alert-warning" role="alert">
                  <a href="#" class="alert-link"><IcWarning />  For now your job vacancies and company page will not be seen by talent, please contact us so that your company can immediately get the best talent from hacklab.</a> <a href="http://t.me/hacklabrocksid" className="alert-link-active">Click this link to contact us</a>
                </div> : null}
              <div className='d-flex align-items-center pb-4'>
                <div className='header-font font-weight-medium'>
                  Job Posting
                </div>
                <div className='ml-auto'>
                  <Link to='/jobs/create' className='w-150px normal-font py-2 rounded-pill font-weight-medium btn btn-post-job'>
                    <IcPlus className='hc-ic mr-2 svg-white' /> Post a Job
                  </Link>
                </div>
              </div>
              {/*              
              <div className='mb-3 container btn-group-toggle row my-3' data-toggle='buttons'>
                <label className='col-3 py-2 m-2 btn btn-recommendation-reverse rounded-pill active'>
                  <input type='radio' name='options' id='option1' autocomplete='off' checked /> All Jobs (3)
                </label>
                <label className='col-3 py-2 m-2 btn btn-recommendation-reverse rounded-pill'>
                  <input type='radio' name='options' id='option2' autocomplete='off' /> Open Jobs (2)
                </label>
                <label className='col-3 py-2 m-2 btn btn-recommendation-reverse rounded-pill'>
                  <input type='radio' name='options' id='option3' autocomplete='off' /> Closed Jobs (1)
                </label>
              </div>
              <div className='card bg-white rounded-lg normal-font mb-3'>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-lg-4 col-xs-12'>
                      <input type='text' className='p-4 m-2 form-control rounded-pill custom-textfield' placeholder='Search by job title' />
                    </div>
                    <div className='col-lg-4 col-xs-12'>
                      <input type='text' className='p-4 m-2 form-control rounded-pill custom-textfield' placeholder='Search by city or province' />
                    </div>
                    <div className='col-lg-4 col-xs-12'>
                      <div className='dropdown m-2 custom-select-bw'>
                        <b>Sort by :</b>
                        <select className='cursor-pointer form-select select-simple' aria-label='Default select example'>
                          <option selected> Posting date</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* INFINITE SCROLL */}
              <InfiniteScroll
                dataLength={this.state.items.length}
                next={this.fetchMoreData}
                hasMore={this.state.hasMore}
                loader={
                  <div className='d-flex align-item-center pb-4 justify-content-center'>
                    <span className='medium-font text-muted'>Loading...</span>
                  </div>
                }
              >
                {this.state.items.map((i, index) => (
                  <div key={`jobs-${i}`} className='card bg-white border-none text-left pb-0 mb-3 rounded-lg'>
                    <div className='card-body'>
                      <div className='row'>
                        <Link to={`/recruiter/jobs/${i.uuid}`} className='col-md-5 col-sm-12'>
                          <div>
                            <div style={{ color: 'black' }} className='medium-font'>
                              <b>{i.title}</b>
                            </div>
                            <p className='normal-font'>
                              <span className='d-block'>{i.province ?? '-'}</span>
                              <span className='text-muted'>Created : {moment(i.created_at).format('LL')}</span>
                            </p>
                          </div>
                        </Link>
                        <div className='col-md-4 col-sm-12 align-items-center justify-content-center d-flex rounded-sm'>
                          <div className='d-flex w-100 custom-stats-card p-2 rounded-md'>
                            <div className='p-2'>
                              <h5 className='d-block'>{i.new_post}</h5>
                              <span className='font-weight-medium'>New</span>
                            </div>
                            <div className='p-2'>
                              <h5 className='d-block'>{i.rejected}</h5>
                              <span className='font-weight-medium'>Rejected</span>
                            </div>
                            <div className='p-2'>
                              <h5 className='d-block'>{i.hired}</h5>
                              <span className='font-weight-medium'>Hired</span>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-3 col-sm-12 align-items-center justify-content-center d-flex' >
                          <select onChange={() => this.OnToggle(i.uuid)} style={{ backgroundColor: i.status ? '#29C973' : '#E04F5F', color: '#fff' }} className='m-2 normal-font w-100 rounded-pill custom-select-dropdown' >
                            <option value={1} selected={i.status === 1}>Open</option>
                            <option value={0} selected={i.status === 0}>Close</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </InfiniteScroll>
            </div>
            <div className='col-md-3 col-xs-12'>
              {/* <div className='card bg-recommendation border-none text-left pb-0 mb-3 rounded-lg'>
                <div className='card-header white-br-bottom'>
                  <h6 className='pt-2'><b>Top Talent </b></h6>
                </div>
                <div className='card-body normal-font font-weight-bold px-0 pb-4 pt-2'>
                  {
                    [1, 2, 3, 4, 5].map(function (i) {
                      return (
                        <div key={`top-talent-${i}`} className='d-flex item-recommendation'>
                          <div className='p-2 ml-3'>
                            <img src={User2} className='avatar-xs rounded-circle' alt='icon' />
                          </div>
                          <div className='p-2'>
                            <b>Fanisha Arafah</b>
                            <br />
                            <span className='font-weight-normal'>
                              UI/UX Designer
                            </span>
                          </div>
                        </div>
                      )
                    })
                  }
                  <Link className=' p-2 mx-3 text-white  normal-font d-flex align-items-center justify-content-start font-weight-medium'>
                    <div className='flex-grow-1'>
                      See all top talent
                    </div>
                    <div>
                      <IcChevronRight className='hc-ic ml-2 svg-white' />
                    </div>
                  </Link>
                </div>
              </div> */}
              <div className='card custom-sidebar-two border-none text-left pb-0 mb-3 rounded-lg'>
                <div className='card-header card-header-company'>
                  <h6 className='pt-2'>
                    <b>New Applicant </b>
                  </h6>
                </div>
                <div className='card-body pl-0 pr-2'>
                  <div>
                    <ul className='pl-0 normal-font font-weight-bold'>
                      {this.state?.applicants?.map((item, i) => (
                        <li
                          className='pb-3 pt-2 card-item-company pl-3 d-flex'
                          key={`event${i}`}
                        >
                          <img className="avatar-sm rounded-circle" src={item?.profile_picture ? process.env.REACT_APP_BASE_API.replace('http://139.180.184.96:3005/','')+'/storage/user/'+item?.profile_picture : Avatar } />
                          <div className='ml-2 mt-1'>
                            {item?.name}
                            <br />
                            <span className='font-weight-normal mt-0 pt-0'>
                              Applied for {item?.applied_to_job}
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className='normal-font card-body pt-0 pb-1 d-flex justify-content-around font-weight-medium'>
                    <Link
                      to='/recruiter/jobs/progress'
                      className='text-white d-flex justify-content-between p-0 m-0'
                    >
                      <div className="mr-2">See all new applicant</div>
                      <div> <IcChevronRight className='hc-ic svg-white' /></div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HomeLanding>
    )
  }
}

JobList.propTypes = {
  auth: PropTypes.object.isRequired,
  thisUser: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
  console.log(state.response);
  return {
    auth: state.auth,
    thisUser: state.thisUser,
    response: state.response,
    responseDashboard: state.responseDashboard,
    suggestedCompany: state.suggestedCompany,
  }
}

const mapPropsToDispatch = {
  getJobsPosting,
  companyDashboard,
  jobStatus
}

export default withRouter(connect(mapStateToProps, mapPropsToDispatch)(JobList))

