import React from 'react'

export const StatusButton = props => {
  let buttonText, buttonClass

  switch (props.status) {
    case 'Submitted':
      buttonText = 'Submitted'
      buttonClass = 'btn-submitted'
      break
    case 'Proceed Next Step':
      buttonText = 'Proceed Next Step'
      buttonClass = 'btn-proceed'
      break
    case 'Rejected':
      buttonText = 'Rejected'
      buttonClass = 'btn-rejected'
      break
    default:
  }

  return <button className={`btn btn-primary ${buttonClass} rounded-pill`}>{buttonText}</button>
}
