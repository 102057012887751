import React, {Component} from 'react';
import {Modal} from 'react-bootstrap'
import {Link} from "react-router-dom";
import IcSallyRocket from '../../images/saly-rocket.svg'

class SimpleModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title ?? "Data saved successfully!",
            desc: this.props.desc ?? "",
            titleButton: this.props.titlebutton ?? "Okay!",
            hrefButton: this.props.hrefbutton ?? "#",
            background: this.props.background ?? "bg-orange-base"
        }
    }

    render() {
        return (
            <Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            className={"text-dark medium-font"}
            contentClassName={"bg-transparent no-border"}
            centered
            >
                <Modal.Body className={`${this.state.background} rounded-lg text-white`}>
                    <div className="d-flex align-items-center" style={{height:"150px"}}>
                        <div>
                            <img alt="sally" src={IcSallyRocket} className="d-lg-block d-none" style={{transform:"translateY(-20px)"}}/>
                        </div>
                        <div className="p-2 flex-grow-1">
                            <h4 className="font-weight-medium">{this.state.title}</h4>
                            <p className="medium-font font-weight-medium">
                                {this.state.desc}
                            </p>
                            <Link to={`${this.state.hrefButton}`} onClick={this.state.hrefButton === "#" ? this.props.onHide : null} className="btn btn-transparent-white w-150px normal-font font-weight-medium rounded-pill">{this.state.titleButton}</Link>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

export default SimpleModal
