import {Link, withRouter} from "react-router-dom";
import React, {Component} from 'react';
import Countdown, {zeroPad} from "react-countdown";
import HomeLanding from "../../components/layouts/HomeLanding";
import {ReactComponent as IcClock} from "../../images/clock.svg";
import IcCompany from "../../images/company.svg";
import { JobListingModal, JobQuizModal } from "../../components";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { getJobDetails } from "../../actions";
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { APPLY_JOB, GET_JOB_DETAILS } from "../../actions/responseTypes";
import { currency } from "../../utils/stringHelpers";
import { applyJob } from "../../actions";
import { alertErrorValidation } from "../../utils";

class JobsQuiz extends Component {

    constructor(props) {
        super(props)
        const { id } = props.match.params;
        this.state = {
            uuid: id,
            countDownApi: null,
            company: {},
            page: 1,
            quiz: [],
            loading: false,
            form: {},
            option: ['A', 'B', 'C', 'D'],
            time: Date.now() + 60000,
            modalStart: true,
            modalShow: false,
            submit: false,
        }
    }

    setRef = (countdown) => {
        if (countdown) {
            let countdownApi = countdown.getApi();
            this.setState({countDownApi: countdownApi})
        }
    };

    handleStartClick = () => {
        this.state.countDownApi && this.state.countDownApi.start();
        this.setState({modalStart: false})
    };

    onChange = (uuid, val) => {
        this.setState(prevState => ({
            form: {
                ...prevState.form,
                [uuid]: val
            }
        }))
    };

    handleModalCallback = (isModalShow) => {
        this.setState({modalShow: isModalShow})
    }

    handleSubmit = () => {
        const keys = Object.keys(this.state.form)
        if (keys.length > 0) {
            let quiz = {
                quiz: []
            }
            this.setState({loading: true})
            keys.forEach((item) => {
                quiz.quiz.push({
                    uuid: item,
                    answer: this.state.form[item]
                })
            })
            this.props.applyJob(this.state.company.uuid, quiz)
        } else {
            this.setState({modalShow: true})
        }
        this.state.countDownApi.pause()
    };

    componentDidMount = () => {
        this.props.getJobDetails(this.state.uuid)
    }

    componentDidUpdate = async (prevProps, prevState) => {
        if (prevProps.error !== this.props.error && this.props.error) {
            alertErrorValidation(this.props.error)
            this.setState({loading: false})
        }
        if (prevState.submit !== this.state.submit && this.state.submit) {
            this.handleSubmit()
        }
        if (this.props.response && prevProps.response !== this.props.response) {
          switch (this.props.response.type) {
            case GET_JOB_DETAILS:
                let data = this.props.response.data
                this.setState(prevState => ({
                    company: data,
                    quiz: data.quiz,
                    time: Date.now() + (60000 * data.has_quiz.count)
                }))
                break;
            case APPLY_JOB:
                this.setState({loading: false})
                if (this.props.response.data.data.result.result === 'FAIL') {
                    this.setState({modalShow: true})
                }
                if (this.props.response.data.data.result.result === 'PASS') {
                    toast.success(() => <div>Congratulations, you pass this test!<br/>You will be redirected to Home page</div>, {
                        position: toast.POSITION.TOP_CENTER
                    })
                    setTimeout(() => { this.props.history.push('/home') }, 1000)
                }
                break;
            default:
              break;
          }
        }
      }

    render() {
        // Random component
        const Completionist = () => {
            return (<span className="normal-font">Time's Up!</span>)
        }

        // Renderer callback with condition
        const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            return <Completionist />;
        } else {
            // Render a countdown
            return <span>{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
        }
        };

        return (
            <HomeLanding>
                <div className="pt-3">
                    <div className={"full-width text-center my-5"}>
                        <h1 className="header-font font-weight-medium">Skill Test</h1>
                        <span className="medium-font">Before your apply to this job, please answer the following questions</span>
                    </div>
                    <div className="card bg-white rounded-lg normal-font">
                        <div className="card-header bg-transparent">
                            <span>
                                <div className={"d-flex align-items-top"}>
                                    <div className={"p-2"}>
                                        <img alt="company" src={IcCompany} className={"avatar-lg rounded-circle"}/>
                                    </div>
                                    <div className={"p-2 flex-grow-1"}>
                                        <div className="d-flex align-content-between flex-wrap">
                                            <div className={"full-width"}>
                                                <span className={"medium-font font-weight-medium"}>
                                                    {this.state.company.title}
                                                </span>
                                                {this.state.company.sallary_min && this.state.company.sallary_max ? <p className={"normal-font"}>Estimated Sallary {' IDR '}<span className={"color-salary font-weight-medium"}>{currency(this.state.company.sallary_min)} - {currency(this.state.company.sallary_max)}</span></p> : null}
                                                <p className={"normal-font text-muted"}>{this.state.company?.company_detail?.company_name ?? "-"} - Jakarta, ID</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"p-2 bg-orange font-weight-medium"}>
                                            <IcClock className="hc-ic pr-2"/> 
                                            <Countdown
                                                autoStart={false}
                                                ref={this.setRef}
                                                date={this.state.time}
                                                renderer={renderer}
                                                onComplete={this.handleSubmit}
                                                className="normal-font"/>
                                    </div>
                                </div>
                            </span>
                        </div>
                        <div className="card-body">
                            <span className={"font-weight-medium"}>Question {this.state.page}</span>
                            <br/><br/>
                            <span>{this.state.quiz.length > 0 && this.state.quiz[this.state.page - 1].question}</span>
                            <br/><br/>
                            <span className={"font-weight-medium"}>Your Answer</span>
                            <div className="row">
                                {
                                    this.state.quiz.length > 0 && this.state.quiz[this.state.page - 1].option.map((item, index) => {
                                        return (<div key={`${this.state.option[index]}-${this.state.quiz[this.state.page - 1].uuid}`} className={"col-md-6 col-xs-12 mt-2"}>
                                        <div className="form-check">
                                            <input onClick={() => this.onChange(this.state.quiz[this.state.page - 1].uuid, this.state.option[index])} value={this.state.option[index]} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            {item[this.state.option[index]]}
                                            </label>
                                        </div>
                                    </div>)
                                    })
                                }
                            </div>
                        </div>
                        <div className={"card-footer"}>
                            <div className={"row"}>
                                <div className={"col-6 text-left"}>
                                    <span className="normal-font">Question <b>{this.state.page} of {this.state.quiz.length} Questions</b></span>
                                </div>
                                <div className={"col-6"}>
                                <div className={"d-flex float-right"}>
                                    {
                                        this.state.quiz.length > 0 && this.state.page < this.state.quiz.length ? (
                                        <div className={"p-0 px-1"}>
                                            <div onClick={() => this.setState({page: this.state.page + 1})} className="form-control normal-font py-2 px-3 btn btn-recommendation-reverse rounded-pill"> Skip</div>
                                        </div>
                                        ) : (null)
                                    }
                                    {
                                        this.state.quiz.length > 0 && this.state.page < this.state.quiz.length ? (
                                            <div className={"p-0 px-1"}>
                                                <div onClick={() => this.setState({page: this.state.page + 1})} className="form-control normal-font py-2 px-3 btn btn-primary rounded-pill">Next</div>
                                            </div>
                                        ) : (
                                            <div className={"p-0 px-1"}>
                                                <div onClick={() => this.handleSubmit()} className="form-control normal-font py-2 px-3 btn btn-primary rounded-pill">
                                                {
                                                    !this.state.loading ? (<div>Submit</div>) : 
                                                    (
                                                        <div>
                                                            <span className="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true"></span>
                                                            Loading...
                                                        </div>
                                                    )
                                                }
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <CreateJobModal
                    show={this.state.modalShow}
                    background={'bg-orange-base'}
                    titelbutton={"Okay!"}
                    desc={"Congratulations, you are qualified to this role, your apply will be process by recruiter"}
                    onHide={() => this.setState({modalShow: false})}
                /> */}
                <JobQuizModal
                    show={this.state.modalStart}
                    onHide={() => this.setState({ modalStart: false })}
                    onCancel={() => this.props.history.goBack()}
                    onStart={() => this.handleStartClick()}
                />

                <JobListingModal
                    show={this.state.modalShow}
                    title={"Sorry, you are not qualified to apply this positions :("}
                    doublebutton={true}
                    titlebutton={["Get Course", "Close"]}
                    subtitlebutton={""}
                    onHide={() => this.setState({modalShow: false})}
                    modalCallback={this.handleModalCallback}
                />
            </HomeLanding>
        );
    }
}

JobsQuiz.propTypes = {
    auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    thisUser: state.thisUser,
    error: state.error,
    invalid: state.invalid,
    response: state.response
})

export default withRouter(connect(mapStateToProps, { getJobDetails, applyJob })(JobsQuiz));