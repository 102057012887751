import React, { Component } from "react";
import {ReactComponent as IcPencil} from "../../images/pencil.svg";

class EditButton extends Component {

    render() {
        return (
            <div className="m-1 float-right cursor-pointer">
                <div className="medium-font font-weight-medium d-flex align-items-center justify-content-center">
                    <IcPencil className={"hc-ic"}/>
                </div>
            </div>
        );
    }
}

export default EditButton;
