import React, { Component } from 'react'
import Landing from '../../../components/layouts/Landing'
import AuthCard from '../../../components/cards/AuthCard'
import Mail from '../../../images/mail-sent.svg'

class VerifyAccount extends Component {
  render () {
    return (
      <Landing
        isLogoHidden
        isBgPersonHidden
      >
        <div className='d-flex align-items-start flex-column'>
          <div className='mb-auto p-2 full-width'>
            <div className='row justify-content-center'>
              <div className='col-lg-5 col-sm-8'>
                <div className='ml-auto'>
                  <AuthCard>
                    <form className='text-center'>
                      <img src={Mail} alt='mail' />
                      <h5 className='pt-4'><b>Verify Your Email</b></h5>
                      <p>
                        You’ve entered <b>valerie@email.com</b> as the email address
                        Please verify this email by clicking button bellow
                      </p>
                      <button className='form-control rounded-pill p-4 btn btn-primary font-weight-medium'>Verify Your Email</button>
                    </form>
                  </AuthCard>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Landing>
    )
  }
}

export default VerifyAccount
