import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ReactComponent as IcCompany } from '../../images/company.svg'
import { ReactComponent as IcClock } from '../../images/clock-bw.svg'
import { ReactComponent as IcQuestion } from '../../images/question.svg'
import { ReactComponent as IcClose } from '../../images/close.svg'
import { ReactComponent as IcLink } from '../../images/circle-link.svg'
import { ReactComponent as IcWhatsapp } from '../../images/circle-whatsapp.svg'
import { ReactComponent as IcFacebook } from '../../images/circle-facebook.svg'
import { ReactComponent as IcTwitter } from '../../images/circle-twitter.svg'
import { ReactComponent as IcEmail } from '../../images/circle-email.svg'
import { useHistory, useLocation } from 'react-router-dom'

class JobQuizModal extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
      <Modal
        {...this.props}
        size='lg'
        backdrop="static"
        keyboard={false}
        aria-labelledby='contained-modal-title-vcenter'
        className='text-dark medium-font'
        contentClassName='bg-transparent no-border'
        centered
      >
        <Modal.Body className='bg-white rounded-lg'>
          <div className='d-flex align-items-center'>
            <div className='px-4 flex-grow'>
              <div className='d-flex align-content-between flex-wrap'>
                <div className='full-width'>
                  <span className='medium-font font-weight-medium'>
                    Before your apply to this job, You must complete the skill test
                  </span>
                  <ul className='pl-3'>
                    <li>
                      You must complete this skill test in one session
                    </li>
                    <li>
                      If you exit the skill test before completing it then your skill test result will not be saved
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div />
          </div>
          <hr className='mb-0' />
          <div className='d-flex align-items-center'>
            <div className='p-4 flex-grow'>
              <div className='d-flex align-content-between flex-wrap'>
                <div className='full-width'>
                  <div className='d-flex align-items-center'>
                    <IcQuestion className='pr-2' /> <span><b>{this.props.counter ?? 1}</b> questions</span>
                  </div>
                  <div className='d-flex align-items-center'>
                    <IcClock className='pr-2' /> <span><b>60 seconds</b> per question</span>
                  </div>
                </div>
              </div>
            </div>
            <div />
          </div>
          <hr className='mb-0' />
          <div className='px-4 py-2 row justify-content-right'>
            <div className='col-lg-6 d-lg-block d-none' />
            <div className='col-lg-3 my-2 col-xs-12 p-0 px-1'>
              <div onClick={this.props.onCancel} className='form-control normal-font py-2 px-3 btn btn-recommendation-reverse rounded-pill'> Cancel</div>
            </div>
            <div className='col-lg-3 my-2 col-xs-12 p-0 px-1'>
              <div onClick={this.props.onStart} className='form-control normal-font py-2 px-3 btn btn-primary rounded-pill'>Start</div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

export default JobQuizModal
