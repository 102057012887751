// import { ADMIN } from "../constants";
import CompanyPage from "../pages/admin/company/company";
import SkillPage from "../pages/admin/company/skill";
import SkillSettingPage from "../pages/admin/general-setting/skill";
import IndustryCatgeoryPage from "../pages/admin/general-setting/industry-category";
import JobRolePage from "../pages/admin/general-setting/job-role";
import { ADMIN } from '../constants'


export const adminRoutes = [
    {
      role: ADMIN,
      exact: true,
      path: '/admin/company',
      component: CompanyPage
    },
    {
      role: ADMIN,
      exact: true,
      path: '/admin/company/skill-interest',
      component: SkillPage
    },
    {
      role: ADMIN,
      exact: true,
      path: '/admin/general-setting/industry-category',
      component: IndustryCatgeoryPage
    },
    {
      role: ADMIN,
      exact: true,
      path: '/admin/general-setting/skill-interest',
      component: SkillSettingPage
    },
    {
      role: ADMIN,
      exact: true,
      path: '/admin/general-setting/job-role',
      component: JobRolePage
    }
]