import React from 'react'
import './App.css'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'
import { PrivateRoute } from './components'
import store from './store'
import jwt_decode from 'jwt-decode'
import { Provider } from 'react-redux'
import { GET_USER, SET_AUTH } from './actions/types'
import { privateGroup, publicGroup } from './appRoutes'

import NotFound from "./pages/NotFound";

if (localStorage.jwtToken) {
  const token = localStorage.jwtToken
  const decoded = jwt_decode(token)
  store.dispatch({
    type: SET_AUTH,
    payload: decoded
  })
  const currentTime = Date.now() / 1000
  if (decoded.exp < currentTime) {
    localStorage.removeItem('jwtToken')
    localStorage.removeItem('user')
    store.dispatch({
      type: SET_AUTH,
      payload: null
    })
  }
}
if (localStorage.user) {
  const user = localStorage.user
  const decoded = JSON.parse(user)
  store.dispatch({
    type: GET_USER,
    payload: decoded
  })
}

class App extends React.Component {
  render () {
    return (
      <Provider store={store}>
        <Router>
          <div className='App'>
            <ToastContainer />
            <Switch>
              {/* PRIVATE ROUTES */}
              {
                privateGroup.map((item) => {
                  return (
                    <PrivateRoute
                      key={item.path}
                      exact={item.exact}
                      role={item.role}
                      path={item.path}
                      component={item.component}
                    />
                  )
                })
              }
              {/* PUBLIC ROUTES */}
              {
                publicGroup.map((item) => {
                  return (
                    <Route
                      key={item.path}
                      exact={item.exact}
                      path={item.path}
                      component={item.component}
                    />
                  )
                })
              }
               <Route component={NotFound}/>
            </Switch>
          </div>
        </Router>
      </Provider>
    )
  }
}

export default App
