import React from 'react';

import {Navigation} from 'react-minimal-side-navigation';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import building from '../../../images/menu_company.svg'
import GT from '../../../images/menu_general_setting.svg'
import './NavList.css'
import { useHistory } from "react-router-dom";
const NavList = ()=> {
  const history = useHistory();
    return (
      <>
        <Navigation
            // you can use your own router's api to get pathname
            activeItemId={history.location.pathname}
            onSelect={({itemId}) => {
              // maybe push to the route
              if(itemId.includes('/')){
                history.push(itemId);
              }
            }}
            items={[
              {
                title: 'Company',
                itemId: 'company',
                elemBefore: () => <img src={building} className='item-icon' alt='hacklab icon' />,
                subNav: [
                  {
                    title: 'All Company',
                    itemId: '/admin/company',
                  },
                  {
                    title: 'Skill & Interest',
                    itemId: '/admin/company/skill-interest',
                  }
                ],
              },
              {
                title: 'General Setting',
                itemId: 'general-setting',
                elemBefore: () => <img src={GT} className='item-icon' alt='hacklab icon' />,
                subNav: [
                  {
                    title: 'Industry Category',
                    itemId: '/admin/general-setting/industry-category',
                  },
                  {
                    title: 'Skill & Interest',
                    itemId: '/admin/general-setting/skill-interest',
                  },
                  {
                    title: 'Job Role',
                    itemId: '/admin/general-setting/job-role',
                  },
                ],
              },
            ]}
          />
      </>
    );
}

export default NavList;