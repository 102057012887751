import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import IcSallyError from '../../images/saly-error.svg'

class JobListingModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      title: this.props.title ?? 'Uh.. Oh!',
      desc: this.props.desc ?? '',
      titleButton: this.props.titlebutton ?? ['Go to Dashboard', 'Close'],
      doubleButton: this.props.doublebutton ?? false,
      subtitleButton: this.props.subtitlebutton ?? 'Close and go back',
      hrefButton: this.props.hrefButton ?? ['/', '/']

    }
  }
  
  onClickClose = (e) => {
    this.props.modalCallback(false);
    e.preventDefault();
  }

  render () {
    return (
      <Modal
        {...this.props}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        className='text-dark medium-font'
        contentClassName='bg-transparent no-border'
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className='bg-assessment rounded-lg'>
          <div className='d-flex align-items-center' style={{ height: '200px' }}>
            <div>
              <img alt='sally' src={IcSallyError} className='d-lg-block d-none' style={{ transform: 'translateY(-40px)' }} />
            </div>
            <div className='p-2 flex-grow-1'>
              <h4 className='font-weight-medium'>{this.state.title}</h4>
              <p className='medium-font font-weight-medium'>
                {this.state.desc}
              </p>
              {
                                this.state.doubleButton
                                  ? (
                                    <div className='row'>
                                      <Link to={`${this.state.hrefButton[0]}`} className='col-md-4 mx-2 mt-2 col-12 btn btn-recruiter-primary no-border w-150px normal-font font-weight-medium rounded-pill'>{this.state.titleButton[0]}</Link>
                                      <Link to={`${this.state.hrefButton[1]}`} className='col-md-4 mx-2 mt-2 col-12 btn btn-transparent-black w-150px normal-font font-weight-medium rounded-pill'>{this.state.titleButton[1]}</Link>
                                    </div>)
                                  : (
                                    <Link to={`/${this.state.hrefButton[0]}`} className='btn btn-recommendation no-border w-150px normal-font font-weight-medium rounded-pill'>{this.state.titleButton[0]}</Link>
                                    )
                            }
               
              <small onClick={this.onClickClose} className='pl-3'>{this.state.subtitleButton}</small>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

export default JobListingModal
