import React from 'react'

const CustomSelect = (props) => {
  let isValid = true
  let errorMessage = ''

  const handleInputChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    if (name !== undefined) {
      props.onChange(name, value)
    }
    // props.onChange(event)
  }

  if (props.validation) {
    isValid = !((props.validation ?? []).length > 0)
    errorMessage = (props.validation ?? [''])[0]
  }

  return (

    <div className='w-100'>
      <select
        className={`${props.className} form-control rounded-pill custom-textfield`}
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        value={props.value}
        required={props.required}
        id={props.id}
        name={props.name}
        onChange={handleInputChange}
      >
        <option key={`${props.name}-0`} value=''>{props.placeholder ?? 'Choose an item'}</option>
        {
          props.data && props.data.length > 0 && (props.data ?? []).map((item, i) => {
            return (<option key={`${props.name}-${i + 1}`} value={item[props.nameValue ?? 'id']}>{item[props.displayValue ?? 'text']}</option>)
          })
        }
      </select>
      {(props.helperText || !isValid) && (<span className={`${isValid ? 'text-muted' : 'text-danger'} normal-font`}>{isValid ? props.helperText : errorMessage}</span>)}
    </div>

  )
}

CustomSelect.propTypes = {}
export default (CustomSelect)
