import { Link, withRouter } from 'react-router-dom'
import React, { Component } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import InfiniteScroll from 'react-infinite-scroll-component'
import HomeLanding from '../../components/layouts/HomeLanding'
import { ReactComponent as IcStar } from '../../images/star.svg'
import { ReactComponent as IcClock } from '../../images/clock.svg'
import { ReactComponent as IcCase } from '../../images/suitcase.svg'
import { ReactComponent as IcCompany } from '../../images/company.svg'
import companyPlaceholder from '../../images/company-placeholder.svg'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { jobList,  } from '../../actions'
import { getSuggestedCompany } from '../../actions/dashboard'
import { GET_JOB } from '../../actions/responseTypes'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { currency, capitalize } from '../../utils/stringHelpers'
import _ from 'lodash'
import { alertErrorValidation } from '../../utils'
import Select from 'react-select/async'
import axios from 'axios'


const filterSearch = async (inputValue) => {
  const token = localStorage.jwtToken
  try {
    const res = await axios.get(`${process.env.REACT_APP_BASE_API}/v1/master/location?q=${inputValue}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    const optionLocation = await res.data.map(option => {
      return {
        value: option.id,
        label: option.name,
      }
    })

    return optionLocation
  } catch (e) {
    console.log(e)
  }
}

const promiseOptions = inputValue =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(filterSearch(inputValue));
    }, 1000);
  });

class Jobs extends Component {
  constructor (props) {
    super(props)
    this.state = {
      items: [],
      itemSuggested: [],
      page: 1,
      maxPage: 1,
      search: '',
      totalJobs: 0,
      showListJob: "1-10",
      selectedLocation: '',
    }
    this.fetchMoreData = this.fetchMoreData.bind(this)
  }

  fetchMoreData () {
    this.setState({page: (this.state.page + 1)}, () => {
      this.props.jobList(this.state.page, this.state.search)
    })
  };

  componentDidMount = async () => {
    await this.props.jobList(1, this.state.search)
    await this.props.getSuggestedCompany(1, 10)
    const res = await this.props.responseDashboard
    if(res) {
      this.setState({
        itemSuggested: res?.suggestedCompany ?? [],
      })
    }
  }
  
  componentDidUpdate = async (prevProps) => {
    if (prevProps.error !== this.props.error && this.props.error) {
      alertErrorValidation(this.props.error)
      this.setState({loading: false})
    }
    if (this.props.response && prevProps.response !== this.props.response) {
      switch (this.props.response.type) {
        case GET_JOB:
          this.setState(prevState => ({
            maxPage: this.props.response.data.last_page,
            items: [
              ...prevState.items,
              ...this.props.response.data.data
            ]
          }))
          {this.props.response.data.data.length !== 0 ? this.setState({totalJobs: this.props.response.data.total}) : this.setState({totalJobs: 0})}
          {this.props.response.data.data.length !== 0 ? this.setState({showListJob: this.props.response.data.from !== null && this.props.response.data.from + "-" + this.props.response.data.to}) : this.setState({showListJob: '0-0'})}
          break;
        default:
          break;
      }
    }
  }

  fetchSearch = async () => {
    this.setState({items: []})
    await this.props.jobList(1, this.state.search, this.state.selectedLocation)   
  }

  selectedValue = (location) => {
    // console.log(location.value)
    this.setState({selectedLocation: location.value})
  }

  render () {
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
        slidesToSlide: 5 // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2 // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
      }
    }

    return (
      <HomeLanding search={false}>
        <div className='container-padding'>
          <div className='card bg-white border-none text-left pb-0 mb-3 rounded-lg'>
            <div className='card-body text-center'>
              <h5><b>Search for your next job</b></h5>
              <div className='row pt-3'>
                <div className='col-md-5'>
                  <div className='form-group'>
                    <input onChange={(e) => {this.setState({search : e.target.value})}} type='text' className='p-4 form-control rounded-pill custom-textfield' placeholder='Search by title, skill or company' />
                  </div>
                </div>
                <div className='col-md-5'>
                  <div className='form-group'>
                  <Select placeholder="Search by city or province" className="select-dropdown-wrapper" cacheOptions defaultOptions loadOptions={promiseOptions} onChange={this.selectedValue}  classNamePrefix="select-dropdown"/>
                    {/* <input type='text' className='p-4 form-control rounded-pill custom-textfield' placeholder='Search by city or province' /> */}
                  </div>
                </div>
                <div className='col-md-2'>
                  <div className='form-group'>
                    <button type='submit' onClick={this.fetchSearch} className='normal-font py-4 form-control px-3 btn btn-primary rounded-pill'>Search</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className='card bg-recommendation border-none text-left pb-0 mb-3 rounded-lg'>
            <div className='card-body'>
              <h6><b>Suggested Company for you</b></h6>
              <Carousel
                responsive={responsive}
                ssr
                containerClass='carousel-container'
                removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
                deviceType={this.props.deviceType}
              >
                {this.state.itemSuggested.map((item, i) => (
                    <Link
                      to={`/company/${item.uuid}`}
                      key={`jobs-${i}`}
                      className='card custom-sm-card-2 mr-3 text-center shadow-sm mb-2 rounded-md'
                    >
                      <div className='card-body'>
                        <div className='d-flex align-items-center flex-column'>
                          <div>
                            {item.logo_link !== null ? (
                              <img
                                className='avatar-sm mt-3 rounded-circle'
                                src={item.logo_link}
                              />
                            ) : (
                              <div className='avatar-sm mt-3 rounded-circle' />
                            )}
                          </div>
                          <div>
                            <p className='mb-0 normal-font text-dark font-weight-medium'>
                              {item.company_name}
                            </p>
                            <p className='mb-0 small-font text-dark'>
                              {item.industry_category}
                            </p>
                            <p className='mb-0 small-font text-muted'>
                              {item.province_detail &&
                                _.startCase(
                                  _.toLower(item.province_detail.name),
                                )}
                              , {item.country}
                            </p>
                          </div>
                          <div className='mt-auto p-2'>
                            <hr className='p-0 mb-0 mt-2' />
                            <small className='text-muted'>
                              {item.available_job_post} Jobs available to apply
                            </small>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                {/* {[...Array(5)].map((x, i) =>
                  <div key={`jobs-${i}`} className='card custom-sm-card-2 mx-2 text-center shadow-sm mb-2 rounded-md'>
                    <div className='card-body'>
                      <div className='d-flex align-items-center flex-column'>
                        <div>
                          <IcCompany className='avatar-sm mt-3 rounded-circle' />
                        </div>
                        <div>
                          <p className='mb-0 normal-font text-dark font-weight-medium'>PT Indocyber Global {i}</p>
                          <p className='mb-0 small-font text-dark'>Network Solution</p>
                          <p className='mb-0 small-font text-muted'>Jakarta, ID</p>
                        </div>
                        <div className='mt-auto p-2'>
                          <hr className='p-0 mb-0 mt-2' />
                          <small className='text-muted'>3 Jobs available to apply</small>
                        </div>
                      </div>
                      <div>
                        <p className='mb-0 normal-font text-dark font-weight-medium'>
                          {item.company_name}
                        </p>
                        <p className='mb-0 small-font text-dark'>
                          {item.industry_category}
                        </p>
                        <p className='mb-0 small-font text-muted'>
                          {item.province_detail?.name ?? "-"}, ID
                        </p>
                      </div>
                      <div className='mt-auto p-2'>
                        <hr className='p-0 mb-0 mt-2' />
                        <small className='text-muted'>
                          3 Jobs available to apply
                        </small>
                      </div>
                    </div>
                  </div>
                )}
              </Carousel>
            </div>
          </div> */}
          <div className='d-flex'>
            <div className='p-2 font-weight-medium'>{this.state.showListJob} of {this.state.totalJobs} job matches</div>
            <div className='p-2 flex-grow-1'><hr /></div>
          </div>
          {/* INFINITE SCROLL */}
          <InfiniteScroll
            dataLength={this.state.items.length}
            next={this.fetchMoreData}
            hasMore={this.state.page < this.state.maxPage}
            className='row'
            loader={
              <div className='col-12 d-flex align-item-center pb-4 justify-content-center'>
                <span className='medium-font text-muted'>Loading...</span>
              </div>
            }
          >
            {this.state.items.map((i, index) => (
              <div onClick={() => this.props.history.push(`/jobs/${i.uuid}`)} key={`list-job-${i.uuid}`} className='col-md-6 col-sm-12 cursor-pointer'>
                <div className='card bg-white border-none text-left pb-0 mb-3 rounded-lg'>
                  <div className='card-body'>
                    <div className='row'>
                      <div className='col-md-2 col-sm-12'>
                        {/* <IcCompany className='avatar-sm rounded-circle' /> */}
                        <img className="avatar-sm rounded-circle" src={ i?.company_detail?.logo_link ? i.company_detail.logo_link : companyPlaceholder} alt="company-logo" />
                      </div>
                      <div className='col-md-10 col-sm-12'>
                        <div>
                          <span className='medium-font'>
                            <b>
                              {i?.title ?? '-'} 
                              {/* {i?.company_detail?.company_name ?? '-'} */}
                            </b>
                          </span>
                          <p className='normal-font text-muted mb-1'>
                            {_.startCase(
                              _.toLower(
                                i?.province_detail?.name ?? '-',
                              ),
                            )}
                            , {i?.province_detail?.name ?? '-'}  { i.can_work_remotely ? '(Remote)' : null }
                          </p>
                          { i?.sallary_min && i?.sallary_max ? <p className='normal-font'>Estimated Sallary IDR <span className='color-salary font-weight-medium'>{currency(i?.sallary_min ?? '-')} - {currency(i?.sallary_max ?? '-')}</span></p> : null }
                          <p className='normal-font text-muted'>{i?.company_detail?.company_name ?? '-'}</p>
                        </div>
                        <hr />
                        <div className='d-flex py-1 text-left normal-font align-items-center justify-content-start'>
                          <IcStar className='hc-small-ic mr-2 svg-black' /> {i?.job_level_detail?.level ?? '-'}
                        </div>
                        <div className='d-flex py-1 text-left normal-font align-items-center justify-content-start'>
                          <IcClock className='hc-small-ic mr-2 svg-black' /> {i?.job_type_detail?.type ?? '-'}
                        </div>
                        <div className='d-flex py-1 text-left normal-font align-items-center justify-content-start'>
                          <IcCase className='hc-small-ic mr-2 svg-black' /> {i?.job_role_detail?.job_role ?? '-'}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='card-footer bg-transparent'>
                    <div className='row'>
                      <div className='offset-2 col-10'>
                        <div
                          className='d-inline normal-font py-2  mr-2'
                        >
                          <div className="d-inline">
                            <span className="normal-font mt-1">Posted {i?.created_at_detail?.human} {'  '} • {'  '} {i?.people_applied} Applicants</span>
                          </div>
                        </div>
                        {/* <Link to='/' className='d-inline normal-font py-2 px-3 btn btn-primary rounded-pill mr-2'>Apply Now</Link> */}
                        {/* <Link to='/' className='d-inline normal-font py-2 px-3 btn btn-recommendation-reverse rounded-pill'>Save Job</Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </HomeLanding>
    )
  }
}

Jobs.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  thisUser: state.thisUser,
  error: state.error,
  responseDashboard: state.responseDashboard,
  invalid: state.invalid,
  response: state.response,
  responseDashboard: state.responseDashboard,
})

export default withRouter(connect(mapStateToProps, { jobList, getSuggestedCompany })(Jobs))
