import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  getMaritalStatus,
} from '../../actions'
import { GET_MARITAL_STATUS } from '../../actions/responseTypes'

class AboutCard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      user: this.props.user,
      work: this.props.work,
      education: this.props.education,
      className: this.props.className,
      marital_status: null
    }
  }

  componentDidMount () {
    this.props.getMaritalStatus()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.response !== this.props.response) {
      switch (this.props.response.type) {
        case GET_MARITAL_STATUS:
          const data = this.props.response.data
          data.forEach((item) => {
            if (this.state.user.marital_status === `${item.id}`) {
              this.setState({marital_status: item.text})
            }
          })
          break
        default:
          break
      }
    }
  }

  render () {
    return (
      <div className={`${this.state.className} card custom-sidebar-summary border-none text-left pb-0 mb-3 rounded-lg`}>
        <div className='card-header white-br-bottom'>
          <h6 className='pt-2'><b>About </b></h6>
        </div>
        <div className='card-body normal-font font-weight-bold'>
          <div className='pb-3'>
            <span>
              <span className='font-weight-normal'>
                Current Work
              </span>
              <br />
              <b>{this.props.user?.current_position?.company ?? '-'}</b>
            </span>
          </div>
          <div className='pb-3'>
            <span>
              <span className='font-weight-normal'>
                Current Position
              </span>
              <br />
              <b>{this.props.user?.current_position?.job_position ?? '-'}</b>
            </span>
          </div>
          <div className='pb-3'>
            <span>
              <span className='font-weight-normal'>
                Phone Number
              </span>
              <br />
              <b>{this.state.user.phone ?? '-'}</b>
            </span>
          </div>
          <div className='pb-3'>
            <span>
              <span className='font-weight-normal'>
                Education
              </span>
              <br />
              <b>{this.state.education.school ?? '-'}</b>
            </span>
          </div>
          <div className='pb-3'>
            <span>
              <span className='font-weight-normal'>
                Lives In
              </span>
              <br />
              <b>{this.state.user.city_name ?? '-'}, Indonesia</b>
            </span>
          </div>
          <div className='pb-3'>
            <span>
              <span className='font-weight-normal'>
                Marital Status
              </span>
              <br />
              <b>{this.state.marital_status ?? '-'}</b>
            </span>
          </div>
        </div>
      </div>
    )
  }
}

AboutCard.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error,
  invalid: state.invalid,
  response: state.response
})

export default withRouter(connect(mapStateToProps, { getMaritalStatus })(AboutCard))
