import axios from 'axios'

import { SET_ERROR, SET_INVALID, SET_LOADING } from './types'

// SET ERROR
export const setError = (dispatch, errorMessage = null, error = null) => {
  if (error) {
    dispatch({
      type: SET_ERROR,
      payload: {
        ...(error?.response?.data?.error ?? error?.response?.data ?? {}),
        developerMessage: errorMessage
      }
    })
  } else {
    dispatch({type: SET_ERROR})
  }
}

// SET INVALID
export const setInvalid = (dispatch, field = null, text = null) => {
  dispatch({
    type: SET_INVALID,
    payload: field || text ? { field, text } : null
  })
}

// SET LOADING
export const setLoading = (dispatch, payload = false) => {
  dispatch({
    type: SET_LOADING,
    payload
  })
}

// GET HTTP (Axio Get)
export const httpGet = async (dispatch, url, option = {}) => {
  try {
    !option.withoutLoading && setLoading(dispatch, true)

    const response = await axios.get(process.env.REACT_APP_BASE_API + url, option)

    if (response.status !== 200 || response.statusText !== 'OK' || typeof response.data !== 'object') {
      setError(dispatch, 'Unsuccessfully get ' + url, { response })
      return
    }

    if (response.data.error) {
      setError(dispatch, response.data.error.message, response.data.error)
      return
    }

    if (response.data.invalid && typeof response.data.invalid === 'object') {
      setInvalid(dispatch, response.data.invalid.field, response.data.invalid.text)
      return
    }

    setError(dispatch)
    setInvalid(dispatch)
    setLoading(dispatch)

    return response.data
  } catch (error) {
    setError(dispatch, 'Unable to get ' + url, error)
  }
}

// POST HTTP (Axio Post)
export const httpPost = async (dispatch, url, data = {}, option = {}) => {
  try {
    // !option.withoutLoading && setLoading(dispatch, true)

    const response = await axios.post(process.env.REACT_APP_BASE_API+url, data, option)

    if (response.status === 204) {
      return null
    }

    if (response.status !== 200 || response.statusText !== 'OK' || typeof response.data !== 'object') {
      setError(dispatch, 'Unsuccessfully post ' + url, { response })
      return
    }
    if (response.data.error) {
      setError(dispatch, response.data.error.message, response.data.error)
      return
    }

    if (response.data.invalid && typeof response.data.invalid === 'object') {
      setInvalid(dispatch, response.data.invalid.field, response.data.invalid.text)
      return
    }

    setError(dispatch)
    setInvalid(dispatch)
    // setLoading(dispatch)

    return response.data
  } catch (error) {
    setError(dispatch, 'Unable to post ' + url, error)
    throw(error);
  }
}

// PATCH HTTP (Axio Patch)
export const httpPatch = async (dispatch, url, data = {}, option = {}) => {
  try {
    // !option.withoutLoading && setLoading(dispatch, true)

    const response = await axios.patch(process.env.REACT_APP_BASE_API + url, data, option)

    if (response.status !== 200 || response.statusText !== 'OK' || typeof response.data !== 'object') {
      setError(dispatch, 'Unsuccessfully patch ' + url, { response })
      return
    }

    if (response.data.error) {
      setError(dispatch, response.data.error.message, response.data.error)
      return
    }

    if (response.data.invalid && typeof response.data.invalid === 'object') {
      setInvalid(dispatch, response.data.invalid.field, response.data.invalid.text)
      return
    }

    setError(dispatch)
    setInvalid(dispatch)
    // setLoading(dispatch)

    return response.data
  } catch (error) {
    setError(dispatch, 'Unable to patch ' + url, error)
  }
}

// STORAGE URL

export const storageBaseUrl = process.env.REACT_APP_STORAGE
