/* eslint-disable no-useless-constructor */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import HomeLanding from '../../components/layouts/HomeLanding'
import Skillana from '../../images/company/skillana.png'

class Search extends Component {
  constructor (props) {
    super(props)
  }

  render () {
    return (
      <HomeLanding>
        <div className='container-padding'>
        <div className='card bg-white border-none text-left pb-0 mb-3 rounded-lg'>
            <div className='card-body text-center'>
              <h5><b>Search Assesment</b></h5>
              <div className='row pt-3'>
                <div className='col-md-10 col-sm-12'>
                  <div className='form-group'>
                    <input type='text' className='p-4 form-control rounded-pill custom-textfield' placeholder='Search by company name' />
                  </div>
                </div>
                <div className='col-md-2 col-sm-12'>
                  <button type='submit' className='normal-font py-4 form-control px-5 btn btn-primary rounded-pill'>Search</button>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex  align-items-center pt-3 pb-3'>
            <h6 style={{width: 210 ,}}><b>1 assesment matches</b></h6>
            <div style={{ borderBottom: '1px', borderBottomStyle:'solid', borderBottomColor:'#C4C4C4', width:'100%' }}/>
          </div>
          <div class="row">
            <div class="col-md-4">
              <Link to="/assesment" target="_blank" >
              <div className='card bg-white border-none text-left p-2 mb-3 rounded-lg'>
                <div className='card-body text-center'>
                    <h5><b style={{ color: '#333333' }}>Psychological Test</b></h5>
                    <div className="d-flex justify-content-center">
                      <p style={{ backgroundColor: '#EFEFEF', color: '#939393',  padding: 6, borderRadius: 3 }}>Powered By <img src={Skillana} alt="skillana"/> </p>
                  </div>
                </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </HomeLanding>
    )
  }
}

const mapStateToProps = state => ({
  thisUser: state.thisUser,
  auth: state.auth,
  error: state.error,
  invalid: state.invalid
})

export default withRouter(connect(mapStateToProps, { })(Search))
