import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Landing from '../../../components/layouts/Landing'
import AuthCard from '../../../components/cards/AuthCard'
import SecurePass from '../../../images/secure-pass.svg'

class ResetPasswordSuccess extends Component {
  render () {
    return (
      <Landing
        isLogoHidden
        isBgPersonHidden
      >
        <div className='d-flex align-items-start flex-column'>
          <div className='mb-auto p-2 full-width'>
            <div className='row justify-content-center'>
              <div className='col-lg-5 col-sm-8'>
                <div className='ml-auto'>
                  <AuthCard>
                    <form className='text-center'>
                      <img src={SecurePass} alt='secure password' />
                      <h5><b>Reset Password Success!</b></h5>
                      <p>
                        Your password has been reset
                        Please  click button bellow to login
                      </p>
                      <Link to='/login' className='form-control rounded-pill p-4 btn btn-primary font-weight-medium'>
                        Continue Login
                      </Link>
                    </form>
                  </AuthCard>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Landing>
    )
  }
}

export default ResetPasswordSuccess
