import { GET_RESPONSE } from '../actions/types'

const initState = null

export const response = (state = initState, action) => {
  switch (action.type) {
    case GET_RESPONSE:
      return action.payload

    default:
      return state
  }
}

const Responses = {
  response
}

export default Responses
