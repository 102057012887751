import { Link } from "react-router-dom";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import Salty from "../../images/salty.svg";

import { ReactComponent as IcPlus } from "../../images/plus-rect.svg";
import { ReactComponent as IcClose } from "../../images/close.svg";
import HomeLanding from "../../components/layouts/HomeLanding";
import CreateJobModal from "../../components/modals/CreateJobModal";
import {
  CustomSelect,
  CustomInput,
  CustomTextarea,
  CustomCheckbox,
  CustomRadio,
} from "../../components";
import {
  getProvince,
  getCity,
  getJobRole,
  getJobLevel,
  getJobType,
  createJob,
} from "../../actions";
import {
  GET_JOB_ROLE,
  GET_PROVINCE,
  GET_CITY,
  GET_JOB_LEVEL,
  GET_JOB_TYPE,
  CREATE_JOB,
} from "../../actions/responseTypes";
import { RULES_JOB_CREATE, Validator } from "../../rules";
import { alertErrorValidation, captionErrorValidation } from "../../utils";

const qTemp = {
  question: "",
  option: [
    {
      A: "",
    },
    {
      B: "",
    },
    {
      C: "",
    },
    {
      D: "",
    },
  ],
  answer: "",
};

const formTemp = {
  job_role: "",
  job_type: "",
  job_level: "",
  province: "",
  city: "",
  title: "",
  can_work_remotely: 0,
  description: "",
  key_responsibilitis: "",
  requirements: "",
  sallary_min: "",
  sallary_max: "",
  benefit: "",
  quiz_success_threshold: "",
  quiz: [],
};

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopUpALert = styled.div`
  height: 231px;
  width: 671px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  background: #62c3d0;
  border-radius: 20px;
  .close-back {
    margin-top: 10px;
    font-size: 12px;
  }
  img {
    width: 252px;
  }
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    height: auto;
    padding: 20px;
  }
  p, h3{
    color: #fff
  }

`;

const ButtonVeryfy = styled.div`
  width: 260px;
  height: 40px;
  background: #62c3d0;
  border-radius: 50px;
  color: #fff;
  text-align: center;
  padding-top: 6px;
  font-weight: 700;
  z-index: 9999;
  border: 3px solid #fff;
`;

class CreateJobs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAlert: false,
      form: formTemp,
      validation: {},
      error: false,
      loading: false,
      cityLoading: false,
      modalShow: false,
      page: 1,
      job_roles: [{ text: "Loading...", id: 1 }],
      job_type: [{ text: "Loading...", id: 1 }],
      job_level: [{ text: "Loading...", id: 1 }],
      provinces: [{ name: "Loading...", id: 1 }],
      cities: [{ name: "Loading...", id: 1 }],
      title: [
        "Job Desctiption",
        "Job Requirements",
        "Sallary & Benefits",
        "Skill Test (Optional)",
      ],
    };

    this.onAddQuestion = this.onAddQuestion.bind(this);
    this.onRemoveQuestion = this.onRemoveQuestion.bind(this);
    this.onNext = this.onNext.bind(this);
    this.onPrevious = this.onPrevious.bind(this);
    this.onReset = this.onReset.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.handleFormValidation = this.handleFormValidation.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount = async () => {
    await this.props.getJobRole();
    await this.props.getProvince();
    await this.props.getJobLevel();
    await this.props.getJobType();
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.error !== this.props.error && this.props.error) {
      alertErrorValidation(this.props.error);
      const check = captionErrorValidation(this.props.error, RULES_JOB_CREATE);
      this.setState({ loading: false, validation: check });
    }
    if (prevProps.response !== this.props.response && this.props.response) {
      switch (this.props.response.type) {
        case GET_JOB_ROLE:
          this.setState({ job_roles: this.props.response.data ?? [] });
          break;
        case GET_JOB_LEVEL:
          this.setState({ job_level: this.props.response.data ?? [] });
          break;
        case GET_JOB_TYPE:
          this.setState({ job_type: this.props.response.data ?? [] });
          break;
        case GET_PROVINCE:
          this.setState({ provinces: this.props.response.data ?? [] });
          break;
        case GET_CITY:
          this.setState({
            cities: this.props.response.data.cities ?? [],
            cityLoading: false,
          });
          break;
        case CREATE_JOB:
          this.setState({ loading: false, isAlert: true });
          // toast.success(
          //   () => (
          //     <div>
          //       Job successfully created!
          //       <br />
          //       You will be redirected to the page <b>dashboard</b>.
          //     </div>
          //   ),
          //   {
          //     position: toast.POSITION.TOP_CENTER,
          //   }
          // );
          // setTimeout(() => {
          //   this.props.history.push(`/my-company`);
          // }, 1000);
          break;
        default:
          break;
      }
    }
  };

  handleInputChange = (name, value) => {
    let tmp = name.split(".");
    let result = value;
    switch (tmp[0]) {
      case "quiz":
        result = JSON.parse(JSON.stringify(this.state.form[tmp[0]]));
        if (tmp[2] === "option") {
          result[parseInt(tmp[1])].option[parseInt(tmp[3])] = {
            [tmp[4]]: value,
          };
        } else {
          result[parseInt(tmp[1])][tmp[2]] = value;
        }
        break;
      default:
        if (name === "province") {
          this.setState({ cityLoading: true });
          this.props.getCity(value);
        }
        break;
    }
    if (tmp[0] === "can_work_remotely") {
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          [tmp[0]]: !this.state.form.can_work_remotely,
        },
      }));
    } else {
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          [tmp[0]]: result,
        },
      }));
    }
  };

  handleFormValidation = async (event) => {
    event.preventDefault();
    // if(Number(this.state.form.sallary_min >= Number(this.state.form.sallary_max))){
    //   toast.error("Estimated Sallary, min sallary can't be bigger than max sallary", {
    //     position: toast.POSITION.TOP_CENTER
    //   })
    //   return false;
    // }
    // if(this.state.form.quiz.length > 0 && this.state.form.quiz.length !== this.state.form.quiz.filter((r)=>r.question).length){
    //   toast.error("Question can't be empty", {
    //     position: toast.POSITION.TOP_CENTER
    //   })
    //   return false;
    // }
    // if(this.state.form.quiz.length > 0 && this.state.form.quiz.length !== this.state.form.quiz.filter((r)=>r.answer).length){
    //   toast.error("Correct answers can't be empty", {
    //     position: toast.POSITION.TOP_CENTER
    //   })
    //   return false;
    // }
    // for (let index = 0; index < this.state.form.quiz.length; index++) {
    //   const el = this.state.form.quiz[index];
    //   for (let x = 0; x < el.option.length; x++) {
    //     const t = el.option[x];
    //     const key = Object.keys(t)[0];
    //     if(!t[key]){
    //       toast.error(`Answers Option can't be empty`, {
    //         position: toast.POSITION.TOP_CENTER
    //       })
    //       return false;
    //     }
    //   }
    // }
    this.setState({ loading: true });
    this.props.createJob(this.state.form);
  };

  onChangePage(e, p) {
    this.setState({ page: p });
  }

  onNext(e) {
    const p = this.state.page + 1;
    this.setState({ page: p });
  }

  onPrevious(e) {
    const p = this.state.page - 1;
    this.setState({ page: p });
  }

  onReset(e) {
    this.setState({ form: formTemp });
  }

  onRemoveQuestion(i) {
    const quiz = this.state.form.quiz;
    quiz.splice(i, 1);
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        quiz: quiz,
      },
    }));
  }

  onAddQuestion(e) {
    e.preventDefault();
    const q = JSON.parse(JSON.stringify(this.state.form.quiz));
    if (q.length >= 5) {
      toast.error("Question have reached its maximum (5 Question)", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    q.push(qTemp);
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        quiz: q,
      },
    }));
  }

  goToDB() {
    this.props.history.push(`/my-company`);
  }

  render() {
    return (
      <HomeLanding isRecruiter>
        {this.state.isAlert && (
          <Backdrop>
            <PopUpALert>
              <img style={{ marginTop: -50 }} src={Salty} alt="human" />
              <div>
                <h3>You’re Rocks!</h3>
                <p>
                  Your job has posting, let’s see how much job seeker interested
                  to your vacancies
                </p>
                <ButtonVeryfy onClick={() => this.goToDB()}>Go to Dashboard</ButtonVeryfy>
              </div>
            </PopUpALert>
          </Backdrop>
        )}
        <div className="row pt-3">
          <div className="col-3">
            <div className="card bg-recommendation border-none text-left pb-0 mb-3 rounded-lg">
              <div className="card-header white-br-bottom">
                <h6 className="pt-2">
                  <b>Job Posting </b>
                </h6>
              </div>
              <div className="card-body pb-1">
                <div>
                  <ul type="none" className="pl-0 normal-font">
                    <li
                      className={`pb-3 ${
                        this.state.page === 1 && "font-weight-bold"
                      }`}
                      key="job-description"
                    >
                      <Link
                        onClick={(e) => this.onChangePage(e, 1)}
                        to="#job-desc"
                        className="link-white large-font"
                      >
                        Job Description
                      </Link>
                    </li>
                    <li
                      className={`pb-3 ${
                        this.state.page === 2 && "font-weight-bold"
                      }`}
                      key="job-requirements"
                    >
                      <Link
                        onClick={(e) => this.onChangePage(e, 2)}
                        to="#job-requirements"
                        className="link-white large-font"
                      >
                        Job Requirements
                      </Link>
                    </li>
                    <li
                      className={`pb-3 ${
                        this.state.page === 3 && "font-weight-bold"
                      }`}
                      key="salary-benefits"
                    >
                      <Link
                        onClick={(e) => this.onChangePage(e, 3)}
                        to="#salary-benefits"
                        className="link-white large-font"
                      >
                        Salary & Benefits
                      </Link>
                    </li>
                    {/* <li className={`${this.state.page === 4 && 'font-weight-bold'}`} key='skill-test'>
                      <Link onClick={(e) => this.onChangePage(e, 4)} to='#skill-test' className='link-white large-font'>Skill Test (Optional)</Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-9">
            <div className="card bg-white border-none text-left pb-0 mb-3 rounded-lg">
              <div className="card-header">
                <h6 className="pt-2">
                  <b>{this.state.title[this.state.page - 1]} </b>
                </h6>
              </div>
              <div className="card-body">
                <form onSubmit={this.handleFormValidation}>
                  {this.state.page === 1 && (
                    <div id="form-1">
                      <div className="form-group">
                        <label className="pl-3 normal-font font-weight-medium">
                        <span style={{color:'red'}}>* </span> Job Title
                        </label>
                        <CustomInput
                          validation={this.state.validation["title"]}
                          placeholder="Enter Job Title"
                          value={this.state.form.title}
                          onChange={this.handleInputChange}
                          name="title"
                          id="title"
                        />
                      </div>
                      <div className="form-group">
                        <label className="pl-3 normal-font font-weight-medium">
                        <span style={{color:'red'}}>* </span> Job Role
                        </label>
                        <CustomSelect
                          validation={this.state.validation["job_role"]}
                          value={this.state.form.job_role}
                          data={this.state.job_roles}
                          onChange={this.handleInputChange}
                          placeholder="Select Job Role"
                          displayValue="text"
                          name="job_role"
                          id="job_role"
                        />
                      </div>
                      <div className="form-group">
                        <label className="pl-3 normal-font font-weight-medium">
                        <span style={{color:'red'}}>* </span> Job Type
                        </label>
                        <CustomSelect
                          validation={this.state.validation["job_type"]}
                          value={this.state.form.job_type}
                          data={this.state.job_type}
                          onChange={this.handleInputChange}
                          placeholder="Select Job Type"
                          displayValue="text"
                          name="job_type"
                          id="job_type"
                        />
                      </div>

                      <div className="form-group">
                        <label className="pl-3 normal-font font-weight-medium">
                        <span style={{color:'red'}}>* </span> Job Level
                        </label>
                        <CustomSelect
                          validation={this.state.validation["job_level"]}
                          value={this.state.form.job_level}
                          data={this.state.job_level}
                          onChange={this.handleInputChange}
                          placeholder="Select Job Level"
                          displayValue="text"
                          name="job_level"
                          id="job_level"
                        />
                      </div>
                      <div className="form-row">
                        <div className="col-md-12">
                          <label className="pl-3 normal-font font-weight-medium">
                          <span style={{color:'red'}}>* </span>  Work Location
                          </label>
                        </div>
                        <div className="form-group col-md-6">
                          <CustomSelect
                            validation={this.state.validation["province"]}
                            value={this.state.form.province}
                            data={this.state.provinces}
                            onChange={this.handleInputChange}
                            placeholder="Choose a province"
                            displayValue="name"
                            name="province"
                            id="province"
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <CustomSelect
                            disabled={
                              this.state.form.province === "" ||
                              this.state.cityLoading
                            }
                            validation={this.state.validation["city"]}
                            value={this.state.form.city}
                            data={this.state.cities}
                            onChange={this.handleInputChange}
                            displayValue="name"
                            placeholder="Choose a city"
                            name="city"
                            id="city"
                          />
                        </div>
                      </div>
                      <CustomCheckbox
                        validation={this.state.validation["can_work_remotely"]}
                        checked={this.state.form.can_work_remotely}
                        className="ml-3 my-3"
                        value="1"
                        emptyValue="0"
                        name={"can_work_remotely"}
                        onChange={this.handleInputChange}
                        id={"can_work_remotely"}
                        checkboxLabel={"Can work remotely"}
                      />
                      <div className="form-group">
                        <label className="pl-3 normal-font font-weight-medium">
                        <span style={{color:'red'}}>* </span> Job Resume
                        </label>
                        <CustomTextarea
                          placeholder="Write job description here"
                          rows={4}
                          name="description"
                          id="description"
                          value={this.state.form.description}
                          validation={this.state.validation["description"]}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                  )}

                  {this.state.page === 2 && (
                    <div id="form-2">
                      <div className="form-group">
                        <label className="pl-3 normal-font font-weight-medium">
                        <span style={{color:'red'}}>* </span> Key Responsibilities
                        </label>
                        <CustomTextarea
                          placeholder="Write job responsibilities here"
                          rows={4}
                          name="key_responsibilitis"
                          id="key_responsibilitis"
                          value={this.state.form.key_responsibilitis}
                          validation={
                            this.state.validation["key_responsibilitis"]
                          }
                          onChange={this.handleInputChange}
                        />
                      </div>

                      <div className="form-group">
                        <label className="pl-3 normal-font font-weight-medium">
                        <span style={{color:'red'}}>* </span>  Requirements
                        </label>
                        <CustomTextarea
                          placeholder="Write requirements here"
                          rows={4}
                          name="requirements"
                          id="requirements"
                          value={this.state.form.requirements}
                          validation={this.state.validation["requirements"]}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                  )}

                  {this.state.page === 3 && (
                    <div id="form-3">
                      <div className="form-row">
                        <div className="col-md-12">
                          <label className="pl-3 normal-font font-weight-medium">
                           Estimated Sallary
                          </label>
                        </div>
                        <div className="form-group col-md-6">
                          <CustomInput
                            validation={this.state.validation["sallary_min"]}
                            placeholder="Start from"
                            value={this.state.form.sallary_min ?? ""}
                            onChange={this.handleInputChange}
                            name={"sallary_min"}
                            id={"sallary_min"}
                            type={"number"}
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <CustomInput
                            validation={this.state.validation["sallary_max"]}
                            placeholder="Up to"
                            value={this.state.form.sallary_max ?? ""}
                            onChange={this.handleInputChange}
                            name={"sallary_max"}
                            id={"sallary_max"}
                            type={"number"}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="pl-3 normal-font font-weight-medium">
                          <span style={{color:'red'}}>* </span> Benefits
                        </label>
                        <CustomTextarea
                          placeholder="Write benefits here"
                          rows={4}
                          name="benefit"
                          id="benefit"
                          value={this.state.form.benefit}
                          validation={this.state.validation["benefit"]}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                  )}

                  {this.state.page === 4 && (
                    <div id="form-4">
                      <label className="normal-font pb-2">
                        To help you filter out potential candidates, make some
                        specific questions about the position you are opening
                        (Maximum 5 questions)
                      </label>
                      {this.state.form.quiz.map((item, i) => {
                        return (
                          <div
                            key={`question-${i}`}
                            className="shadow-sm card bg-white border-none text-left pb-0 mb-3 rounded-lg"
                          >
                            <div className="card-header">
                              <IcClose
                                onClick={() => this.onRemoveQuestion(i)}
                                className="hc-ic float-right mr-3 cursor-pointer"
                              />
                              <h6 className="pt-2">
                                <b>Question {i + 1}</b>
                              </h6>
                            </div>
                            <div className="card-body pb-1">
                              <div className="form-group">
                                <label className="pl-3 normal-font font-weight-medium">
                                  Question
                                </label>
                                <CustomTextarea
                                  placeholder="Write a question here"
                                  rows={4}
                                  onChange={this.handleInputChange}
                                  validation={
                                    this.state.validation[`quiz.${i}.question`]
                                  }
                                  value={item.question ?? ""}
                                  name={`quiz.${i}.question`}
                                  id={`quiz.${i}.question`}
                                />
                              </div>
                              <div className="form-row">
                                <div className="col-md-9">
                                  <label className="pl-3 normal-font font-weight-medium">
                                    Answer Option
                                  </label>
                                </div>
                                <div className="col-md-3 text-center">
                                  <label className="normal-font font-weight-medium">
                                    Correct Answer
                                  </label>
                                </div>
                                {["A", "B", "C", "D"].map((char, j) => {
                                  return (
                                    <>
                                      <div className="form-group col-md-9">
                                        <CustomInput
                                          validation={
                                            this.state.validation[
                                              `quiz.${i}.option.${j}.${char}`
                                            ]
                                          }
                                          placeholder="Input a answer option"
                                          value={item.option[j][char] ?? ""}
                                          onChange={this.handleInputChange}
                                          name={`quiz.${i}.option.${j}.${char}`}
                                          id={`quiz.${i}.option.${j}.${char}`}
                                        />
                                      </div>
                                      <CustomRadio
                                        className="col-md-3"
                                        value={char}
                                        onChange={this.handleInputChange}
                                        name={`quiz.${i}.answer`}
                                        id={`quiz.${i}.answer`}
                                      />
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <button
                        onClick={this.onAddQuestion}
                        className="btn btn-transparent d-flex align-items-center font-weight-medium"
                      >
                        <IcPlus className="hc-ic mr-2" /> Add more question
                      </button>

                      <div className="shadow-sm mt-3 card bg-white border-none text-left pb-0 mb-3 rounded-lg">
                        <div className="card-body pb-1">
                          <div className="form-group">
                            <label className="pl-3 normal-font font-weight-medium">
                              Minimum score to pass the skill test session
                            </label>
                            <CustomSelect
                              validation={
                                this.state.validation["quiz_success_threshold"]
                              }
                              value={this.state.form.quiz_success_threshold}
                              data={this.state.form.quiz.map((item, index) => {
                                return {
                                  id: `${index + 1}`,
                                  name: `${index + 1}`,
                                };
                              })}
                              onChange={this.handleInputChange}
                              displayValue="name"
                              placeholder="Choose how many points the minimum answer must be answered correctly"
                              name="quiz_success_threshold"
                              id="quiz_success_threshold"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="d-flex pt-5">
                    {this.state.page > 1 && (
                      <div className="p-2">
                        <button
                          onClick={this.onPrevious}
                          type="button"
                          className="normal-font w-150px form-control py-4 btn btn-recommendation-reverse rounded-pill"
                        >
                          Previous
                        </button>
                      </div>
                    )}
                    {this.state.page < 3 && (
                      <div className="p-2">
                        <button
                          onClick={this.onNext}
                          type="button"
                          to="/"
                          className="normal-font w-150px form-control py-4 btn btn-primary rounded-pill"
                        >
                          Next
                        </button>
                      </div>
                    )}
                    {this.state.page === 3 && (
                      <div className="p-2">
                        <button
                          onClick={this.onReset}
                          type="button"
                          className="normal-font w-150px form-control py-4 btn btn-gray rounded-pill"
                        >
                          Reset All
                        </button>
                      </div>
                    )}

                    {this.state.page === 3 && (
                      <div className="ml-auto p-2 bd-highlight">
                        <button
                          type="submit"
                          className="normal-font w-150px form-control py-4 btn btn-orange rounded-pill"
                        >
                          Post a Job Now
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <CreateJobModal
          show={this.state.modalShow}
          desc="Your job has posting, let’s see how much job seeker interested to your vacancies"
          onHide={() => this.setState({ modalShow: false })}
        />
      </HomeLanding>
    );
  }
}

CreateJobs.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  thisUser: state.thisUser,
  auth: state.auth,
  error: state.error,
  invalid: state.invalid,
  response: state.response,
});

const mapStateToDispatch = {
  getJobRole,
  getProvince,
  getCity,
  getJobType,
  getJobLevel,
  createJob,
};

export default withRouter(
  connect(mapStateToProps, mapStateToDispatch)(CreateJobs)
);
