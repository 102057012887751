import { Link, withRouter } from 'react-router-dom'
import React, { Component } from 'react'
import moment from 'moment'
import 'react-multi-carousel/lib/styles.css'
import InfiniteScroll from 'react-infinite-scroll-component'
import HomeLanding from '../../components/layouts/HomeLanding'
import { ReactComponent as IcStar } from '../../images/star.svg'
import { ReactComponent as IcClock } from '../../images/clock.svg'
import { ReactComponent as IcCase } from '../../images/suitcase.svg'
import { ReactComponent as IcCompany } from '../../images/company.svg'
import { ReactComponent as IcShare } from '../../images/share.svg'
import { ReactComponent as IcRock } from '../../images/rock.svg'
import { ReactComponent as IcReply } from '../../images/reply.svg'
import { ReactComponent as IcOption } from '../../images/option.svg'
import { ReactComponent as IcDownload } from '../../images/download.svg'
import { ReactComponent as IcMail } from '../../images/mail.svg'
import User from '../../images/user-2.svg'
import DefaultUser from '../../images/default-picture.svg'
import JobListingModal from '../../components/modals/JobListingModal'
import { ShareModal } from '../../components'
import { connect } from 'react-redux'
import { jobList, getJobsPosting } from '../../actions'
import PropTypes from 'prop-types'
import { GET_JOB } from '../../actions/responseTypes'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { currency, capitalize } from '../../utils/stringHelpers'
import { ReactComponent as IcAddRectangle } from '../../images/add-rectangle.svg'
import ReactPaginate from 'react-paginate';
import { alertErrorValidation } from '../../utils'
import { httpGet } from '../../actions/utils'
import Company2 from "../../images/building.svg";
import Company from "../../images/new-building.svg";
import Campus from "../../images/campus.svg";
import UserDefault from "../../images/user-default.svg";
import { getJobAplicantLite, getJobAplicantDetail, jobStatus, getJobDetails } from '../../actions/jobs'
import { companyDashboard } from '../../actions/companies'

class JobsApplicant extends Component {
  constructor(props) {
    super(props)
    this.state = {
      //   items: [],
      items: [1, 2, 3, 4, 5],
      page: 1,
      maxPage: 1,
      modalShow: false,
      modalShare: false,
      detailData: {
        title: null,
      },
      status: 0,
      applicanList: [],
      selected: 0,
      user_complete:null,
    }
    this.fetchMoreData = this.fetchMoreData.bind(this)
  }

  fetchMoreData() {
    setTimeout(() => {
      this.setState({
        items: this.state.items.concat([1, 2, 3, 4, 5]),
      })
    }, 1500)
  }

  //   fetchMoreData() {
  //     this.setState({ page: this.state.page + 1 }, () => {
  //       this.props.jobList(this.state.page)
  //     })
  //   }

  handleModalCallback = (isModalShow) => {
    this.setState({ modalShow: isModalShow })
  }

  componentDidMount = async () => {
    const res1 = await this.props.getJobDetails(this.props.match.params.id);
    // console.log('res1', res1);
    this.setState({ detailData: res1, status: res1.status });
    const res = await this.props.getJobAplicantLite(res1.company_detail.uuid, this.props.match.params.id);
    // console.log('res', res.data[0]);
    this.setState({ applicanList: res.data },()=>{
      this.detailUser();
    });
    
  }

  async detailUser(){
    const { applicanList, selected } = this.state;
    if(applicanList.length){
      const respon = await this.props.getJobAplicantDetail(this.props.match.params.id, applicanList[selected].id);
      console.log('respon', respon);
      this.setState({user_complete: respon.user_complete});
    }
  }

  componentDidUpdate = async prevProps => {
    if (prevProps.error !== this.props.error && this.props.error) {
      alertErrorValidation(this.props.error)
      this.setState({ loading: false })
    }
    if (this.props.response && prevProps.response !== this.props.response) {
      switch (this.props.response.type) {
        case GET_JOB:
          this.setState(prevState => ({
            maxPage: this.props.response.data.last_page,
            items: [...prevState.items, ...this.props.response.data.data],
          }))
          break
        default:
          break
      }
    }
  }


  async OnToggle(uuid) {
    await this.props.jobStatus(uuid);
    toast.success('Data successfully changed!', {
      position: toast.POSITION.TOP_CENTER
    });
    await this.props.companyDashboard();
    this.props.getJobsPosting();
    this.setState({ status: !this.state.status });
  }

  formatMoney = amount => {
    return amount ? amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null;
  }

  render() {
    const { detailData, applicanList, selected, user_complete } = this.state;
    return (
      <HomeLanding search={false}>
        <div className='pt-3'>
          <div className='row justify-content-between align-items-center mr-0 mb-3 mt-3'>
            <div className="col-md-6 col-sm-12 ">
              <h4 className='font-weight-bold'>Job Posting</h4>
            </div>
            <div className=" col-sm-12 col-md-6 text-md-right">
              <Link to='/jobs/create'
                className='rounded-pill btn btn-post-job font-weight-medium pt-2'
              >
                <IcAddRectangle className='hc-ic mr-2 mb-1 svg-white' />
                <span className='mt-2 align-items-center'>Post a Job</span>
              </Link>
            </div>
          </div>
          <div className='card bg-white border-none text-left pb-0 mb-3 rounded-lg'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-md-2 col-sm-12'>
                  <img className="avatar-lg rounded-circle" src={detailData?.company_detail?.logo_link ? detailData.company_detail.logo_link : Company2} alt="icon" />
                </div>
                <div className='col-md-10 col-sm-12 mt-5'>
                  <div className='font-weight-medium'>
                    {this.state.detailData.title}
                  </div>
                  <p className='normal-font'>
                    Estimated Salary{' '} IDR {' '}
                    <span className='color-salary font-weight-medium'>
                      {this.formatMoney(this.state.detailData.sallary_min)} -
                      {this.formatMoney(this.state.detailData.sallary_max)}
                    </span>
                  </p>
                  <p className='text-muted'>
                    {this.state.detailData?.city_detail?.name} , ID
                  </p>

                  <div className='d-flex justify-content-between pt-2'>
                    <div className='d-flex flex-wrap'>
                      <div className="mr-2 mb-2">
                        <select onChange={() => this.OnToggle(this.props.match.params.id)} style={{ backgroundColor: this.state.status ? '#29C973' : '#E04F5F', color: '#fff' }} className='mr-2 normal-font w-120px rounded-pill custom-select-dropdown-small' >
                          <option value={1} selected={this.state.status === 1}>Open</option>
                          <option value={0} selected={this.state.status === 0}>Close</option>
                        </select>
                      </div>
                      {/* <button
                        onClick={() => this.setState({ modalShow: true })}
                        className='normal-font py-2 px-3 btn btn-primary rounded-pill mr-2'
                      >
                        50 Applied
                      </button> */}
                      <Link
                        to={`/recruiter/jobs/${this.props.match.params.id}`}
                        className='rounded-pill btn btn-primary font-weight-medium pr-3 pt-0 py-2 mr-2 mb-2'
                      >
                        Job Detail
                      </Link>
                      <button
                        onClick={() => this.setState({ modalShare: true })}
                        className='mb-2 d-inline-block normal-font py-2 px-3 btn btn-recommendation-reverse rounded-pill'
                      >
                        <IcShare className='hc-ic mr-2' /> Share
                      </button>
                    </div>

                    <div className='d-flex'>
                      {/* <div>
                        <Link
                          to={`/recruiter/jobs/${this.props.match.params.id}`}
                          className='rounded-pill btn btn-recommendation-reverse font-weight-medium pr-3 pt-0 py-2 mr-2'
                        >
                          Job Detail
                        </Link>
                      </div> */}

                      {/* <div>
                        <select
                          className='normal-font w-120px rounded-pill custom-select-dropdown-small'
                          defaultValue=''
                        >
                          <option value=''>Open</option>
                          <option value='Close'>Close</option>
                        </select>
                      </div> */}
                    </div>
                  </div>

                  <p className='mt-4 text-muted small'>Posted {moment(detailData.created_at).fromNow()}</p>
                </div>
              </div>
            </div>
          </div>
          {applicanList.length ? 
          <><div className='d-flex'>
              <div className='p-2 font-weight-medium'>1-10 of 10 applicants</div>
              <div className='p-2 flex-grow-1'>
                <hr />
              </div>
            </div><div className='row pb-4'>
                <div className='col-md-5'>
                  <div className='card bg-white rounded-lg'>
                    <div className='card-header'>
                      <h6 className='pt-2'>
                        <b>Job Applicant </b>
                      </h6>
                      {/* <p>97 talents have applied in 3 weeks</p> */}
                    </div>
                    <div className='card-body'>
                      {/* INFINITE SCROLL */}
                      {/* <InfiniteScroll
      dataLength={this.state.items.length}
      next={this.fetchMoreData}
      hasMore
      height={500}
      loader={
        <div className='d-flex align-item-center pb-4 justify-content-center'>
          <span className='medium-font text-muted'>
            Loading...
          </span>
        </div>
      }
    > */}
                      {applicanList.map((item, index) => (
                        <div
                          onClick={()=>{
                            this.setState({ selected: index },()=>{
                              this.detailUser();
                            });
                          }}
                          style={{ cursor: 'pointer' }}
                          key={`jobs-list-${index}`}
                          className='card bg-job-listing border-none text-left pb-0 mb-3 rounded-lg'
                        >
                          <div className={index === selected ? `card-body active-job` : `card-body`}>
                            <div className='row'>
                              <div className='col-md-3 col-sm-12 pr-0'>
                                <img src={item.user.profile_picture_url ? item.user.profile_picture_url : UserDefault} className='avatar-sm rounded-circle' />
                              </div>
                              <div className='col-md-9 col-sm-12'>
                                <div className='d-flex align-content-between flex-wrap'>
                                  <div className='full-width'>
                                    <span className='normal-font font-weight-medium'>
                                      {item.user.name}
                                      {/* {i.title}, {i.company_detail.company_name} */}
                                    </span>
                                    <p className='normal-font '>
                                      {item.active_work_experience.job_position} at <br /> <a href="javascript:" style={{ color: '#62C3D0' }}>{item.active_work_experience.company}</a>
                                      {/* {i.company_detail.company_name} -{' '} */}
                                      {/* {capitalize(
              i.company_detail.province_detail.name,
            )}
            , ID */}
                                    </p>
                                  </div>
                                  <div className='full-width'>
                                    <hr className='mb-0' />
                                    <div className='d-flex py-1 text-left normal-font align-items-center justify-content-start'>
                                      <p className='normal-font  mb-0' style={{ color: '#939393' }}>
                                        {/* <span>Score skill test : 9/10</span> */}
                                        {/* {'  '}  */}
                                        •{' '}
                                        <span>Applied {item.created_at.human}</span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      {/* </InfiniteScroll> */}
                    </div>
                  </div>
                  <ReactPaginate
                    previousLabel=""
                    nextLabel=""
                    pageClassName="page-paginate"
                    containerClassName="react-paginate"
                    activeClassName="page-active"
                    previousClassName="page-previous"
                    nextClassName="page-next"
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={1}
                    pageCount={1}
                    initialPage={1}
                    breakLabel={"..."} />
                </div>
                <div className='col-md-7'>
                  <div className='card bg-gray-shadow px-4 py-4 rounded-lg'>
                    <div className='card-header bg-white rounded-lg mb-1'>
                      <span>
                        <div className='row pt-2'>
                          <div className='col-md-2 col-sm-12 p-2'>
                            <img src={applicanList[selected].user.profile_picture_url ? applicanList[selected].user.profile_picture_url : UserDefault} className='avatar-sm rounded-circle' />
                          </div>
                          <div className='col-md-9 col-sm-12 p-2 flex-grow'>
                            <div className='d-flex align-content-between flex-wrap'>
                              <div className='full-width'>
                                <span className='medium-font font-weight-medium'>
                                  {applicanList[selected].user.name}
                                </span>
                                <p
                                  className='normal-font text-muted pt-3'
                                  style={{ width: '180px' }}
                                >
                                  {applicanList[selected].active_work_experience.job_position}{' '}at{' '}
                                  <br />
                                  <span className='color-salary'>
                                  {applicanList[selected].active_work_experience.company}
                                  </span>
                                  <br/>
                                  •{' '}<span>Applied {applicanList[selected].created_at.human}</span>
                                </p>
                              </div>
                              <div className='full-width'>
                                <div className='d-flex flex-wrap'>
                                  {/* <div className='align-self-center'>
                                    <button
                                      onClick={() => this.setState({ modalShow: true })}
                                      className='normal-font py-2 px-3 btn btn-recruiter-primary rounded-pill mr-2'
                                    >
                                      <IcDownload className='hc-ic mr-2 svg-white' />
                                      Download CV
                                    </button>
                                  </div> */}
                                  <div className='mb-2 mr-2'>
                                    <button  onClick={() => window.open(`mailto:?subject=I wanted you to see this site&amp;body=Check out this site ${window.location.href}.`, '')} className='normal-font py-2 px-3 btn btn-recommendation-reverse rounded-pill'>
                                      <IcMail className='hc-ic mr-2' />
                                      Send Email
                                    </button>
                                  </div>
                                  <div className='flex-grow-1'>
                                    <div>
                                      <select
                                        className='d-inline-block normal-font w-150px rounded-pill custom-select-dropdown-small'
                                        defaultValue=''
                                      >
                                        <option value=''>On Review</option>
                                        {/* <option value='Close'>Close</option> */}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </span>
                    </div>

                    <div className='card mt-3 custom-sidebar-summary border-none text-left pb-0 mb-3 rounded-lg'>
                      <div className='card-header ch-border-white'>
                        <h6 className='pt-2 pl-2'>
                          <b>About </b>
                        </h6>
                      </div>
                      <div className='card-body'>
                        <div>
                          <ul
                            type='1'
                            className='pl-2 normal-font font-weight-bold'
                            style={{ listStyle: 'none' }}
                          >
                            <li className='pb-3'>
                              <div>
                                <span className='font-weight-normal'>
                                  Current Work
                                </span>
                                <br />
                                <b className='pt-3'>{applicanList[selected].active_work_experience.company}</b>
                              </div>
                            </li>
                            <li className='pb-3'>
                              <div>
                                <span className='font-weight-normal'>
                                  Current Position
                                </span>
                                <br />
                                <b className='pt-3'>{applicanList[selected].active_work_experience.job_position}</b>
                              </div>
                            </li>
                            <li className='pb-3'>
                              <div>
                                <span className='font-weight-normal'>
                                  Education
                                </span>
                                <br />
                                <b className='pt-3'>
                                  {user_complete?.user_has_educational_background.length ? user_complete?.user_has_educational_background[0].title +', '+user_complete.user_has_educational_background[0].school  : '-'}
                                  </b>
                              </div>
                            </li>
                            <li className='pb-3'>
                              <div>
                                <span className='font-weight-normal'>Lives In</span>
                                <br />
                                <b className='pt-3'>{user_complete?.user_detail?.city_detail?.name}, Indonesia</b>
                              </div>
                            </li>
                            <li className=''>
                              <div>
                                <span className='font-weight-normal'>
                                  Marital Status
                                </span>
                                <br />
                                <b className='pt-3 normal-font'>
                                {user_complete?.user_detail?.marital_status==="2" ? 'Married' : 'Single'}
                                </b>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className='card bg-white border-none text-left pb-0 mb-3 rounded-lg'>
                      <div className='card-body'>
                        <h6>
                          <b>Job & Skill Interest</b>
                        </h6>
                        <hr />
                        <div className='form-group'>
                          <label className='pl-3 normal-font font-weight-medium'>
                            Job Role
                          </label>
                          <div className='container-items'>
                            <div className='d-flex flex-wrap p-3'>
                              {user_complete?.user_has_job_role.map((item, i) => {
                                  return <div className="btn btn-primary rounded-pill w-160px mr-3 mb-3" key={i}>{item.detail.job_role}</div>
                                })}
                              </div>
                          </div>
                        </div>
                        <div className='form-group'>
                          <label className='pl-3 normal-font font-weight-medium'>
                            Industry Category
                          </label>
                          <div className='container-items'>
                            <div className='d-flex flex-wrap p-3'>
                              {user_complete?.user_has_industry_category.map((item, i) => {
                                  return <div className="btn btn-primary rounded-pill w-160px mr-3 mb-3" key={i}>{item.detail.industry_category}</div>
                                })}
                              </div>
                          </div>
                        </div>
                        <div className='form-group'>
                          <label className='pl-3 normal-font font-weight-medium'>
                            Skill Interest
                          </label>
                          <div className='container-items'>
                            <div className='d-flex flex-wrap p-3'>
                              {user_complete?.user_has_skill_interest.map((item, i) => {
                                  return <div className="btn btn-primary rounded-pill w-160px mr-3 mb-3" key={i}>{item.detail.skill_interest}</div>
                                })}
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='card bg-white border-none text-left pb-0 rounded-lg'>
                      <div className='card-body'>
                        <h6>
                          <b>Background</b>
                        </h6>
                        <hr />
                        <span className='font-weight-medium'>Summary</span>
                        <br />
                        <div className='normal-font pt-2'>
                          {user_complete?.user_detail?.about_you ?? '-'}
                        </div>
                        <hr />
                        <span className='font-weight-medium'>Experience</span>
                        <br />
                        {
                    (user_complete?.user_has_work_experience.length ?? 0) === 0
                      ? '-'
                      : (
                        <ul className='timeline'>
                          {
                            user_complete?.user_has_work_experience.map((item, i) => {
                              const jump = user_complete?.user_has_work_experience[i-1];
                              const runNo = user_complete?.user_has_work_experience.length-1;
                              return (
                                <li key={`experience-${i}`}>
                                  <div>
                                    <div>
                                      <p className='p-0 m-0'>
                                        {
                                          item.end_date === '' ? (<>
                                            {/* <span className='d-block fz-10px'>{moment(new Date()).format('MMM')}</span> */}
                                            <span className='d-block fz-12px font-weight-medium'>{moment(new Date()).format('YYYY')}</span>
                                                                  </>)
                                            : (<>
                                            {/* <span className='d-block fz-10px'>{moment(item.end_date).format('MMM')}</span> */}
                                            <span className='d-block fz-12px font-weight-medium'>{runNo === i && i>0 ? moment(new Date(item.start_date)).format('YYYY') : (i===0?'NOW':moment(new Date()).format('YYYY'))}</span>
                                             </>)
                                        }
                                      </p>
                                    </div>
                                    <div className='details-job'>
                                      <div>
                                        <img src={Company} className='company-img-profile' alt='icon' />
                                      </div>
                                      <p className='mb-0 normal-font'>
                                        <span className='d-block font-weight-medium'>{item.job_position} </span>
                                        <span className='d-block company-address font-weight-medium'>{item.company}</span>

                                        <span className='d-block text-deactive'>{moment(item.start_date).format('MMM YYYY')} - {item.currently_work_here === 1 ? 'Present' : moment(item.end_date).format('MMM YYYY')} 
                                        {moment.duration(moment(item.end_date).diff(moment(item.start_date))).format('y [years] M [months]',{ trim: "small final" }) && "("+moment.duration(moment(item.end_date).diff(moment(item.start_date))).format('y [years] M [months]',{ trim: "small final" }).replace('0 years ','')+")"}</span>
                                        {/* <span className='d-block text-deactive'>{moment.duration(moment(item.end_date).diff(moment(item.start_date))).format('y [y] M [m]')}</span> */}
                                        <span>{item.job_description}</span>
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              )
                            })
                          }
                          <li key='first'>
                            <div>
                              <div>
                                <p className='p-0 m-0'>
                                  <span className='d-block fz-12px font-weight-medium'>{moment(user_complete?.user_has_work_experience[user_complete?.user_has_work_experience.length - 1].start_date).format('YYYY')}</span>
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                        )
                  }
                  <hr />
                  <span className='font-weight-medium'>Education</span>
                  <br />
                  {
                    (user_complete?.user_has_educational_background.length ?? 0) === 0
                      ? '-'
                      : (
                        user_complete?.user_has_educational_background.map((item, i) => {
                            return (
                              <div key={`education-${i}`} className='d-flex align-items-center'>
                                <div className='p-2'>
                                  <img src={Campus} className='avatar-sm rounded-circle' alt='icon' />
                                </div>
                                <div className='p-2 normal-font text-left'>
                                  <span className='font-weight-medium'>{item.school}</span>
                                  <br />
                                  <span>{item.title}</span>
                                  <br />
                                  <span className='text-deactive'>{moment(item.start_date).format('MMM YYYY')} - {item.currently_studied_here ? 'Present' : moment(item.end_date).format('MMM YYYY')}</span>
                                </div>
                              </div>
                            )
                          })
                        )
                  }
                      </div>
                    </div>
                  </div>
                </div>
              </div></>
            : null}
        </div>
        {/* <JobListingModal
          show={this.state.modalShow}
          desc='You cannot apply to this job vacancy before completing your CV'
          titlebutton={['Complete a CV Now']}
          onHide={() => this.setState({ modalShow: false })}
          modalCallback={this.handleModalCallback}
        /> */}
        <ShareModal
          jobDetail={detailData}
          show={this.state.modalShare}
          onHide={() => this.setState({ modalShare: false })}
        />
      </HomeLanding>
    )
  }
}

// export default withRouter(JobsApplicant);

const mapStateToProps = state => ({
  thisUser: state.thisUser,
  auth: state.auth,
  error: state.error,
  invalid: state.invalid,
  response: state.response
})

const mapStateToDispatch = {
  getJobAplicantLite,
  getJobAplicantDetail,
  jobStatus,
  getJobDetails,
  companyDashboard,
  getJobsPosting
}

export default withRouter(connect(mapStateToProps, mapStateToDispatch)(JobsApplicant))
