import React, { Component } from 'react'
import NavBar from './NavBar'

class HomeLanding extends Component {
  constructor (props) {
    super(props)
    this.state = {
      search: this.props.search ?? true,
      isRecruiter: this.props.isRecruiter ?? false
    }
  }

  render () {
    return (
      <div className='bg-home'>
        <NavBar 
          search={this.state.search} 
          onEnterSearch={this.props.onEnterSearch}
          onClickSearch={this.props.onClickSearch}
          isRecruiter={this.state.isRecruiter} />
        <div className='container' style={{ marginTop: '69px' }}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default HomeLanding
