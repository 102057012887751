export const RULES_JOB_CREATE = {
  job_role: 'required|numeric',
  job_type: 'required|numeric',
  job_level: 'required|numeric',
  province: 'required|numeric',
  city: 'required|numeric',
  title: 'required',
  description: 'required',
  key_responsibilitis: 'required',
  requirements: 'required',
  sallary_min: 'required|numeric',
  sallary_max: 'required|numeric',
  benefit: 'required',
  quiz_success_threshold: 'present',
  'quiz.*.question': 'present',
  'quiz.*.answer': 'present',
  quiz: 'present|array',
  'quiz.*.option': 'present|array'
}

export const RULES_EDIT_COMPANY = {
  company_name: 'required',
  province: 'required|numeric',
  city: 'required|numeric',
  about_company: 'required',
  company_address: 'required',
  industry_category: 'required|numeric',
  number_of_employee: 'required|numeric',
  website_address: 'required',
  benefit: 'required'
}