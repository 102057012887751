import { Link, withRouter } from 'react-router-dom'
import React, { Component } from 'react'
import User from '../../images/user-1.svg'
import Carousel from 'react-multi-carousel'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import 'react-multi-carousel/lib/styles.css'
import InfiniteScroll from 'react-infinite-scroll-component'
import HomeLanding from '../../components/layouts/HomeLanding'
import BctnLanding from '../../components/layouts/BctnLanding'
import { ReactComponent as IcStar } from '../../images/star.svg'
import { ReactComponent as IcClock } from '../../images/clock.svg'
import { ReactComponent as IcVideo } from '../../images/video.svg'
import { ReactComponent as IcCase } from '../../images/suitcase.svg'
import { ReactComponent as IcCamera } from '../../images/camera.svg'
import { ReactComponent as IcCompany } from '../../images/company.svg'
import { ReactComponent as IcApp } from '../../images/applications.svg'
import { ReactComponent as IcChevronRight } from '../../images/chevron_right.svg'
import { ReactComponent as IcAddRectangle } from '../../images/add.svg'
import DashboardModal from '../../components/modals/DashboardModal'
import { getJobs, getSuggestedCompany, getCurrent } from '../../actions/dashboard'
import { applyJob } from '../../actions'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash'
import { APPLY_JOB } from '../../actions/responseTypes'
import { alertErrorValidation } from '../../utils'

class Bctn extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [],
      itemSuggested: [],
      modalShow: false,
      page: 1,
      perPage: 10,
      pageSuggested: 1,
      perPageSuggested: 10,
      hasMore: false,
      search: '',
      isLoading: false,
      currentPosition: '',
    }
  }

  componentDidMount = async () => {
    // redirect to profile for release 31-08-2021
    // this.props.history.push('/profile')
    this.setState({isLoading: true})

    const { page, perPage, pageSuggested, perPageSuggested } = this.state

    // await this.props.getSuggestedCompany(pageSuggested, perPageSuggested)
    // await this.props.getJobs(page, perPage, '')
    // await this.props.getCurrent()
    // const res = await this.props.responseDashboard
    // this.setState({
    //   items: res?.jobs ?? [],
    //   hasMore: true,
    //   currentPosition: res?.current?.current_position,
    //   itemSuggested: res?.suggestedCompany ?? [],
    // })
    this.setState({isLoading: false})

  }

  // componentDidUpdate = (prevProps) => {
  //   if (prevProps.error !== this.props.error && this.props.error) {
  //     alertErrorValidation(this.props.error)
  //   }
  //   if (prevProps.response !== this.props.response && this.props.response) {
  //     switch (this.props.response.type) {
  //       case APPLY_JOB:
  //         toast.success(() => <div>Pendaftaran berhasil!</div>, {
  //           position: toast.POSITION.TOP_CENTER
  //         })
  //         break
  //       default:
  //         break
  //     }

  //   }
  // }

  render() {
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2,
        slidesToSlide: 1, // optional, default to 1.
        partialVisibilityGutter: 40,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2, // optional, default to 1.
        partialVisibilityGutter: 30,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
        partialVisibilityGutter: 20,
      },
    }

    return (
      <BctnLanding >
        <iframe className="border-0 w-100 min-vh-100" src="https://hacklab.rocks/bctn/event/"></iframe>
      </BctnLanding>
    )
  }
}

Bctn.propTypes = {
  auth: PropTypes.object.isRequired,
  thisUser: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  auth: state.auth,
  thisUser: state.thisUser,
  response: state.response,
  responseDashboard: state.responseDashboard,
  suggestedCompany: state.suggestedCompany,
})

const mapPropsToDispatch = {
  getJobs,
  getSuggestedCompany,
  applyJob,
  getCurrent,
}

export default withRouter(connect(mapStateToProps, mapPropsToDispatch)(Bctn))
