import HomePageRecruiter from '../recruiter-pages/home/Home'

import EditCompanyProfilePage from '../recruiter-pages/profile/EditProfile'
import RecruiterProfilePage from '../recruiter-pages/profile/Profile'

import CreateJobsPage from '../recruiter-pages/jobs/CreateJobs'
import EditJobsPage from '../recruiter-pages/jobs/EditJobs'
import EditJobsDataPage from '../recruiter-pages/jobs/EditJobsData'
import RecruiterJobPage from '../recruiter-pages/jobs/JobDetails'
import RecruiterJobListPage from '../recruiter-pages/jobs/JobList'
import RecruiterJobApplicant from '../recruiter-pages/jobs/JobApplicant'

import RecruitmenProgress from '../recruiter-pages/recruitment/RecruitmentProgress'

import RecruiterRegisterPage from '../recruiter-pages/auth/RecruiterRegister'

import RecruiterSettingsPage from '../recruiter-pages/settings/RecruiterSettings'
import RecruiterAdminSettingsPage from '../recruiter-pages/settings/RecruiterAdminSettings'
import RecruiterReportsPage from '../recruiter-pages/settings/RecruiterReports'
import { RECRUITER } from '../constants'

export const recruiterRoutes = [
  {
    exact: true,
    path: '/create-company',
    component: RecruiterRegisterPage
  },
  {
    exact: true,
    // role: RECRUITER,
    path: '/my-company',
    // component: RecruiterJobListPage,
    component: HomePageRecruiter,
  },
  {
    exact: true,
    role: RECRUITER,
    path: '/recruiter/profile/edit',
    component: EditCompanyProfilePage
  },
  {
    exact: true,
    role: RECRUITER,
    path: '/recruiter/profile',
    component: RecruiterProfilePage
  },
  {
    exact: true,
    role: RECRUITER,
    path: '/recruiter/jobs/applicant/:id',
    component: RecruiterJobApplicant
  },
  {
    exact: true,
    role: RECRUITER,
    path: '/recruiter/jobs/progress',
    component: RecruitmenProgress
  },
  {
    exact: true,
    role: RECRUITER,
    path: '/recruiter/jobs/:id',
    component: RecruiterJobPage
  },
  {
    exact: true,
    role: RECRUITER,
    path: '/recruiter/jobs',
    component: RecruiterJobListPage
  },
  {
    exact: true,
    role: RECRUITER,
    path: '/jobs/create',
    component: CreateJobsPage
  },
  {
    exact: true,
    role: RECRUITER,
    path: '/jobs/edit/:id',
    component: EditJobsDataPage
  },
  {
    exact: true,
    role: RECRUITER,
    path: '/recruiter/settings',
    component: RecruiterSettingsPage
  },
  {
    exact: true,
    role: RECRUITER,
    path: '/recruiter/reports',
    component: RecruiterReportsPage
  },
  {
    exact: true,
    role: RECRUITER,
    path: '/recruiter/admin-settings',
    component: RecruiterAdminSettingsPage
  }
]
