import React, {Component} from 'react';
import {Modal} from 'react-bootstrap'
import {Link} from "react-router-dom";
import IcSallyDance from '../../images/saly-dance.svg'

class CreateJobModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            background: this.props.background ?? "bg-recommendation",
            title: this.props.title ?? "You’re Rocks!",
            desc: this.props.desc ?? "",
            titleButton: this.props.titlebutton ?? "Go to Dashboard",
            hrefButton: this.props.hrefbutton ?? "/"
        }
    }

    render() {
        return (
            <Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            className={"text-white medium-font"}
            contentClassName={"bg-transparent no-border"}
            centered
            >
                <Modal.Body className={`${this.state.background} rounded-lg`}>
                    <div className="d-flex align-items-center" style={{height:"200px"}}>
                        <div>
                            <img src={IcSallyDance} className="d-lg-block d-none" alt="sally"/>
                        </div>
                        <div className="p-2 flex-grow-1">
                            <h4 className="font-weight-medium">{this.state.title}</h4>
                            <p className="medium-font font-weight-medium">
                                {this.state.desc}
                            </p>
                            <Link to={`${this.state.hrefButton}`} className="btn btn-transparent-white w-150px normal-font font-weight-medium rounded-pill">{this.state.titleButton}</Link>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

export default CreateJobModal
