import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const alertError = (html = <>Terjadi kesalahan, mohon coba lagi.</>) => {
  toast.error(() => html, {
    position: toast.POSITION.TOP_CENTER
  })
}

export const alertSuccess = (html = <>Data saved successfully!</>) => {
  toast.success(() => html, {
    position: toast.POSITION.TOP_CENTER
  })
}

export const alertErrorValidation = (obj = {}) => {
  if ('details' in obj) {
    Object.keys(obj.details).forEach((item, i) => {
      obj.details[item].forEach((msg) => {
        toast.error(msg, {
          position: toast.POSITION.TOP_CENTER
        })
      })
    })
  } else {
    const isMsg = 'message' in obj
    toast.error(isMsg ? obj.message : 'Terjadi kesalahan, mohon coba lagi', {
      position: toast.POSITION.TOP_CENTER
    })
  }
}

export const captionErrorValidation = (obj = {}, rule) => {
  let validation = {}
  if ('details' in obj) {
    if (rule) {
      Object.keys(obj.details).forEach((key) => {
        const arrKey = key.split('.')
        if (key in rule && arrKey.length === 1) {
          validation[key] = obj.details[key]
        }
        if (arrKey.length > 1) {
          const regex = `^(${arrKey[0]}.).*.(${arrKey[arrKey.length - 1]})$`
          Object.keys(rule).forEach((ruleKey) => {
            if (new RegExp(regex).test(ruleKey)) {
              validation[key] = obj.details[key]
            }
          })
        }
      })
    } else {
      validation = obj.details
    }
  }

  return validation
}
