import React, { Component } from 'react'
import Logo from '../../images/hackalab-logo white 1.svg'
import Footer from '../../components/layouts/Footer'

class Landing extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLogoHidden: this.props.isLogoHidden ?? false,
      isBgPersonHidden: this.props.isBgPersonHidden ?? false,
      top: this.props.top,
      isRecruiter: this.props.isRecruiter ?? false
    }
  }

  render () {
    const {
      isLogoHidden = false,
      isBgPersonHidden = false,
      top = null,
      isRecruiter = false
    } = this.state

    return (
      <div>
        <div>
          { isBgPersonHidden ? null : (<div className={`${isRecruiter ? 'bg-jump' : 'bg-person'} d-none d-md-block`} />) }
          <div className={`container-fluid p-0 text-white ${isRecruiter ? 'bg-recruiter-landing' : 'bg-landing'}`} style={{ height: '50vh' }}>
            <div className='container py-2 position-relative full-height'>
              <div className='d-flex align-items-start flex-column full-height'>
                {
                    isLogoHidden ? null
                      : (
                        <div className='mb-auto p-2'>
                          <img src={Logo} className='App-logo' alt='hacklab' />
                        </div>
                        )
                }
                {top}
              </div>
            </div>
          </div>
          <div className='container py-2 position-relative' style={{ minHeight: '40vh' }}>
            {this.props.children}
            <Footer />
          </div>
        </div>
      </div>
    )
  }
}

export default Landing
